import { TeacherAssessmentCard } from "../components";

export const Modules = ({ modules = [], onSelect, onEdit }) => {
  return (
    <div className="grid lg:grid-cols-3 grid-rows-3 gap-3 w-full mb-20 pt-3 pb-20 overflow-auto scrollbar-hide lg:h-98 lg:pr-0 pr-5 lg:pb-0">
      {modules.map?.((item) => (
        <TeacherAssessmentCard
          {...{
            assessment: item,
            key: item?.id,
            viewSubmissionStatus: (id, assessment) =>
              onSelect?.(id, assessment),
            onEdit: (id, assessment) => onEdit?.(id, assessment),
          }}
        />
      ))}
    </div>
  );
};
