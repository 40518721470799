import React from "react";
import { GradePDF, Modal, Typography } from "../../../common/sharedComponent";
import { DocumentTextIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import "./teachernew.css";
import { useState, useEffect } from "react";
import View from "./viewexam";
import { ViewPdf } from "../../assignment/ViewPdf";
import {
  Action,
  Button,
  CardPlaceholderLoader,
  Search,
  EmptyContent,
  Table,
  Tabs,
} from "../../../common/sharedComponent";
import "./Teacher";
import { useAllSubExaminationQuery } from "../api/index";
import { useSelector } from "react-redux";
import { annotatedExam } from "../../../common/moduleSlice";

const Submitted = ({ id, back, exam, task, backOff }) => {
  const [viewExam, setViewExam] = useState(false);
  const [assessmentStatus, setAssessmentStatus] = useState(false);
  const [goBack, setGoBack] = useState(true);
  const [examinationView, setExaminationView] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [selectedExam, setSelectedExam] = useState(null);
  const [students, setStudents] = useState([]);
  const [annotateExam, setAnnotateExam] = useState(null);
  const [gradeExam, setGradeExam] = useState(null);
  const [viewAnnotatedExam, setViewAnnotatedExam] = useState(false);
  const [gradeStatus, setGradStatus] = useState(false);
  const isAnnotatedPdf = useSelector(annotatedExam);
  const { submitted, notSubmitted, mark, isLoading, refetch } =
    useAllSubExaminationQuery(
      { examination_id: exam?.id },
      {
        selectFromResult: ({ data, isLoading }) => ({
          submitted: data?.submitted_examinations ?? [],
          notSubmitted: data?.not_submitted ?? [],
          mark: data?.marked_examinations ?? [],
          isLoading,
        }),
      }
    );

  const downloadAnnotated = (data) => {
    window.open(data.file_url, "_blank");
  };

  const submittedCol = [
    {
      Header: "Student Name",
      accessor: "student_name",
    },
    {
      Header: "Student ID",
      accessor: "student_id",
    },
    {
      Header: "Action",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex space-x-3">
            <>
              <Button
                variant=""
                sizes="fullWidth"
                className="text-red border border-red rounded-lg   bg-white"

                shadow="none"
                capitalize
                onClick={() => {
                  setViewExam(true);
                  setViewAnnotatedExam(false);
                  setAnnotateExam(row.original); //original exam data
                }}
              >
                View Solution / Add Annotation
              </Button>
              <Button

                variant=""
                sizes="fullWidth"
                className="text-red border border-red rounded-lg bg-white "

                shadow="none"
                capitalize
                onClick={() => {
                  setGradStatus(true);
                  setGradeExam(row.original);
                }}
              >
                Add Score
              </Button>
              <Button

                variant=""
                sizes="fullWidth"
                className="text-red border border-red rounded-lg   bg-white hidden"

                shadow="none"
                capitalize
                onClick={() => {
                  setViewExam(true);
                  setViewAnnotatedExam(true);
                  setAnnotateExam(row.original); //original exam data
                }}
              >
                View Annotation
              </Button>
            </>

            {/* view student cbt assessment */}
          </div>
        );
      },
    },
  ];
  const notSubmittedCol = [
    {
      Header: "Student Name",
      accessor: "name",
    },
    {
      Header: "Student ID",
      accessor: "student_id",
    },
  ];

  const markedCol = [
    {
      Header: "Student Name",
      accessor: "student_name",
    },
    {
      Header: "Student ID",
      accessor: "student_id",
    },
    {
      Header: "score ID",
      accessor: "student_score",
    },
    {
      Header: "Action",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex space-x-3">
            <>
              <Button
                variant=""
                sizes="fullWidth"
                className="text-red border border-red rounded-lg   bg-white"
                shadow="none"
                capitalize
                onClick={() => {
                  setViewExam(true);
                  setViewAnnotatedExam(true);
                  setAnnotateExam(row.original); //original exam data
                }}
              >
                View Annotation
              </Button>
              <Button
                variant=""
                sizes="fullWidth"
                className="text-red border border-red rounded-lg   bg-white"
                shadow="none"
                capitalize
                onClick={() => {
                  downloadAnnotated(row?.original);
                }}
              >
                Download Annotation
              </Button>
            </>

            {/* view student cbt assessment */}
          </div>
        );
      },
    },
  ];

  const [colums, setColumn] = useState(submittedCol);

  // const initialState = {
  //   hiddenColumns: ["file_url", "student_uuid"],
  // };

  const status = [
    { id: 1, only_name: "Submitted" },
    { id: 2, only_name: "Not Submitted" },
  ];

  const submittedList = submitted.map((item) => {
    return {
      student_name: item?.student_name,
      file_url: item?.file_url,
      student_id: item?.student_id,
      student_uuid: item?.student_uuid,
      id: item?.id,
      total: item?.marks,
    };
  });

  const markedList = mark.map((item) => {
    return {
      student_name: item?.student.name,
      file_url: item?.file_url,
      student_score: item?.score,
      student_id: item?.resource?.student?.student_id,
      student_uuid: item?.resource?.student?.id,
      id: item?.resource?.id,
      total: item?.marks,
    };
  });

  const selectStatus = (id) => {
    if (id === 1) {
      setStudents(submitted);
      setExaminationView(true);
      setColumn({
        name: submittedCol?.student?.name,
        file_url: submittedCol?.file_url,
        id: submittedCol?.student?.id,
      });
    } else if (id === 2) {
      setStudents(notSubmitted);
      setExaminationView(true);
      setColumn(notSubmittedCol);
    } else if (id === 3) {
      if (id === 1) {
        setStudents(markedCol);
        setExaminationView(true);
        setColumn({
          name: mark?.student?.name,
          file_url: mark?.file_url,
          id: mark?.student?.id,
        });
      }
    }
    setAssessmentStatus(false);
  };

  return (
    <>
      {!viewExam && (
        <div>
          <div
            onClick={back}
            className="flex gap-x-2 mb-3 items-center cursor-pointer"
          >
            {" "}
            <ArrowLeftIcon className="h-5 w-5 " />{" "}
            <Typography {...{ capitalize: true }}>Back</Typography>{" "}
          </div>
          <div>
            <Typography className="exam-title mb-4">
              Examination - {exam?.module?.name}
            </Typography>
          </div>
          <div className="flex justify-between items-center mb-5">
            <Typography className=" card1 p-2 bg-white">
              Number of Students Offering this course -{" "}
              {submitted?.length + notSubmitted?.length + mark?.length}
            </Typography>

            <div>
              <Button
                variant={selectedStatus === 1 ? "contained" : `outlined`}
                sizes={"sm"}
                onClick={() => setSelectedStatus(1)}
                className={
                  selectedStatus === 1
                    ? "text-white"
                    : "!text-on-primary-container border-on-primary-container"
                }
              >
                Submitted
              </Button>
              <Button
                variant={selectedStatus === 2 ? "contained" : `outlined`}
                sizes={"sm"}
                onClick={() => setSelectedStatus(2)}
                className={
                  selectedStatus === 2
                    ? "text-white mr-2 ml-2"
                    : "!text-on-primary-container border-on-primary-container mr-2 ml-2"
                }
              >
                Not Submitted
              </Button>
              <Button
                variant={selectedStatus === 3 ? "contained" : `outlined`}
                sizes={"sm"}
                onClick={() => setSelectedStatus(3)}
                className={
                  selectedStatus === 3
                    ? "text-white"
                    : "!text-on-primary-container border-on-primary-container"
                }
              >
                Marked
              </Button>
            </div>
          </div>

          {isAnnotatedPdf && (
            <div className=" w-full bg-red p-3 text-white ">
              Please Add Score for {isAnnotatedPdf?.student_name} to grade
            </div>
          )}

          {mark?.length > 0 && (
            <div className=" w-full bg-red p-3 text-white flex  justify-between">
              <span>Notice:</span>
              <span className="text-red graded-info text-sm">
                {mark.length} Student’s Solution has been graded
              </span>{" "}
            </div>
          )}

          <div>
            <div className="mb-5 hidden">
              <Button
                variant={"outlined"}
                sizes={"sm"}
                onClick={() => setAssessmentStatus(!assessmentStatus)}
                className="!text-on-primary-container border-on-primary-container"
              >
                {selectedStatus === 1 ? "Submitted" : "Not Submitted"}
              </Button>
            </div>
            {assessmentStatus && (
              <div className="absolute bg-white mt-9 p-1.5 rounded-md w-32 shadow ring-1 ring-black ring-opacity-5">
                {status.map(({ id, only_name }) => (
                  <Typography
                    className="py-2  border-b cursor-pointer text-center"
                    key={id}
                    onClick={() => setSelectedStatus(id)}
                  >
                    {only_name}
                  </Typography>
                ))}
              </div>
            )}
            <div>
              <Table
                {...{
                  columns:
                    selectedStatus === 1
                      ? submittedCol
                      : selectedStatus === 2
                      ? notSubmittedCol
                      : markedCol,
                  data:
                    selectedStatus === 1
                      ? submittedList
                      : selectedStatus === 2
                      ? notSubmitted
                      : markedList,
                  isLoading: isLoading,
                }}
              />

              {/* {isLoading && <CardPlaceholderLoader />} */}
            </div>
          </div>
        </div>
      )}
      {viewExam && (
        <View
          backOff={() => setViewExam(false)}
          annotate={annotateExam}
          view={viewAnnotatedExam}
        />
      )}
      <Modal
        {...{ open: gradeStatus, onClose: () => setGradStatus(!gradeStatus) }}
      >
        <GradePDF
          annotatedPdf={gradeExam}
          refetch={() => refetch()}
          close={() => setGradStatus(!gradeStatus)}
        />
      </Modal>
    </>
  );
};

export default Submitted;
