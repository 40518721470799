import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery, AxiosBaseQuery } from "./baseQuery";

let baseUploadUrl = "/uploads";

export const uploadApis = createApi({
  reducerPath: "uploads",
  baseQuery: AxiosBaseQuery(),
  // baseQuery: baseQuery((headers) => {
  //   // headers.set("content-type", "multipart/form-data");
  //   headers.set("accept", "application/json");
  //   return headers;
  // }),
  endpoints: (builder) => ({
    postFileUpload: builder.mutation({
      query: ({ body }) => ({
        url: baseUploadUrl,
        method: "POST",
        body,
      }),
      invalidatesTags: [],
    }),
    uploadPredefinedUrl: builder.mutation({
      query: ({ body }) => ({
        url: `${baseUploadUrl}/presigned-url`,
        method: "POST",
        body,
        // removeHeaders: true
      }),
    }),
    uploadLargeFile: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { usePostFileUploadMutation, useUploadPredefinedUrlMutation } =
  uploadApis;

export const predefinedApi = createApi({
  reducerPath: "presigned",
  // baseQuery: AxiosBaseQuery(),
  baseQuery: baseQuery((headers) => {
    headers.delete("authorization");
    return headers;
  }),
  endpoints: (builder) => ({
    uploadLargeFile: builder.mutation({
      query: ({ url, body }) => {
        return {
          url,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const { useUploadLargeFileMutation } = predefinedApi;
