export interface userPanelPayload {
  body: string;
  chatting_with: string;
  id: string;
  owner: boolean;
  receiver: string;
  receiver_object: {
    id: string;
    avatar: string;
  };
  isNewChatMessage: boolean | null | undefined;
  receiver_type: string;
  reference: string;
  sender: string;
  sender_id: string;
  sender_object: { id: string; avatar: string };
}

export interface moduleTypes {
  date_created: string;
  default_semester: boolean;
  enrolled: boolean;
  id: string;
  logo: string;
  modified_name: string;
  name: string;
  only_name: string;
  qualification_collection: [];
  qualifications: string;
  semester: string | null;
  slug: string;
  standard: {};
}

export interface messagesPayload {
  body: string;
  chatting_with: string;
  id: string;
  owner: boolean;
  receiver: string;
  receiver_object: {
    avatar: string;
    id: string;
  };
  receiver_type: string;
  reference: string;
  sender: string;
  sender_id: string;
  sender_object: { id: string; avatar: string };
}

export interface newChatSelection {
  avatar: null;
  avatar_alternative: string;
  id: string;
  isNewChatMessage: boolean;
  name: string;
  student_id: string;
}

/**
 * interface for useReducer message state
 */
export interface messageState {
  module: moduleTypes | null;
  message: {
    isNew: boolean;
    reset: boolean;
    messages: [];
    input: string | null;
  };
  chat: {
    selected: userPanelPayload | null;
  };
  recipient: {
    id: string;
    only_name: string;
  };
  newContacts: userPanelPayload[];
}

/**
 * enum of useReducer message actions
 */
export enum MessageActionKind {
  ADD_CONTACTS = "ADD_CONTACTS",
  REMOVE_CONTACT = "REMOVE_CONTACT",
  UPDATE_CONTACT = "UPDATE_CONTACT",
  CHAT_SELECTION = "CHAT_SELECTION",
  ADD_INPUT_MESSAGE = "ADD_INPUT_MESSAGE",
  CLEAR_INPUT_MESSAGE = 'CLEAR_INPUT_MESSAGE',
  RESET_MESSAGES = "RESET_MESSAGE",
  CHANGE_RECIPIENT_TYPE = "CHANGE_RECIPIENT_TYPE",
  CHANGE_MODULE = "CHANGE_MODULE",
  ADD_MESSAGES = 'ADD_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
}

/**
 * enum of useReducer message payloads
 */
enum MessagePayloadKind {
  number,
  any,
}

/**
 * interface of useReducer message action
 */
export interface messageAction {
  type: MessageActionKind;
  payload: any;
}


export interface recipientRequestTypes {
  module_id: string,
  receiver: string,
  user_role: string
}