import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { Typography } from ".";
import { Transition } from "@headlessui/react";
import { useOutsideDetecter } from "../../hooks";

const data = [
  {
    id: "1",
    name: "To Be Submitted",
  },
  {
    id: "2",
    name: "Submitted",
  },
  {
    id: "3",
    name: "Results Announced",
  },
];

export const SelectInput = ({
  onchange,
  isLoading,
  options,
  defaultValue,
  containerClassName,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue ?? "none");

  /* Creating a ref reference */
  const wrapperRef = useRef(null);

  /* A custom hook that detects if the user clicks outside the dropdown. */
  useOutsideDetecter(wrapperRef, () => setShowMore(false));

  // Switch between options props or use default data array available.
  let selectOptions = options ?? data;

  useEffect(() => {
    let abortController = new AbortController();
    if (selectOptions?.length && inputValue === "none") {
      handleSelect(selectOptions[0], true);
    }
    return () => {
      abortController.abort();
    };
  }, [selectOptions, inputValue]);

  /**
   * `handleSelect` is a function that takes in a value and an initialize boolean. It calls the onchange
   * function with the value, sets the inputValue to the value's name or the value, and if initialize is
   * false, it sets the showMore boolean to the opposite of the current showMore boolean
   * @param value - The value of the selected item.
   * @param initialize - boolean - whether or not to initialize the component
   */
  const handleSelect = (value, initialize) => {
    onchange?.(value);
    setInputValue?.(value.name ?? value);
    !initialize && setShowMore?.(!showMore);
  };

  return (
    <div ref={wrapperRef} className={`relative ${containerClassName ?? ""}`}>
      <div
        onClick={() => setShowMore(!showMore)}
        className="h-10 flex bg-[#E5F0EF] rounded items-center cursor-pointer"
      >
        <input
          value={inputValue}
          name="select"
          id="select"
          disabled
          className="px-4 appearance-none outline-none text-[#006B5D] w-full text-body2"
          checked
        />

        <label
          for="show_more"
          className="cursor-pointer outline-none focus:outline-none bg-[#E5F0EF] h-full transition-all grid place-items-center text-[#006B5D] rounded-tr-md rounded-br-md"
        >
          {showMore ? (
            <ChevronDownIcon
              className="h-4 w-4 mx-2"
              onClick={() => setShowMore(!showMore)}
            />
          ) : (
            <ChevronUpIcon
              className="h-4 w-4 mx-2"
              onClick={() => setShowMore(!showMore)}
            />
          )}
        </label>
      </div>

      <input
        type="checkbox"
        name="show_more"
        id="show_more"
        className="hidden peer"
        checked
      />
      <Transition
        as={Fragment}
        show={showMore}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute rounded shadow bg-[#E5F0EF] overflow-auto hidden peer-checked:flex flex-col w-full mt-1 border border-gray-200 z-50 h-40">
          {!isLoading &&
            selectOptions.map((item) => (
              <div
                key={item?.id ?? item}
                className="cursor-pointer group"
                onClick={() => handleSelect(item)}
              >
                <Typography
                  variant="link"
                  to="#"
                  className="block p-2 text-body2 border-transparent border-l-4 group-hover:border-[#006B5D] group-hover:!text-white group-hover:bg-[#006B5D]/50"
                >
                  {item.name ?? item}
                </Typography>
              </div>
            ))}
          {isLoading && (
            <>
              <div className="my-2 w-full h-48 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="my-2 w-full h-48 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="my-2 w-full h-48 bg-gray-200 animate-pulse rounded-md"></div>
            </>
          )}
        </div>
      </Transition>
    </div>
  );
};
