import {
  BreadCrumb,
  Tabs,
  Typography,
  Table,
} from "../../../common/sharedComponent";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { SingleView } from "../SingleView";
import {
  useStudentClassroomQuery,
  useStudentModuleQuery,
  useGetStudentDashboardQuery,
} from "../api";
import { useLocation } from "react-router-dom";
import {
  getEnrolledModules,
  getRecentLesson,
  getTabModules,
} from "../../../helpers";

const columns = [
  {
    Header: "Lesson",
    accessor: "lesson",
  },
  {
    Header: "Module",
    accessor: "module",
  },
  {
    Header: "Class Date",
    accessor: "class_date",
  },
  {
    Header: "Start Time",
    accessor: "start_time",
  },
  {
    Header: "Duration",
    accessor: "duration",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

const StudentTutorial = () => {
  const [singleView, setSingleView] = useState(false);
  const [activeSubject, setActiveSubject] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const { state } = useLocation();
  const [page, setPage] = useState(1);
  const { classes, classMeta, isLoadingClassroom } = useStudentClassroomQuery(
    page,
    {
      selectFromResult: ({ data, isLoading }) => ({
        classes: data?.data ?? [],
        classMeta: data?.meta,
        isLoadingClassroom: isLoading,
      }),
    }
  );
  const { subjects, isLoading } = useGetStudentDashboardQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      subjects: getEnrolledModules(data),
      isLoading,
    }),
  });

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    let abortController = new AbortController();
    if (state?.module_id) {
      let classroom = classes.find((item) => item.id === state?.module_id);
      setSelectedTutorial(classroom);
      setSingleView(true);
    }
    return () => {
      abortController.abort();
    };
  }, [state, classes]);

  let filtered_modules = classes?.filter((module) =>
    getTabModules(module, activeSubject)
  );

  return (
    <div className="w-full scrollbar-hide">
      <div className=""></div>
      {singleView ? (
        <div
          onClick={() => {
            setSingleView(!singleView);
            // setSelectedAssignment(null);
          }}
          className="flex items-center gap-x-2 mb-3 cursor-pointer"
        >
          <ArrowLeftIcon className="h-5 w-5" />
          <Typography {...{}}>Back</Typography>
        </div>
      ) : (
        <BreadCrumb className="lg:hidden" info={selectedTutorial?.module} />
      )}
      {!singleView && (
        <>
          <div className="lg:flex items-center gap-x-1 lg:mb-8">
            <Typography
              variant="link"
              to="#"
              color="defaultHeader"
              className=" hidden lg:block"
            >
              Audio/Video
            </Typography>
          </div>

          <Tabs
            {...{
              className: "",
              subjects,
              isLoading,
              selectedSubject: (value) => setActiveSubject(value),
            }}
          />

          <div className="w-full pt-3 pb-20 overflow-auto scrollbar-hide h-screen mt-5 pr-5 lg:pr-0">
            <Table
              {...{
                columns,
                data: filtered_modules,
                isLoading: isLoadingClassroom,
                currentPage: page,
                totalPages: classMeta?.total,
                onChangePaginate: handlePageChange,
              }}
            />
          </div>
        </>
      )}
      {singleView && <SingleView {...{ tutorial: selectedTutorial }} />}
    </div>
  );
};

export default StudentTutorial;
