export const QuizActionKind = {
  ADD_QUESTIONS:'ADD_QUESTIONS',
  GET_ANSWRS: 'GET_ANSWERS',
  QUESTION_INDEX: 'QUESTION_INDEX',
  INCREASE_QUESTION_INDEX: 'INCREASE_QUESTION_INDEX',
  DECREASE_QUESTION_INDEX: 'DECREASE_QUESTION_INDEX',
  UPDATE_ANSWER: 'UPDATE_ANSWER',
  SELECTED_OPTION: 'SELECTED_OPTION'
}

export const reducer = (state, action) => {
  switch (action.type) {
    case QuizActionKind.ADD_QUESTIONS:
      return {
        ...state,
        questions: action.payload
      }
    case QuizActionKind.INCREASE_QUESTION_INDEX:
      return {
        ...state,
        questionIndex: state.questionIndex + 1
      }
    case QuizActionKind.DECREASE_QUESTION_INDEX:
      return {
        ...state,
        questionIndex: state.questionIndex - 1
      }
    case QuizActionKind.UPDATE_ANSWER:
      return {
        ...state,
        answers: [...state.answers, action.payload]
      }
    case QuizActionKind.SELECTED_OPTION:
      return {
        ...state,
        selectedOption: action.payload
      }
    default:
      return state
  }
}