import { useState } from "react";
import {
  Action,
  CardPlaceholderLoader,
  EmptyContent,
  Typography,
} from "../../../common/sharedComponent";
import { Upload } from "../../dashboard/components";
import { useGetTeacherLessonByIdQuery } from "../api";
import { Player } from "../component";

const TeacherView = ({ lesson }) => {
  const { data, isLoading } = useGetTeacherLessonByIdQuery({ id: lesson.id });
  const [showUpload, setShowUpload] = useState(false);

  const uploadCourse = () => {
    setShowUpload(!showUpload);
  };
  return (
    <>
      <div className="mb-3 -mt-4">
        <Action noLive uploadAction={uploadCourse}>
          {lesson.module}
        </Action>
      </div>
      <Player module={lesson} />
      <Typography variant={"headlineS"} className="mb-3">
        Course Material
      </Typography>
      {!isLoading && data?.course_materials?.length === 0 && (
        <EmptyContent message={"No other resources for this course"} />
      )}
      {isLoading && <CardPlaceholderLoader />}
      {!isLoading &&
        data?.course_materials?.length > 0 &&
        data?.course_materials.map(
          ({ title, file_url, file_type, id }) =>
            file_type === "file" && (
              <>
                <div key={id}>
                  {title}
                  {/* <div> */}
                  <iframe
                    src={`https://docs.google.com/viewer?url=${file_url}&embedded=true`}
                    frameborder="0"
                    height="500px"
                    title={id}
                    width="100%"
                  ></iframe>
                </div>
                {/* </div> */}
              </>
            )
        )}
      {showUpload && (
        <Upload
          action={uploadCourse}
          taskTitle={["training material"]}
          taskId={2}
          edit={1}
          editTask={lesson}
          open={showUpload}
          module={lesson?.id}
        />
      )}
    </>
  );
};

export default TeacherView;
