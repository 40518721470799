import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Typography } from "../../common/sharedComponent";
import { Pdf } from "./component";
import TeacherView from "./TeacherView";
import DOMPurify from "dompurify";
import { Logo } from "../../assets";

const Preview = ({ preview, back }) => {
  const [goBack, setGoBack] = useState(true);
  const rawHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <>
      {goBack && (
        <>
          <div
            onClick={() => setGoBack(false)}
            className="flex items-center gap-x-2 mb-3 cursor-pointer"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <Typography {...{}}>Back</Typography>
          </div>
          <div className="pb-20 ">
            <Typography variant="subheader1" className="text-center mb-3">
              Preview Examination
            </Typography>
            <Pdf>
              <div className="w-4/5 flex flex-col px-10 py-5 ">
                <div className="flex items-center mb-5">
                  <div className="w-36">
                    <img src={Logo} alt="logo" className="w-full" />
                  </div>
                  <div className="flex-1">
                    <Typography variant={"headline"}>
                      HillCross College
                    </Typography>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-5 mb-3">
                  <div className="flex space-x-3 items-center">
                    <Typography variant={"headlineS"}>Date: </Typography>
                    <Typography>{preview?.date}</Typography>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <Typography variant={"headlineS"}>Time: </Typography>
                    <Typography>{preview?.time}</Typography>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <Typography variant={"headlineS"}>Duration: </Typography>
                    <Typography>{preview?.duration} minutes</Typography>
                  </div>
                </div>
                <div className="flex space-x-3 items-center">
                  <Typography variant={"headlineS"}>Instruction: </Typography>
                  <Typography>{preview?.instruction}</Typography>
                </div>
                <div className="flex items-start flex-col space-y-2 mt-1.5">
                  <Typography variant={'headlineS'}>Questions</Typography>
                  <div
                    dangerouslySetInnerHTML={rawHTML(preview?.examination)}
                  ></div>
                </div>
              </div>
            </Pdf>
          </div>
        </>
      )}
      {!goBack && <TeacherView toEdit={back} value={preview} />}
    </>
  );
};

export default Preview;
