import { useSelector } from "react-redux";
import { useEffect, useReducer, useState } from "react";
import { Typography } from "../../common/sharedComponent";
import { TEACHER_ROLE } from "../../common/variables";
import { currentUser, enrolledModules, role } from "../login/authSlice";
import {
  useGetMessagesQuery,
  usePostReplyMutation,
  useCreateMessageChatMutation,
  useGetPreviousRecipientQuery,
} from "./api";
import { ChatList, MessageList, NewChatDialog } from "./layouts/index.js";
import { useMediaQuery } from "../../hooks";
import { reducer } from "./reducer/index";
import { MessageActionKind } from "./types/index";
import { useToasts } from "react-toast-notifications";

const initialState = {
  module: null,
  message: {
    isNew: false,
    reset: false,
    messages: [],
    input: null,
  },
  chat: {
    selected: null,
  },
  recipient: {
    id: "1",
    only_name: "Student",
  },
  newContacts: [],
};

const Messaging = () => {
  // Selectors
  const userRole = useSelector(role);
  const user = useSelector(currentUser);
  const enrolled_modules = useSelector(enrolledModules);
  const { addToast } = useToasts();

  // useReducer
  const [state, dispatch] = useReducer(reducer, initialState, undefined);

  // States
  const [skip, setSkip] = useState(true);
  const [isNewMessage, setNewMessage] = useState(false);
  const [hasFirstCall, setHasFirstCall] = useState(false);
  const [hasChangedRecipient, setHasChangedRecipient] = useState(false);

  const isMobileView = useMediaQuery("(max-width: 640px)");

  // Api query functions
  const { messages, refetch, isLoadingMessages } = useGetMessagesQuery(
    {
      id: state.chat.selected?.reference,
      userRole: userRole?.toLowerCase(),
    },
    {
      selectFromResult: ({ data, isFetching, currentData }) => ({
        messages: data ? [data?.data, ...data?.replies] : [],
        isLoadingMessages: isFetching,
        currentMessage: currentData
          ? [currentData?.data, ...currentData?.replies]
          : [],
      }),
      skip,
    }
  );

  const [onReply, { isReplying }] = usePostReplyMutation({
    selectFromResult: ({ isLoading }) => ({
      isReplying: isLoading,
    }),
  });

  const [onCreateMessage, { isCreatingLoader }] = useCreateMessageChatMutation({
    selectFromResult: ({ isFetching }) => ({
      isCreatingLoader: isFetching,
    }),
  });

  const { usersList, isLoadingUsersList } = useGetPreviousRecipientQuery(
    { page: 1, user_id: user?.id, role: userRole?.toLowerCase() },
    {
      selectFromResult: ({ data, isFetching }) => ({
        isLoadingUsersList: isFetching,
        usersList: data?.data ?? [],
      }),
    }
  );

  const handleCreateMessage = async () => {
    // This function checks if the comment
    // state is not empty then post the
    // message to the server.
    if (state.message.input === null) return;
    try {
      let result = await onCreateMessage({
        receiver: state.chat.selected?.id,
        receiver_type:
          userRole === TEACHER_ROLE ? "Student" : state.recipient?.only_name,
        message: state.message.input,
        user_role: userRole?.toLowerCase(),
      }).unwrap();
      dispatch({ type: MessageActionKind.CLEAR_INPUT_MESSAGE });
      dispatch({ type: MessageActionKind.CHAT_SELECTION, payload: result.data });
      refetch();
    } catch (error) {
      addToast(error?.error.data?.message, { appearance: "error" });
    }
  };

  const handleMessageReply = async () => {
    // Sends message out to the server but
    // checks if comment state is not empty
    // before dispatching out
    if (state.message.input === null || state.message.input === '') return;
    try {
      let result = await onReply({
        messaging_id: state.chat.selected?.id,
        user_role:
          userRole === TEACHER_ROLE
            ? "student"
            : state.chat.selected?.receiver_type.toLowerCase(),
        body: { body: state.message.input },
      }).unwrap();
      dispatch({ type: MessageActionKind.CLEAR_INPUT_MESSAGE });
      dispatch({
        type: MessageActionKind.UPDATE_MESSAGE,
        payload: result.data,
      });
    } catch (error) {
      addToast(error?.error.data?.message, { appearance: "error" });
    }
  };

  useEffect(() => {
    // This function fetches existing
    // chat members from server.
    let abortController = new AbortController();

    // Update reducer state once 
    if (state?.message?.messages.length !== messages.length && !hasFirstCall) {
      dispatch({ type: MessageActionKind.ADD_MESSAGES, payload: messages });
      setHasFirstCall(true);
    }

    // Update reducer state when called upon
    if (hasChangedRecipient) {
      dispatch({ type: MessageActionKind.ADD_MESSAGES, payload: messages });
      setHasChangedRecipient(false);
    }

    return () => abortController.abort();
  }, [
    messages,
    state?.message?.messages.length,
    hasFirstCall,
    hasChangedRecipient,
  ]);

  const handleSelectedChat = (value) => {
    // handles selected chat account
    dispatch({ type: MessageActionKind.CHAT_SELECTION, payload: value });
    setHasChangedRecipient(() => true);

    if (value.reference) {
      refetch();
    }
  };

  const handleChatSelection = (selectedMember) => {
    // dispatch new chat member payload to the state and also reset
    // messages.
    let isUserExist = usersList.filter(
      (item) =>
        item?.receiver_object.id === selectedMember.id ||
        item?.sender_object.id === selectedMember.id
    );
    if (isUserExist.length) {
      dispatch({
        type: MessageActionKind.CHAT_SELECTION,
        payload: isUserExist[0],
      });
    } else {
      dispatch({
        type: MessageActionKind.CHAT_SELECTION,
        payload: selectedMember,
      });
      dispatch({
        type: MessageActionKind.RESET_MESSAGES,
        payload: !state.message.reset,
      });
    }

    setNewMessage(false);
  };

  useEffect(() => {
    let abortController = new AbortController();
    if (state.chat.selected?.reference) {
      setSkip(false);
      refetch();
    }
    return () => abortController.abort();
  }, [state.chat.selected?.reference, refetch]);

  let recipientData = [
    {
      id: "1",
      only_name: "Student",
    },
    {
      id: "2",
      only_name: "Teacher",
    },
  ];

  return (
    <div className="px-3 pt-4 h-screen select-none">
      <div
        className={`md:flex flex-row justify-between bg-white h-full ${isNewMessage ? "hidden" : ''}`}
      >
        <ChatList
          {...{
            onNewMessageModal: () => setNewMessage(true),
            className: isMobileView && state.chat.selected ? "hidden" : "",
            onSelection: (value) => handleSelectedChat(value),
            selected: state?.chat.selected,
            usersList,
            isLoadingUsersList,
          }}
        />
        <div
          className={`w-full md:px-5 flex flex-col h-[90%] ${
            isMobileView && !state.chat.selected ? "hidden" : ""
          }`}
        >
          {state.chat.selected !== null ? (
            <MessageList
              {...{
                key: "1",
                messages: state.message.messages,
                selectedChat: state.chat.selected,
                isReplying,
                inputValue: state.message.input,
                isLoading: isLoadingMessages,
                isMobileView,
                onClose: () => handleSelectedChat(null),
                onChange: (e) =>
                  dispatch({
                    type: MessageActionKind.ADD_INPUT_MESSAGE,
                    payload: e.target.value,
                  }),
                onClick: state.chat.selected?.isNewChatMessage
                  ? handleCreateMessage
                  : handleMessageReply,
              }}
            />
          ) : (
            <div className="flex items-center justify-center h-full w-full">
              <Typography>Select a member to send message</Typography>
            </div>
          )}
        </div>
      </div>
      <NewChatDialog
        {...{
          dispatch,
          enrolled_modules,
          handleChatSelection,
          user,
          userRole,
          onClose: () => setNewMessage(false),
          open: isNewMessage,
          recipientData,
          state,
        }}
      />
    </div>
  );
};

export default Messaging;
