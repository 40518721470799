import { useState } from "react";
import {
  CardPlaceholderLoader,
  EmptyContent,
  PdfEditor,
  PdfViewer,
} from "../../common/sharedComponent";
import { useListMarkedPdfQuery } from "./api";

export const ViewPdf = ({ type, id, data, student, deleted, mark, task }) => {

 /* A hook that is used to fetch data from the server. */
  const { data: annotations, isLoading } = useListMarkedPdfQuery(
    { type: task, id: student?.id },
    { skip: !mark }
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div>
        {student && !mark && (
          <PdfEditor
            {...{
              url: student?.file_url,
              numPages,
              pageNumber,
              showPageNumber: true,
              onDocumentLoadSuccess,
              meta: {
                fileName: student?.student_name,
                id: student?.student_uuid,
                task: task,
                resource_id: student?.id,
              },
            }}
          />
        )}

        {type && (
          <PdfViewer
            {...{
              url: type?.file_url,
              numPages,
              pageNumber,
              showPageNumber: true,
              onDocumentLoadSuccess,
              meta: {
                fileName: type?.module?.only_name,
                id: type?.id,
              },
            }}
          />
        )}

        {isLoading && mark && <CardPlaceholderLoader />}

        {mark && annotations && (
          <PdfViewer
            {...{
              url: annotations?.data?.file_url,
              numPages,
              pageNumber,
              showPageNumber: true,
              onDocumentLoadSuccess,
              meta: {
                fileName: annotations?.data?.student?.name,
                id: annotations?.data?.id,
              },
            }}
          />
        )}

        {mark && !annotations && (
          <EmptyContent
            message={`${student?.student_name} ${task} have not been graded`}
          />
        )}

      </div>
    </>
  );
};
