import { Avatar, Typography } from "../../../common/sharedComponent";
import { getNemaInitials } from "../../../helpers";

export const MessagePanel = ({ item }) => {
  // let initials =
  return (
    <div
      className={`flex mb-4 ${item?.owner ? "justify-end" : "justify-start"}`}
    >
      {item?.owner && (
        <div className="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white bg-red">
          <Typography {...{ color: "white" }}>{item?.body}</Typography>
        </div>
      )}
      <div className="w-8 h-8 mx-3">
        <Avatar
          {...{
            avatarAlt: item?.owner
              ? getNemaInitials(item?.sender)
              : getNemaInitials(item?.receiver),
          }}
        />
      </div>
      {!item?.owner && (
        <div className="ml-2 py-3 px-4 bg-slate-800 rounded-br-3xl rounded-tr-3xl rounded-tl-xl">
          <Typography {...{ color: "white" }}>{item?.body}</Typography>
        </div>
      )}
    </div>
  );
};

export const MessagePanelLoader = () => {
  return (
    <div className="flex justify-end my-2 gap-x-1 w-full">
      <div class="py-3 px-4 w-44 h-10 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white bg-gray-200 animate-pulse"></div>
      <div className="h-10 w-10 rounded-full bg-gray-200 animate-pulse"></div>
    </div>
  );
};
