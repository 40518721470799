import cn from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const variantsMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subheader1: "h6",
  subheader2: "h6",
  body1: "p",
  body2: "p",
  small: "small",
  link: Link,
  span: "span",
};

const fontSizeVariantMapping = {
  h1: "h1 text-4xl font-bold",
  h2: "text-3xl font-bold",
  h3: "",
  h4: "text-2xl",
  h5: "text-xl",
  h6: "text-lg font-bold",
  subheader1: "text-base font-bold leading-4 tracking-wider",
  subheader2: "text-subheader2 font-semibold leading-4 tracking-wider",
  body1: "lg:text-base  leading-6 tracking-wider font-normal",
  body2: "lg:text-sm text-base leading-4 tracking-normal",
  small: "text-xs leading-4 tracking-wider font-medium",
};

const fontSizeVariantMapping2 = {
  display: "text-display-sm lg:text-display-lg tracking-normal font-bold ",
  headline: "text-headline-sm lg:text-headline-lg font-bold",
  headlineS: "text-headline-small-sm lg:text-headline-small-lg font-bold",
  body: "text-body-lg font-normal",
  body2: "text-body-small-lg font-normal",
  caption: "text-caption-lg font-medium",
  captionS: "text-caption-small-lg font-medium",
};

const colorVariantMapping = {
  defaultHeader: "text-primary-color",
  defaultBody: "text-primary-color",
  white: "text-white",
  red: "text-danger",
  black: "prose-black",
  link: "text-blue-200",
  redStatus: "text-[#6B0000]",
  neutralStatus: "text-[#888888]",
  successStatus: "text-[#006B5D]",
  primaryStatus: "text-[#006B5D]",
};

const Typography = ({
  variant,
  className,
  color,
  children,
  capitalize,
  uppercase,
  lowercase,
  ...props
}) => {
  const Component = variant ? variantsMapping[variant] ?? "p" : "p";
  return (
    <Component
      className={`${cn({
        [fontSizeVariantMapping2[variant]]: variant,
        [colorVariantMapping[color]]: color,
        capitalize: capitalize,
        uppercase: uppercase,
        lowercase: lowercase,
        // 'leading-[1.063rem]': variant === 'body2',
        // 'leading-[o.25px]': variant === 'sunheader2',
        // 'leading-[o.1px]': variant === 'sunheader1'
      })} ${className}`}
      {...props}
    >
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  variant: "body2",
  color: "defaultHeader",
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "display",
    "headline",
    "headlineS",
    "body",
    "body2",
    "caption",
    "captionS",
  ]),
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "defaultHeader",
    "defaultBody",
    "white",
    "red",
    "black",
  ]),
  children: PropTypes.node,
};

export default Typography;
