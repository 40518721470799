import { Route, Routes } from "react-router-dom";
import { MainLayout } from "../common/sharedComponent/layout";
import {
  Dashboard,
  Assignment,
  Tutorial,
  Assessment,
  Examination,
  StudentAssignment,
  Message,
  Notification,
  Lessons,
  ComingSoon
} from "../features";
import Preview from "../features/examination/Preview";

const ProtectedRoutes = () => {
  return (
    <MainLayout>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="assignments/*" element={<Assignment />} />
        <Route path="tutorial" element={<Tutorial />} />
        <Route path="assessments/*" element={<Assessment />} />
        <Route path="examinations" element={<Examination />} />
        <Route path="examinations/preview" element={<Preview />} />
        <Route path="student_assignment" element={<StudentAssignment />} />
        <Route path="inbox" element={<Message />} />
        <Route path="notifications" element={<Notification />} />
        <Route path="lessons" element={<Lessons />} />
        <Route path="coming-soon" element={<ComingSoon />} />
      </Routes>
    </MainLayout>
  );
};

export default ProtectedRoutes;
