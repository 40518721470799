import {
  Typography,
  CardLayout,
  Button,
} from "../../../common/sharedComponent";
import moment from "moment";
import { truncateMiddleString } from "../../../helpers";
import Moment from "react-moment";
import cover from "../../../assets/hillcross-dark-assessment.png";
import UploadIcon from "../../../assets/upArrow.svg";
import DownloadIcon from "../../../assets/downArrow.svg";
import { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";

const Timer = ({ minutes, seconds, hours }) => (
  <div>
    {minutes === 0 && seconds === 0 ? null : (
      <Typography className="!text-[#ED1000] text-[15.3952px] leading-[19px] font-[700] p-2 bg-white mt-2">
        {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    )}
  </div>
);

export const AssessmentCard = ({
  assessment,
  onDownload,
  onUpload,
  onAssessmentView,
  onGradedAssignment,
}) => {
  let submissionArrayDate = assessment?.submission_date?.split?.(" ");
  let startDateTime = `${submissionArrayDate[0]} 09:00:00`;
  let endDateTime = `${submissionArrayDate[0]} 23:59:00`;

  const [duration, setDuration] = useState(null);

  // // test ...
  // const hasStarted =
  //   moment().isAfter(startDateTime) && moment().isBefore(endDateTime);

  /* Checking if the exam has started. */
  const hasStarted =
    moment().isAfter(startDateTime) && moment().isBefore(endDateTime);

  useEffect(() => {
    let interval = setInterval(() => {
      /* Calculating the time left for the assessment. */
      const currentDate = new moment();
      const endAssessment = new moment(endDateTime);

      let duration = moment.duration(endAssessment.diff(currentDate));

      setDuration(duration);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <CardLayout
      {...{
        key: assessment?.id,
        // isNewContent: !assessment?.has_submitted,
        containerClass:
          "!rounded-[8.79725px] border border-[#006B5D1A]/10 bg-[#F8F8F8]",
        imgContainer:
          "w-full rounded-tl-md rounded-tr-md grid place-items-center relative lg:h-[129.76px] mt-[9.9px]",
        img: (
          <>
            <img className="px-[11px] h-full w-full" alt="" src={cover} />
            <div className="absolute bottom-3 left-0 right-0 flex items-center justify-between mx-[25.29px]">
              <Typography className="text-[15.3952px] leading-[19px] font-bold !text-white ">
                {truncateMiddleString(assessment?.module?.only_name, 23)}
              </Typography>
              {hasStarted && (
                <div className="text-right">
                  <Typography className="text-[8px] leading-[10px] font-bold !text-white">
                    Time Left:
                  </Typography>
                  <Timer
                    hours={duration?.hours()}
                    minutes={duration?.minutes()}
                    seconds={duration?.seconds()}
                  />
                </div>
              )}
            </div>
          </>
        ),
        children: (
          <div style={{marginTop:'60px'}} className="w-full h-full py-5 mt-5">
            <div className="flex w-full justify-between items-center">
              <div className="py-5  w-full flex gap-1 items-center ">
                <Typography
                  className={
                    assessment?.has_submitted
                      ? "!text-[#006B5D] text-[11px] leading-[13.31px]"
                      : "!text-[#C50D00] text-[11px] leading-[13.31px]"
                  }
                  capitalize
                >
                  {assessment?.has_submitted ? "Status:" : "Deadline:"}
                </Typography>
                <Typography
                  className={
                    assessment?.has_submitted
                      ? "!text-[#006B5D] text-[11px] leading-[13.31px]"
                      : "!text-[#C50D00] text-[11px] leading-[13.31px]"
                  }
                >
                  {assessment?.has_submitted ? (
                    "Submitted"
                  ) : (
                    <>
                      <Moment format="L">{assessment?.submission_date}</Moment>
                      {" | "}
                      <Moment format="LT">{assessment?.submission_date}</Moment>
                    </>
                  )}
                </Typography>
              </div>
              <Button
                shadow="none"
                className={`h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px]  text-[12px] leading-[15px] flex items-center justify-center ${"!text-[#006B5D]"}`}
                onClick={onAssessmentView}
                // disabled={!hasStarted}
              >
                View
              </Button>
            </div>
            {!assessment?.has_annotated && assessment?.has_submitted && (
              <Typography className="!text-[#006B5D] text-[12px] text-center">
                Not Graded yet
              </Typography>
            )}
            {!assessment?.has_submitted && (
              <div className="flex w-full items-center justify-between mt-[9.9px]">
                <Button
                  shadow="none"
                  className={`h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px]  text-[12px] leading-[15px] flex items-center justify-center !text-[#006B5D] ${"!text-[#006B5D]"}`}
                  onClick={() =>
                    onDownload(assessment?.file_url, assessment?.module)
                  }
                  // disabled={!hasStarted}
                >
                  <img
                    src={DownloadIcon}
                    alt="upload"
                    className={`h-3 w-3 mr-[6.59px] `}
                  />
                  Download
                </Button>
                <Button
                  shadow="none"
                  className={`px-1 h-[30.59px] w-[127.32px] bg-[#006B5D1A]/20 rounded-[4.51792px] text-[12px] leading-[15px] flex items-center justify-center  !text-[#006B5D] ${"!text-[#006B5D]"}`}
                  disabled={
                    new Date(
                      assessment?.submission_date.split?.(" ")[0] + " 23:59:00"
                    ) > new Date()
                      ? ""
                      : "disabled"
                  }
                  onClick={onUpload}
                  // disabled={!hasStarted}
                >
                  <img
                    src={UploadIcon}
                    alt="upload"
                    className={`h-3 w-3 mx-[3.59px]`}
                  />
                  Upload Solution
                </Button>
              </div>
            )}
            {assessment?.has_annotated && (
              <div className="flex justify-between items-center">
                <Button
                  {...{
                    className: `h-[30.59px] !w-full bg-[#006B5D1A]/20 rounded-[4.51792px] text-[12px] leading-[15px] flex items-center justify-center !text-[#006B5D]`,
                    sizes: "sm",
                    shadow: "none",
                    onClick: onGradedAssignment,
                  }}
                >
                  Check Result
                </Button>
              </div>
            )}
          </div>
        ),
      }}
    />
  );
};

export const TeacherAssessmentCard = ({
  assessment,
  viewSubmissionStatus,
  onEdit,
}) => {
  const statusColor = {
    End: "redStatus",
    Pending: "neutralStatus",
    Active: "successStatus",
    Primary: "primaryStatus",
  };
  return (
    <CardLayout
      {...{
        containerClass:
          "!rounded-[8.79725px] border border-[#006B5D1A]/10 bg-[#F8F8F8]",
        imgContainer:
          "w-full rounded-tl-md rounded-tr-md grid place-items-center py-4 relative ",
        img: (
          <>
            <img className="px-4 w-100 " alt="" src={cover} />
            <div className="absolute bottom-10 left-9">
              <Typography
                variant={"headlineS"}
                className="text-[15.3952px] leading-[19px] font-bold !text-white"
              >
                {truncateMiddleString(assessment?.module?.only_name, 23)}
              </Typography>
            </div>
          </>
        ),
        children: (
          <div className="w-full flex items-center px-5 pb-3">
            <div className="w-full justify-between items-center ">
              <div className="flex  justify-between items-center px-4">
                <Button
                  {...{
                    variant: "",
                    className:
                      "h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px]  text-[12px] leading-[15px] flex items-center justify-center gap-2 mb-2 !text-[#006B5D]",
                    shadow: "none",
                    onClick: () => {
                      onEdit(assessment?.id, assessment);
                    },

                    capitalize: true,
                  }}
                >
                  <img src={edit} alt="" className="h-4 w-4" />
                  edit
                </Button>

                <Button
                  {...{
                    variant: "",
                    className:
                      "h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px]  text-[12px] leading-[15px] flex items-center justify-center !text-[#006B5D]",
                    shadow: "none",
                    onClick: () => {
                      viewSubmissionStatus?.(assessment?.id, assessment);
                    },

                    capitalize: true,
                  }}
                >
                  view
                </Button>
              </div>
              <Typography
                {...{
                  variant: "body",
                  color: statusColor.Primary,
                  className: "py-2",
                }}
              >
                Deadline: {assessment?.submission_date}
              </Typography>
              <div className="flex justify-between items-center">
                <Typography
                  {...{
                    variant: "body2",
                    color: statusColor[assessment.status],
                  }}
                >
                  Upload Status: {assessment?.status}
                </Typography>
                <Typography
                  {...{
                    variant: "body2",
                    color: statusColor.Primary,
                  }}
                >
                  Uploaded: {assessment?.created_at}
                </Typography>
              </div>
            </div>
          </div>
        ),
      }}
    />
  );
};
