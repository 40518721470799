import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Typography, Input, UploadButton } from "..";
import { currentUser } from "../../../features/login/authSlice";
import { useChangePasswordMutation } from "../../../features/login/authApi";
import { usePatchProfileMutation } from "../../../features/login/authApi";
import { usePostFileUploadMutation } from "../../uploadApi";
import { retrievePdfFile } from "../../../helpers";
import { XIcon } from "@heroicons/react/solid";
import { useToasts } from "react-toast-notifications";

export const ProfileCard = ({ showProfile, onClose }) => {
  const user = useSelector(currentUser);
  const { addToast } = useToasts();
  const [edit, setEdit] = useState(false);
  const [onSetPassword, { isUpdatingPassword }] = useChangePasswordMutation({
    selectFromResult: ({ isLoading }) => ({
      isUpdatingPassword: isLoading,
    }),
  });
  const [isChangePassword, setChangePassword] = useState(false);
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState(null);
  const [profile, setProfile] = useState({
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    phone: user?.phone_number,
    dob: "",
  });
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [onPatchProfile, { isPatching }] = usePatchProfileMutation({
    selectFromResult: ({ isLoading }) => ({
      isPatching: isLoading,
    }),
  });
  const [onFileUpload, { isUploading }] = usePostFileUploadMutation({
    selectFromResult: ({ isLoading, data }) => ({
      isUploading: isLoading,
    }),
  });

  const handleChange = (e) => {
    edit && setProfile({ ...profile, [e.target.id]: e.target?.value });
    isChangePassword &&
      setPassword({ ...password, [e.target.id]: e.target?.value });
  };

  const handleFile = (e) => {
    let file = e.target.files;
    setFiles(file[0]);
    retrievePdfFile(Object.values(file), (img) => {
      setImage(img);
    });
  };

  const handleUpdate = async () => {
    let data = new FormData();
    try {
      files !== null && data.append("file", files);
      let resultFromUpload = await onFileUpload({ body: data }).unwrap();
      files !== null &&
        resultFromUpload?.error &&
        addToast(resultFromUpload?.error.data?.message, {
          appearance: "error",
        });
      if (resultFromUpload?.status === "success") {
        let resultFromPatch = await onPatchProfile({
          file_url: resultFromUpload?.data?.file_url,
          ...profile,
        }).unwrap();
        resultFromPatch?.error &&
          addToast(resultFromPatch?.error.data?.message, {
            appearance: "error",
          });
        resultFromPatch?.status === "success" &&
          addToast("Your profile updated", { appearance: "success" });
      }
      if (files === null) {
        let resultFromPatch = await onPatchProfile({
          file_url: resultFromUpload?.data?.file_url,
          ...profile,
        }).unwrap();
        resultFromPatch?.error &&
          addToast(resultFromPatch?.error.data?.message, {
            appearance: "error",
          });
        resultFromPatch?.status === "success" &&
          addToast("Your profile updated", { appearance: "success" });
      }
    } catch (error) {
      addToast("An error occurred, please try again later", {
        appearance: "error",
      });
    }
  };

  const handleUpdatePassword = async () => {
    try {
      let { confirmPassword, newPassword } = password;
      if (confirmPassword === newPassword) {
        let result = await onSetPassword().unwrap();

        result?.error &&
          addToast(result?.error.data?.message, { appearance: "error" });

        result?.data &&
          addToast("Successfull updated", { appearance: "success" });
      } else {
        addToast("password don't match", { appearance: "error" });
      }
    } catch (error) {
      addToast("An error occurred, please try again later", {
        appearance: "error",
      });
    }
  };

  const location = useLocation();
  const isDashboardRoute = location.pathname === "/dashboard";

  return (
    <Transition
      as={Fragment}
      show={showProfile}
      enter="transition-all ease-in duration-500"
      enterFrom="transform -translate-y-full"
      enterTo="transform -translate-y-0"
      leave="transition-all ease-out duration-500"
      leaveFrom="transform -translate-y-0"
      leaveTo="transform -translate-y-full"
    >
      <div
        className={`container mx-auto max-w-sm w-72 rounded-lg overflow-hidden shadow-lg my-2 bg-white absolute  
      ${isDashboardRoute ? "top-[15px]" : ""}  right-3 z-50`}
      >
        <div
          className="relative z-10 h-60"
          style={{
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw))",
          }}
        >
          <XIcon
            onClick={onClose}
            className={`h-[16px] w-[16px] absolute  right-2 top-2 cursor-pointer`}
          />
          {user?.profile?.avatar && (
            <img
              className="w-full object-cover h-full"
              src={image ?? user?.profile?.avatar}
              alt=""
            />
          )}
          {!user?.profile?.avatar && (
            <div
              className={`h-full w-full grid place-items-center bg-primary-container`}
            >
              <Typography
                {...{
                  color: "white",
                  variant: "display",
                  className: "text-on-primary-container",
                }}
              >
                {user?.avatar_alternative}
              </Typography>
            </div>
          )}
          {edit && (
            <div className="text-center absolute left-0 top-0 right-0 bottom-0 grid place-items-center bg-background/30">
              <UploadButton
                {...{
                  variant: "outlined",
                  className: "border-white text-white px-5 py-2 rounded-md",
                  onFiles: (e) => handleFile(e),
                }}
              >
                Upload
              </UploadButton>
            </div>
          )}
        </div>

        {!isChangePassword && (
          <div className="h-56 flex flex-col justify-between overflow-auto px-5 ">
            <div className="pt-6 pb-3 grid grid-cols-2 h">
              <Typography {...{ variant: "body", capitalize: true }}>
                first name:{" "}
              </Typography>
              {edit ? (
                <Input
                  name="first_name"
                  value={profile?.first_name}
                  text="text"
                  onChange={handleChange}
                  className="pl-2"
                />
              ) : (
                <Typography>{user?.first_name} </Typography>
              )}
            </div>
            <div className="pb-3 grid grid-cols-2">
              <Typography {...{ variant: "body", capitalize: true }}>
                last name:{" "}
              </Typography>
              {edit ? (
                <Input
                  name="last_name"
                  value={profile?.last_name}
                  type="text"
                  onChange={handleChange}
                  className="pl-2"
                />
              ) : (
                <Typography>{user?.last_name} </Typography>
              )}
            </div>
            <div className="pb-3 grid grid-cols-2 ">
              <Typography {...{ variant: "body", capitalize: true }}>
                student id:{" "}
              </Typography>
              {edit ? (
                <Input
                  name="student_id"
                  value={user?.student_id}
                  disabled
                  onChange={handleChange}
                  className="pl-2"
                />
              ) : (
                <Typography>{user?.student_id} </Typography>
              )}
            </div>
            <div className="pb-3 grid grid-cols-2 ">
              <Typography {...{ variant: "body", capitalize: true }}>
                email:{" "}
              </Typography>
              {edit ? (
                <Input
                  name="email"
                  value={profile?.email}
                  type="email"
                  onChange={handleChange}
                  className="pl-2"
                />
              ) : (
                <Typography>{user?.email ?? "NAN"} </Typography>
              )}
            </div>
            <div className="pb-3 grid grid-cols-2 ">
              <Typography {...{ variant: "body", capitalize: true }}>
                phone:{" "}
              </Typography>
              {edit ? (
                <Input
                  name="phone_number"
                  value={profile?.phone}
                  type="tel"
                  onChange={handleChange}
                  className="pl-2"
                />
              ) : (
                <Typography>{user?.phone_number ?? "NAN"} </Typography>
              )}
            </div>
            {/* <div className="pb-3 grid grid-cols-2 ">
              <Typography {...{ variant: 'subheader2', capitalize: true}}>date of birth: </Typography>
              {edit? <Input name="dob" defaultValue={user?.first_name} value={profile?.dob} type="date" onChange={handleChange} />  :<Typography>{user?.profile?.dob} </Typography>}
            </div> */}
          </div>
        )}

        {isChangePassword && (
          <>
            <Typography
              {...{
                capitalize: true,
                className: "mb-5 pl-5",
                variant: "headlineS",
              }}
            >
              Change Password
            </Typography>
            <div className="flex flex-col justify-between overflow-auto px-5">
              <div className="pb-3 grid grid-cols-2 ">
                <Typography {...{ variant: "body1", capitalize: true }}>
                  current:{" "}
                </Typography>
                <Input
                  name="currentPassword"
                  value={password?.currentPassword}
                  type="text"
                  onChange={handleChange}
                  className="pl-2"
                />
              </div>
              <div className="pb-3 grid grid-cols-2 ">
                <Typography {...{ variant: "body1", capitalize: true }}>
                  new:{" "}
                </Typography>
                <Input
                  name="newPassword"
                  value={password?.newPassword}
                  type="text"
                  onChange={handleChange}
                  className="pl-2"
                />
              </div>
              <div className="pb-3 grid grid-cols-2 ">
                <Typography {...{ variant: "body1", capitalize: true }}>
                  confirm:{" "}
                </Typography>
                <Input
                  name="confirmPassword"
                  value={password?.confirmPassword}
                  type="text"
                  onChange={handleChange}
                  className="pl-2"
                />
              </div>
            </div>
          </>
        )}

        {!isChangePassword && (
          <div className="flex justify-center gap-x-3 pb-2 mt-4">
            <Button
              {...{
                capitalize: true,
                variant: edit ? "outlined" : "contained",
                className: edit ? "text-red" : "text-white",
                sizes: "sm",
                shadow: "none",
                onClick: () => setEdit(!edit),
              }}
            >
              {" "}
              {edit ? "cancel" : "edit"}{" "}
            </Button>
            {edit && (
              <Button
                {...{
                  capitalize: true,
                  variant: "contained",
                  className: "text-white",
                  sizes: "sm",
                  shadow: "none",
                  onClick: handleUpdate,
                  isLoading: isPatching || isUploading,
                }}
              >
                {" "}
                update{" "}
              </Button>
            )}
          </div>
        )}
        {isChangePassword && (
          <div className="flex justify-center gap-x-3 pb-2 mt-4">
            <Button
              {...{
                capitalize: true,
                variant: "outlined",
                className: "text-red",
                sizes: "sm",
                shadow: "none",
                onClick: () => setChangePassword(false),
              }}
            >
              cancel
            </Button>
            <Button
              {...{
                capitalize: true,
                variant: "contained",
                className: "text-white",
                sizes: "sm",
                shadow: "none",
                onClick: handleUpdatePassword,
                isLoading: isUpdatingPassword,
              }}
            >
              update
            </Button>
          </div>
        )}
        {!isChangePassword && !edit && (
          <Typography
            {...{
              className: "text-center mb-2 cursor-pointer",
              capitalize: true,
              onClick: () => setChangePassword(true),
            }}
          >
            change password
          </Typography>
        )}
      </div>
    </Transition>
  );
};
