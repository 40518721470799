import AudioPlayer from "react-audio-player";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  PdfViewer,
  Button,
  CardPlaceholderLoader,
} from "../../../common/sharedComponent";

import { role } from "../../login/authSlice";
import { STUDENT_ROLE } from "../../../common/variables";
import VideoPlayer from "react-player";
import { BsDownload } from "react-icons/bs";
import useFileDownloader from "../../../hooks/useFileDownloader";
// import {
//   usePostVideoStatusUploadMutation,
//   useVideoPercentageQuery,
// } from "../../../common/updateVideoPercentage";

const Player = ({
  module,
  onLessonModule,
  containerClass,
  onLessonMaterial,
}) => {
  const userRole = useSelector(role);

  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const download = (file) => downloadFile(file);
  return (
    <div className={`pb-10 pr-5 lg:pr-0 mb-5 ${containerClass}`}>
      {module?.file_type === "file" && (
        // <iframe {...{
        //     src: module?.file_url,
        //     title: module?.topic,
        //     className: 'w-full h-[30rem] mt-2'
        // }}></iframe>
        <iframe
          src={`https://docs.google.com/viewer?url=${module?.file_url}&embedded=true`}
          frameborder="0"
          height="500px"
          title={module?.file_url}
          width="100%"
        ></iframe>
        // <PdfViewer
        //   {...{
        //     url: module?.file_url,
        //     meta: {
        //       fileName: module?.module?.only_name,
        //       id: module?.id,
        //     },
        //   }}
        // />
      )}
      {module?.file_type === "video" && (
        <>
          <VideoComponents {...{ src: module?.file_url, id: module?.id }} />

          <button
            className="flex flex-row space-x-2 rounded-md border border-gray-800 p-2  transition-opacity hover:bg-black/10"
            // onClick={async (e) => {
            //   e.preventDefault();
            //   return downLoadFile(item);
            // }}
            onClick={(e) => {
              e.preventDefault();
              const myArray = module?.file_url.split(".");
              if (myArray.length > 0) {
                let ext = myArray[myArray.length - 1];
                const filename = module?.topic + "." + ext;
                const file = {
                  name: module?.topic,
                  file: module?.file_url,
                  filename: filename,
                };
                download(file);
              }
            }}
          >
            <BsDownload className="w-5 h-5" />
            <span>Download</span>
          </button>
          {downloaderComponentUI}
        </>
      )}
      {module?.file_type === "audio" && (
        <AudioPlayer
          {...{ src: module?.file_url, autoPlay: true, controls: true }}
        />
      )}
      <div className="flex justify-between items-center mb-2 mt-10">
        <Typography
          {...{
            variant: "headline",
            className: "",
            uppercase: true,
          }}
        >
          {module?.topic}
        </Typography>
        {module?.file_type === "file" && (
          <Button
            {...{
              className: "text-red rounded grid place-items-center",
              component: "a",
              shadow: "none",
              href: module?.file_url,
              download: module?.topic,
              uppercase: true,
            }}
          >
            download file
          </Button>
        )}
        {userRole === STUDENT_ROLE && module?.id && (
          <Button
            {...{
              className:
                "text-red rounded grid place-items-center hidden lg:block",
              shadow: "none",
              uppercase: true,
              onClick: onLessonMaterial,
            }}
          >
            lesson materials
          </Button>
        )}
      </div>
      <Typography
        {...{
          color: "defaultBody",
          className: "mb-4 w-4/5",
          capitalize: true,
        }}
      >
        {module?.extra_note}
      </Typography>

      <div className="grid place-items-center lg:hidden">
        {module?.file_type === "file" && (
          <Button
            {...{
              className:
                "text-red rounded mx-auto grid place-items-center mt-5",
              component: "a",
              href: module?.file_url,
              shadow: "none",
              download: module?.topic,
              uppercase: true,
            }}
          >
            download file
          </Button>
        )}
      </div>
      <div className="grid place-items-center w-full lg:hidden mt-3">
        <Button
          {...{
            className: "text-white rounded",
            variant: "contained",
            shadow: "none",
            fullWidth: true,
            uppercase: true,
            onClick: onLessonMaterial,
          }}
        >
          lesson materials
        </Button>
      </div>
    </div>
  );
};

export default Player;
const Loader = () => {
  return <div className="h-full w-full bg-gray-200 animate-pulse"></div>;
};

const VideoComponents = ({ src, id }) => {
  // const [onVideoStatusUpdate, { isLoading: videoStatusUploading }] =
  //   usePostVideoStatusUploadMutation();
  const [isLoading, setIsLoading] = useState(true);
  // const [watchedPercentage, setWatchedPercentage] = useState(0);
  const [duration, setDuration] = useState(0);
  // const [percentage, setPercentage] = useState(null);
  // const { videoStatus, isResultLoading, errorStatus } = useVideoPercentageQuery(
  //   { module_id: id },
  //   {
  //     selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
  //       videoStatus: data?.data,
  //       isResultLoading: isLoading,
  //       errorStatus: error?.originalStatus,
  //     }),
  //   }
  // );

  // useEffect(() => {
  //   // if (errorStatus === 404) {
  //   //   setPercentage(0.0);
  //   // }
  //   // console.log(isResultLoading);
  //   // console.log(videoStatus);
  // }, [videoStatus, isResultLoading, errorStatus]);

  // const handleProgress = async (state) => {
  //   if (duration) {
  //     // setPercentage((state.playedSeconds / duration) * 100);

  //     // setWatchedPercentage(percentage.toFixed(2));
  //   }
  // };

  // useEffect(() => {
  //   // if (percentage !== null && percentage !== 0.0) {
  //   //   let data = new FormData();
  //   //   data.append("module_id", id);
  //   //   data.append("percentage", percentage.toFixed(2));
  //   //   let result = onVideoStatusUpdate({ body: data }).unwrap();
  //   //   // console.log(result);
  //   // }
  // }, [percentage, id, onVideoStatusUpdate]);

  // const handleDuration = (duration) => {
  //   setDuration(duration);
  // };

  return (
    <div className="lg:h-[512px] w-full mb-8">
      {/* <p>Watched Percentage: {watchedPercentage}%</p> */}
      {/* {isLoading && (
        <div className="h-full w-full bg-gray-200 animate-pulse"></div>
      )} */}
      <VideoPlayer
        {...{
          url: src,
          className: `w-full aspect-video `,
          width: "100%",
          height: "100%",
          playing: true,
          controls: true,
          config: {
            file: { attributes: { controlsList: "nodownload" } },
          },
          onReady: () => setIsLoading(false),
        }}
        fallback={<Loader />}
      />

      {/* <iframe
        {...{
          src,
          title: "lesson",
          className: "w-full aspect-video",
          allow: "fullscreen; autoplay",
        }}
      ></iframe> */}
    </div>
  );
};
