import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./baseQuery";

const notificationBaseUrl = "/notifications";

export const notificationApis = createApi({
  reducerPath: "notifications",
  baseQuery,
  endpoints: (builder) => ({
    notification: builder.query({
      query: () => notificationBaseUrl,
    }),
   
  }),
});

export const {
  useNotificationQuery
} = notificationApis;
