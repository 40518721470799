import React from "react";
import { useState } from "react";
import { DocumentTextIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { Typography } from "../../../common/sharedComponent";
import "./teachernew.css";
import edit from "../../../assets/edit.png";
import Submitted from "./submittedexam";
import { Button } from "../../../common/sharedComponent";

const AllEdit = ({ exam, onBack  }) => {
  const [submittedExam, setSubmittedExam] = useState(false);
 

  return (
    <>
      {!submittedExam && (
        <div className="">
          <div
            onClick={onBack}
            className="flex gap-x-2 mb-3 items-center cursor-pointer"
          >
            {" "}
            <ArrowLeftIcon className="h-5 w-5 " />{" "}
            <Typography {...{ capitalize: true }}>back</Typography>{" "}
          </div>

          <div style={{justifyContent:'space-between'}} className="single-name mb-3 flex">
            <div>
              <h1>{exam?.module?.only_name}</h1>
            </div>
            <div className="">
              <Button
                {...{
                  variant: "",
                  shadow: "none",
                  className: "btn-exam btn ",
                  sizes: "fullwidth",
                  onClick: () => {
                    setSubmittedExam(true);
                  },
                  capitalize: true,
                }}
              >
                <span>Submitted</span>
              </Button>
            </div>
          </div>

          <div className="allBg">
            <div className="bg">
              <div className="flex">
                <Typography className="edit-text mr-1">File:</Typography>
                <Typography className="edit-text mr-1">
                  {exam?.file_url}
                </Typography>
              </div>
            </div>
            <div className="bg mt-5">
              <div className="flex">
                <Typography className="edit-text mr-1">Date:</Typography>
                <Typography className="edit-text mr-1">
                  {exam?.available_at}
                </Typography>
              </div>
            </div>
            <div className="bg mt-5">
              <div className="flex">
                <Typography className="edit-text mr-1">Time:</Typography>
                <Typography className="edit-text mr-1">
                  {exam?.exam_start_time}
                </Typography>
              </div>
            </div>

            <div className="bg mt-5">
              <div className="flex">
                <Typography className="edit-text mr-1">Duration:</Typography>
                <Typography className="edit-text mr-1">
                  {exam?.duration}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
      {submittedExam && <Submitted exam={exam}  back={() => setSubmittedExam(false)} />}
    </>
  );
};

export default AllEdit;
