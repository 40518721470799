import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const verifyMeApi = createApi({
  reducerPath: "verifyMeApi",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    me: builder.query({ query: () => "/me" }),
  }),
});

export const { useMeQuery } = verifyMeApi;
