import { Form } from "react-final-form";
import { Button } from ".";
import ProgressBar from "@ramonak/react-progress-bar";
import { selectUploadProgress } from "../uploadProgressSlice";
import { useSelector } from "react-redux";

const FormField = ({
  children,
  buttonText,
  btnStyles,
  onSubmit,
  isLoading,
  end,
  values,
  btnClassName,
  // progress,
}) => {
  const progress = useSelector(selectUploadProgress);

  return (
    <Form
      className="w-2/3 mx-auto"
      onSubmit={onSubmit}
      initialValues={values}
      render={({ handleSubmit, submitting, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          {children}
          <div
            className={`flex ${
              end ? "justify-end" : "justify-center"
            }  ${btnStyles}`}
          >
            {progress !== 0 ? (
              <ProgressBar
                className="w-40"
                bgColor="#006B5D"
                // barContainerClassName="bg-red"
                completed={progress}
              />
            ) : (
              <Button
                type="submit"
                variant="contained"
                shadow="sm"
                isLoading={isLoading}
                disabled={submitting || hasValidationErrors || isLoading}
                className={btnClassName}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </form>
      )}
    />
  );
};

export default FormField;
