/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeftIcon, SearchIcon } from "@heroicons/react/outline";
import { useState, useReducer } from "react";
import {
  BreadCrumb,
  SelectInput,
  Typography,
  CardPlaceholderLoader,
  EmptyContent,
  Action,
} from "../../../common/sharedComponent";
import { AssessmentCard } from "../components";
import { UploadModal } from "../../../common/Layouts";
import {
  useStudentPostAssessmentMutation,
  // useStudentRequestAttemptQuery,
  useLazyStudentAssessmentsQuery,
  useGetStudentDashboardQuery,
} from "../api";
import { StudentSingleView } from "../pages";
import { useToasts } from "react-toast-notifications";
import { handleDownloadFile, perseCustomError } from "../../../helpers";
import { usePostFileUploadMutation } from "../../../common/uploadApi";
import {
  setProgress,
  selectUploadProgress,
} from "../../../common/uploadProgressSlice";
import { useDispatch, useSelector } from "react-redux";
import { reducer } from "../reducer";
import { initialState } from "../reducer/constants";
import {
  setAssessments,
  setRemoveUploadFiles,
  setSelectFilterModule,
  setSingleAssessment,
  setSingleViewType,
  setUploadFiles,
  toggleModal,
  toggleSingleView,
} from "../reducer/action";
import StudentAssessmentTabs from "../components/StudentAssessmentTabs";

const optionsData = [
  {
    id: "1",
    name: "Assessment to Be Submitted",
  },
  {
    id: "2",
    name: "Assessment Submitted",
  },
  {
    id: "3",
    name: "Assessment Results",
  },
];

export const Student = () => {
  const [state, dispatcher] = useReducer(reducer, initialState);

  // this is a simple file upload success state.
  const [fileSuccess, setFileSuccess] = useState(false);

  // this is a state for the response from file upload api.
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const [singleModule, setSingleModule] = useState(null);
  const [singleView, setSingleView] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const [currentModuleName, setCurrentModuleName] = useState("");
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  // retrieve the progress state from redux store.
  const progress = useSelector(selectUploadProgress);

  // Using the usePostFileUploadMutation hook to upload file data through the REST API.
  const [onFileUpload, { isLoading: isFileUploading }] =
    usePostFileUploadMutation();

  /**
   * A react-toast-notification hook, for triggering a notification display.
   */
  const { addToast } = useToasts();

  /**
   * Using the useLazyStudentAssessmentsQuery hook to trigger a fetch of student assignment data through the REST API.
   */
  const [onStudentAssessment, { isAssessmentLoading }] =
    useLazyStudentAssessmentsQuery({
      selectFromResult: ({ isLoading, isFetching }) => ({
        isAssessmentLoading: isLoading || isFetching,
      }),
    });

  /**
   * Using the useGetStudentDashboardQuery hook to fetch student dashboard data through the REST API.
   */
  const { subjects, isSubjectLoading } = useGetStudentDashboardQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => ({
        subjects: data?.data?.currently_enrolled_modules ?? [],
        isSubjectLoading: isLoading,
      }),
    }
  );

  /**
   * Using the useStudentPostAssessmentMutation hook to trigger a post of student assessment data through the REST API.
   */
  const [submit, { isLoading: postLoader }] =
    useStudentPostAssessmentMutation();

  /**
   * This function takes in a module and sends the module id
   * to the server, to get the assessment lists related to the
   * module id and updates the reducer state: ASSESSMENTS.
   * @param {object} module
   */
  const handleFetchStudentAssessment = async (module) => {
    try {
      const result = await onStudentAssessment({
        page,
        module_id: module?.id ? module?.id : module?.only_name,
      }).unwrap();

      result?.data?.length && setAssessments(dispatcher, result?.data ?? []);
    } catch (error) {
      // handles different kinds of errors.
      perseCustomError(error, addToast);
    }
  };

  /**
   * This function handles the upload of the selected pdf
   * files to the api hook trigger.
   * @param {object} file
   */
  const handleUpload = async (file) => {
    let data = new FormData();
    try {
      data.append("file", file);
      let result = await onFileUpload({ body: data }).unwrap();

      if (result?.status !== "success") {
        // returns the upload progress state back to zero.
        dispatch(setProgress(0));
        perseCustomError(result, addToast);
      }

      // updates the success state
      if (result?.status === "success") {
        dispatch(setProgress(0));
        setFileSuccess(true);
        setFileUploadResponse(result?.data);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  const handlePageChange = (page) => {
    // changes the current page of content
    setPage(page);
  };

  /**
   * It takes the uploaded file url and the assignment id
   * and sends it to the api hook trigger.
   */
  const handleAssessmentSubmission = async () => {
    try {
      // parse the uploaded file url and assessment id to the api.
      let submitResult = await submit({
        assessment_id: state.selectedAssessment?.id,
        file_url: fileUploadResponse?.file_url,
      }).unwrap();

      // if error occured while parsing the above data, show notification.
      submitResult?.error &&
        addToast(submitResult?.error.data?.message, { appearance: "error" });

      // if successfully while parsing the above data, show notification
      if (submitResult?.data?.id) {
        addToast("Assessment submitted successfully", {
          appearance: "success",
        });

        // hides the upload modal
        toggleModal(dispatcher);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  return (
    <div className="w-full overflow-hidden scrollbar-hide">
      {singleModule ? (
        <>
          <div
            onClick={() => {
              toggleSingleView(dispatcher);
              setSingleAssessment(dispatcher, null);
              setSingleModule(!singleModule);
              if (singleView) {
                setSingleView(!singleView);
              }
            }}
            className="flex gap-x-2 mb-3 items-center cursor-pointer"
          >
            {" "}
            <ArrowLeftIcon className="h-5 w-5 " />{" "}
            <Typography {...{ capitalize: true }}>back</Typography>{" "}
          </div>
          <div className="mb-3 -mt-4 ">
            <Action
              noLive
              noUpload
              //                     uploadAction={uploadCourse}
              // btnText="upload lessons"
            >
              {currentModuleName}
            </Action>
          </div>
        </>
      ) : (
        ""
      )}
      {!singleModule && (
        <>
          <Action noLive noUpload>
            Courses
          </Action>
          <StudentAssessmentTabs
            subjects={subjects}
            loading={isSubjectLoading}
            onClick={(value) => {
              setSelectFilterModule(dispatcher, value);
              handleFetchStudentAssessment(value);
              setCurrentModuleName(value.name);
              setSingleModule(!singleModule);
            }}
          />
        </>
      )}
      {singleModule && !singleView && (
        <>
          {!isAssessmentLoading && state?.assessments?.length !== 0 && (
            <div className="grid  lg:grid-cols-3 grid-rows-3 gap-3 w-full mb-20 pt-3 pb-20 overflow-auto scrollbar-hide lg:h-98 lg:pr-0 pr-5 lg:pb-0">
              {!isAssessmentLoading &&
                state?.assessments?.map((item, index) => (
                  <AssessmentCard
                    {...{
                      key: index,
                      assessment: item,
                      onDownload: (file_url, module) =>
                        handleDownloadFile(file_url, module),
                      onGradedAssignment: () => {
                        setSingleView(!singleView);
                        setSingleViewType(dispatcher, optionsData[2]?.name);
                        setSingleAssessment(dispatcher, item);
                        toggleSingleView(dispatcher);
                      },
                      // onResultAssignment: () => {
                      //   setSingleViewType(dispatcher, optionsData[2]?.name);
                      //   setSingleAssessment(dispatcher, item);
                      //   toggleSingleView(dispatcher);
                      // },
                      onUpload: () => {
                        setSingleAssessment(dispatcher, item);
                        setShowUpload(!showUpload);
                        toggleModal(dispatcher);
                      },
                      onAssessmentView: () => {
                        setSingleView(!singleView);
                        setSingleAssessment(dispatcher, item);
                        setSingleViewType(dispatcher, optionsData[0].name);
                        toggleSingleView(dispatcher);
                      },
                    }}
                  />
                ))}
            </div>
          )}
          {isAssessmentLoading && <CardPlaceholderLoader />}
          {!isAssessmentLoading && state?.assessments?.length === 0 && (
            <div className="h-72">
              <EmptyContent message="No Assessment Yet, Select another module" />
            </div>
          )}
        </>
      )}
      {singleView && (
        <StudentSingleView
          {...{
            assessment: state?.selectedAssessment,
            selectFilter: state?.showSingleType,
            optionsData,
            state,
            dispatcher,
          }}
        />
      )}
      {showUpload && (
        <UploadModal
          {...{
            progress,
            open: state.modal,
            uploadSuccess: fileSuccess,
            submitButtonLoader: postLoader,
            uploadFiles: state.uploadFiles,
            onSubmit: handleAssessmentSubmission,
            onUpload: (file) => handleUpload(file),
            onClose: () => {
              setShowUpload(!showUpload);
              return toggleModal(dispatcher);
            },
            moduleName: state?.selectedAssessment?.module?.only_name,
            onRemove: (file) => setRemoveUploadFiles(dispatcher, file),
            onAcceptedFiles: (file) => setUploadFiles(dispatcher, file),
          }}
        />
      )}
    </div>
  );
};
