import { Typography } from "../../../common/sharedComponent";
import Button from "../../../common/sharedComponent/Button";
import { useDispatch } from "react-redux";
import { setAciveAsseesment } from "../../../common//moduleSlice";

function AssessmentTabs({ subjects, handleFetchAssessment, loading }) {
  const dispatch = useDispatch();
  return (
    <div
      className={`grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-4 mt-5 mx-2 sm:mx-4 lg:mx-0 grid-flow-row `}
    >
      {loading && <TabLoader />}

      {!loading && subjects.length < 1 && (
        <Typography className={"w-32"}>No Module Allocated</Typography>
      )}

      {subjects.length >= 1 &&
        subjects.map((subject) => (
          <div class="border-white border-2 bg-[#F6F6F6] rounded-lg flex flex-col justify-between h-[187px]">
            <div className="px-3 pt-5 pb-3 flex flex-col space-y-3 w-full">
              <Typography
                style={{
                  color: "#006B5D",
                }}
                {...{
                  className: "font-[400] text-[16px] leading-[21.28px]",
                }}
              >
                {subject?.semester?.name ?? "First Semester"}
              </Typography>
              <Typography
                variant={"headlineS"}
                style={{
                  color: "#006B5D",
                }}
                {...{
                  className: "font-[600] text-[20px] leading-[26.6px]",
                }}
              >
                {subject?.name ?? ""}
              </Typography>
            </div>
            <div className="w-full">
              <Button
                {...{
                  capitalize: true,
                  variant: "contained",
                  className: "text-white !w-full",
                  //   sizes: "md",
                  shadow: "none",
                  onClick: () => {
                    dispatch(setAciveAsseesment(subject.id));
                    handleFetchAssessment({
                      id: subject.id,
                      name: subject.name,
                    });
                  },
                }}
              >
                {"Click to view Assessment "}
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
}

export const TabLoader = () => {
  return [1, 2, 3, 4].map((item) => (
    <div
      key={item}
      className="h-32 w-32 mx-auto bg-gray-200 animate-pulse rounded-md my-10"
    ></div>
  ));
};

export default AssessmentTabs;
