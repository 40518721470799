import { useState } from "react";
import { Modal } from "../../../common/sharedComponent";
import VideoPlayer from "react-player";

const VideoModal = ({ open, onClose, src }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Modal {...{ open, onClose }}>
      <VideoPlayer
        {...{
          url: src,
          className: `w-full aspect-video `,
          width: "100%",
          height: "100%",
          playing: true,
          controls: true,
          config: {
            file: { attributes: { controlsList: "nodownload" } },
          },
          onReady: () => setIsLoading(false),
        }}
        fallback={<Loader />}
      />
    </Modal>
  );
};

const Loader = () => {
  return <div className="h-full w-full bg-gray-200 animate-pulse"></div>;
};

export default VideoModal;
