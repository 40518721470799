import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { currentUser } from "../../../../features/login/authSlice";
import logo from "../../../../assets/hillcross.png";
import { ReactComponent as Icon1 } from "../../../../assets/homeicon.svg";
import { ReactComponent as Icon2 } from "../../../../assets/lessonicon.svg";
import { ReactComponent as Icon3 } from "../../../../assets/assicon.svg";
import { ReactComponent as Icon5 } from "../../../../assets/dashboard/examination.svg";
import { ReactComponent as Icon6 } from "../../../../assets/dashboard/result.svg";
import { ReactComponent as Icon4 } from "../../../../assets/Group.svg";
import chat from "../../../../assets/message.png";
import {
  ASSESSMENT_ROUTE,
  ASSIGNMENT_ROUTE,
  EXAMINATION_ROUTE,
  LESSONS_ROUTE,
  MESSAGE_INBOX_ROUTE,
} from "../../../../routes";
import { XIcon } from "@heroicons/react/outline";
import { Avatar, Typography } from "../..";
import { useMediaQuery } from "../../../../hooks";

const SideNav = ({ close, inboxBadge, show }) => {
  const user = useSelector(currentUser);
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const location = useLocation();
  const link = [
    { link: "/dashboard", title: "Dashboard" },
    { link: LESSONS_ROUTE, title: "Lessons" },
    { link: ASSIGNMENT_ROUTE, title: "Assignments" },
    { link: ASSESSMENT_ROUTE, title: "Assessments" },
    { link: EXAMINATION_ROUTE, title: "Examination" },
  ];

  const getIcons = (index, link) => {
    return index === 0 ? (
      <Icon1
        className={` h-5 w-5 group-hover:text-white ${
          location.pathname === link ? "text-white" : "text-[#006B5D]"
        }`}
      />
    ) : index === 1 ? (
      <Icon2
        className={` h-5 w-5 group-hover:text-white ${
          location.pathname === link ? "text-white" : "text-[#006B5D]"
        }`}
      />
    ) : index === 2 ? (
      <Icon3
        className={` h-5 w-5 group-hover:text-white ${
          location.pathname === link ? "text-white" : "text-[#006B5D]"
        }`}
      />
    ) : index === 3 ? (
      <Icon4
        className={` h-5 w-5 group-hover:text-white ${
          location.pathname === link ? "text-white " : "text-[#006B5D]"
        }`}
      />
    ) : (
      <Icon5
        className={` h-5 w-5  ${
          location.pathname === link ? "text-white " : "text-[#006B5D]"
        }`}
      />
    );
  };

  return (
    <Transition
      className={`h-screen background p-3 space-y-2 w-64 lg:hidden bg-[#006B5D] fixed left-0 bottom-0 z-50 lg:static lg:z-0 shrink-0`}
      as={"div"}
      show={show}
      enter="transition-all ease-in duration-1000"
      enterFrom="transform -translate-x-full"
      enterTo="transform -translate-x-0"
      leave="transition-all ease-out duration-1000"
      leaveFrom="transform -translate-x-0"
      leaveTo="transform -translate-x-full"
    >
      <XIcon
        className="h-6 w-6 cursor-pointer lg:hidden relative"
        onClick={close}
      />
      {isMobileView && (
        <div className="absolute flex w-full justify-between bottom-2">
          <div className="flex items-center gap-x-3 pb-2">
            <div className="h-[60px] w-[60px] mx-auto ">
              <Avatar
                name={user?.profile?.avatar}
                avatarAlt={user?.avatar_alternative}
                className="border-[3px] border-white"
                sizes="full"
              />
            </div>
            <div>
              <Typography
                // variant="h6"
                color="white"
                className="mt-1"
              >
                {`${user?.first_name} ${user?.last_name}`}
              </Typography>
              <Typography variant="body2" color="white" className="">
                {user?.student_id}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center p-2">
        <Link to="/">
          <img src={logo} alt="Dashboard" className="w-full h-12" />
        </Link>
      </div>
      <div className="py-14">
        <ul className="">
          {link.map(({ link, title, user, Component }, index) => (
            <Link to={link}>
              <li
                key={link}
                onClick={isMobileView || isTabletView ? close : null}
                className={`my-2 flex ml-6 pl-4 py-2  w-[187.39px] p-[11px] rounded-lg text-[16px] mb-[25px] group hover:bg-[#72A7A0] ${
                  location.pathname === link ? "bg-[#72A7A0]" : "bg-[#F8F8F8]"
                } `}
              >
                {getIcons(index, link)}
                <Typography
                  variant="body"
                  className={` group-hover:!text-white ${
                    location.pathname === link
                      ? "!text-white ml-4 "
                      : "!text-[#006B5D] ml-4"
                  }`}
                >
                  {title}
                </Typography>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div
        className={`cursor-not-allowed mx-auto items-center w-[187.39px] p-[11px] rounded-lg text-[16px] mb-[25px] group ${
          location.pathname === link ? "bg-[#72A7A0]" : "bg-white"
        }`}
      >
        <Link className={`flex justify-center `} to={"#"}>
          <Icon6
            className={` h-5 w-5  ${
              location.pathname === link ? "text-white " : "text-[#006B5D]"
            }`}
          />
          <Typography
            variant="body"
            className={`cursor-not-allowed group-hover:!text-white ${
              location.pathname === link
                ? "!text-white ml-4 "
                : "!text-[#006B5D] ml-4"
            }`}
          >
            Result
          </Typography>
        </Link>
      </div>

      <div
        className={`cursor-not-allowed mx-auto items-center w-[187.39px] p-[11px] rounded-lg text-[16px] mb-[25px] group ${
          location.pathname === link ? "bg-[#72A7A0]" : "bg-white"
        }`}
      >
        <Link className={`flex justify-center `} to={"#"}>
          <img className="w-[20px] group-hover:bg-white" alt="" src={chat} />
          <Typography
            variant="body"
            className={`cursor-not-allowed group-hover:!text-white ${
              location.pathname === link
                ? "!text-white ml-4 "
                : "!text-[#006B5D] ml-4"
            }`}
          >
            Messages
          </Typography>

          {inboxBadge && (
            <span className="ml-2 bg-red text-white rounded-full text-sm py-1 px-1.5">
              5
            </span>
          )}
        </Link>
      </div>
    </Transition>
  );
};

export default SideNav;
