import { Typography } from "../../../common/sharedComponent";
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from "react";

const calculateDuration = (eventTime, endTime) =>
  moment.duration(
    Math.max(eventTime - endTime, 0),
    "seconds"
  );

// TODO: trigger a callback notification of end time
export const Timer = ({
  eventTime = "1366549200",
  endTime = "1366549200",
  interval = "1000",
}) => {
  const [duration, setDuration] = useState(calculateDuration(eventTime, endTime));

  const timerRef = useRef(0);

  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime, endTime));
  }, [eventTime, endTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime, interval, timerCallback]);

  return (
    <div className="flex items-center gap-x-3">
      <div className="flex flex-col items-center justify-center bg-white h-[4rem] w-[4rem] rounded">
        <Typography {...{ variant: "headline" }}>
          {" "}
          {duration.hours()}{" "}
        </Typography>
        <Typography {...{ variant: "captionS", capitalize: true }}>
          {" "}
          hour(s){" "}
        </Typography>
      </div>
      <div className="flex flex-col items-center justify-center bg-white h-[4rem] w-[4rem] rounded">
        <Typography {...{ variant: "headline" }}>
          {" "}
          {duration.minutes()}{" "}
        </Typography>
        <Typography {...{ variant: "captionS", capitalize: true }}>
          {" "}
          minute(s){" "}
        </Typography>
      </div>
      <div className="flex flex-col items-center justify-center bg-white h-[4rem] w-[4rem] rounded">
        <Typography {...{ variant: "headline" }}>
          {" "}
          {duration.seconds()}{" "}
        </Typography>
        <Typography {...{ variant: "captionS", capitalize: true }}>
          {" "}
          second(s){" "}
        </Typography>
      </div>
    </div>
  );
};
