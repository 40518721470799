import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useToasts } from "react-toast-notifications";
import {
  Action,
  Button,
  FieldArray,
  Question,
} from "../../../common/sharedComponent";
import { composeValidators, minNum, required } from "../../../common/sharedComponent/InputValidate";
import {
  useGetExamCbtQuestionsQuery,
  usePostExamCbtQuestionsMutation,
} from "../api";

const ExaminationCbt = ({ value }) => {
  const [page, setPage] = useState(1);
  const { addToast } = useToasts();
  const [postCbt, { isLoading }] = usePostExamCbtQuestionsMutation();
  const { getCbt, meta, loadCbt, refetch } = useGetExamCbtQuestionsQuery(
    {
      id: value?.id,
      page,
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        getCbt: data?.data ?? [],
        loadCbt: isLoading,
        meta: data?.meta,
      }),
    }
  );

  const handlePageChange = (page) => {
    setPage(page);
  };
  const Questions = [
    {
      name: "question",
      type: "text",
      label: "Question",
      validate: required,
    },
    {
      name: "mark",
      type: "number",
      label: "Mark",
      validate: composeValidators(required, minNum(1)),

    },
    {
      name: "has_options",
      type: "checkbox",
      label: "Has Options?",
      containerStyles: "lg:mt-10",
    },
  ];

  const onSubmit = async (values) => {
    const {
      option1,
      option2,
      option3,
      option4,
      is_correct_answer,
      question,
      mark,
      has_options,
    } = values;
    let data;
    if (has_options) {
      data = {
        question,
        mark,
        has_options: has_options || false,
        options: [
          {
            option: option1,
            is_correct_answer: is_correct_answer === "option1" ? true : false,
          },
          {
            option: option2,
            is_correct_answer: is_correct_answer === "option2" ? true : false,
          },
          {
            option: option3,
            is_correct_answer: is_correct_answer === "option3" ? true : false,
          },
          {
            option: option4,
            is_correct_answer: is_correct_answer === "option4" ? true : false,
          },
        ],
      };
    }
    if (!has_options) {
      data = { question, mark, has_options: false };
    }
    try {
      const res = await postCbt({ body: data, id: value?.id });
      if (res?.error) {
        addToast(res?.error.data?.message, { appearance: "error" });
      } else {
        addToast("Question Succefully Posted", { appearance: "success" });
        refetch()
      }
    } catch (e) {
      addToast("An error occurred, Question not posted", {
        appearance: "error",
      });
    }
  };

  return (
    <>
      <Action noButton> {value?.module} Examination</Action>
      <div className="mt-6 pb-20">
        <Question
          data={getCbt}
          loading={loadCbt}
          currentPage={page}
          totalPages={meta?.last_page}
          onChangePaginate={handlePageChange}
          message={"Questions not uploaded"}
        />
        {value?.total_questions !== getCbt?.length && (
          <Form
            className="w-2/3 mx-auto"
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              values,
              hasValidationErrors,
              form,
            }) => (
              <form
                onSubmit={async (event) => {
                  await handleSubmit(event);
                  form.reset();
                }}
              >
                <>
                  <div className="flex  flex-col space-y-3  lg:grid lg:grid-cols-3 lg:gap-3 lg:space-y-0">
                    <FieldArray field={Questions} />
                  </div>
                  {values.has_options && (
                    <>
                      <div className="flex space-x-3 my-2">
                        <div className="flex flex-col w-1/2">
                          <label
                            htmlFor="option"
                            className="text-subheader2 text-on-background mb-1.5"
                          >
                            Option
                          </label>
                          <Field
                            component="input"
                            name="option1"
                            className="border-tertiary-container/40 border-2 rounded-sm h-10 focus:ring-tertiary-container focus:border-tertiary-container text-body2"
                          />
                        </div>
                        <div className="mt-10 flex">
                          <Field
                            component="input"
                            type="radio"
                            name="is_correct_answer"
                            value="option1"
                            className="ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container"
                          />
                          <div className="ml-3">
                            <label
                              htmlFor="is_correct_answer1"
                              className="text-subheader2 text-on-background"
                            >
                              Select Answer
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-3 my-2">
                        <div className="flex flex-col w-1/2">
                          <label
                            htmlFor="option"
                            className="text-subheader2 text-on-background mb-1.5"
                          >
                            Option
                          </label>
                          <Field
                            component="input"
                            name="option2"
                            className="border-tertiary-container/40 border-2 rounded-sm h-10 focus:ring-tertiary-container focus:border-tertiary-container text-body2"
                          />
                        </div>
                        <div className="mt-10 flex">
                          <Field
                            component="input"
                            type="radio"
                            name="is_correct_answer"
                            value="option2"
                            className="ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container"
                          />
                          <div className="ml-3">
                            <label
                              htmlFor="is_correct_answer"
                              className="text-subheader2 text-on-background"
                            >
                              Select Answer
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-3 my-2">
                        <div className="flex flex-col w-1/2">
                          <label
                            htmlFor="option"
                            className="text-subheader2 text-on-background mb-1.5"
                          >
                            Option
                          </label>
                          <Field
                            component="input"
                            name="option3"
                            className="border-tertiary-container/40 border-2 rounded-sm h-10 focus:ring-tertiary-container focus:border-tertiary-container text-body2"
                          />
                        </div>
                        <div className="mt-10 flex">
                          <Field
                            component="input"
                            type="radio"
                            name="is_correct_answer"
                            value="option3"
                            className="ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container"
                          />
                          <div className="ml-3">
                            <label
                              htmlFor="is_correct_answer"
                              className="text-subheader2 text-on-background"
                            >
                              Select Answer
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="flex space-x-3 my-2">
                        <div className="flex flex-col w-1/2">
                          <label
                            htmlFor="option"
                            className="text-subheader2 text-on-background mb-1.5"
                          >
                            Option
                          </label>
                          <Field
                            component="input"
                            name="option4"
                            className="border-tertiary-container/40 border-2 rounded-sm h-10 focus:ring-tertiary-container focus:border-tertiary-container text-body2"
                          />
                        </div>
                        <div className="mt-10 flex">
                          <Field
                            component="input"
                            type="radio"
                            name="is_correct_answer"
                            value="option4"
                            className="ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container"
                          />
                          <div className="ml-3">
                            <label
                              htmlFor="is_correct_answer"
                              className="text-subheader2 text-on-background"
                            >
                              Select Answer
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>

                <div className={`flex justify-end mt-5`}>
                  <Button
                    type="submit"
                    variant="contained"
                    shadow="sm"
                    isLoading={isLoading}
                    disabled={submitting || hasValidationErrors || isLoading}
                  >
                    Post Examination
                  </Button>
                </div>
              </form>
            )}
          />
        )}{" "}
      </div>
    </>
  );
};

export default ExaminationCbt;
