import { XIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";

export const Modal = ({
  children,
  open,
  onClose,
  width,
  hideCloseButton,
  modalPaperClass,
}) => {
  let w = width ? `lg-${width}` : "md:w-8/12 w-full";
  return (
    <Transition
      as={Fragment}
      show={open}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        className={`items-center justify-center fixed left-0 bottom-0 w-full h-full bg-[rgba(0,0,0,0.5)] z-20 px-4 lg:px-0 ${
          open ? "flex" : "hidden"
        }`}
      >
        {!hideCloseButton && (
          <XIcon
            onClick={onClose}
            className="h-[30px] w-[30 px] absolute right-4 top-5 cursor-pointer block text-white"
          />
        )}
        <div
          className={`bg-white md:rounded-lg ${w} md:mr-20 md:relative rounded-md lg:rounded-none ${
            modalPaperClass ? modalPaperClass : ""
          }`}
        >
          {children}
        </div>
      </div>
    </Transition>
  );
};
