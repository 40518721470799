import React, { useState } from "react";
import Typography from "./Typography";

export const Tooltip = ({ delay, children,  onClick, content }) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div 
      onMouseEnter={showTip}
      onMouseLeave={hideTip} 
      className="relative"
      onClick={onClick}
    >
      <div class="group relative cursor-pointer inline-block text-center">
        {children}
        <div className="opacity-0 w-28 bg-secondary-container text-on-secondary-container text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 -bottom-9 left-0 px-3 pointer-events-none">
          <Typography {...{ capitalize:true, variant: 'caption' }}>
          {content}
          </Typography>
        </div>
      </div>
    </div>
  );
};

