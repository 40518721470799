import { ArrowLeftIcon, SearchIcon } from "@heroicons/react/outline";
import { useState, useReducer, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  BreadCrumb,
  Typography,
  SelectInput,
  CardPlaceholderLoader,
  EmptyContent,
  Action,
} from "../../../common/sharedComponent";
import { handleDownloadFile, perseCustomError } from "../../../helpers";
import { UploadModal } from "../../../common/Layouts";
import { AssignmentCard } from "../components";
import {
  useLazyStudentAssignmentsQuery,
  useGetStudentDashboardQuery,
  useStudentPostAssignmentMutation,
} from "../api";
import { SingleViewAssigment } from "../SingleView";
import { reducer } from "../reducer";
import { initialState } from "../reducer/contants";
import { useToasts } from "react-toast-notifications";
import {
  setSingleAssignment,
  toggleModal,
  toggleSingleView,
  setSelectFilterModule,
  setAssignments,
  setRemoveUploadFiles,
  setUploadFiles,
  setSingleViewType,
  setSearch,
} from "../reducer/action";
import { usePostFileUploadMutation } from "../../../common/uploadApi";
import {
  selectUploadProgress,
  setProgress,
} from "../../../common/uploadProgressSlice";
import { useDispatch, useSelector } from "react-redux";
import StudentAssignmentTabs from "../components/StudentAssignmentTabs";

const optionsData = [
  {
    id: "1",
    name: "Assignment to be Submitted",
  },
  {
    id: "2",
    name: "Assignment Submitted",
  },
  {
    id: "3",
    name: "Assignment Results",
  },
];

export const StudentAssignment = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [currentModuleName, setCurrentModuleName] = useState("");
  const [page, setPage] = useState(1);

  const [singleModule, setSingleModule] = useState(null);
  const [singleView, setSingleView] = useState(null);

  // this is a simple file upload success state.
  const [fileSuccess, setFileSuccess] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  // this is a state for the response from file upload api.
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  // retrieve the progress state from redux store.
  const progress = useSelector(selectUploadProgress);

  /**
   * A react-toast-notification hook, for triggering a notification display.
   */
  const { addToast } = useToasts();

  /**
   * A react-router-dom location hooks for getting assignment module from
   * dashboard route.
   */
  const { state: locationState } = useLocation();

  const dispatcher = useDispatch();

  /**
   * Using the useLazyStudentAssignmentsQuery hook to trigger a fetch of student assignment data through the REST API.
   */
  const [onStudentAssignment, { isAssignmentLoading }] =
    useLazyStudentAssignmentsQuery({
      selectFromResult: ({ isLoading, isFetching }) => ({
        isAssignmentLoading: isLoading || isFetching,
      }),
    });

  /**
   * Using the useStudentPostAssignmentMutation hook to trigger a post of student assignment data through the REST API.
   */
  const [onSubmit, { isLoading: postLoader }] =
    useStudentPostAssignmentMutation();

  // Using the usePostFileUploadMutation hook to upload file data through the REST API.
  const [onFileUpload, { isLoading: isFileUploading }] =
    usePostFileUploadMutation();

  /**
   * Using the useGetStudentDashboardQuery hook to fetch student dashboard data through the REST API.
   */
  const { subjects, isLoading } = useGetStudentDashboardQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      subjects: data?.data?.currently_enrolled_modules ?? [],
      isLoading,
    }),
  });

  /**
   * This function takes in a module and sends the module id
   * to the server, to get the assignment lists related to the
   * module id and updates the reducer state: ASSIGNMENTS.
   * @param {object} module
   */
  const handleFetchStudentAssignment = async (module) => {
    // if (module?.id) {
    //   setAssignments(dispatch, []);
    //   return;
    // }
    setAssignments(dispatch, []);
    if (!module) {
      return;
    }
    try {
      const result = await onStudentAssignment({
        page,
        module_id: module?.id ? module?.id : module?.only_name,
      }).unwrap();

      result?.data?.length && setAssignments(dispatch, result?.data ?? []);
    } catch (error) {
      // handles different kinds of errors.
      perseCustomError(error, addToast);
    }
  };

  // TODO: the UI has a different structure, fix it.
  useLayoutEffect(() => {
    let abortController = new AbortController();
    if (locationState?.assignment) {
      setSingleAssignment(dispatch, locationState?.assignment);
      toggleSingleView(dispatch);
    }

    return () => {
      abortController.abort();
    };
  }, [locationState]);

  const handlePageChange = (page) => {
    // changes the current page of content
    setPage(page);
  };

  /**
   * This function handles the upload of the selected pdf
   * files to the api hook trigger.
   * @param {object} file
   */
  const handleUpload = async (file) => {
    let data = new FormData();
    try {
      data.append("file", file);
      let result = await onFileUpload({ body: data }).unwrap();

      if (result?.status !== "success") {
        // returns the upload progress state back to zero.
        dispatcher(setProgress(0));
        perseCustomError(result, addToast);
      }

      // updates the success state
      if (result?.status === "success") {
        dispatcher(setProgress(0));
        setFileSuccess(true);
        setFileUploadResponse(result?.data);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  /**
   * It takes the uploaded file url and the assignment id
   * and sends it to the api hook trigger.
   */
  const handleAssignmentSubmission = async () => {
    try {
      // parse the uploaded file url and assignment id to the api.
      let submitResult = await onSubmit({
        assignment_id: state.selectedAssignment?.id,
        file_url: fileUploadResponse?.file_url,
      }).unwrap();

      // if error occured while parsing the above data, show notification.
      submitResult?.error &&
        addToast(submitResult?.error.data?.message, { appearance: "error" });

      // if successfully while parsing the above data, show notification
      // console.log(submitResult);
      if (submitResult?.data?.id) {
        addToast("Assignment submitted successfully", {
          appearance: "success",
        });
        // hides the upload modal
        toggleModal(dispatch);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  /**
   * Triggered when the search input is more than three in length and equally
   * not null.
   */
  useEffect(() => {
    const handleSearch = async (search = "") => {
      try {
        const result = await onStudentAssignment({
          page,
          search,
        }).unwrap();

        result?.data?.length && setAssignments(dispatch, result?.data ?? []);
      } catch (error) {
        // handles different kinds of errors.
        perseCustomError(error, addToast);
      }
    };
    if (state?.search !== null && state?.search?.trim?.()?.length >= 3) {
      handleSearch(state.search);
    }
  }, [state.search, addToast, page]);

  return (
    <div className="w-full overflow-hidden scrollbar-hide">
      {singleModule ? (
        <>
          <div
            onClick={() => {
              toggleSingleView(dispatch);
              setSingleAssignment(dispatch, null);
              setSingleModule(!singleModule);
              if (singleView) {
                setSingleView(!singleView);
              }
            }}
            className="flex items-center gap-x-2 mb-3 cursor-pointer"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <Typography {...{}}>Back</Typography>
          </div>

          <div className="mb-3 -mt-4 ">
            <Action
              noLive
              noUpload
              //                     uploadAction={uploadCourse}
              // btnText="upload lessons"
            >
              {currentModuleName}
            </Action>
          </div>
        </>
      ) : (
        ""
      )}
      {!singleModule && (
        <div className={state?.modal && `hidden lg:block`}>
          <>
            <Action noLive noUpload>
              Courses
            </Action>
            <StudentAssignmentTabs
              subjects={subjects}
              loading={isLoading}
              handleFetchStudentAssignment={(value) => {
                setSelectFilterModule(dispatch, value);
                handleFetchStudentAssignment(value);
                setCurrentModuleName(value.name);
                setSingleModule(!singleModule);
              }}
            />
          </>
        </div>
      )}
      {singleModule && !singleView && (
        <>
          {!isAssignmentLoading && state.assignments?.length !== 0 && (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-rows-3 gap-3 mb-20 pt-3 overflow-auto scrollbar-hide h-98 lg:pr-0 pr-5 pb-20 lg:pb-0">
              {!isAssignmentLoading &&
                state.assignments.map((item, index) => (
                  <AssignmentCard
                    {...{
                      key: index,
                      assignment: item,
                      onDownload: (file_url, module) =>
                        handleDownloadFile(file_url, module),
                      onGradedAssignment: () => {
                        setSingleView(!singleView);
                        setSingleViewType(dispatch, optionsData[2]?.name);
                        setSingleAssignment(dispatch, item);
                        toggleSingleView(dispatch);
                      },
                      onUpload: () => {
                        setSingleAssignment(dispatch, item);
                        setShowUpload(!showUpload);
                        toggleModal(dispatch);
                      },
                      onAssignmentView: () => {
                        setSingleAssignment(dispatch, item);
                        toggleSingleView(dispatch);
                        setSingleView(!singleView);
                      },
                    }}
                  />
                ))}
            </div>
          )}
          {isAssignmentLoading && <CardPlaceholderLoader />}
          {!isAssignmentLoading && state.assignments?.length === 0 && (
            <div className="h-72">
              <EmptyContent message="No Assignment yet, Select another module" />
            </div>
          )}
        </>
      )}

      {singleView && (
        <SingleViewAssigment
          {...{
            selectedAssignment: state?.selectedAssignment,
            selectFilter: state?.showSingleType,
            optionsData,
            dispatcher: dispatch,
            state,
          }}
        />
      )}

      {showUpload && (
        <UploadModal
          {...{
            progress,
            open: state.modal,
            uploadSuccess: fileSuccess,
            uploadFiles: state.uploadFiles,
            submitButtonLoader: postLoader,
            onUpload: (item) => handleUpload(item),
            moduleName: state.selectedAssignment?.module?.only_name,
            onClose: () => {
              setShowUpload(!showUpload);
              return toggleModal(dispatch);
            },
            onSubmit: handleAssignmentSubmission,
            onRemove: (item) => setRemoveUploadFiles(dispatch, item?.name),
            onAcceptedFiles: (value) => setUploadFiles(dispatch, value),
            submitButtonDisabled:
              state?.uploadFiles?.length === 0 || isFileUploading,
          }}
        />
      )}
    </div>
  );
};
