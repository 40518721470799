import { AdjustmentsIcon, SearchIcon } from "@heroicons/react/outline";
import { Form } from "react-final-form";
import { FieldArray } from ".";
import { required } from "./InputValidate";

const Search = ({ action }) => {
  const Fields = [
    {
      name: "search",
      type: "text",
      placeholder: "Search",
      validate: required,
      containerStyles: "w-full",
    },
  ];

  return (
    <div className="flex mt-2 lg:justify-end items-center">
      {/* <AdjustmentsIcon className="h-5 w-5 mr-3" /> */}
      <div className="w-full flex items-center lg:w-1/4 lg:mr-0">
        <Form
          onSubmit={action}
          render={({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} className="w-full items-center flex">
              <FieldArray field={Fields} />
              <button
                type="submit"
                disabled={submitting || hasValidationErrors}
              >
                <SearchIcon
                  className={`h-5 w-5 -ml-7 cursor-pointer ${
                    submitting || (hasValidationErrors && "cursor-not-allowed")
                  }`}
                />
              </button>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default Search;
