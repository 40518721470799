import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Button } from ".";

export const DropZone = ({ accept, onAcceptedFiles, styles, text }) => {

  const onDrop = useCallback(acceptedFiles => {
    onAcceptedFiles && onAcceptedFiles(acceptedFiles);
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
    noKeyboard: true,
    multiple: false,
  });


  return (
    <div
      {...getRootProps()}
      className={`flex flex-col items-center  ${styles}`}
    >
      <input {...getInputProps()} />
      {!isDragActive && !isDragReject ? (
        <>
          <Typography
            {...{
              variant: "body1",
              className: "mb-4",
              color: "defaultBody",
            }}
          >
            {text}
          </Typography>
          <Typography
            {...{
              variant: "body1",
              className: "mb-4",
              color: "defaultBody",
              uppercase: true,
            }}
          >
            or
          </Typography>
          <Button
            {...{
              variant: "contained",
              className: "text-white rounded bg-red",
              sizes: "sm",
              shadow: "none",
              uppercase: true,
              // onClick: open,
            }}
          >
            browse
          </Button>
        </>
      ) : (
        <div>
          <Typography
            {...{
              variant: "body1",
              // className: "mb-7",
              color: "defaultBody",
            }}
          >
            Release to drop the files here
          </Typography>
        </div>
      )}
      {isDragReject && (
        <>
          <Typography
            {...{
              variant: "body1",
              className: "mb-7",
              color: "red",
            }}
          >
            File format not supported
          </Typography>
          <Typography
            {...{
              variant: "body1",
              className: "mb-7",
              color: "defaultBody",
              uppercase: true,
            }}
          >
            or
          </Typography>
          <Button
            {...{
              variant: "contained",
              className: "text-white rounded bg-red",
              sizes: "sm",
              shadow: "none",
              uppercase: true,
              // onClick: open,
            }}
          >
            browse
          </Button>
        </>
      )}
    </div>
  );
};
