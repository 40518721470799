import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "./baseQuery";

export const academicCalenderApis = createApi({
  reducerPath: "academic_calender",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getAcademicCalender: builder.query({
      query: () => "/academic-calenders",
    }),
  }),
});

export const { useGetAcademicCalenderQuery } = academicCalenderApis;
