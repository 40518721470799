import {
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import { avatar } from "../../../assets";
import { Action, Button, Typography } from "../../../common/sharedComponent";

const Assignment = () => {
  const [grade, setGrade] = useState(false);

  const grades = [
    { id: "A", grade: "A Grade" },
    { id: "B", grade: "B Grade" },
    { id: "C", grade: "C Grade" },
    { id: "D", grade: "D Grade" },
    { id: "E", grade: "E Grade" },
    { id: "F", grade: "F Grade" },
  ];
  const selectGrade = (id) => {
    // console.log(id);
    setGrade(false);
  };
  return (
    <>
      <Action noButton>Assignment Title Here</Action>
      <div className=" flex relative flex-col-reverse lg:flex-row lg:bg-gray-200 lg:justify-between lg:py-3 lg:px-5">
        <div className="grid bg-gray-200 mt-5 py-2 grid-cols-4  items-center lg:py-0 lg:mt-0 lg:bg-none lg:grid-cols-5">
          <img
            src={avatar}
            alt={"student"}
            className="h-10 w-10 rounded-full ml-5"
          />
          <Typography className="col-span-2">{"Rachael Lebsack"}</Typography>
          <Typography className=" hidden lg:flex lg:col-span-2">
            {"Tue Jun 04 2058 04:17:23 GMT+0500"}
          </Typography>
          <div className="flex justify-end mr-5 lg:hidden">
            <DotsVerticalIcon className="h-6 w-6" />
          </div>
        </div>
        <Button
          variant="contained"
          className="flex justify-between items-center w-52 px-3"
          sizes={"md"}
          onClick={() => setGrade(!grade)}
        >
          Grade Assignment
          {!grade ? (
            <ChevronDownIcon className="h-5 w-5 ml-7" />
          ) : (
            <ChevronUpIcon className="w-5 h-5 ml-7" />
          )}
        </Button>
        {grade && (
          <div className="absolute w-full rounded-md shadow bg-white ring-1 ring-black ring-opacity-5 lg:w-72 lg:right-0 lg:mr-5 lg:mt-20 focus:outline-none">
            {grades.map((grade) => (
              <Typography
                key={grade.id}
                className="py-2 pl-5  border-b cursor-pointer"
                onClick={() => selectGrade(grade.id)}
              >
                {grade.grade}
              </Typography>
            ))}
          </div>
        )}
      </div>
      <div className="mt-20">
        <Typography className="text-center" variant="subheader2">
          {"Title"}
        </Typography>
        <Typography className="mt-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </div>
    </>
  );
};

export default Assignment;
