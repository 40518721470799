import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";
import { teacherModuleAllocation } from "../../../helpers";

const lessonBaseUrl = "/students/lessons";

export const LessonApis = createApi({
  reducerPath: "lessons",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getLessons: builder.query({
      query: ({ page = 1, module_id = "all" }) =>
        `${lessonBaseUrl}?page=${page}&module=${module_id}`,
    }),
    getModuleLesson: builder.query({
      query: ({ teacher, module, page }) =>
        `${teacherModuleAllocation}/${teacher}/modules/${module}/lessons?adminStatus=not-end`,
    }),
    getSingleLessons: builder.query({
      query: (module_id) => `${lessonBaseUrl}/${module_id}`,
    }),
    getStudentDashboard: builder.query({
      query: () => "/students/dashboard",
    }),
    getTeacherLessonById: builder.query({
      query: ({ id }) => `/teachers/lessons/${id}`,
    }),
  }),
});

export const {
  useGetLessonsQuery,
  useGetModuleLessonQuery,
  useGetSingleLessonsQuery,
  useGetStudentDashboardQuery,
  useGetTeacherLessonByIdQuery,
} = LessonApis;
