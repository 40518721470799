import { useSelector } from "react-redux";
import { currentUser, role } from "../../../../features/login/authSlice";
import { Avatar, Badge, Typography } from "../..";
import { ChevronDownIcon, MenuIcon } from "@heroicons/react/solid";
import { BellIcon } from "@heroicons/react/outline";
import { TEACHER_ROLE } from "../../../variables";
import { useNotificationQuery } from "../../../notificationApi";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_ROUTE } from "../../../../routes";

const Header = ({ onMenu, onSideNav }) => {
  const user = useSelector(currentUser);
  const userRole = useSelector(role);
  const { data } = useNotificationQuery();
  const navigate = useNavigate();

  return (
    <div
      className={
        userRole === TEACHER_ROLE
          ? "flex bg-white w-full h-fit items-center justify-between lg:border-b-2 lg:justify-end lg:flex-row lg:text-black py-2 shadow overflow-hidden"
          : "flex w-full h-fit items-center justify-between bg-white border-b-2 lg:justify-end text-black py-2 shadow overflow-hidden"
      }
    >
      <div className="hidden lg:block flex-1 ml-3">
        <Typography
          style={{ color: "#888888" }}
          {...{
            className: "font-inter font-[400] text-[16px] leading-[19.36px]",
          }}
        >
          Welcome to
        </Typography>
        <Typography
          style={{ color: "#006B5D" }}
          {...{
            className: "font-inter font-[600] text-[24px] leading-[29.05px]",
          }}
        >
          HillCross College
        </Typography>
      </div>
      <MenuIcon onClick={onSideNav} className="h-8 w-8 ml-3 lg:hidden" />

      {/* {userRole === TEACHER_ROLE && <TeacherHeader />} */}
      <div className="flex pr-5 items-center gap-x-3">
        <div
          onClick={() => navigate(NOTIFICATION_ROUTE, { state: data })}
          className="hover:bg-gray-300 hover:p-2 hover:mr-3 rounded-full mr-5"
        >
          <Badge number={data?.data.length ?? 0} className="hidden lg:block">
            <BellIcon
              style={{ color: "#006B5D" }}
              className="h-6 w-6 text-slate-500 cursor-pointer hidden lg:block"
            />
          </Badge>
        </div>
        <div className="h-10 w-10">
          <Avatar
            name={user?.profile?.avatar}
            avatarAlt={user?.avatar_alternative}
            sizes="10"
          />
        </div>
        <Typography
          style={{ color: "#006B5D" }}
          {...{ className: "hidden lg:block" }}
        >{`${user?.first_name} ${user?.last_name}`}</Typography>
        <ChevronDownIcon onClick={onMenu} className="h-5 w-5 cursor-pointer" />
      </div>
    </div>
  );
};

export default Header;
