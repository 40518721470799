import Typography from "./Typography";
import UserIcon from "../../assets/user_icon.png";
import { useLocation } from "react-router-dom";

const Avatar = ({
  name,
  className,
  avatarAlt,
  altContainerClass,
  sizes = "12",
}) => {
  const location = useLocation();
  const isDashboardRoute = location.pathname === "/dashboard";
  return !name && avatarAlt ? (
    !isDashboardRoute ? (
      <div
        className={`h-${sizes} w-${sizes} rounded-full grid place-items-center bg-on-primary-container/80 ${altContainerClass}`}
      >
        <Typography {...{ color: "white" }}>{avatarAlt}</Typography>
      </div>
    ) : (
      <img
        src={UserIcon}
        alt={`Avatar`}
        className={`w-full h-full object-cover rounded-full ${className}`}
      />
    )
  ) : (
    <img
      src={name}
      alt={`${name} avatar`}
      className={`w-full h-full object-cover rounded-full ${className}`}
    />
  );
};

export default Avatar;
