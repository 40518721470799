import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../common/baseQuery";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery((headers, getState) => {
    const token = getState().auth.token;
    if (token) return headers.set("authorization", `Bearer ${token}`);
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/login",
        method: "POST",
        body: data,
      }),
    }),
    logOut: builder.query({
      query: () => "/logout",
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/forgot-password",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "reset-password",
        method: "POST",
        body: data,
      }),
    }),
    patchProfile: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "patch",
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: "users/change-password",
        method: "Post",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogOutQuery,
  usePatchProfileMutation,
  useChangePasswordMutation,
} = authApi;
