import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useState } from "react";

const Input = ({
  name,
  label,
  type,
  placeholder,
  error,
  styles,
  options,
  rows,
  textarea,
  className,
  min,
  ...input
}) => {
  const today = new Date();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={`flex flex-col ${styles} `}>
        {label && (
          <label
            htmlFor={name}
            className={`text-subheader2 text-on-background mb-1.5 ${
              (type === "checkbox" || type === "radio") && "hidden"
            }`}
          >
            {label}
          </label>
        )}

        {type === "textarea" ? (
          <textarea
            id={name}
            rows={rows}
            placeholder={placeholder}
            {...input}
            className={`${
              error ? "border-red" : "border-slate-400"
            } border-2 rounded-sm ${textarea}`}
          ></textarea>
        ) : type === "select" ? (
          <>
            <select id={name} {...input}>
              <option value={""} disabled defaultValue={placeholder}>
                {placeholder}
              </option>
              {options?.map((option) => (
                <option
                  key={option?.id}
                  value={option?.id}
                  className="ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container"
                >
                  {option?.name}
                </option>
              ))}
            </select>
          </>
        ) : type === "checkbox" || type === "radio" ? (
          <>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id={name}
                  value={name}
                  {...input}
                  type={type}
                  className="ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container"
                />
              </div>
              <div className="ml-3">
                <label
                  htmlFor={name}
                  className="text-subheader2 text-on-background"
                >
                  {label}
                </label>
              </div>
            </div>
          </>
        ) : name === "password" ? (
          <div className="flex flex-row space-x-0 w-full">
            <input
              id={name}
              {...input}
              type={visible ? "text" : "password"}
              placeholder={placeholder}
              className={`${
                error ? "border-red" : "border-tertiary-container/40"
              } border-2 rounded-l-sm h-10 focus:ring-tertiary-container focus:border-tertiary-container w-[80%] sm:w-[90%] text-body2 ${className}`}
            />
            {!visible ? (
              <AiOutlineEyeInvisible
                onClick={(e) => setVisible(!visible)}
                className={`${
                  error ? "border-red" : "border-tertiary-container/40"
                } border-2 rounded-r-sm h-10 p-2 text-black w-[20%] sm:w-[10%] focus:ring-tertiary-container focus:border-tertiary-container `}
              />
            ) : (
              <AiOutlineEye
                onClick={(e) => setVisible(!visible)}
                className={`${
                  error ? "border-red" : "border-tertiary-container/40"
                } border-2 rounded-r-sm h-10 p-2 text-black w-[20%] sm:w-[10%] focus:ring-tertiary-container focus:border-tertiary-container `}
              />
            )}
          </div>
        ) : (
          <>
            <input
              id={name}
              {...input}
              type={type}
              min={
                type === "date" &&
                min &&
                new Date(today.setDate(today.getDate() + 1))
                  .toISOString()
                  .split("T")[0]
              }
              placeholder={placeholder}
              className={`${
                error ? "border-red" : "border-tertiary-container/40"
              } border-2 rounded-sm h-10 focus:ring-tertiary-container focus:border-tertiary-container text-body2 ${className}`}
            />
          </>
        )}
      </div>
    </>
  );
};
export default Input;
