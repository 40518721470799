import { useDispatch, useSelector } from "react-redux";
import {  useGetTeacherModulesQuery } from "../../../features/dashboard/api";
import { currentUser } from "../../../features/login/authSlice";
import {  findTeacher } from "../../../helpers";
import { setLoadingModule, setModules, setTeacherModule } from "../../moduleSlice";

const Teacher = ({ children }) => {
  const dispatch = useDispatch();
  const teacher = useSelector(currentUser)
  const { module, loadModule } = useGetTeacherModulesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      loadModule: isLoading,
      module: findTeacher(data, teacher?.id)
    }),
  });
  dispatch(setModules(module?.modules??[]));
  dispatch(setLoadingModule(loadModule));
  dispatch(setTeacherModule(module))
  return <>{children}</>;
};

export default Teacher;
