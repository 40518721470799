import { messageAction,  MessageActionKind } from "../types/index";

interface singleMessage {
  body: string;
  owner: boolean;
  reference: string;
  sender: string;
}
interface messageStateTypes {
  module: null;
  message: {
    isNew: false;
    input: string;
    reset: false;
    messages: singleMessage[];
  };
  chat: {
    selected: null;
  };
  recipient: {
    id: "1";
    only_name: "Student";
  };
  newContacts: singleMessage[];
}

export const reducer = (
  state: messageStateTypes,
  action: messageAction
): messageStateTypes => {
  switch (action.type) {
    case MessageActionKind.ADD_CONTACTS:
      return {
        ...state,
        newContacts: action.payload,
      };
    case MessageActionKind.UPDATE_CONTACT:
      return {
        ...state,
        newContacts: [...state?.newContacts, action.payload],
      };
    case MessageActionKind.CHAT_SELECTION:
      return {
        ...state,
        chat: {
          selected: action.payload,
        },
      };
    case MessageActionKind.ADD_INPUT_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          input: action.payload,
        },
      };
    case MessageActionKind.RESET_MESSAGES:
      return {
        ...state,
        message: {
          ...state.message,
          messages: [],
          reset: action.payload,
        },
      };
    case MessageActionKind.CHANGE_RECIPIENT_TYPE:
      return {
        ...state,
        recipient: action.payload,
      };
    case MessageActionKind.CHANGE_MODULE:
      return {
        ...state,
        module: action.payload,
      };
    case MessageActionKind.CLEAR_INPUT_MESSAGE:
      return {
        ...state,
        message: { ...state.message, input: "" },
      };
    case MessageActionKind.ADD_MESSAGES:
      return {
        ...state,
        message: { ...state.message, messages: action.payload },
      };
    case MessageActionKind.UPDATE_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          messages: [...state.message.messages, action.payload],
        },
      };
    default:
      return state;
  }
};
