import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { userModule } from "../../common/moduleSlice";
import { FieldArray, Form } from "../../common/sharedComponent";
import {
  composeValidators,
  minNum,
  required,
} from "../../common/sharedComponent/InputValidate";
import { usePostTeacherClassroomMutation } from "./api";

const NewClassroom = ({ submit, edit, editTask }) => {
  const { addToast } = useToasts();
  const [postClass, { isLoading }] = usePostTeacherClassroomMutation();
  const modules = useSelector(userModule);

  const Duration = [
    {
      name: "duration",
      type: "number",
      label: "Duration",
      placeholder: "Time in Minutes",
      validate: composeValidators(required, minNum(30)),
    },
    {
      name: "class_date",
      type: "date",
      label: "Start Date",
      validate: required,
    },
    {
      name: "start_time",
      type: "time",
      label: "Start Time",
      validate: required,
    },
  ];

  const Fields = [
    {
      name: "meeting_name",
      type: "text",
      label: "Meeting Name",
      validate: required,
    },
    {
      name: "module_id",
      type: "select",
      placeholder: "Select Module",
      validate: required,
      // containerStyles: "lg:mt-6",
      label: "Select Module",
      option: modules,
    },
  ];
  const onSubmit = async (values) => {
    if (edit === 1) {
    } else {
      try {
        const res = await postClass({ body: values });
        // console.log(values);
        // console.log('res',res)
        if (res?.error) {
          addToast(res?.error.data?.message, { appearance: "error" });
        } else {
          addToast("New Classroom Succefully Addded", {
            appearance: "success",
          });
          submit();
        }
      } catch (e) {
        console.log(e, "err");
        addToast("An error occurred, please try again later", {
          appearance: "error",
        });
      }
    }
  };

  return (
    <div className="px-5">
      <Form
        btnStyles="my-5 "
        buttonText="Create New Class"
        onSubmit={onSubmit}
        end={true}
        isLoading={isLoading}
        values={edit === 1 && editTask}
      >
        <div className="">
          <div className="mt-7 flex flex-col lg:mt-5">
            <div className="flex  flex-col space-y-5  lg:flex-row  lg:space-x-3 lg:space-y-0">
              <FieldArray field={Fields} />
            </div>
            <div className="flex  flex-col space-y-5  lg:flex-row  lg:space-x-3 lg:mt-4 lg:space-y-0">
              <FieldArray field={Duration} />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default NewClassroom;
