import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Form, FieldArray } from ".";
import { useTeacherPostPdfAnnotationMutation } from "../../features/assignment/api";
import { perseCustomError } from "../../helpers";
import { annotatedExam, setAnnotatedExam } from "../moduleSlice";
import { composeValidators, maxNum, required } from "./InputValidate";

export const GradePDF = ({ annotatedPdf, refetch, close }) => {
  const isAnnotatedPdf = useSelector(annotatedExam);
  const dispatch = useDispatch();
  const [gradeAnnotated, { isLoading }] = useTeacherPostPdfAnnotationMutation();
  const { addToast } = useToasts();

  const grade = [
    {
      name: "score",
      type: "number",
      validate: composeValidators(required),
      label: "Add Score",
      style: "lg:col-span-1",
      //   containerStyles: "w-full",
    },
  ];

  const onSubmit = async ({ score }) => {
    const data = {
      student_id: annotatedPdf?.student_uuid,
      resource_id: annotatedPdf?.id,
      score,
      // total: annotatedPdf?.total,
      file_url: isAnnotatedPdf?.annotated,
      type: isAnnotatedPdf?.type,
    };
    const res = await gradeAnnotated({ data });
    if (res?.error) {
      perseCustomError(res?.error, addToast);
    } else {
      dispatch(setAnnotatedExam(""));
      addToast(`${isAnnotatedPdf?.type} successfully graded`, {
        appearance: "success",
      });
    }
    close();
    refetch();
  };

  return (
    <div className=" flex justify-center flex-col p-5">
      {isAnnotatedPdf.student_id === annotatedPdf?.student_uuid ? (
        <>
          <div className="card1 exam-title">
            Add score for {annotatedPdf?.student_name}
          </div>
          <Form
            btnStyles="my-5"
            buttonText="Grade Examination"
            end={true}
            onSubmit={onSubmit}
          >
            <div className="">
              <FieldArray field={grade} />
            </div>
          </Form>
        </>
      ) : (
        <div className="card1 exam-title">Add Annotation before grade</div>
      )}
    </div>
  );
};
