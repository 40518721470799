import { useEffect, useState } from "react";
import {
  Action,
  Button,
  CardPlaceholderLoader,
  Typography,
} from "../../../common/sharedComponent";
import "./teachernew.css";
import AllEdit from "./editexam";
import {
  useDeleteExaminationMutation,
  useTeacherExaminationsQuery,
} from "../api";
import { Upload } from "../../dashboard/components";
import {
  ArrowLeftIcon,
  ChevronDoubleDownIcon,
  CogIcon,
} from "@heroicons/react/outline";
import ExaminationCbt from "../component/ExaminationCbt";
import Moment from "react-moment";
import { Submitted } from "../component";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom";
import { required } from "../../../common/sharedComponent/InputValidate";
import cover from "../../../assets/hillcross-dark-examination.png";
import lock from "../../../assets/Vector.png";
import lock1 from "../../../assets/Vector1.png";
import edit from "../../../assets/edit.png";
import { SubmittedExamination } from ".";
import { NewCardLayout } from "../../../common/sharedComponent/MediaLayout";

export const Teacher = () => {
  const [detailedView, setDetailedView] = useState(false);
  const [editState, setEditState] = useState(false);
  const [detail, setDetails] = useState(null);
  const [actionValue, setActionValue] = useState(null);
  const [activeSubject, setActiveSubject] = useState(null);
  const [floatAction, setFloatAction] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [task, setTask] = useState(null);
  const [viewSubmissionId, setViewSubmissionId] = useState(null);
  const [page, setPage] = useState(1);
  const [editingExam, setEditingExam] = useState(null);
  const [viewExam, setViewExam] = useState(null);
  const [viewExamState, setViewExamState] = useState(false);
  const { addToast } = useToasts();
  const { state } = useLocation();
  const { examinatons, loading, meta, refetch } = useTeacherExaminationsQuery(
    { id: page, module_id: activeSubject?.id },
    {
      selectFromResult: ({ data, isLoading }) => ({
        examinatons: data?.data ?? [],
        loading: isLoading,
        meta: data?.meta,
      }),
    }
  );

  const [deleteExam, { isLoading: deleting }] = useDeleteExaminationMutation();

  const allExamination = examinatons.map((exam) => {
    return {
      calender: exam?.academic_calender?.name,
      module: exam?.module?.name,
      module_id: exam?.module?.id,
      exam_date: exam?.exam_date,
      start_time: exam?.start_time,
      total_questions: exam?.total_questions,
      total_questions_answerable: exam?.answerable_questions,
      total_score: exam?.total_score,
      adminStatus: exam?.admin_approval,
      duration: exam?.exam_duration,
      status: exam?.status,
      id: exam?.id,
      start_time: exam?.start_time,
      // file: exam?.file_url,
      // type:exam?.examination_type
    };
  });

  const uploadCourse = () => {
    // setShowUpload(!showUpload);
    // setFloatAction(false);
    setShowUpload(!showUpload);
    if (!showUpload) {
      setTask(null);
    }
  };

  useEffect(() => {
    if (state) {
      setDetailedView(true);
    }
  }, [state]);

  // console.log(state);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const deleted = async (id) => {
    setFloatAction(false);
    try {
      const res = await deleteExam(id);
      if (res?.error) {
        addToast(res?.error.data?.message, { appearance: "error" });
      } else {
        addToast("Examination Succefully Deleted", { appearance: "success" });
      }
    } catch (e) {
      addToast("An error occurred, Question not posted", {
        appearance: "error",
      });
    }
  };

  const setAction = (i) => {
    setFloatAction(!floatAction);
    setActionValue(i);
  };

  const submissionView = (id) => {
    setDetailedView(true);
    setViewSubmissionId(id);
  };

  const editExam = (editingExam) => {
    setTask(5);
    setEditingExam(editingExam);
  };

  const viewDetails = (data) => {
    setDetailedView(true);
    setDetails(data);
  };

  const setReset = () => {
    setDetailedView(false);
    setViewSubmissionId(null);
  };

  const columns = [
    {
      accessor: "module",
    },
    {
      accessor: "exam_date",
    },
    {
      accessor: "start_time",
      validate: required,
    },
    {
      accessor: "duration",
      validate: required,
    },
    {
      accessor: "module_id",
    },
    {
      accessor: "total_questions",
    },
    {
      accessor: "total_questions_answerable",
    },
    {
      accessor: "total_score",
    },
    {
      accessor: "type",
    },
    {
      accessor: "adminStatus",
    },
    {
      accessor: "status",
    },
    {
      accessor: "file",
    },
    {
      accessor: "id",
      Cell: ({ cell: { row } }) => {
        return (
          <div
            className=" rounded-md relative  border border-red"
            onClick={() => setAction(row.id)}
          >
            <div className="cursor-pointer px-2.5 py-1 flex space-x-1">
              <CogIcon className="w-4 h-4" />
              <ChevronDoubleDownIcon className="w-4 h-4" />
            </div>
            {floatAction && actionValue === row.id && (
              <div className="absolute rounded-md z-40 shadow bg-white ring-1 ring-black ring-opacity-5 mt-1 focus:outline-none right-0 w-20">
                {row.values.adminStatus !== "pending" && (
                  <Typography
                    onClick={() => editExam(row.values)}
                    className="pt-1 text-center cursor-pointer hover:bg-gray-200"
                    variant={"caption"}
                  >
                    Edit
                  </Typography>
                )}
                {row.values.adminStatus !== "pending" && (
                  <Typography
                    className="pt-1 text-center cursor-pointer hover:bg-danger hover:text-white"
                    variant={"caption"}
                    color="red"
                    onClick={() => deleted(row.values.id)}
                  >
                    Delete
                  </Typography>
                )}
                <Typography
                  className="pt-1 text-center cursor-pointer hover:bg-gray-200"
                  variant={"caption"}
                  onClick={() => viewDetails(row.values)}
                >
                  Details
                </Typography>
                <Typography
                  className="pt-1 text-center cursor-pointer hover:bg-gray-200"
                  variant={"caption"}
                  onClick={() => submissionView(row.values.id)}
                >
                  Submitted
                </Typography>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const initialState = {
    hiddenColumns: ["duration", "module_id", "file"],
  };

  return (
    <div className="mb-20">
      {detailedView && (
        <div
          onClick={() => setReset()}
          className="flex items-center gap-x-2 mb-3 cursor-pointer"
        >
          <ArrowLeftIcon className="h-5 w-5" />
          <Typography {...{}}>Back</Typography>
        </div>
      )}
      {!editState && !viewExamState && (
        <div className="w-full scrollbar-hide">
          {!detailedView && (
            <>
              <Action
                className="new_btn1"
                noLive
                uploadAction={uploadCourse}
                btnText={"New Exam"}
              >
                Examinations
              </Action>
              <div className="pt-2">
                <div className="mb-3 text-center p-2 ml-2 mr-6 bg-white">
                  <span>All available examinations are shown below</span>
                </div>
                {!detailedView && (
                  <div className="grid  lg:grid-cols-3 grid-rows-3 gap-3 w-full  pt-3 pb-20 overflow-auto scrollbar-hide lg:h-98 lg:pr-0 pr-5 lg:pb-0">
                    {!detailedView &&
                      examinatons?.map((exam) => {
                        const minutes = parseInt(exam?.exam_duration)
                          ? parseInt(exam?.exam_duration) % 60 === 0
                            ? ""
                            : (parseInt(exam?.exam_duration) % 60) + " minutes"
                          : "";
                        const hour = exam?.exam_duration
                          ? Math.floor(parseInt(exam?.exam_duration) / 60)
                          : "";

                        return (
                          <NewCardLayout
                            {...{
                              key: exam?.id,
                              isNewContent:
                                examinatons?.has_done === "not_done" ||
                                !examinatons?.has_submitted,
                              containerClass: "lg:h- pb-3",
                              imgContainer:
                                "w-full rounded-tl-md rounded-tr-md grid place-items-center  pb-2 relative ",
                              img: (
                                <>
                                  <img
                                    className=" w-100 rounded-lg"
                                    src={cover}
                                  />
                                  <div className="absolute bottom-6 left-9">
                                    <h1 className="exam_name">
                                      {exam?.module?.only_name}
                                    </h1>
                                  </div>
                                </>
                              ),
                              children: (
                                <>
                                  <div className="flex items-center justify-between mt-3">
                                    <Button
                                      {...{
                                        variant: "",
                                        className:
                                          "bg-[#E5F0EE] rounded-lg text-[#006b5d] text-[16px] px-3 font-[500] btn  text-center",
                                        sizes: "sm",
                                        shadow: "none",
                                        onClick: () => {
                                          setEditState(true);

                                          setEditingExam(exam);
                                        },

                                        capitalize: true,
                                      }}
                                    >
                                      <div className="flex justify-center items-center">
                                        {
                                          <span className="mr-2">
                                            <img src={edit} />
                                          </span>
                                        }
                                        <span> edit</span>
                                      </div>
                                    </Button>
                                    <Button
                                      {...{
                                        variant: "",
                                        className:
                                          "bg-[#E5F0EE] rounded-lg text-[#006b5d] text-[16px] px-3 font-[500] btn  text-center btn text-center",
                                        sizes: "sm",
                                        shadow: "none",
                                        onClick: () => {
                                          setViewExamState(true);

                                          setViewExam(exam);
                                        },

                                        capitalize: true,
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                  {!detailedView && (
                                    <>
                                      <div className="card1 py-1 px-5 w-full justify-between items-center ">
                                        <div className="rounded-md mb-2 flex justify-between items-center">
                                          <Button
                                            {...{
                                              variant: "",
                                              className: "btn2 btn hidden",
                                              sizes: "fullwidth",
                                              shadow: "none",
                                              capitalize: true,
                                            }}
                                          >
                                            <div className="flex">
                                              {exam?.status ===
                                              "Unlock Portal" ? (
                                                <span className="mx-1">
                                                  <img src={lock1} />
                                                </span>
                                              ) : (
                                                <span className="mx-1">
                                                  <img src={lock} />
                                                </span>
                                              )}
                                              {exam?.status
                                                ? "Unlock Portal"
                                                : "Unlocked"}
                                            </div>
                                          </Button>
                                        </div>
                                        <div className="flex ">
                                          <Typography
                                            className="typo_text mr-1"
                                            variant="body"
                                            capitalize
                                          >
                                            Available: {exam?.available_at}
                                            {" | "}
                                            {exam?.exam_start_time}
                                          </Typography>
                                          <Typography
                                            className="typo_text"
                                            variant="body"
                                            capitalize
                                          >
                                            {exam?.start_time}
                                          </Typography>
                                        </div>
                                        <div className="flex">
                                          <Typography className="typo_text mr-1">
                                            Duration:
                                          </Typography>

                                          <Typography className="typo_text mr-1">
                                            {hour
                                              ? hour +
                                                " hour(s) " +
                                                (minutes ?? "")
                                              : minutes ?? ""}
                                          </Typography>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {detailedView && <></>}
                                </>
                              ),
                            }}
                          />
                        );
                      })}
                  </div>
                )}
                {/* <Table
                {...{
                  columns,
                  data: allExamination,
                  isLoading: loading,
                  initialState,
                  currentPage: page,
                  totalPages: meta?.last_page,
                  onChangePaginate: handlePageChange,
                }}
              /> */}
              </div>
              {/* <Fab type={"Upload Examinaton"} upload={uploadCourse} /> */}
            </>
          )}
        </div>
      )}
      {loading && <CardPlaceholderLoader />}
      {((detailedView && !viewSubmissionId) || (detailedView && state)) && (
        // <TeacherView id={detailedView} toEdit={task} deleted={deleted} />
        <ExaminationCbt value={detail || state} />
      )}
      {viewSubmissionId && <Submitted id={viewSubmissionId} />}
      {showUpload && (
        <Upload
          action={uploadCourse}
          edit={task}
          editTask={editingExam}
          taskTitle={["Examinations"]}
          taskId={5}
          open={showUpload}
          refetch={refetch}
        />
      )}
      {editState && (
        <AllEdit exam={editingExam} onBack={() => setEditState(false)} />
      )}

      {viewExamState && (
        <SubmittedExamination
          exam={viewExam}
          back={() => setViewExamState(false)}
        />
      )}
    </div>
  );
};
