import React from "react";
import { useTable, useRowSelect } from "react-table";
import { CustomPagintion, EmptyContent } from ".";

export const Table = ({
  columns,
  data,
  isLoading,
  onChangePaginate,
  currentPage,
  totalPages,
  onSelection,
  initialState,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable({
    columns,
    data,
    initialState,
  });

  return (
    <div className="flex flex-col w-full sm:px-6 lg:px-8 mt-7 lg:mt-0 overflow-x-auto lg:overflow-hidden">
      <div className="-my-2 sm:-mx-6 lg:-mx-8  shadow sm:rounded-lg border-b border-gray-200">
        <div className="align-middle inline-block min-w-full  h-96 overflow-auto scrollbar-hide">
          {!isLoading && rows.length !== 0 && (
            <table
              className="min-w-full max-w-full divide-y divide-gray-200"
              {...getTableProps()}
            >
              {/* <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#006B5D",
                        }}
                        className="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-on-background uppercase tracking-wider static"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead> */}

              <tbody
                className={` bg-white divide-y divide-gray-200`}
                {...getTableBodyProps()}
              >
                {!isLoading &&
                  rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#006B5D",
                              }}
                              className="capitalize px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-on-background "
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          {isLoading &&
            [1, 2, 3, 4, 5, 6].map((item) => (
              <div
                key={item}
                className="h-10 w-full bg-gray-200 animate-pulse my-1"
              ></div>
            ))}
          {!isLoading && rows.length === 0 && (
            <EmptyContent {...{ message: "No content" }} />
          )}
        </div>
        <div className="flex justify-center py-3 border-t">
          <CustomPagintion
            {...{
              currentPage,
              onChangePaginate,
              totalPages,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    let abortController = new AbortController();
    setValue(initialValue);
    return () => {
      abortController.abort();
    };
  }, [initialValue]);
  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};
