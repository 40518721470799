import { useSelector } from "react-redux";
import { AdminLayout, PageLayout, StudentLayout, TeacherLayout } from ".";
import { role } from "../../../features/login/authSlice";
import { STUDENT_ROLE, TEACHER_ROLE } from "../../variables";

const Main = ({ children }) => {
  const userRole = useSelector(role);
  return (
    <main className="h-full">
      <PageLayout>
        {userRole === STUDENT_ROLE ? (
          <StudentLayout>{children}</StudentLayout>
        ) : userRole === TEACHER_ROLE ? (
          <TeacherLayout>{children}</TeacherLayout>
        ) : (
          <AdminLayout>{children}</AdminLayout>
        )}
      </PageLayout>
    </main>
  );
};
export default Main;
