import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progress: 0
}

const uploadProgressSlice = createSlice({
  name: 'upload_progress',
  initialState,
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    }
  }
})


export const { setProgress } = uploadProgressSlice.actions;
export const reducer = uploadProgressSlice.reducer;
export const selectUploadProgress = state => state.uploadProgress.progress;