import {
  Typography,
  Comments,
  CommentInput,
  PdfViewer,
} from "../../common/sharedComponent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { currentUser } from "../../features/login/authSlice";
import AudioPlayer from "react-audio-player";
// import PDFFile from "../../assets/HillCross e-Learning.pdf";

const VideoComponent = ({ src }) => {
  return (
    <div className="lg:h-[512px] w-full mb-8">
      <iframe
        {...{ src, className: "w-full aspect-video", title: "e" }}
      ></iframe>
    </div>
  );
};

export const SingleView = ({ tutorial }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [comment, setComment] = useState("");
  const user = useSelector(currentUser);

  function onDocumentLoadSuccess({ numPages }) {
    // console.log(numPages);
    setNumPages(numPages);
  }

  const handlePageIncrement = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePageDecrement = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className="pb-10 pr-5 lg:pr-0">
      {tutorial?.file_type === "file" && (
        <PdfViewer
          {...{
            url: "",
            numPages,
            pageNumber,
            showPageNumber: true,
            onDocumentLoadSuccess,
            onLeftClick: handlePageDecrement,
            onRightClick: handlePageIncrement,
          }}
        />
      )}
      {tutorial?.file_type === "video" && (
        <VideoComponent {...{ src: tutorial?.file_url }} />
      )}
      {tutorial?.file_type === "audio" && (
        <AudioPlayer
          {...{ src: tutorial?.file_url, autoPlay: true, controls: true }}
        />
      )}
      <Typography
        {...{
          variant: "subheader2",
          className: "text-black mb-2 mt-10",
        }}
      >
        {tutorial?.topic}
      </Typography>
      <Typography {...{ color: "defaultBody", className: "mb-4" }}>
        {tutorial?.extra_note}
      </Typography>

      <CommentInput
        {...{
          avatarUrl: user?.profile?.avatar,
          userName: user?.first_name + " " + user?.last_name,
          value: comment,
          onChange: (value) => setComment(value),
        }}
      />

      <div className="pb-24">
        <Comments />
      </div>
    </div>
  );
};
