import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { LOGIN_ROUTE } from ".";
import { onAuthentication, userToken } from "../features/login/authSlice";

const Protected = ({ children }) => {
  let isLoggedIn = useSelector(onAuthentication);
  let token = useSelector(userToken);
  if (!isLoggedIn && !token) {
    return <Navigate to={LOGIN_ROUTE} />;
  }
  return children;
};

export default Protected;
