import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";

const assignmentBaseUrl = "/students/assignments";

export const assignmentApis = createApi({
  reducerPath: "assignments",
  refetchOnReconnect: true,
  tagTypes: ["studentAssignment", "teacherAssignment"],
  baseQuery: baseQuery((headers) => {
    return headers.set("accept", "application/json");
  }),
  endpoints: (builder) => ({
    studentAssignments: builder.query({
      query: ({ page, module_id, search }) =>
        `${assignmentBaseUrl}?page=${page}&${
          search ? `search=${search}` : `module=${module_id}`
        }&adminStatus=not-end`,
      providesTags: ["studentAssignment"],
    }),
    studentPostAssignment: builder.mutation({
      query: ({ assignment_id, file_url }) => ({
        url: assignmentBaseUrl,
        method: "Post",
        body: {
          assignment_id,
          file_type: "file",
          file_url,
        },
      }),
      invalidatesTags: ["studentAssignment"],
    }),
    getStudentDashboard: builder.query({
      query: () => "/students/dashboard",
    }),
    teacherAssignments: builder.query({
      query: ({ page, module_id }) =>
        `/teachers/assignments?page=${page}&module=${module_id}&adminStatus=not-end`,
      providesTags: ["teacherAssignment"],
    }),
    teacherAssignmentById: builder.query({
      query: (id) => `/teachers/assignments/${id}?adminStatus=not-end`,
      providesTags: ["documentAnnotation"],
    }),
    closeTeacherAssignment: builder.query({
      query: (id) => `/teachers/assignments/${id}/close`,
    }),
    getGradeAssignment: builder.query({
      query: ({ type, resource_id }) =>
        `/document-annotations/students/${type}/resources/${resource_id}`,
    }),
    listMarkedPdf: builder.query({
      query: ({ type, id }) =>
        `/document-annotations/teachers/${type}/resources/${id}`,
      providesTags: ["documentAnnotation"],
    }),
    teacherPostPdfAnnotation: builder.mutation({
      query: ({ data }) => ({
        url: "document-annotations",
        method: "Post",
        body: data,
      }),
      invalidatesTags: ["documentAnnotation"],
    }),
  }),
});

export const {
  useStudentAssignmentsQuery,
  useLazyStudentAssignmentsQuery,
  useStudentPostAssignmentMutation,
  useTeacherAssignmentByIdQuery,
  useTeacherAssignmentsQuery,
  useLazyTeacherAssignmentsQuery,
  useGetStudentDashboardQuery,
  useStudentFileUploadMutation,
  useCloseTeacherAssignmentQuery,
  useListMarkedPdfQuery,
  useGetGradeAssignmentQuery,
  useTeacherPostPdfAnnotationMutation,
} = assignmentApis;
