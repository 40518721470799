export const ACTIONKINDS = {
  SINGLEVIEW: "SINGLEVIEW",
  TOGGLEMODAL: "TOGGLEMODAL",
  ASSESSMENTS: "ASSESSMENTS",
  UPLOADFILES: "UPLOADFILES",
  REMOVEUPLOADFILE: "REMOVEUPLOADFILE",
  SHOWSINGLEVIEWTYPE: "SHOWSINGLEVIEWTYPE",
  SELECTEDASSESSNMENT: "SELECTEDASSESSNMENT",
  GRADEDASSESSMENT: "GRADEDASSESSMENT",
  SELECTFILTERMODULE: "SELECTFILTERMODULE"
};

export const initialState = {
  singleView: false,
  modal: false,
  selectedAssessment: null,
  selectFilterModule: null,
  assessments: [],
  gradedAssessment: null,
  showSingleType: "Assessment to Be Submitted",
  uploadFiles: [],
};
