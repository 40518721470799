import { fetchBaseQuery, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { getCompletedProgressStatus } from "../helpers";
import { setProgress } from "./uploadProgressSlice";

/**
 *
 * @param {callback} header
 * @returns
 */
export const baseQuery = (header) =>
  fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_HOST
        : `/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      // const isLoggedIn = getState().auth.isAuththenticated;
      // console.log(token);
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      header && header(headers, getState);
      return headers;
    },
  });

export const AxiosBaseQuery =
  (
    { baseUrl } = {
      baseUrl:
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_HOST
          : `/api`,
    }
  ) =>
  async (
    { url, method, body, removeHeaders },
    { signal, dispatch, getState, extra }
  ) => {
    const token = getState().auth.token;

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: body,
        onUploadProgress: (progressEvent) => {
          getCompletedProgressStatus(progressEvent, (percent) => {
            dispatch(setProgress(percent));
          });
        },
        headers: removeHeaders
          ? {}
          : { authorization: `Bearer ${token}`, accept: "application/json" },
      });
      return {
        data: result.data,
        status: result?.status || result?.data?.status,
      };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };
