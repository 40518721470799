import ViewSDKClient from "../viewSdkClient";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userToken } from "../../features/login/authSlice";
import { pdfStatus, setPdfStatus } from "../moduleSlice";
import { useToasts } from "react-toast-notifications";

export const PdfEditor = ({ url, meta, containerClass }) => {
 /* Getting the userToken and pdfStatus from the redux store. */
  const token = useSelector(userToken);
  const status = useSelector(pdfStatus);

  /* A hook that allows us to add a toast notification to the screen. */
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const [annotatedPdf, setAnnotatedPdf] = useState(false);

  /**
   * `loadPDF` is an async function that creates a new instance of the ViewSDKClient, waits for it to
   * be ready, and then calls the `previewFile` method on the client
   */
  const loadPDF = async () => {
    let viewerConfig = {
      embedMode: "FULL_WINDOW",
      defaultViewMode: "FIT_WIDTH",
      showAnnotationTools: true,
      showLeftHandPanel: false,
      showPageControls: false,
      showDownloadPDF: false,
      showPrintPDF: true,
      enableAnnotationAPIs: true,
      includePDFAnnotations: true,
    };

    try {
      const viewSDKClient = new ViewSDKClient();
      let ready = await viewSDKClient.ready();

      if (ready) {
        viewSDKClient.previewFile("pdf-div", viewerConfig, { url, meta });
        await viewSDKClient.registerSaveApiHandler(token, meta);
      }
    } catch (error) {
      console.error(error, "err");
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    loadPDF();

    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (status === true) {
      addToast(`${meta.fileName} ${meta.task}, Please continue to grade`, {
        appearance: "success",
      });
      setAnnotatedPdf(true);
      dispatch(setPdfStatus(false));
    }
  }, [status]);

  return (
    <>
      <div className={`h-[30rem] overflow-auto ${containerClass}`}>
        <div
          id="pdf-div"
          // onLoad={loadPDF()}
          // onDocumentLoad={loadPDF}
          className="overflow-auto"
        ></div>
      </div>
    </>
  );
};
