import { useState } from "react";
import { useSelector } from "react-redux";
import {
  // SelectInput,
  Tooltip,
  // Typography,
} from "../../../common/sharedComponent";
import { currentUser } from "../../login/authSlice";
import { Search, UserPanel, UserPanelPlaceholderLoader } from "../components";
// import { useGetRecipientsMutation } from "../api";
import {
  ChatIcon,
  SearchIcon,
  EyeOffIcon,
} from "@heroicons/react/outline";
import {
  getNemaInitials,
  isCurrentUser,
  truncateString,
} from "../../../helpers";

export const ChatList = ({
  onNewMessageModal,
  onSelection,
  selected,
  usersList = [],
  isLoadingUsersList,
  className
}) => {
  const [isSearchListActive, setSearchListActive] = useState(false);
  const user = useSelector(currentUser);
  // const userRole = useSelector(role);
  const [search, setSearch] = useState("");
  const [isChangeCourseActive, setChangeCourseActive] = useState(false);


  const menuList = [
    {
      id: "1",
      name: "search",
      onClick: () => setSearchListActive(true),
      icon: <SearchIcon className="h-5 w-5 text-on-background" />,
    },
    // {
    //   id: "2",
    //   name: "change course",
    //   onClick: () => setChangeCourseActive(true),
    //   icon: <BookOpenIcon className="h-5 w-5 text-on-background" />,
    // },
    {
      id: "3",
      name: "new message",
      onClick: () => onNewMessageModal(true),
      icon: <ChatIcon className="h-5 w-5 text-on-background" />,
    },
  ];

  const handleChatSelection = (item) => {
    onSelection(item);
  };


  const filteredCourseMembers = usersList.filter(
    (item) =>
      item?.receiver?.toLowerCase().includes(search.toLowerCase()) ||
      item?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const onHide = () => {
    // This changes states.
    isChangeCourseActive && setChangeCourseActive(false);
    isSearchListActive && setSearchListActive(false);
  };

  return (
    <div className={`flex flex-col md:w-2/5 border-r-2 h-full pr-3 lg:pr-0 ${className}`}>
      <div className={`flex items-center gap-x-2 py-3 pb-1 px-2`}>
        {isSearchListActive || isChangeCourseActive ? (
          <EyeOffIcon
            className="h-5 w-5 text-on-background cursor-pointer"
            {...{ onClick: onHide }}
          />
        ) : (
          <div className="w-full gap-x-3 flex">
            {menuList.map((item) => (
              <Tooltip {...{ content: item?.name, onClick: item?.onClick }}>
                {item.icon}
              </Tooltip>
            ))}
          </div>
        )}
      </div>
      {isSearchListActive && (
        <Search
          {...{
            placeholder: "search",
            containerClassName: "pb-1 pr-2",
            onChange: (e) => setSearch(e.target.value),
          }}
        />
      )}
      {/* <SelectInput
        {...{
          onchange: (value) => handleModuleChange(value),
          containerClassName: `pb-1 pr-2 ${
            isChangeCourseActive ? "block" : "hidden"
          }`,
          options: enrolledModulesList,
        }}
      /> */}

      <div className="overflow-y-auto pb-14 scrollbar-hide">
        {!isLoadingUsersList &&
          filteredCourseMembers.map((item) => (
            <UserPanel
              {...{
                key: item?.id,
                name: isCurrentUser(item?.sender_id, user?.id)
                  ? item?.receiver
                  : item?.sender,
                selected: item?.reference === selected?.reference,
                avatar: isCurrentUser(item?.sender_id, user?.id)
                  ? item?.receiver_object?.avatar
                  : item?.sender_object?.avatar,
                message: truncateString(item?.body ?? "", 30),
                avatarAlt: isCurrentUser(item?.sender_id, user?.id)
                  ? getNemaInitials(item?.receiver)
                  : getNemaInitials(item?.sender),
                onClick: () => handleChatSelection(item),
              }}
            />
          ))}
        {isLoadingUsersList && <UserPanelPlaceholderLoader />}
      </div>
    </div>
  );
};
