import { useState } from "react";
import { Player, LessonMaterial } from "../component";

export const StudentSingleView = ({ tutorial }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mt-8">
      <Player module={tutorial} onLessonMaterial={() => setOpen(!open)} />
      <LessonMaterial {...{ open, setOpen: () => setOpen(!open), tutorial }} />
    </div>
  );
};
