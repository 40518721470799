export const ACTIONKINDS = {
  SINGLEVIEW: "SINGLEVIEW",
  TOGGLEMODAL: "TOGGLEMODAL",
  SELECTEDASSIGNMENT: "SELECTEDASSIGNMENT",
  SELECTFILTERMODULE: "SELECTFILTERMODULE",
  ASSIGNMENTS: "ASSIGNMENTS",
  UPLOADFILES: "UPLOADFILES",
  REMOVEUPLOADFILE: "REMOVEUPLOADFILE",
  SHOWSINGLEVIEWTYPE: "SHOWSINGLEVIEWTYPE",
  SEARCH: "SEARCH",
};

export const initialState = {
  singleView: false,
  modal: false,
  selectedAssignment: null,
  selectFilterModule: null,
  assignments: [],
  showSingleType: "Assignment to be Submitted",
  uploadFiles: [],
  search: null,
};
