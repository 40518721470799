import {
  DocumentTextIcon,
  PlayIcon,
  SpeakerphoneIcon,
} from "@heroicons/react/outline";
import { playCircle, rez_poster } from "../../assets";
import { Avatar, Button, Typography } from ".";
import "./media.css";
import { useMediaQuery } from "../../hooks";
import { BsDownload } from "react-icons/bs";

import { truncateString } from "../../helpers";
const statusColor = {
  Ended: "redStatus",
  Pending: "neutralStatus",
  Approved: "successStatus",
  Primary: "primaryStatus",
};

export const NewMediaLayout = ({ lesson, onClick }) => {
  const isMobileView = useMediaQuery("(max-width: 640px)");

  return (
    <div className="flex flex-col justify-center w-full  !h-[35vh] !min-h-fit !max-h-fit items-center bg-white border rounded-lg p-2">
      <div
        onClick={onClick}
        className={`w-full h-[62%] lg:h-[70%] flex flex-col bg-watchVideo bg-cover bg-center bg-black rounded-lg justify-center items-center`}
      >
        <img src={playCircle} alt="Play" className="h-10 w-10" />

        <Typography
          {...{
            variant: "headlineS",
            className: "mb",
            color: "white",
          }}
        >
          {lesson.module}
        </Typography>

        <Typography {...{ variant: "body2", color: "white" }}>
          Topic: {truncateString(lesson.topic, 50) ?? " "}
        </Typography>
      </div>
      <div
        className={`flex flex-col w-full  h-[38%] lg:h-[35%] px-2 space-y-2`}
      >
        <div className={`flex flex-row justify-center mt-1 items-center`}>
          <Button
            sizes="sm"
            variant="contained"
            // className={`bg-[#006B5D]/10 !text-[#006B5D] !p-2`}
            className="btn text-white"
            onClick={onClick}
          >
            View
          </Button>
        </div>
        <div
          className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row w-full lg:justify-between pb-3`}
        >
          <Typography
            {...{
              variant: "body",
              color: statusColor[lesson.status],
            }}
          >
            Upload Status: {lesson.status ?? ""}
          </Typography>

          <Typography
            {...{
              variant: "body",
              color: statusColor.Primary,
            }}
          >
            Uploaded: {lesson.updated_at ?? ""}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const MediaLayout = ({
  left,
  right,
  leftContainer,
  leftClick,
  isNewContent,
  containerClass,
}) => {
  return (
    <div
      className={`lg:mr-5 border lg:h-40 md:flex lg:px-0 gap-x-5 mb-5 bg-white p-4 rounded-md relative  ${containerClass}`}
    >
      {isNewContent && (
        <Typography
          {...{
            className:
              "absolute top-0 right-5 lg:right-16 bg-red px-2 rounded-b",
            color: "white",
            capitalize: true,
            variant: "small",
          }}
        >
          new
        </Typography>
      )}
      <div onClick={leftClick} className={`lg:ml-5 h-full ${leftContainer}`}>
        {left}
      </div>
      <div className={`flex-1`}>{right}</div>
    </div>
  );
};

export const NewMediaCardLayout = ({
  lesson,
  download,
  onClick,
  avatarAlt,
}) => {
  return (
    <div className="flex flex-col justify-center w-full  !h-[30vh] !min-h-fit !max-h-fit  items-center bg-white border rounded-lg p-2">
      <div
        onClick={onClick}
        className={`w-full cursor-pointer h-[62%] lg:h-[70%] flex flex-col space-y-1 bg-watchVideo bg-cover bg-center bg-black rounded-lg justify-center items-center`}
      >
        <img src={playCircle} alt="Play" className="h-10 w-10" />

        <Typography
          {...{
            variant: "headlineS",
            className: "mb pl-1",
            color: "white",
          }}
        >
          {lesson.module}
        </Typography>

        <Typography {...{ variant: "body2", color: "white" }}>
          Topic: {truncateString(lesson.topic, 50) ?? " "}
        </Typography>
      </div>

      <div
        className={`flex flex-col w-full h-[38%] lg:h-[30%] px-2 mt-2 lg:mt-1`}
      >
        <div className={`flex flex-row justify-center mt-1 items-center`}>
          <button
            className="flex flex-row space-x-2 rounded-md border bg-[#006B5D]/10 text-[#006B5D] py-1 px-2  transition-opacity "
            // onClick={async (e) => {
            //   e.preventDefault();
            //   return downLoadFile(item);
            // }}
            onClick={(e) => {
              e.preventDefault();
              const myArray = lesson?.file_url.split(".");
              if (myArray.length > 0) {
                let ext = myArray[myArray.length - 1];
                const filename = lesson?.topic + "." + ext;
                const file = {
                  name: lesson?.topic,
                  file: lesson?.file_url,
                  filename: filename,
                };
                download(file);
              }
            }}
          >
            <BsDownload className="w-5 h-5 text-[#006B5D]" />
            <span>Download</span>
          </button>
        </div>
        <div
          className={`flex  flex-col space-y-3 lg:space-y-0 lg:flex-row w-full mt-3 lg:mt-1 lg:justify-between pb-3`}
        >
          <Typography
            {...{
              variant: "body",
              color: statusColor.Primary,
            }}
          >
            Uploaded: {lesson.updated_at ?? ""}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const MediaCardLayout = ({
  topic,
  author,
  body,
  isNewContent,
  containerClass,
  file_type,
  onClick,
  avatarAlt,
  children,
}) => {
  return (
    <div>
      <div
        onClick={onClick}
        className={`lg:mr border px-5 gap-x-5 mb-5 bg-white shadow-md p-4 rounded-md relative cursor-pointer max-h-96 ${containerClass}`}
      >
        {isNewContent && (
          <Typography
            {...{
              className:
                "absolute top-0 right-5 lg:right-10 bg-red px-2 rounded-b",
              color: "white",
              capitalize: true,
              variant: "caption",
            }}
          >
            new
          </Typography>
        )}
        <div className="flex items-center justify-between w-full mt-2">
          <div className="flex items-center gap-x-2">
            <Avatar
              {...{
                avatarAlt,
                sizes: "10",
                className: "shrink-0",
                altContainerClass: "shrink-0",
              }}
            />
            <div>
              <Typography {...{ variant: "headlineS", className: "mb" }}>
                {topic}
              </Typography>
              <Typography {...{ variant: "body2" }}>{author}</Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage:
              file_type === "video" ? `url(${rez_poster})` : "none",
          }}
          className={`h-[120px] mt-3 rounded-md  cursor-pointer mb-2 lg:mb-0 object-scale-down ${
            file_type === "video"
              ? ""
              : "bg-primary-container grid place-items-center"
          }`}
        >
          {file_type === "file" ? (
            <DocumentTextIcon className="h-[88px] w-[88px] lg:h-16 lg:w-16 text-on-primary-container" />
          ) : file_type === "video" ? (
            <div className="bg-black/50 h-full w-full grid place-items-center">
              {/* <img src={poster} alt='' className="h-full w-full " /> */}
              <PlayIcon className="h-[88px] w-[88px] lg:h-16 lg:w-16 text-white" />
            </div>
          ) : (
            <SpeakerphoneIcon className="h-[88px] w-[88px] lg:h-16 lg:w-16 text-on-primary-container" />
          )}
        </div>
        <div className="py-4">{body}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export const MediaPlaceholderLoader = () => {
  return [1, 2, 3].map((item) => (
    <div
      key={item}
      className="h-40 my-2 w-full bg-gray-200 animate-pulse rounded-md"
    ></div>
  ));
};

export const NewCardLayout = ({
  imgContainer,
  img,
  children,
  containerClass,
}) => {
  return (
    <div
      className={`rounded-md relative z-0 select-none bg-white p-2 rounded-lg ${containerClass}`}
    >
      <div className={`${imgContainer}`}>{img}</div>
      <div className="w-full ">{children}</div>
    </div>
  );
};

export const CardLayout = ({ imgContainer, img, children, containerClass }) => {
  return (
    <div
      className={`rounded-md relative z-0 select-none  bg-white p-2 ${containerClass}`}
    >
      <div className={`${imgContainer}`}>{img}</div>
      <div className="w-full flex ">{children}</div>
    </div>
  );
};

export const CardPlaceholderLoader = ({ style }) => {
  return (
    <div
      className={`grid lg:grid-cols-3 grid-rows-3 gap-3 pt-3 pr-5 lg:pr-5 ${style}`}
    >
      {[1, 2, 3].map((item) => (
        <div
          key={item}
          className="my-2 w-full h-48 bg-gray-200 animate-pulse rounded-md"
        ></div>
      ))}
    </div>
  );
};
