import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery, AxiosBaseQuery } from "./baseQuery";

let baseVideoUpdateUrl = "/students/update-video-status";

export const updateVideoPercentage = createApi({
  reducerPath: "update-video-percentage",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    postVideoStatusUpload: builder.mutation({
      query: ({ body }) => ({
        url: baseVideoUpdateUrl,
        method: "POST",
        body,
      }),
      invalidatesTags: [],
    }),
    /* This is a query that is used to fetch the watched percentage */
    videoPercentage: builder.query({
      query: ({ module_id }) => `${baseVideoUpdateUrl}/${module_id}`,
    }),
  }),
});

export const { usePostVideoStatusUploadMutation, useVideoPercentageQuery } =
  updateVideoPercentage;
