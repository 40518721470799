import { Route, Routes } from "react-router-dom";
import {
  FORGET_PASSWORD,
  LOGIN_ROUTE,
  ProtectedRoutes,
  Protected,
} from "./routes";
import { ForgetPassword, Login, ResetPassword, QuizView } from "./features";
import { ToastProvider } from 'react-toast-notifications'

function App() {
  return (
    <ToastProvider placement="top-right" autoDismiss>
      <Routes>
        <Route path={LOGIN_ROUTE} element={<Login />} />
        <Route path={FORGET_PASSWORD} element={<ForgetPassword />} />
        {/* <Route path={REGISTER} element={<Register />} /> */}
        <Route path="/quiz" element={<QuizView />} />
        <Route path="reset_password" element={<ResetPassword />} />
        {/* <Route path={TEACHER_LOGIN_ROUTE} element={<Login />} /> */}
        <Route
          path="dashboard/*"
          element={
            <Protected>
              <ProtectedRoutes />
            </Protected>
          }
        />
      </Routes>
    </ToastProvider>
  );
}

export default App;
