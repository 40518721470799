import { Typography } from ".";
import { avatar } from "../../assets";
import { DotsVerticalIcon, DocumentIcon } from "@heroicons/react/outline";

const ListStudents = ({
  students,
  firstColTitle,
  secondColTitle,
  thirdColTitle,
  seeDetails,
  seeAssignment,
  assignment,
}) => {
  const return5Assignment = (arr, name) => {
    return arr
      .slice(0, 3)
      .map((newArr, i) => (
        <DocumentIcon
          key={i}
          onClick={() => seeAssignment(name)}
          className="w-4 h-4 mx-1"
        />
      ));
  };

  return (
    <div className="mt-8 rounded-lg border">
      <div
        className={`bg-white grid grid-cols-5 py-3 lg:grid-cols-8 rounded-md ${
          assignment && "hidden lg:grid"
        }`}
      >
        <div></div>
        <Typography
          variant="subheader1"
          className="col-span-3 lg:col-span-2 capitalize"
        >
          {firstColTitle}
        </Typography>
        <Typography
          variant="subheader1"
          className="hidden lg:block lg:col-span-3 capitalize"
        >
          {secondColTitle}
        </Typography>
        <Typography
          variant="subheader1"
          className="hidden lg:block lg:col-span-2 lg:text-center capitalize"
        >
          {thirdColTitle}
        </Typography>
        {/* <div></div> */}
      </div>
      {students.map((student, i) => (
        <div
          key={i}
          className={` ${assignment && "hidden lg:grid"} ${
            i % 2 === 0 ? "bg-gray-300" : "bg-white"
          } grid grid-cols-4 py-1 lg:grid-cols-8 items-center`}
        >
          <img
            src={avatar}
            alt={student.name}
            className="h-10 w-10 rounded-full ml-5"
          />

          <Typography className="col-span-3 lg:col-span-2">
            {student.name}
          </Typography>
          <Typography className="hidden lg:block lg:col-span-3">
            {student.date}
          </Typography>
          <div className="hidden lg:block lg:col-span-2 lg:text-center">
            <div className={`flex  cursor-pointer justify-center`}>
              {Array.isArray(student.assignment) &&
                return5Assignment(student.assignment, student.name)}
              <Typography
                className="  cursor-pointer hover:text-blue"
                onClick={() => seeDetails(student.name)}
              >
                View Assignment
              </Typography>
            </div>
            {/* {!Array.isArray(student.assignment) && (
              <Typography
                className="hover:text-blue cursor-pointer mt-3 text-center "
                onClick={() => seeDetails(student.name)}
              >
                View Assignment
              </Typography>
            )} */}
          </div>

          {/* <div className="flex justify-end mr-5">
            <DotsVerticalIcon className="h-6 w-6" />
          </div> */}
        </div>
      ))}
      <div className={`${!assignment && "hidden"} lg:hidden`}>
        {students.map((student, i) => (
          <div key={i}>
            <div className="grid grid-cols-3 bg-white py-2 items-center">
              <img
                src={avatar}
                alt={student.name}
                className="h-10 w-10 rounded-full ml-5"
              />
              <Typography className="col-span-2">{student.name}</Typography>
              {/* <div className="flex justify-end mr-5">
                <DotsVerticalIcon className="h-6 w-6" />
              </div> */}
            </div>
            <div className="flex justify-evenly bg-gray-300 py-3 ">
              <Typography className="">{student.date}</Typography>
              <Typography
                className="cursor-pointer hover:text-blue"
                onClick={() => seeDetails(student.name)}
              >
                View Assignment
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListStudents;
