import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../common/baseQuery';


export const QuizApi = createApi({
  reducerPath: "quiz",
  refetchOnReconnect: true,
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    postAnswers: builder.mutation({
      query: ({ questions, examination_id }) => ({
        url: `/students/examinations/${examination_id}/questions/answers`,
        method: "POST",
        body: { questions },
      }),
    }),
  }),
});


export const { usePostAnswersMutation } = QuizApi;