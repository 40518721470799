import { useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Header } from "../../../common/Layouts";
import { userModule } from "../../../common/moduleSlice";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import {
  Action,
  CardPlaceholderLoader,
  Fab,
  Typography,
  EmptyContent,
} from "../../../common/sharedComponent";

import { getTabModules, perseCustomError } from "../../../helpers";
import { isEqual, isUndefined } from "../../../utils";
import { Upload } from "../../dashboard/components";
import { useLazyTeacherAssignmentsQuery } from "../api";
import { Modules } from "../layout";
import { reducer } from "../reducer";
import { setAssignments } from "../reducer/action";
import { initialState } from "../reducer/contants";
import AssignmentTabs from "../components/AssignmentTabs";

export const Teacher = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [showAssignments, setShowAssignments] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [showUpload, setShowUpload] = useState(false);

  const navigate = useNavigate();

  /* A react hook that is used to manage state in a functional component. */
  const [state, dispatch] = useReducer(reducer, initialState);

  /* Getting the userModule from the redux store. */
  const subjects = useSelector(userModule);
  /**
   * A react-toast-notification hook, for triggering a notification display.
   */
  const { addToast } = useToasts();

  /* A hook that is used to fetch data from the server. */
  const [onAssignment, { loading, meta }] = useLazyTeacherAssignmentsQuery({
    selectFromResult: ({ isLoading, isFetching, data }) => ({
      loading: isLoading || isFetching,
      data: data?.data ?? [],
      meta: data?.meta,
    }),
  });

  /**
   * It fetches the assignments of a particular subject
   */
  const handleFetchAssignment = async ({ id, name }) => {
    try {
      const result = await onAssignment({ page: 1, module_id: id }).unwrap();

      let modules = result?.data?.filter?.((module) =>
        getTabModules(module?.module, { id })
      );
      setSubjectName(name);
      !isUndefined(result.data) && setShowAssignments(true);
      !isUndefined(result.data) && setAssignments(dispatch, modules);
    } catch (error) {
      // if (!isEqual(id, activeSubject?.id)) setAssignments(dispatch, []);

      // handles different kinds of errors.
      perseCustomError(error, addToast);
    }
  };

  return (
    <>
      {!showAssignments && (
        <>
          <Action noLive noUpload>
            Courses
          </Action>

          <AssignmentTabs
            subjects={subjects}
            loading={loading}
            handleFetchAssignment={(value) => handleFetchAssignment(value)}
          />
        </>
      )}

      {loading && <CardPlaceholderLoader />}

      {showAssignments && (
        <>
          <div
            onClick={() => {
              setShowAssignments(!showAssignments);
            }}
            className="flex items-center gap-x-2 mb-3 cursor-pointer"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <Typography {...{}}>Back</Typography>
          </div>
          <div className="mb-3 -mt-4 ">
            <Action
              noLive
              uploadAction={() => setShowUpload(true)}
              btnText="upload Assignment"
            >
              {subjectName}
            </Action>
          </div>

          {!loading && !isEqual(state?.assignments?.length, 0) && (
            <Modules
              {...{
                modules: state?.assignments,
                onEdit: (id, assignment) => {
                  setAssignment(assignment);
                  setIsEdit(true);
                  setShowUpload(true);
                },
                onView: (assignment) => {
                  navigate("single", { state: { assignment } });
                },
              }}
            />
          )}

          {!loading && isEqual(state?.assignments?.length, 0) && (
            <div className="h-[30rem]">
              <EmptyContent message={"No Assessments"} />
            </div>
          )}
        </>
      )}

      {showUpload && (
        <Upload
          edit={isEdit}
          open={showUpload}
          editTask={assignment}
          taskTitle={["Assignment"]}
          taskId={1}
          action={() => {
            setShowUpload(false);
            isEdit && setIsEdit(false);
            isEdit && setAssignment(null);
          }}
        />
      )}
    </>
  );
};
