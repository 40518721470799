import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Field } from "react-final-form";
import {
  Action,
  Typography,
  FieldArray,
  Form,
} from "../../common/sharedComponent";
import {
  composeValidators,
  minNum,
  mustBeNumber,
  required,
} from "../../common/sharedComponent/InputValidate";
import { Question } from "./component";
import { Teacher } from "./layouts/Teacher";
import Preview from "./Preview";

const TeacherView = ({ toEdit, value }) => {
  const [examination, setExamination] = useState(null);
  const [goBack, setGoBack] = useState(true);

  const onSubmit = (values) => {
    console.log(values);
    setExamination(values);
  };
  const DURATION_FIELD = [
    {
      name: "date",
      type: "date",
      label: "Select Date",
      validate: required,
    },
    {
      name: "time",
      type: "time",
      label: "Select Time",
      validate: required,
    },
    {
      name: "Duration",
      type: "number",
      validate: composeValidators(required, mustBeNumber, minNum(30)),
      label: "duration",
      placeholder: "Duration in Minutes",
    },
  ];

  const FIELDS = [
    {
      name: "instruction",
      type: "text",
      validate: required,
      label: "Instruction",
      containerStyles: " mt-3",
    },
  ];
  return (
    <>
      {goBack && !examination && (
        <>
          <div
            onClick={() => setGoBack(false)}
            className="flex items-center gap-x-2 mb-3 cursor-pointer"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <Typography {...{}}>Back</Typography>
          </div>
          <div className="pb-20 ">
            <Action noButton>{`${
              toEdit ? "Edit" : "Set"
            } Examination for module`}</Action>
            <div className="w-full scrollbar-hide">
              <div className="w-full lg:w-4/5 lg:mx-auto">
                <Form
                  btnStyles="flex justify-center mt-3"
                  buttonText={`${toEdit ? "Edit" : "Add"} Module Name`}
                  end={true}
                  onSubmit={onSubmit}
                  isLoading={false} values={value}
                >
                  <div className="mt-4 flex flex-col lg:mt-5">
                    <div className="flex  flex-col space-y-5 mt-4 lg:flex-row  lg:space-x-3 lg:space-y-0">
                      <FieldArray field={DURATION_FIELD} />
                    </div>
                    <FieldArray field={FIELDS} />
                    <div className={`flex flex-col mt-5`}>
                      <label
                        htmlFor="date"
                        className="text-subheader2 text-on-background mb-3"
                      >
                        Set Examination
                      </label>
                      <Field
                        component={Question}
                        name="examination"
                        validate={required}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
      {examination && <Preview preview={examination} back={toEdit} />}
      {!goBack && !examination && <Teacher />}
    </>
  );
};
export default TeacherView;
