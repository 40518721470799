import { Avatar, Button, Typography } from ".";
import { avatar } from "../../assets";

export const Comments = ({ containerClass }) => {
  return (
    <div className={` border p-3 mb-5 bg-white rounded-md ${containerClass}`}>
      <div className="lg:flex items-center gap-x-4">
        <div className="flex gap-x-4 w-full">
          <Avatar name={avatar} className="h-[46px] w-[46px]" />
          <div className="flex-1">
            <div className="flex items-center gap-x-3">
              <Typography
                {...{
                  variant: "subheader2",
                  className: "lg:mb-1 mb-3 text-black",
                }}
              >
                Rachael Lebsack
              </Typography>
              <Typography {...{ variant: "small", className: "mb-1" }}>
                1 Day Ago
              </Typography>
            </div>
            <Typography {...{ color: "defaultBody" }}>
              Consequuntur qui animi totam. Non dolores nemo non doloribus illo
              veritatis error et ea.
            </Typography>
          </div>
        </div>
        <Button
          {...{
            capitalize: true,
            className:
              "text-[rgba(32,135,255,1)] text-body2 ml-16 lg:ml-0 mt-4 lg:mt-0 mb-2 lg:mb-0 lg:mr-2",
            variant: "text",
            sizes: "text",
            shadow: "none",
          }}
        >
          {" "}
          reply
        </Button>
      </div>
      <div class="flex lg:items-center space-x-2 mt-3 border-t ml-16  border-gray-200 pt-3 lg:pt-2 gap-x-4">
        <Avatar name={avatar} className="h-[46px] w-[46px]" />
        <div className="flex-1">
          <Typography
            {...{ variant: "subheader2", className: "lg:mb-1 mb-3 text-black" }}
          >
            Teacher Name
          </Typography>
          <Typography {...{ color: "defaultBody" }}>
            Consequuntur qui animi totam. Non dolores nemo non doloribus illo
            veritatis error et ea.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const CommentInput = ({ avatarUrl, userName, onChange, value }) => {
  return (
    <div>
      <Typography
        {...{
          color: "defaultBody",
          variant: "subheader2",
          className: "mt-5 mb-4",
        }}
      >
        Comments
      </Typography>
      <div className="flex lg:mb-5 mb-10 gap-x-7">
        <div>
          <Avatar name={avatarUrl ?? avatar} className="h-[46px] w-[46px]" />
        </div>
        <div className="flex-1">
          <div className="lg:flex">
            <div className="flex-1">
              <Typography
                {...{
                  variant: "subheader2",
                  className: "text-black mb-1",
                }}
              >
                {userName}
              </Typography>
              <textarea
                className="w-full text-body2 p-0 border-0 focus:outline-none focus:border-0"
                placeholder="Write your comment here..."
                value={value}
                onChange={onChange}
                rows={4}
              />
            </div>
            <div className="h-full">
              <div className="flex justify-end items-center mt-4 h-full lg:mt-0">
                <Button
                  {...{
                    variant: "text",
                    className: "text-black",
                    sizes: "sm",
                    shadow: "none",
                    capitalize: true,
                  }}
                >
                  cancel
                </Button>
                <Button
                  {...{
                    variant: "contained",
                    className: "text-white rounded Lg;mr-5",
                    sizes: "sm",
                    shadow: "none",
                    capitalize: true,
                  }}
                >
                  send reply
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
