import { ACTIONKINDS } from "./constants";

/**
 * Toggles the modal state off and on.
 * @param {React.DispatchWithoutAction} dispatch
 * @returns
 */
export const toggleModal = (dispatch) =>
  dispatch({ type: ACTIONKINDS.SHOW_MODAL });

/**
 * Updates the reducer state of singleView.
 * @param {React.DispatchWithoutAction} dispatch
 * @param {any} payload
 * @returns
 */
export const setSingleView = (dispatch) =>
  dispatch({ type: ACTIONKINDS.SINGLE_VIEW });

/**
 * Updates the reducer state of activeSubject.
 * @param {React.DispatchWithoutAction} dispatch
 * @param {any} payload
 * @returns
 */
export const setActiveSubject = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.ACTIVE_SUBJECT, payload });

/**
 * Updates the reducer state of selectedExam.
 * @param {React.DispatchWithoutAction} dispatch
 * @param {any} payload
 * @returns
 */
export const setSelectedExam = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.SELECTED_EXAM, payload });

/**
 * Updates the reducer state of uploadFiles.
 * @param {React.DispatchWithoutAction} dispatch
 * @param {any} payload
 * @returns
 */
export const setUploadFiles = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.UPLOAD_FILES, payload });

/**
 * Updates the reducer state of uploadFiles with null.
 * @param {React.DispatchWithoutAction} dispatch
 * @param {any} payload
 * @returns
 */
export const setRemoveUploadFiles = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.REMOVE_UPLOAD_FILES, payload });
