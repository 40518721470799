export const Search = ({ containerClassName, onChange, ...props}) => {
  return (
    <div className={`border-b-2 ${containerClassName}`}>
      <input
        type="text"
        className="py-1.5 px-4 border-2 focus:border-0 border-gray-200  focus:ring-primary-container text-gray-300 outline-none rounded-2xl w-full"
        onChange={onChange}
        {...props}
      />
    </div>
  );
};
