import {
  BreadCrumb,
  MediaCardLayout,
  NewMediaCardLayout,
  Tabs,
  Typography,
  MediaPlaceholderLoader,
  EmptyContent,
  CustomPagintion,
  SelectInput,
  Action,
  LessonTabs,
} from "../../../common/sharedComponent";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { StudentSingleView } from "./index";
import { useGetLessonsQuery, useGetStudentDashboardQuery } from "../api";
import { useLocation } from "react-router-dom";
import {
  getEnrolledModules,
  truncateString,
  getTabModules,
  getNemaInitials,
  groupBy,
  truncateMiddleString,
} from "../../../helpers";
import useFileDownloader from "../../../hooks/useFileDownloader";

export const Student = () => {
  const [singleView, setSingleView] = useState(false);
  const [page, setPage] = useState(1);
  const [activeSubject, setActiveSubject] = useState(null);
  const [showSingleView, setShowSingleView] = useState(false);

  const [activeLessons, setActiveLessons] = useState(null);
  const [showLessons, setShowLessons] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [currentModuleName, setCurrentModuleName] = useState("");
  const { state } = useLocation();
  const { lessons, meta, isLoadingLessons } = useGetLessonsQuery(
    {
      page,
      module_id: activeSubject?.id
        ? activeSubject?.id
        : activeSubject?.only_name,
    },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        lessons: data?.data ?? [],
        meta: data?.meta,
        isLoadingLessons: isLoading || isFetching,
      }),
    }
  );

  const { subjects, isLoading } = useGetStudentDashboardQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      subjects: getEnrolledModules(data),
      isLoading,
    }),
  });

  let filtered_modules = lessons?.filter((module) =>
    getTabModules(module, activeSubject)
  );

  const handlePageChange = (page) => {
    // changes the current page of content
    setPage(page);
  };

  const loadSubject = (index) => {
    setActiveSubject(index);
    // dispatch(SetModuleIndex(index));
    setShowLessons(true);
    setSingleView(!singleView);
    setCurrentModuleName(index.name);
  };

  return (
    <div className="w-full">
      {singleView ? (
        <>
          <div
            onClick={() => {
              setSingleView(!singleView);
              setShowLessons(!showLessons);
              if (showSingleView) {
                setShowSingleView(!showSingleView);
              }
              setSelectedTutorial(null);
            }}
            className="flex items-center gap-x-2 mb-3 cursor-pointer"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <Typography {...{}}>Back</Typography>
          </div>
          <div className="mb-3 -mt-4 ">
            <Action
              noLive
              noUpload
              //                     uploadAction={uploadCourse}
              // btnText="upload lessons"
            >
              {currentModuleName}
            </Action>
          </div>
        </>
      ) : (
        <BreadCrumb className="lg:hidden" info={selectedTutorial?.module} />
      )}
      {!singleView && (
        <>
          {!showLessons && (
            <>
              <Action noLive noUpload>
                <span className="hidden lg:block">Courses</span>
              </Action>
              <div className="w-full">
                <LessonTabs
                  subjects={subjects}
                  selectedSubject={loadSubject}
                  index={activeSubject}
                  className="mt-3"
                  isLoading={isLoading}
                />
              </div>
            </>
          )}
        </>
      )}

      {activeSubject?.name !== "all" && singleView && !showSingleView && (
        <ViewSingleLesson
          {...{
            filtered_modules,
            handlePageChange,
            isLoadingLessons,
            meta,
            onClick: (item) => {
              setShowSingleView(!showSingleView);
              setSelectedTutorial(item);
            },
          }}
        />
      )}

      {singleView && showSingleView && (
        <StudentSingleView {...{ tutorial: selectedTutorial }} />
      )}
    </div>
  );
};

const ViewSingleLesson = ({
  isLoadingLessons,
  filtered_modules,
  onClick,
  meta,
  handlePageChange,
}) => {
  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const download = (file) => downloadFile(file);
  return (
    <>
      {downloaderComponentUI}

      {!isLoadingLessons && filtered_modules?.length !== 0 && (
        // <div className="md:grid grid-cols-2 pt-3 pb-5 overflow-auto gap-x-2 scrollbar-hide lg:h-[30rem] h-[40rem] lg:pr-0 pr-2 mt-5">
        <div
          className={`grid grid-cols-1 gap-4 lg:grid-cols-3  lg:gap-4  mx-2 sm:mx-4 lg:mx-0 mb:20 lg:mb-10 grid-flow-row`}
        >
          {filtered_modules.map((item) => (
            <NewMediaCardLayout
              {...{
                key: item.id,
                lesson: item,
                avatarAlt: getNemaInitials(item?.teacher),
                onClick: () => onClick(item),
                file_type: item?.file_type,
                download: download,
              }}
            ></NewMediaCardLayout>
          ))}
        </div>
      )}

      {isLoadingLessons && (
        <div className="pr-5 h-[30rem] lg:grid grid-cols-2 gap-x-2">
          <MediaPlaceholderLoader />
        </div>
      )}

      {!isLoadingLessons && filtered_modules?.length === 0 && (
        <div className="h-[30rem]">
          <EmptyContent message="No lessons" />
        </div>
      )}
    </>
  );
};
