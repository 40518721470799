import axios from "axios";
import store from "../app/store";
import { getCompletedProgressStatus } from "../helpers";
import { isEqual } from "../utils";
import moduleSlice, { pdfStatus } from "./moduleSlice";

class ViewSdkClient {
  #clientId =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_ADOBE_CLIENT_ID
      : "00966f36ee2246bb981377f07f5e93fa";
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve(true);
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve(true);
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  saveOptions = {
    autoSaveFrequency: 0,
    enableFocusPolling: false,
    showSaveButton: true,
  };

  previewFile(divId, viewerConfig, data) {
    const config = {
      clientId: this.#clientId,
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: { url: data.url },
        },
        metaData: data.meta,
      },
      viewerConfig
    );
    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId, filePromise, fileName) {
    let config = {
      clientId: this.#clientId,
      divId,
    };
    this.adobeDCView = new window.AdobeDC.View(config);
    this.adobeDCView.previewFile(
      {
        content: { promise: filePromise },
        metaData: { fileName },
      },
      {}
    );
  }

  registerSaveApiHandler(token, file) {
    const getPredefinedUrl = async (metaData) => {
      let formData = new FormData();

      try {
        formData.append("file_type", "pdf");
        formData.append("file_extension", metaData?.mimeType);
        formData.append("file_name", file?.fileName);
        formData.append("folder", file?.task);

        let result = await axios.post(
          process.env.NODE_ENV === "production"
            ? `${process.env.REACT_APP_HOST}/uploads/presigned-url`
            : `/api/uploads/presigned-url`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return result.data;
      } catch (error) {
        return error.data;
      }
    };

    const saveApiHandler = async (metaData, content, options) => {
      store.dispatch(moduleSlice.actions.setPdfStatus("loading"));

      let uint8Array = new Uint8Array(content);
      let blob = new Blob([uint8Array], { type: "application/pdf" });

      let formData = new FormData();

      /* Getting the file extension of the file. */
      // let file_extension = getFileExtension(file.fileName);

      formData.append("file", blob, `${file.fileName}-${file.id}.pdf`);

      let presigned_url = await getPredefinedUrl(metaData);

      let result = await axios.put(presigned_url, blob);

      if (!isEqual(result?.status, 200)) {
        return new Promise((resolve, reject) =>
          reject("Failed to upload file.")
        );
      }

      // update redux with annotated url
      // if (uploads.status === 200 && file.task === "examination") {
      store.dispatch(
        moduleSlice.actions.setAnnotatedExam({
          student_id: file.id,
          type: file.task,
          student_name: file.fileName,
          annotated: `https:${presigned_url?.split?.("?")[0].substr?.(6)}`,
        })
      );

      store.dispatch(moduleSlice.actions.setPdfStatus(true));

      return new Promise((resolve) => {
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime(),
              }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {
        saveOptions: this.saveOptions,
      }
    );
  }

  registerEventsHandler() {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.STATUS_API,
      (event) => {
        console.log(event);
      },
      { enablePDFAnalytics: true }
    );
  }
}

export default ViewSdkClient;
