import { InformationCircleIcon } from "@heroicons/react/outline";
import { Button } from "../../../common/sharedComponent";

const Content = ({ children, action }) => {
  return (
    <div class=" flex-col my-4  space-y-4   flex justify-center items-center  outline-none focus:outline-none">
      <div class="flex flex-col p-8 w-2/3 bg-white shadow-md hover:shodow-lg rounded-2xl">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <InformationCircleIcon className="w-6 h-6" />
            <div class="flex flex-col ml-3">{children}</div>
          </div>
          <Button variant={'outlined'} onClick={action} colors='black'>View</Button>
        </div>
      </div>
    </div>
  );
};

export default Content;
