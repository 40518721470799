import { Button, EmptyContent } from "../../../common/sharedComponent";
import { MessagePanel, MessagePanelLoader } from "../components";
import { PaperAirplaneIcon, ArrowLeftIcon } from "@heroicons/react/outline";

const SendButton = ({ onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className="ml-2 h-14 cursor-pointer w-16 grid place-items-center rounded-full bg-gray-200"
    >
      {children}
    </div>
  );
}

export const MessageList = ({
  messages = [],
  onChange,
  onClick,
  isReplying,
  isLoading,
  selectedChat,
  inputValue,
  onClose,
  isMobileView
}) => {
  return (
    <div className="flex flex-col mt-5 h-full relative">
      {!isLoading && messages.length !== 0 && (
        <div className="flex flex-col flex-1 overflow-y-auto scrollbar-hide">
          {messages.map((item, index) => {
            return <MessagePanel {...{ item, key: item?.id ?? index }} />;
          })}
          {isReplying && <MessagePanelLoader />}
        </div>
      )}
      {isLoading &&
        messages.length === 0 &&
        [1, 2, 3, 4].map((item) => <MessagePanelLoader key={item} />)}
      {(messages.length === 0 || !selectedChat) && !isLoading && (
        <EmptyContent message="No message" />
      )}
      {!isLoading && (messages.length !== 0 || selectedChat) && (
        <div className=" w-full flex items-center gap-x-3">
          <input
            className="w-full bg-gray-100 py-3 px-3 rounded-xl ring-tertiary-container focus:ring-tertiary-container focus:border-tertiary-container text-body-small-lg"
            onChange={onChange}
            type="text"
            value={inputValue}
            placeholder="type your message here..."
          />
          {/* <Button
            {...{
              isLoading,
              variant: "contained",
              className: "bg-green-500",
              onClick,
              disabled: isLoading,
            }}
          >
            Send
          </Button> */}

          {isMobileView && inputValue && (
            <SendButton {...{ onClick }}>
              <PaperAirplaneIcon className="h-6 w-6 rotate-45 text-green-500" />
            </SendButton>
          )}

          {!isMobileView && (
            <SendButton {...{ onClick }}>
              <PaperAirplaneIcon className="h-6 w-6 rotate-45 text-green-500" />
            </SendButton>
          )}

          {isMobileView && !inputValue && (
            <SendButton {...{ onClick: onClose }}>
              <ArrowLeftIcon className="h-6 w-6 text-green-500" />
            </SendButton>
          )}
        </div>
      )}
    </div>
  );
};
