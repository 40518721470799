import { ACTIONKINDS, initialState } from "./contants";

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONKINDS.TOGGLEMODAL:
      return { ...state, modal: !state.modal };
    case ACTIONKINDS.SINGLEVIEW:
      return { ...state, singleView: !state.singleView };
    case ACTIONKINDS.SELECTEDASSIGNMENT:
      return { ...state, selectedAssignment: payload };
    case ACTIONKINDS.SELECTFILTERASSIGNMENT:
      return { ...state, selectFilterAssignment: payload };
    case ACTIONKINDS.ASSIGNMENTS:
      return { ...state, assignments: payload };
    case ACTIONKINDS.UPLOADFILES:
      return { ...state, uploadFiles: payload };
    case ACTIONKINDS.REMOVEUPLOADFILE:
      return { ...state, uploadFiles: [] };
    case ACTIONKINDS.SHOWSINGLEVIEWTYPE:
      return { ...state, showSingleType: payload };
    case ACTIONKINDS.SEARCH:
      return { ...state, search: payload };
    default:
      return state;
  }
};
