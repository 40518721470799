import { useState } from "react";
import { Answer } from ".";
import { Action, Button, Table } from "../../../common/sharedComponent";
import { useGetSubmittedExamQuery } from "../api";

const ViewSubmission = ({ id }) => {
  const [page, setPage] = useState(1);
  const [viewAnswer, setViewAnswer] = useState(null);
  const [studentName, setStudentName] = useState(null);
  // const { submitted, loading, meta } = useGetSubmittedExamQuery(
  //   { id, page },
  //   {
  //     selectFromResult: ({ data, isLoading }) => ({
  //       submitted: data?.data ?? [],
  //       loading: isLoading,
  //       meta: data?.meta,
  //     }),
  //   }
  // );

  const submitted = [
    {
      id: "9595b35a-2105-4ec7-8583-07685289f6ae",
      academic_calendar: "First Semester 2022",
      end_time: "23:39",
      question_answerable: 23,
      score_obtained: 0,
      start_date: "2022-02-12",
      start_time: "23:38",
      status: "Pending",
      student: {
        id: "92e7da02-fb43-418f-9138-564f77b08a47",
        name: "HILLCROSS TEST",
        student_id: "123456789",
      },
      submission_date: "2022-02-12",
      teacher: "Test Teacher",
      total_answered: 2,
    },
  ];

  const loading = false;
  const meta = { current_page: 1, from: 1, last_page: 1, total: 1 };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const answerData = (data) => {
    setViewAnswer(data?.id);
    setStudentName(data?.student_name);
  };

  const students = submitted.map((item) => {
    return {
      student_name: item?.student?.name,
      date: item?.start_date,
      time: item?.start_time,
      sub_date: item?.submission_date,
      answer: item?.total_answered,
      score: item?.score_obtained,
      status: item?.status,
      end: item?.end_time,
      id: item?.id,
      student_id: item?.student?.id,
    };
  });
  const columns = [
    {
      Header: "Student",
      accessor: "student_name",
    },
    {
      Header: "StudentId",
      accessor: "student_id",
    },
    {
      Header: "Start Date",
      accessor: "date",
    },
    {
      Header: "Exam Start Time",
      accessor: "time",
    },
    {
      Header: "Exam Submission Date",
      accessor: "sub_date",
    },
    {
      Header: "Exam End Time",
      accessor: "end",
    },
    {
      Header: "Question Answered",
      accessor: "answer",
    },
    {
      Header: "Mark Obtained",
      accessor: "score",
    },
    {
      Header: "Admin Approval",
      accessor: "status",
    },

    {
      Header: "Action",
      accessor: "id",
      Cell: ({ cell: { row } }) => {
        return (
          <Button
            variant="outlined"
            sizes="sm"
            className="text-red"
            shadow="none"
            capitalize
            onClick={() => answerData(row.values)}
          >
            answer
          </Button>
        );
      },
    },
  ];
  const initialState = { hiddenColumns: ["student_id"] };

  return (
    <>
      {!viewAnswer && (
        <>
          <Action noButton>Submitted Student</Action>
          <div className="py-10">
            <Table
              {...{
                columns,
                data: students,
                isLoading: loading,
                currentPage: page,
                initialState,
                totalPages: meta?.total,
                onChangePaginate: handlePageChange,
              }}
            />
          </div>
        </>
      )}
      {viewAnswer && <Answer name={studentName} id={viewAnswer} />}
    </>
  );
};

export default ViewSubmission;
