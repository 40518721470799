import { Typography } from ".";

export const Badge = ({ children, number, className }) => {
  return (
    <div className="relative">
      {number !== 0 && (
        <div
          className={`h-4 w-4 grid place-items-center rounded-full absolute -top-1.5 right-4 bg-white lg:bg-red border-2 border-white ${className}`}
        >
          {/* <Typography variant="captionS" className="lg:text-white lg:text-center lg:-mt-.5">
            {number}
          </Typography> */}
        </div>
      )}
      {children}
    </div>
  );
};
