export const ACTIONKINDS = {
  SHOW_MODAL: "SHOW_MODAL",
  SINGLE_VIEW: "SINGLE_VIEW",
  ACTIVE_SUBJECT: "ACTIVE_SUBJECT",
  SELECTED_EXAM: "SELECTED_EXAM",
  UPLOAD_FILES: "UPLOAD_FILES",
  REMOVE_UPLOAD_FILES: "REMOVE_UPLOAD_FILES",
};

export const initialState = {
  showModal: false,
  singleView: false,
  activeSubject: null,
  selectedExam: null,
  uploadFiles: [],
};
