import { useReducer, useState } from "react";
import { useSelector } from "react-redux";

import { ArrowLeftIcon } from "@heroicons/react/outline";
import {
  Action,
  CardPlaceholderLoader,
  EmptyContent,
  Fab,
  Typography,
} from "../../../common/sharedComponent";
import { Upload } from "../../dashboard/components";
import { useLazyTeacherAssessmentsQuery } from "../api";
import { Modules } from "../layouts";
import { aciveAsseesment, userModule } from "../../../common/moduleSlice";
import { isEqual, isUndefined } from "../../../utils";
import { perseCustomError } from "../../../helpers";
import { setAssessments } from "../reducer/action";
import { reducer } from "../reducer";
import { initialState } from "../reducer/constants";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../common/Layouts";
import AssessmentTabs from "../components/AssessmentTabs";

export const Teacher = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [showAssessments, setShowAssessments] = useState(false);
  const [subjectName, setSubjectName] = useState("");

  const navigate = useNavigate();

  /* A react hook that is used to manage state in a functional component. */
  const [state, dispatch] = useReducer(reducer, initialState);

  /* Getting the userModule from the redux store. */
  const subjects = useSelector(userModule);

  /* Getting the active subject from the redux store. */
  const activeSubject = useSelector(aciveAsseesment);

  /**
   * A react-toast-notification hook, for triggering a notification display.
   */
  const { addToast } = useToasts();

  /* A destructuring assignment that assigns the properties of an object to variables. */
  const [onAssessment, { loading }] = useLazyTeacherAssessmentsQuery({
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      modules: data?.data ?? [],
      meta: data?.meta,
      loading: isLoading || isFetching,
    }),
  });

  /**
   * It fetches the assessment data from
   * the API and sets it to the state
   */
  const handleFetchAssessment = async ({ id, name }) => {
    try {
      const result = await onAssessment({
        id: 1,
        module_id: id,
      }).unwrap();

      setSubjectName(name);
      !isUndefined(result?.data) && setShowAssessments(true);
      !isUndefined(result?.data) &&
        setAssessments(dispatch, result?.data ?? []);
    } catch (error) {
      if (!isEqual(id, activeSubject?.id)) setAssessments(dispatch, []);

      // handles different kinds of errors.
      perseCustomError(error, addToast);
    }
  };

  return (
    <>
      {!showAssessments && (
        <>
          <Action noLive noUpload>
            Courses
          </Action>

          <AssessmentTabs
            subjects={subjects}
            loading={loading}
            handleFetchAssessment={(value) => handleFetchAssessment(value)}
          />
        </>
      )}

      {showAssessments && (
        <>
          {/* <Fab type={"upload assignment"} upload={() => setShowUpload(true)} /> */}

          {loading && <CardPlaceholderLoader />}

          <div
            onClick={() => {
              setShowAssessments(!showAssessments);
            }}
            className="flex items-center gap-x-2 mb-3 cursor-pointer"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <Typography {...{}}>Back</Typography>
          </div>
          <div className="mb-3 -mt-4 ">
            <Action
              noLive
              uploadAction={() => setShowUpload(true)}
              btnText="Upload Assessment"
            >
              {subjectName}
            </Action>
          </div>

          {!loading && !isEqual(state?.assessments?.length, 0) && (
            <Modules
              {...{
                modules: state?.assessments,
                onSelect: (id, assessment) => {
                  navigate("single", { state: { assessment, id } });
                },
                onEdit: (id, assessment) => {
                  setAssessment(assessment);
                  setIsEdit(true);
                  setShowUpload(true);
                },
              }}
            />
          )}

          {!loading && isEqual(state?.assessments?.length, 0) && (
            <div className="h-[30rem]">
              <EmptyContent message={"No Assessments"} />
            </div>
          )}
        </>
      )}

      {showUpload && (
        <Upload
          taskId={3}
          edit={isEdit}
          open={showUpload}
          // refetch={refetch}
          editTask={assessment}
          taskTitle={["assessments"]}
          action={() => {
            setShowUpload(false);
            isEdit && setIsEdit(false);
            isEdit && setAssessment(null);
          }}
        />
      )}
    </>
  );
};
