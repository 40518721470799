import { ACTIONKINDS } from "./contants";

/**
 * `toggleSingleView` is a function that takes a dispatch function as an argument and toggles the single view.
 * dispatch function that takes an object as an argument
 */
export const toggleSingleView = (dispatch) =>
  dispatch({ type: ACTIONKINDS.SINGLEVIEW });

/**
 * It takes a dispatch function and a payload as arguments,
 * and then calls the dispatch function with
 * an assignment object that has a type and a payload
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the data you want to send to the reducer
 */
export const setSingleAssignment = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.SELECTEDASSIGNMENT, payload });

/**
 * `toggleModal` is a function that takes a dispatch function as an argument and returns a dispatch
 * function that dispatches an action to toggle the modal with the type `ACTIONKINDS.TOGGLEMODAL`
 */
export const toggleModal = (dispatch) =>
  dispatch({ type: ACTIONKINDS.TOGGLEMODAL });

/**
 * It takes a dispatch function and a payload, and then calls the dispatch function with an action
 * object that has a type of ACTIONKINDS.SELECTFILTERMODULE and a payload of the payload that was passed in.
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the value of the filter
 */
export const setSelectFilterModule = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.SELECTFILTERMODULE, payload });

/**
 * It takes a dispatch function and a payload, and then calls the dispatch function with an action
 * object that has a type of ACTIONKINDS.SELECTFILTERMODULE and a payload of the payload that was
 * passed in
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the array of assignments
 */
export const setAssignments = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.ASSIGNMENTS, payload });

/**
 * It takes a dispatch function and a payload, and then calls the dispatch function with an action
 * object that has a type of ACTIONKINDS.UPLOADFILES and a payload of the payload that was
 * passed in
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the object of pdf
 */
export const setUploadFiles = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.UPLOADFILES, payload });

/**
 * It takes a dispatch function and a payload, and then calls the dispatch function with an action
 * object that has a type of ACTIONKINDS.REMOVEUPLOADFILE and a payload of the payload that was
 * passed in
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the object of pdf
 */
export const setRemoveUploadFiles = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.REMOVEUPLOADFILE, payload });

/**
 * It takes a dispatch function and a payload, and then calls the dispatch function with an action
 * object that has a type of ACTIONKINDS.REMOVEUPLOADFILE and a payload of the payload that was
 * passed in
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the string of either  "Assignment to be Submitted" or "Assignment Submitted" and "Assignment Results"
 */
export const setSingleViewType = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.SHOWSINGLEVIEWTYPE, payload });

/**
 * It takes a dispatch function and a payload, and then calls the dispatch function with an action
 * object that has a type of ACTIONKINDS.SEARCH and a payload of the payload that was
 * passed in
 * @param dispatch - the dispatch function from the redux store
 * @param payload - the string || null
 */
export const setSearch = (dispatch, payload) =>
  dispatch({ type: ACTIONKINDS.SEARCH, payload });
