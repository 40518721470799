import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { selectedSubject, userModule } from "../../../common/moduleSlice";
import { Button, FieldArray } from "../../../common/sharedComponent";
import {
  composeValidators,
  minNum,
  required,
} from "../../../common/sharedComponent/InputValidate";
import { DropComponent } from "../../dashboard/components/upload";
import { academicCalender } from "../../login/authSlice";
import {
  useGetAcademicCalenderQuery,
  usePostTeacherExaminationMutation,
  useUpdateTeacherExaminationMutation,
} from "../api";
import { usePostFileUploadMutation } from "../../../common/uploadApi";
import {
  selectUploadProgress,
  setProgress,
} from "../../../common/uploadProgressSlice";
import ProgressBar from "@ramonak/react-progress-bar";

const NewExamination = ({ submit, edit, editTask }) => {
  const { addToast } = useToasts();
  const modules = useSelector(userModule);
  const progress = useSelector(selectUploadProgress);
  const calender = useSelector(academicCalender);
  const session = calender.find((item) => item.status === "Active");
  const [fileUpload] = usePostFileUploadMutation();
  const dispatch = useDispatch();
  const [postExamination, { isLoading }] = usePostTeacherExaminationMutation();
  const [updateExamination, { isLoading: loadingUpdate }] =
    useUpdateTeacherExaminationMutation();

  const option = [
    { id: "take home", name: "Theory" },
    { id: "cbt", name: "CBT" },
  ];

  const SelectMenu = [
    {
      name: "examination_type",
      type: "select",
      placeholder: "Examination Type",
      validate: required,
      containerStyles: " mt-6",
      label: "Examination Type",
      style: "lg:col-span-1",
      option: option,
    },
    {
      name: "module_id",
      type: "select",
      placeholder: "Select Module",
      validate: required,
      containerStyles: " mt-6",
      label: "Select Module",
      style: "lg:col-span-2",
      option: modules,
    },
  ];

  const Duration = [
    {
      name: "exam_date",
      type: "date",
      label: "Start Date",
      validate: required,
    },
    {
      name: "duration",
      type: "number",
      label: "Duration",
      placeholder: "Duration in Minutes",
      validate: required,
    },
    {
      name: "start_time",
      type: "time",
      label: "Start Time",
      validate: required,
    },
  ];

  const Details = [
    {
      name: "total_questions",
      type: "number",
      label: "Total Question",
      validate: composeValidators(required, minNum(20)),
      style: "lg:col-span-2",
    },
    {
      name: "total_questions_answerable",
      type: "number",
      label: "Total Answerable Question",
      validate: composeValidators(required, minNum(20)),
      style: "lg:col-span-3",
    },
    {
      name: "total_score",
      type: "number",
      label: "Total Mark",
      validate: composeValidators(required, minNum(20)),
      style: "lg:col-span-1",
    },
  ];

  const Fields = [
    {
      name: "total_score",
      type: "number",
      label: "Total Mark",
      validate: composeValidators(required, minNum(10)),
      style: "lg:col-span-2",
    },
    {
      name: "available_at",
      type: "date",
      label: "Available At",
      validate: required,
      style: "lg:col-span-2",
    },
    {
      name: "submission_date",
      type: "date",
      label: "Submission Date",
      validate: required,
      style: "lg:col-span-2",
    },
    {
      name: "start_time",
      type: "time",
      label: "Start Time",
      validate: required,
      style: "lg:col-span-2",
    },
    {
      name: "duration",
      type: "number",
      label: "Duration",
      placeholder: "Duration in Minutes",
      validate: required,
      style: "lg:col-span-2",
    },
  ];

  const onSubmit = async (values) => {
    const {
      module_id,
      total_score,
      exam_date,
      start_time,
      duration,
      total_questions,
      total_questions_answerable,
      file_url,
      available_at,
      submission_date,
      examination_type,
    } = values;

    try {
      let resultUrl;
      if (file_url) {
        // new upload
        let formData = new FormData();
        formData.append("file", file_url[0]);
        resultUrl = await fileUpload({ body: formData }).unwrap();
        resultUrl?.error &&
          addToast(resultUrl?.error.data?.message, { appearance: "error" });
          resultUrl?.status === "success" && dispatch(setProgress(0));
      }
      if (resultUrl?.status === "success" || editTask.file) {
        resultUrl?.status === "success" &&
          addToast("File uploaded successfully", { appearance: "success" });
        if (edit === "take home") {
          const data = {
            module_id: editTask?.module_id,
            total_questions,
            total_score,
            exam_date,
            start_time,
            duration,
            total_questions_answerable,
            examination_type,
            file_url: resultUrl?.data?.file_url || editTask?.file,
            file_type: "file",
            available_at,
            academic_calender_id: session?.id,
            submission_date,
          };
          const res = await updateExamination({
            body: data,
            id: editTask?.id,
          });
          if (res?.error) {
            addToast(res?.error.data?.message, { appearance: "error" });
          } else {
            addToast("Examination Succefully Updated", {
              appearance: "success",
            });
            submit();
          }
        } else {
          const data = {
            academic_calender_id: session?.id,
            module_id,
            total_questions,
            total_score,
            exam_date,
            start_time,
            duration,
            total_questions_answerable,
            examination_type,
            file_url: resultUrl?.data?.file_url || editTask?.file,
            file_type: "file",
            available_at,
            submission_date,
          };
          const res = await postExamination({ body: data });
          if (res?.error) {
            addToast(res?.error.data?.message, { appearance: "error" });
          } else {
            addToast("Examination Succefully Created", {
              appearance: "success",
            });
            submit();
          }
        }
      }
    } catch (e) {
      dispatch(0)
      addToast("An error occurred, please try again later", {
        appearance: "error",
      });
    }
    // }
  };

  return (
    <div className="px-5">
      <Form
        className="w-2/3 mx-auto"
        onSubmit={onSubmit}
        initialValues={edit === "take home" && editTask}
        render={({
          handleSubmit,
          submitting,
          values,
          hasValidationErrors,
          form,
        }) => (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
              form.reset();
            }}
          >
            <div className="mt-7 flex flex-col lg:mt-5">
              {edit !== "take home" && (
                  <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-3 lg:space-y-0">
                  <FieldArray field={SelectMenu} />
                </div>
              )}
              {(values.examination_type === "take home" ||
                (edit === "take home" &&
                  editTask.examination_type === "take home")) && (
                <>
                  <Field
                    component={DropComponent}
                    name="file_url"
                    validate={edit !== "take home" && required}
                    types={"application/pdf"}
                    text="Drop the Examination PDF file here"
                  />
                  <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-6 lg:space-y-0">
                    <FieldArray field={Fields} />
                  </div>
                </>
              )}
              {(values.examination_type === "cbt" ||
                (edit === "take home" &&
                  editTask.examination_type === "CBT")) && (
                <>
                  <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-6 lg:space-y-0">
                    <FieldArray field={Details} />
                  </div>
                  <div className="flex  flex-col space-y-5 lg:mt-5  lg:flex-row  lg:space-x-3 lg:space-y-0">
                    <FieldArray field={Duration} />
                  </div>
                </>
              )}
              {(values.examination_type === "cbt" ||
                values.examination_type === "take home" ||
                edit === "take home") && (
                <div className={`flex justify-end mt-5`}>
                  {progress !== 0 ? (
                    <ProgressBar
                      className="w-40"
                      bgColor="#006B5D"
                      // barContainerClassName="bg-red"
                      completed={progress}
                    />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      shadow="sm"
                      isLoading={
                        edit === "take home" ? loadingUpdate : isLoading
                      }
                      disabled={submitting || hasValidationErrors || isLoading}
                    >
                      {edit === "take home"
                        ? "Update Examination"
                        : "Submit Examination"}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </form>
        )}
      />{" "}
    </div>
  );
};

export default NewExamination;
