import { Link } from "react-router-dom";

import { FieldArray, Form, Typography } from "../../../common/sharedComponent";
import {
  composeValidators,
  minValue,
  required,
} from "../../../common/sharedComponent/InputValidate";
// import { FORGET_PASSWORD, REGISTER } from "../../../routes";
import { useResetPasswordMutation } from "../../login/authApi";
import { useToasts } from "react-toast-notifications";
import { perseCustomError } from "../../../helpers";

const Content = () => {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const { addToast } = useToasts();

  const onSubmit = async (values) => {
    try {
      let result = await resetPassword(values);

      result?.error &&
        addToast(result?.error?.dsts?.message, { appearance: "error" });

      result?.data &&
        addToast(result?.data?.data?.message, { appearance: "success" });
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  const Fields = [
    {
      name: "password",
      type: "password",
      label: "Password",
      validate: composeValidators(required, minValue(8)),
    },
    {
      name: "re_password",
      type: "password",
      label: "Confirm Password",
      containerStyles: "mt-7",
      validate: composeValidators(required, minValue(8)),
    },
  ];
  return (
    <div>
      <Typography color="red" variant="h6" className="text-center mb-5">
        Reset your account password
      </Typography>
      <Form btnStyles="my-12" buttonText="Update Password" onSubmit={onSubmit}>
        <div className=" w-4/5 mx-auto lg:w-2/3">
          <FieldArray field={Fields} />
        </div>
      </Form>
    </div>
  );
};

export default Content;
