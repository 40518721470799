import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FieldArray, Form, Typography } from "../../../common/sharedComponent";
import {
  composeValidators,
  minValue,
  required,
} from "../../../common/sharedComponent/InputValidate";
import { FORGET_PASSWORD, REGISTER } from "../../../routes";
import {
  financialStatus,
  onAuthentication,
  setAcademicCalender,
  setAuthentication,
  setEnrollableModule,
  setEnrolledModules,
  setFinancialStatus,
  setToken,
  setUser,
  setUserRole,
} from "../authSlice";
import { useLoginMutation } from "../authApi";
import { useToasts } from "react-toast-notifications";
import { useMeQuery } from "../../../common/verifyMeApi";
import { useGetAcademicCalenderQuery } from "../../../common/academicApi";
import { perseCustomError } from "../../../helpers";

const Content = () => {
  const { addToast } = useToasts();
  const isLoggedIn = useSelector(onAuthentication);
  const [login, { isLoading }] = useLoginMutation();

  const [fetchMe, setFetchMe] = useState(true);

  const { academicData, isAcademicSuccess } = useGetAcademicCalenderQuery(
    undefined,
    {
      selectFromResult: ({ data, isSuccess }) => {
        return {
          academicData: data?.data ?? [],
          isAcademicSuccess: isSuccess,
        };
      },
    }
  );

  const {
    user,
    role,
    financials,
    enrollableModules,
    enrolledModules,
    isVerifyLoading,
    isVerifyUninitialized,
    refetch,
  } = useMeQuery(undefined, {
    selectFromResult: ({ data, isLoading, isUninitialized }) => {
      return {
        user: data?.data,
        role: data?.data?.role,
        financials: data?.data?.financial_status,
        enrollableModules: data?.data?.profile?.enrolable_modules,
        enrolledModules: data?.data?.profile?.enroled_modules,
        isVerifyLoading: isLoading,
        isVerifyUninitialized: isUninitialized,
      };
    },
    skip: fetchMe,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(setUser(user));
  dispatch(setUserRole(role));
  dispatch(setFinancialStatus(financials));
  dispatch(setEnrollableModule(enrollableModules));
  dispatch(setEnrolledModules(enrolledModules));
  if (isAcademicSuccess) {
    const setDAC = dispatch(setAcademicCalender(academicData));
  }

  const isFinancialCleared = useSelector(financialStatus);

  const onSubmit = async (values) => {
    try {
      let result = await login(values);

      result?.error &&
        addToast(result?.error.data?.message, {
          appearance: "error",
        });

      if (result?.data?.access_token) {
        dispatch(setToken(result?.data?.access_token));
        dispatch(setAuthentication(true));
        setFetchMe(false);
        refetch();
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  useEffect(() => {
    if (isLoggedIn && isFinancialCleared) {
      navigate("/dashboard");
    } else if (!isLoggedIn && !isFinancialCleared && !isVerifyUninitialized) {
      perseCustomError("Login denied, financially uncleared", addToast);
    }
  }, [isLoggedIn, isFinancialCleared]);

  const Fields = [
    {
      name: "username",
      type: "text",
      label: "Enter ID/Passport Number",
      validate: required,
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      containerStyles: "mt-7",
      validate: required,
    },
  ];
  return (
    <div className="w-[90%] sm:w-[60%] pt-20 mx-auto">
      <div className="bg-[#D9D9D9]/[20%]  pb-8">
        <div className="flex w-full bg-[#C50D00] text-center justify-center py-8 border-[#C50D00] border-t-2 !border-b-0 rounded-md">
          <Typography color="white" variant="display">
            Welcome to HillCross
          </Typography>
        </div>
        <Typography color="white" variant="h6" className="text-center my-10">
          Log into your account
        </Typography>
        <Form
          btnStyles="my-10 mt-16"
          btnClassName="bg-[#C50D00]"
          buttonText="Login"
          onSubmit={onSubmit}
          isLoading={isLoading || isVerifyLoading}
        >
          <div className=" w-4/5 mx-auto lg:w-2/3">
            <FieldArray field={Fields} />
            <Typography
              variant="body2"
              capitalize
              className="text-blue text-right mt-3"
            >
              <Link
                to={`/${FORGET_PASSWORD}`}
                className="text-[#191C1B] hover:text-[#C50D00]"
              >
                forget password?
              </Link>
            </Typography>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Content;
