import {
  Modal,
  Typography,
  DropZone,
  UploadItem,
  Button,
} from "../sharedComponent";

export const UploadModal = ({
  open,
  onClose,
  moduleName,
  onAcceptedFiles,
  uploadFiles,
  uploadSuccess,
  progress,
  onUpload,
  onRemove,
  submitButtonDisabled,
  submitButtonLoader,
  onSubmit,
}) => {
  return (
    <Modal {...{ open, onClose }}>
      <div className="flex w-full py-[8px] mt-[25px] justify-center bg-[#E5F0EF80]/50">
        <Typography
          {...{
            variant: "headlineS",
            className: "!text-[#006B5D]",
          }}
        >
          Submit Solution
        </Typography>
      </div>
      <div className="w-full px-[20px]">
        <div className="px-[12.54px] py-[10.59px] bg-[#E5F0EF80]/50 w-fit mt-[39.54px] mb-[34.2px]">
          <Typography className="text-[9.40001px] text-[#006B5D]">
            {moduleName}
          </Typography>
        </div>

        <DropZone
          styles={" h-[179px] bg-[#E5F0EF] rounded-[8px] justify-center"}
          accept={"application/pdf"}
          text=" Drop the PDF file here"
          onAcceptedFiles={onAcceptedFiles}
        />

        {uploadFiles.map((item, index) => (
          <UploadItem
            key={index}
            file={item}
            success={uploadSuccess}
            progress={progress}
            onUpload={() => onUpload(item)}
            onRemove={() => onRemove(item?.name)}
          />
        ))}

        <div className="flex justify-end mt-10 mb-3">
          <Button
            {...{
              variant: "contained",
              className: "w-fit px-[20px]",
              shadow: "none",
              disabled: submitButtonDisabled,
              isLoading: submitButtonLoader,
              onClick: onSubmit,
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
