import { Typography } from "../../../common/sharedComponent";
import { useState } from "react";
import DownloadIcon from "../../../assets/dashboard/arrow-down.png";
import PlayIcon from "../../../assets/dashboard/play.png";
import VideoImg from "../../../assets/dashboard/video.png";
import MenuIcons from "../../../common/sharedComponent/layout/component/MenuIcons";
import useFileDownloader from "../../../hooks/useFileDownloader";
import VideoModal from "../components/VideoModal";
const TeacherPanel = ({ subjects, selectSubject, loadSubject, isLoading }) => {
  const welcome = {
    url: "https://youtu.be/bfdtH0X6O8A",
    name: "Welcome to Hillcross College",
  };

  const lesson = {
    url: "https://www.youtube.com/watch?v=yqWX86uT5jM",
    name: "Lesson Introductory Video",
  };

  const assessment = {
    url: "https://www.youtube.com/watch?v=yqWX86uT5jM",
    name: "Assignement and Assessment Introductory Video",
  };

  const [showVideo, setShowVideo] = useState(false);
  const [src, setSrc] = useState("");

  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  const download = (file) => downloadFile(file);

  return (
    <div className={`rounded-lg flex flex-col justify-center pb-10`}>
      {downloaderComponentUI}
      <div>
        <Typography
          style={{ color: "#000000" }}
          {...{
            className: "text-center font-[600] text-[22px] leading-[25.2px]",
          }}
        >
          Introductory Videos
        </Typography>
        <div className="flex flex-col lg:flex-row space-y-3  lg:space-y-0 lg:space-x-2 mt-3 items-center justify-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.66699C5.40835 1.66699 1.66669 5.40866 1.66669 10.0003C1.66669 14.592 5.40835 18.3337 10 18.3337C14.5917 18.3337 18.3334 14.592 18.3334 10.0003C18.3334 5.40866 14.5917 1.66699 10 1.66699ZM9.37502 6.66699C9.37502 6.32533 9.65835 6.04199 10 6.04199C10.3417 6.04199 10.625 6.32533 10.625 6.66699V10.8337C10.625 11.1753 10.3417 11.4587 10 11.4587C9.65835 11.4587 9.37502 11.1753 9.37502 10.8337V6.66699ZM10.7667 13.6503C10.725 13.7587 10.6667 13.842 10.5917 13.9253C10.5084 14.0003 10.4167 14.0587 10.3167 14.1003C10.2167 14.142 10.1084 14.167 10 14.167C9.89169 14.167 9.78335 14.142 9.68335 14.1003C9.58335 14.0587 9.49169 14.0003 9.40835 13.9253C9.33335 13.842 9.27502 13.7587 9.23335 13.6503C9.19169 13.5503 9.16669 13.442 9.16669 13.3337C9.16669 13.2253 9.19169 13.117 9.23335 13.017C9.27502 12.917 9.33335 12.8253 9.40835 12.742C9.49169 12.667 9.58335 12.6087 9.68335 12.567C9.88335 12.4837 10.1167 12.4837 10.3167 12.567C10.4167 12.6087 10.5084 12.667 10.5917 12.742C10.6667 12.8253 10.725 12.917 10.7667 13.017C10.8084 13.117 10.8334 13.2253 10.8334 13.3337C10.8334 13.442 10.8084 13.5503 10.7667 13.6503Z"
              fill="#006B5D"
            />
          </svg>
          <Typography
            style={{ color: "#191C1B" }}
            {...{
              className: "text-center font-[400] text-[16px] leading-[19.36px]",
            }}
          >
            Watch the videos below to get a quick guide on how to use your
            portal
          </Typography>
        </div>
      </div>
      <MenuIcons />
      <div className="my-4 mx-auto !lg:ml-1">
        <div className="inline-flex space-x-3 items-center bg-white p-1 rounded-lg">
          <img src={DownloadIcon} alt="Download" className="w-6 h-6" />
          <Typography
            style={{ color: "#006B5D" }}
            {...{
              className: "font-inter font-[600] text-[16px] leading-[19.36px]",
            }}
          >
            Download user guide
          </Typography>
        </div>
      </div>
      {/* Videos section */}
      <div className="flex flex-col space-y-5">
        {/* Welcome */}
        <div className="w-full py-5 px-4 bg-[#E5F0EF] flex flex-col space-y-3 lg:flex-row md:space-x-3 md:space-y-0 border-2 border-white rounded-lg">
          <img
            src={VideoImg}
            alt="Video"
            onClick={() => {
              setSrc(welcome.url);
              setShowVideo(!showVideo);
            }}
            className="w-full lg:w-[260px] h-[148px] sm:h-[200px] lg:h-[160px] rounded-lg mb-2 cursor-pointer"
          />
          <div className="flex flex-col space-y-3 mt-5">
            <Typography
              variant={"headlineS"}
              style={{ color: "#1D3930" }}
              {...{
                className: "font-inter font-[600] text-[20px] leading-[24.2px]",
              }}
            >
              Welcome to HillCross College
            </Typography>
            <Typography
              variant={"body"}
              style={{ color: "#00000" }}
              {...{
                className:
                  "font-inter font-[400] text-[16px] leading-[19.36px]",
              }}
            >
              Hello, welcome to hillcross college, watch a brief tutorial on how
              to go about your onboarding.
            </Typography>
            <div className="flex flex-col space-y-3 lg:space-y-0 text-center items-center justify-center lg:justify-start !mt-6 lg:mt-0 lg:flex-row lg:space-x-3">
              <div
                className="inline-flex space-x-2 items-center bg-[#006B5D] text-white py-1 px-2 rounded-lg cursor-pointer"
                onClick={() => {
                  setSrc(welcome.url);
                  setShowVideo(!showVideo);
                }}
              >
                <img src={PlayIcon} alt="Watch Video" className="w-6 h-6" />

                <Typography
                  style={{ color: "#FFFFFF" }}
                  {...{
                    className:
                      "font-inter font-[600] text-[16px] leading-[19.36px]",
                  }}
                >
                  Click to watch video
                </Typography>
              </div>
              <div
                className="inline-flex space-x-2 items-center bg-white py-1 px-2 rounded-lg cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  const myArray = welcome?.url.split(".");
                  if (myArray.length > 0) {
                    let ext = myArray[myArray.length - 1];
                    const filename = welcome?.name + "." + ext;
                    const file = {
                      name: welcome?.topic,
                      file: welcome?.url,
                      filename: filename,
                    };
                    download(file);
                  }
                }}
              >
                <img src={DownloadIcon} alt="Download" className="w-6 h-6" />

                <Typography
                  style={{ color: "#006B5D" }}
                  {...{
                    className:
                      "font-inter font-[600] text-[16px] leading-[19.36px]",
                  }}
                >
                  Click to download video
                </Typography>
              </div>
            </div>
          </div>
        </div>

        {/* Lesson */}

        <div className="w-full py-5 px-4 bg-[#E5F0EF] flex flex-col space-y-3 lg:flex-row md:space-x-3 md:space-y-0 border-2 border-white rounded-lg ">
          <img
            src={VideoImg}
            alt="Video"
            onClick={() => {
              setSrc(lesson.url);
              setShowVideo(!showVideo);
            }}
            className="w-full lg:w-[260px] h-[148px] sm:h-[200px] lg:h-[160px] rounded-lg mb-2  cursor-pointer"
          />
          <div className="flex flex-col space-y-3 mt-5">
            <Typography
              variant={"headlineS"}
              style={{ color: "#1D3930" }}
              {...{
                className: "font-inter font-[600] text-[20px] leading-[24.2px]",
              }}
            >
              Lessons
            </Typography>
            <Typography
              variant={"body"}
              style={{ color: "#00000" }}
              {...{
                className:
                  "font-inter font-[400] text-[16px] leading-[19.36px]",
              }}
            >
              This video explains how to take your lessons and everything you
              need to know about taking your lessons.
            </Typography>
            <div className="flex flex-col space-y-3 lg:space-y-0 text-center items-center justify-center lg:justify-start !mt-6 lg:mt-0 lg:flex-row lg:space-x-3">
              <div
                className="inline-flex space-x-2 items-center bg-[#006B5D] text-white py-1 px-2 rounded-lg"
                onClick={() => {
                  setSrc(lesson.url);
                  setShowVideo(!showVideo);
                }}
              >
                <img src={PlayIcon} alt="Watch Video" className="w-6 h-6" />

                <Typography
                  style={{ color: "#FFFFFF" }}
                  {...{
                    className:
                      "font-inter font-[600] text-[16px] leading-[19.36px]  cursor-pointer",
                  }}
                >
                  Click to watch video
                </Typography>
              </div>
              <div
                className="inline-flex space-x-2 items-center bg-white py-1 px-2 rounded-lg  cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  const myArray = lesson?.url.split(".");
                  if (myArray.length > 0) {
                    let ext = myArray[myArray.length - 1];
                    const filename = lesson?.name + "." + ext;
                    const file = {
                      name: lesson?.topic,
                      file: lesson?.url,
                      filename: filename,
                    };
                    download(file);
                  }
                }}
              >
                <img src={DownloadIcon} alt="Download" className="w-6 h-6" />

                <Typography
                  style={{ color: "#006B5D" }}
                  {...{
                    className:
                      "font-inter font-[600] text-[16px] leading-[19.36px]",
                  }}
                >
                  Click to download video
                </Typography>
              </div>
            </div>
          </div>
        </div>

        {/* Assessments */}

        <div className="w-full py-5 px-4 bg-[#E5F0EF] flex flex-col space-y-3 lg:flex-row md:space-x-3 md:space-y-0 border-2 border-white rounded-lg ">
          <img
            src={VideoImg}
            alt="Video"
            onClick={() => {
              setSrc(assessment.url);
              setShowVideo(!showVideo);
            }}
            className="w-full lg:w-[260px] h-[148px] sm:h-[200px] lg:h-[160px] rounded-lg mb-2  cursor-pointer"
          />

          <div className="flex flex-col space-y-3 mt-5">
            <Typography
              variant={"headlineS"}
              style={{ color: "#1D3930" }}
              {...{
                className: "font-inter font-[600] text-[20px] leading-[24.2px]",
              }}
            >
              Assignments and Assessments
            </Typography>
            <Typography
              variant={"body"}
              style={{ color: "#00000" }}
              {...{
                className:
                  "font-inter font-[400] text-[16px] leading-[19.36px]",
              }}
            >
              This is an explainer video on how to see your assignments and
              assessments, take them and then upload your solutions.
            </Typography>
            <div className="flex flex-col space-y-3 lg:space-y-0 text-center items-center justify-center lg:justify-start !mt-6 lg:mt-0 lg:flex-row lg:space-x-3">
              <div
                className="inline-flex space-x-2 items-center bg-[#006B5D] text-white py-1 px-2 rounded-lg  cursor-pointer"
                onClick={() => {
                  setSrc(assessment.url);
                  setShowVideo(!showVideo);
                }}
              >
                <img src={PlayIcon} alt="Watch Video" className="w-6 h-6" />

                <Typography
                  style={{ color: "#FFFFFF" }}
                  {...{
                    className:
                      "font-inter font-[600] text-[16px] leading-[19.36px]",
                  }}
                >
                  Click to watch video
                </Typography>
              </div>
              <div
                className="inline-flex space-x-2 items-center bg-white py-1 px-2 rounded-lg  cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  const myArray = assessment?.url.split(".");
                  if (myArray.length > 0) {
                    let ext = myArray[myArray.length - 1];
                    const filename = assessment?.name + "." + ext;
                    const file = {
                      name: assessment?.topic,
                      file: assessment?.url,
                      filename: filename,
                    };
                    download(file);
                  }
                }}
              >
                <img src={DownloadIcon} alt="Download" className="w-6 h-6" />

                <Typography
                  style={{ color: "#006B5D" }}
                  {...{
                    className:
                      "font-inter font-[600] text-[16px] leading-[19.36px]",
                  }}
                >
                  Click to download video
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVideo && (
        <VideoModal
          src={src}
          open={showVideo}
          onClose={() => setShowVideo(!showVideo)}
        />
      )}
    </div>
  );
};

export default TeacherPanel;
