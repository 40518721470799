import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  userRole: null,
  enrolledModules: null,
  enrollableModules: null,
  financialStatus: false,
  academicCalender: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setEnrollableModule: (state, action) => {
      state.enrollableModules = action.payload;
    },
    setEnrolledModules: (state, action) => {
      state.enrolledModules = action.payload;
    },
    setFinancialStatus: (state, action) => {
      let status = action.payload === "Cleared";
      state.financialStatus = status;
    },
    setAcademicCalender: (state, action) => {
      state.academicCalender = action.payload;
    },
    reset: () => {},
  },
});

export const {
  setAuthentication,
  setToken,
  setUser,
  setUserRole,
  setEnrollableModule,
  setEnrolledModules,
  setFinancialStatus,
  setAcademicCalender,
  reset,
} = authSlice.actions;
export const currentUser = (state) => {
  return state.auth.user;
};
export const userToken = (state) => state.auth.token;
export const onAuthentication = (state) => state.auth.isAuthenticated;
export const role = (state) => state.auth.userRole;
export const enrollableModules = (state) => state.auth.enrollableModules;
export const enrolledModules = (state) => state.auth.enrolledModules;
export const financialStatus = (state) => state.auth.financialStatus;
export const academicCalender = (state) => state.auth.academicCalender;
export default authSlice.reducer;
