import {
  DocumentTextIcon,
  VideoCameraIcon,
  VolumeUpIcon,
  DotsVerticalIcon,
  ArrowLeftIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userModule,
  moduleState,
  selectedSubject,
  SetModuleIndex,
  setLessons,
  teacherModule,
} from "../../../common/moduleSlice";
import {
  Action,
  Typography,
  Button,
  MediaPlaceholderLoader,
  EmptyContent,
  MediaLayout,
  NewMediaLayout,
  LessonTabs,
  CustomPagintion,
} from "../../../common/sharedComponent";
import { findModule, truncateString } from "../../../helpers";
import { useGetTeacherModulesQuery } from "../../dashboard/api";
import { Upload } from "../../dashboard/components";
import { useGetModuleLessonQuery } from "../api";
import TeacherView from "./TeacherView";
import StudentLists from "../component/StudentLists";
import { useGetTeacherEnrollmentQuery } from "../../dashboard/api";

export const Teacher = () => {
  const [detailedView, setDetailedView] = useState(null);
  const [showLessons, setShowLessons] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [currentModuleName, setCurrentModuleName] = useState("");

  const [task, setTask] = useState(null);
  const subjects = useSelector(userModule);
  const isLoading = useSelector(moduleState);
  const teacher = useSelector(teacherModule);
  const selectSubject = useSelector(selectedSubject);
  const moduleLoading = useSelector(moduleState);
  const [floatAction, setFloatAction] = useState(false);
  const [actionValue, setActionValue] = useState(null);
  const [lessonView, setLessonView] = useState(null);
  const [page, setPage] = useState(1);
  const [editing, setEditing] = useState(null);
  const dispatch = useDispatch();

  const {
    lessons,
    meta,
    isLoading: loadLessons,
    refetch,
  } = useGetModuleLessonQuery(
    { teacher: teacher?.id, module: selectSubject?.id, page },
    {
      selectFromResult: ({ isLoading, data }) => ({
        lessons: data?.data ?? [],
        meta: data?.meta,
        isLoading,
      }),
      skip: moduleLoading,
    }
  );

  const {
    students,
    studentMeta,
    isLoading: loadingStudent,
  } = useGetTeacherEnrollmentQuery(
    { teacher: teacher?.id, module: selectSubject?.id, page },
    {
      selectFromResult: ({ isLoading, data }) => ({
        students: data?.data ?? [],
        studentMeta: data?.meta,
        isLoading,
      }),
      skip: isLoading,
    }
  );

  const student = students.map((item) => {
    return {
      name: item?.student?.name,
      id: item?.student?.student_id,
      level: item?.student?.qualification?.level,
      email: item?.student?.email,
    };
  });

  dispatch(setLessons(lessons));

  const loadSubject = (index) => {
    dispatch(SetModuleIndex(index));
    setShowLessons(true);
    setCurrentModuleName(index.name);
  };

  const deleted = (id) => {
    setFloatAction(false);
    // TODO SEND TO DISPATH
  };

  const uploadCourse = () => {
    setShowUpload(!showUpload);
    if (!showUpload) setTask(null);
  };

  const viewLesson = (lesson, id) => {
    setDetailedView(id);
    setLessonView(lesson);
  };

  const setAction = (i) => {
    setFloatAction(!floatAction);
    setActionValue(i);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const [activeTab, setActiveTab] = useState("courses"); // Initial active tab

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      {(detailedView || showLessons) && (
        <div
          onClick={() => {
            viewLesson(null, null);
            setShowLessons(!showLessons);
          }}
          className="flex items-center gap-x-2 mb-3 cursor-pointer"
        >
          <ArrowLeftIcon className="h-5 w-5" />
          <Typography {...{}}>Back</Typography>
        </div>
      )}
      <div className="w-full">
        {!detailedView && (
          <>
            {!showLessons && (
              <>
                <Action noLive noUpload>
                  <span className="mx-2">Courses / Modules</span>
                </Action>
                <div className="w-full">
                  <LessonTabs
                    subjects={subjects}
                    selectedSubject={loadSubject}
                    index={selectSubject}
                    className="mt-3"
                    isLoading={isLoading}
                  />
                </div>
              </>
            )}
            {showLessons && (
              <div className="mx-6 lg:mx-0">
                <div className="mb-3 -mt-4 ">
                  <Action
                    noLive
                    uploadAction={uploadCourse}
                    btnText="upload lessons"
                  >
                    {currentModuleName}
                  </Action>
                  <div className="flex space-x-3 font-500 text-[20px] leading-[24px] mt-2">
                    {/* Courses */}
                    <button
                      onClick={() => handleTabClick("courses")}
                      className={`
                          ${
                            activeTab === "courses"
                              ? "text-[#006B5D] underline underline-offset-8"
                              : "text-[#333333]"
                          }
                      `}
                    >
                      Courses
                    </button>
                    {/* Enrolled Students */}
                    <button
                      onClick={() => handleTabClick("students")}
                      className={`
                          ${
                            activeTab === "students"
                              ? "text-[#006B5D] underline underline-offset-8"
                              : "text-[#333333]"
                          }
                      `}
                    >
                      Enrolled Students
                    </button>
                  </div>
                </div>
                <div className="w-full pt-3 pb-20 h-screen lg:pr-0 pr-2 mt-5">
                  {loadLessons && <MediaPlaceholderLoader />}

                  {/* Content for Courses */}
                  {activeTab === "courses" &&
                    !loadLessons &&
                    lessons?.length === 0 && (
                      <div className="h-full">
                        <EmptyContent message={"No Lessons Available"} />
                      </div>
                    )}
                  {activeTab === "courses" &&
                    !loadLessons &&
                    lessons?.length > 0 && (
                      <>
                        <div
                          className={`grid grid-cols-1 gap-8 lg:grid-cols-3  lg:gap-4  mx-2 sm:mx-4 lg:mx-0 grid-flow-row`}
                        >
                          {lessons?.map((lesson) => (
                            <NewMediaLayout
                              {...{
                                key: lesson.id,
                                lesson: lesson,
                                onClick: () => viewLesson(lesson, lesson.id),
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}

                  {/* Content for Students */}
                  {activeTab === "students" && (
                    <StudentLists students={student} />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {detailedView && <TeacherView lesson={lessonView} />}
      {showUpload && (
        <Upload
          action={uploadCourse}
          edit={task}
          editTask={editing}
          taskTitle={["audio/video tutorial"]}
          taskId={0}
          open={showUpload}
          refetch={refetch}
        />
      )}
    </div>
  );
};
