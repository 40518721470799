import { useState } from "react";
import {
  ArrowLeftIcon,
  ClockIcon,
  RefreshIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import {
  Action,
  Tabs,
  MediaLayout,
  Typography,
  MediaPlaceholderLoader,
  EmptyContent,
  Button,
  Table,
} from "../../../common/sharedComponent";
import { MessageIcon } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  moduleState,
  selectedSubject,
  SetModuleIndex,
  userModule,
} from "../../../common/moduleSlice";
import { useTeacherClassroomQuery } from "../api";
import Upload from "../../dashboard/components/upload";
import TeacherView from "../TeacherView";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { findModule } from "../../../helpers";

const TeacherTutorial = () => {
  const [showUpload, setShowUpload] = useState(false);
  const [detailedView, setDetailedView] = useState(false);
  const [floatAction, setFloatAction] = useState(false);
  const [actionValue, setActionValue] = useState(null);
  const [task, setTask] = useState(null);
  const subjects = useSelector(userModule);
  const isLoading = useSelector(moduleState);
  const selectSubject = useSelector(selectedSubject);
  const [editing, setEditing] = useState(null);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { module, meta, loading, refetch } = useTeacherClassroomQuery(page, {
    selectFromResult: ({ isLoading, data }) => ({
      module: data?.data ?? [], //.find((module) => module?.modules[0]?.id === selectSubject.id) ?? [],
      meta: data?.meta,
      loading: isLoading,
    }),
  });

  // console.log(module, "mo");

  const loadSubject = (index) => {
    // setSelectSubject(index);
    dispatch(SetModuleIndex(index));
    //   TODO
    // LOAD SUBJECT FROM DB
  };
  const setAction = (i) => {
    setFloatAction(!floatAction);
    setActionValue(i);
  };

  const uploadCourse = () => {
    setShowUpload(!showUpload);
    if (!showUpload) setTask(null);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const editModule = (id) => {
    setShowUpload(!showUpload);
    setTask(1);
    setFloatAction(false);
    setEditing(id);
  };

  const columns = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Date",
      accessor: "class_date",
    },
    {
      Header: "Time",
      accessor: "start_time",
    },
    {
      Header: "Duration",
      accessor: "duration",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ cell: { value } }) => {
        return (
          <Button
            variant="outlined"
            sizes="sm"
            className="text-red"
            shadow="none"
            capitalize
            onClick={() => setDetailedView(value)}
          >
            join
          </Button>
        );
      },
    },
  ];

  // const action=

  const deleteModule = (id) => {
    // console.log(id);
    setFloatAction(false);
    // TODO SEND TO DISPATH
  };

  const join = (id) => {
    // console.log("join");
    setDetailedView(id);
  };

  return (
    <div>
      {detailedView && (
        <div
          onClick={() => setDetailedView(false)}
          className="flex items-center gap-x-2 mb-3 cursor-pointer"
        >
          <ArrowLeftIcon className="h-5 w-5" />
          <Typography {...{}}>Back</Typography>
        </div>
      )}
      <div className="w-full scrollbar-hide">
        {!detailedView && (
          <>
            <Action
              noLive
              uploadAction={uploadCourse}
              btnText={"New Classroom"}
            >
              Audio/Video Tutorials
            </Action>
            <div className="">
              <Tabs
                subjects={subjects}
                selectedSubject={loadSubject}
                index={selectSubject}
                className="mt-3"
                isLoading={isLoading}
              />
            </div>
            <div className="w-full pt-3 pb-20 overflow-auto scrollbar-hide h-screen lg:pr-0 pr-2 mt-5">
              <Table
                {...{
                  columns,
                  data: module,
                  isLoading: loading,
                  currentPage: page,
                  totalPages: meta?.total,
                  onChangePaginate: handlePageChange,
                }}
              />

              {loading && <MediaPlaceholderLoader />}
              {!loading && module?.length === 0 && (
                <div className="h-72">
                  <EmptyContent message="No Classroom for this course" />
                </div>
              )}
            </div>
          </>
        )}
        {detailedView && (
          <TeacherView id={detailedView} deleted={deleteModule} data={module} />
        )}
        {showUpload && (
          <Upload
            action={uploadCourse}
            edit={task}
            editTask={editing}
            taskTitle={["Classroom"]}
            taskId={4}
            open={showUpload}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default TeacherTutorial;
