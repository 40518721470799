import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { STUDENT_ROLE } from "../../common/variables";
import { role } from "../login/authSlice";
import { Teacher, TeacherSingleView, AssessmentPDF, Student } from "./pages";

const Assessment = () => {
  const userRole = useSelector(role);

  return (
    <div className="lg:pt-7 pt-4 select-none">
      {userRole === STUDENT_ROLE ? (
        <Student />
      ) : (
        <Routes>
          <Route path="/">
            <Route index element={<Teacher />} />
            <Route path="single" element={<TeacherSingleView />} />
            <Route path="pdf" element={<AssessmentPDF />} />
          </Route>
        </Routes>
      )}
    </div>
  );
};

export default Assessment;
