import { Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Typography } from "..";
import { reset } from "../../../features/login/authSlice";
import { SideNav, Header, DashboardHeader } from "./component";
import { useLogOutQuery } from "../../../features/login/authApi";
import { useMediaQuery, useOutsideDetecter } from "../../../hooks";
import { ProfileCard } from "./ProfileCard";
import { useLocation } from "react-router-dom";
import MenuIcons from "./component/MenuIcons";

const menu = [
  // {
  //   id: "1",
  //   name: "Profile",
  //   link: "/dashboard/profile",
  // },
];

const Page = ({ children }) => {
  const [isMenu, setMenu] = useState(false);
  const [isProfileVisible, setProfileVisble] = useState(false);
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const [isSideNavVisible, setSideNavVisible] = useState(
    isMobileView ? false : true
  );
  const [requstLogout, setRequstLogout] = useState(true);
  const dispatch = useDispatch();
  const { refetch } = useLogOutQuery(undefined, {
    skip: requstLogout,
  });

  const menuRef = useRef(null);

  const location = useLocation();
  const isDashboardRoute = location.pathname === "/dashboard";
  const UseHeader = isDashboardRoute ? DashboardHeader : Header;
  /* A custom hook that detects if the user clicks outside the dropdown. */
  useOutsideDetecter(menuRef, () => setMenu(false));

  const handleLogOut = async () => {
    setRequstLogout(false);
    await refetch();
    dispatch(reset());
    setRequstLogout(true);
  };

  return (
    <div className={`flex w-full h-full`}>
      {isMobileView || isTabletView ? (
        <div
          onClick={() => setSideNavVisible(false)}
          className={`fixed top-0 left-0 bottom-0 w-full bg-gray-800/60 z-40 ${
            isSideNavVisible ? "" : "hidden"
          }`}
        ></div>
      ) : null}
      {/* {!isDashboardRoute && ( */}
      <SideNav
        {...{
          show: isSideNavVisible,
          // show: () => setSideNavVisible(true),
          close: () => setSideNavVisible(false),
        }}
      />
      {/* )} */}

      <div
        style={{
          width:
            isMobileView || isTabletView
              ? ""
              : // : isDashboardRoute
                // ? "100%"
                "calc(100% - 300px)",
        }}
        className={`${
          !isSideNavVisible ? "!w-full" : "grow"
        } bg-[#E5F0EF] h-full min-h-full relative`}
      >
        <UseHeader
          {...{
            onMenu: () => setMenu(!isMenu),
            onSideNav: () => setSideNavVisible(true),
          }}
        />
        <Transition
          as={Fragment}
          show={isMenu}
          enter="transition-all ease-in duration-500"
          enterFrom="transform -translate-y-full"
          enterTo="transform -translate-y-0"
          leave="transition-all ease-out duration-500"
          leaveFrom="transform -translate-y-0"
          leaveTo="transform -translate-y-full"
        >
          <div
            ref={menuRef}
            className={`w-40 bg-white shadow-xl absolute ${
              isDashboardRoute ? "top-[10px]" : ""
            }  right-3 z-30`}
          >
            {menu.map((item) => (
              <div key={item?.id} className="cursor-pointer group">
                <Link
                  to={item.link}
                  className="block p-2 border-transparent border-l-4 group-hover:border-secondary-container group-hover:bg-gray-100"
                >
                  <Typography>{item.name}</Typography>
                </Link>
              </div>
            ))}
            <div className="cursor-pointer group">
              <div
                onClick={() => {
                  setMenu(!isMenu);
                  setProfileVisble(!isProfileVisible);
                }}
                className="block p-2 border-transparent border-l-4 group-hover:border-secondary-container group-hover:bg-gray-100"
              >
                <Typography>Profile</Typography>
              </div>
            </div>
            {!isDashboardRoute && (
              <div className="cursor-pointer group">
                <div
                  onClick={() => handleLogOut()}
                  className="block p-2 border-transparent border-l-4 group-hover:border-secondary-container group-hover:bg-gray-100"
                >
                  <Typography>Log out</Typography>
                </div>
              </div>
            )}
          </div>
        </Transition>
        <ProfileCard
          {...{
            showProfile: isProfileVisible,
            onClose: () => setProfileVisble(!isProfileVisible),
          }}
        />
        <div
          className={`${
            isDashboardRoute ? "px-4 lg:px-20" : ""
          } relative h-full`}
        >
          <div
            className={`${
              isDashboardRoute
                ? "absolute inset-0 h-full bg-cover bg-center mb-0 lg:bg-dashboardBg"
                : "lg:mb-16"
            }`}
          ></div>
          {isDashboardRoute && (
            <div className="absolute inset-0 bg-white opacity-95"></div>
          )}
          {!isDashboardRoute && !(isMobileView || isTabletView) && (
            <div className="bg-white px-4 lg:px-20 -mt-[65px]">
              <MenuIcons />
            </div>
          )}

          {/* White overlay */}
          <div className={`${!isDashboardRoute ? "px-4 lg:px-20" : ""}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
