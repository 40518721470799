import { useSelector } from "react-redux";
import { STUDENT_ROLE, TEACHER_ROLE } from "../../common/variables";
import { role } from "../login/authSlice";
import { Student, Teacher } from "./layouts";

const Examination = () => {
  const userRole = useSelector(role);
  return (
    <div className="lg:pt-7 pt-4 select-none">
      {userRole === STUDENT_ROLE ? (
        <Student />
      ) : userRole === TEACHER_ROLE ? (
        <Teacher />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Examination;
