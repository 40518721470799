import { XIcon } from "@heroicons/react/outline";
import { MdDoneAll } from "react-icons/md";
import { useState } from "react";
import { Field } from "react-final-form";
import {
  DropZone,
  FieldArray,
  Form,
  Typography,
} from "../../../common/sharedComponent";
import { required } from "../../../common/sharedComponent/InputValidate";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { academicCalender } from "../../login/authSlice";
import {
  usePostFileUploadMutation,
  useUploadPredefinedUrlMutation,
} from "../../../common/uploadApi";
import {
  selectedSubject,
  userModule,
  teacherModule,
} from "../../../common/moduleSlice";
import {
  usePatchTeacherAssignmentMutation,
  usePostTeacherAssignmentMutation,
  usePostTeacherLessonMaterialMutation,
  usePostTeacherLessonMutation,
} from "../api";
import {
  getCompletedProgressStatus,
  getFileExtension,
  perseCustomError,
} from "../../../helpers";
import { AssessmentCbt, NewAssessment } from "../../assessment/components";
import NewClassroom from "../../tutorial/NewClassroom";
import { NewExamination } from "../../examination/component";
import Axios from "axios";
import { setProgress } from "../../../common/uploadProgressSlice";
import { isEqual } from "../../../utils";

const TaskTitle = ({ taskTitles = [] }) => {
  const [selectedTask, setSelectedTask] = useState(0);

  return (
    <div className="flex lg:ml-6 space-x-10">
      {taskTitles.map((task, i) => (
        <div onClick={() => setSelectedTask(i)}>
          {taskTitles && (
            <Typography
              variant="body1"
              capitalize
              color={`${selectedTask === i && "white"}`}
              key={i}
              className={`px-2 py-2.5 lg:px-5 cursor-pointer rounded-md ${
                selectedTask === i && "bg-red"
              }`}
            >
              {task}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

const Upload = ({
  action,
  open,
  edit,
  editTask,
  taskTitle,
  taskId,
  module,
  refetch,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = () => {
    refetch?.();
    setSubmitted(true);
  };

  /**
   * It returns a component based on the taskId
   * @returns A function
   */
  const renderTaskView = () => {
    switch (taskId) {
      case 6:
        return (
          <AssessmentCbt
            edit={edit}
            editTask={editTask}
            submit={() => onSubmit()}
          />
        );
      case 5:
        return (
          <NewExamination
            edit={edit}
            editTask={editTask}
            submit={() => onSubmit()}
          />
        );

      case 4:
        return (
          <NewClassroom
            edit={edit}
            editTask={editTask}
            submit={() => onSubmit()}
          />
        );

      case 3:
        return (
          <NewAssessment
            edit={edit}
            editTask={editTask}
            submit={() => onSubmit()}
          />
        );

      case 2:
        return <Trainng id={module} submit={() => onSubmit()} />;

      case 1:
        return (
          <Assignment
            edit={edit}
            editTask={editTask}
            submit={() => onSubmit()}
          />
        );

      default:
        return (
          <AVTutorial
            edit={edit}
            editTask={editTask}
            submit={() => onSubmit()}
          />
        );
    }
  };

  return (
    <>
      {open && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4">
            <div
              className="hidden fixed inset-0 bg-[rgba(0,0,0,0.5)] transition-opacity md:block"
              aria-hidden="true"
            ></div>
            <span
              className="hidden md:inline-block md:align-middle md:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-2xl">
              <div className="w-full relative flex items-center bg-white px-4 pt-8 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 lg:rounded-lg">
                <button
                  type="button"
                  className="absolute top-2 right-2 text-black"
                  onClick={action}
                >
                  <XIcon className="h-4 w-4" />
                </button>

                <div className="w-full flex flex-col">
                  {submitted ? (
                    <>
                      <MdDoneAll size={50} className="text-red h-20 mx-auto " />
                      <Typography className="text-center">
                        Uploaded. Students have been notified
                      </Typography>
                    </>
                  ) : (
                    <div className="aspect-w-2 aspect-h-3 overflow-hidden sm:col-span-4 lg:col-span-5">
                      <TaskTitle {...{ taskTitles: taskTitle }} />
                      {renderTaskView()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Upload;

const Assignment = ({ submit, edit, editTask }) => {
  /* Using the useSelector hook to get the state from the redux store. */
  const modules = useSelector(userModule);
  const calender = useSelector(academicCalender);

  /* Using the usePostTeacherAssignmentMutation hook to create a mutation function and a loading state. */
  const [addAssignment, { isLoading }] = usePostTeacherAssignmentMutation();

  /* Using the usePatchTeacherAssignmentMutation hook to update the assignment. */
  const [updateAssignment, { isLoading: updating }] =
    usePatchTeacherAssignmentMutation();

  /* Destructuring the useToasts hook from the react-toast-notifications library. */
  const { addToast } = useToasts();

  /* Using the usePostFileUploadMutation hook to upload a file. */
  const [fileUpload] = usePostFileUploadMutation();

  const dispatch = useDispatch();

  /* Finding the first item in the array that has a status of "Active" */
  const sessions = calender.filter((item) => item.status === "Active");
  const academicOptions = sessions.map((session) => ({
    id: session.id,
    name: session.name,
  }));

  const Fields = [
    {
      name: "topic",
      type: "text",
      label: "Topic",
      style: "lg:col-span-2",
    },
    {
      name: "submission_date",
      style: "lg:col-span-2",
      type: "date",
      label: "Submission Date",
      min: true,
      validate: required,
    },
    {
      name: "submission_time",
      type: "time",
      label: "Time",
      style: "lg:col-span-1",
    },
  ];

  const Textarea = [
    {
      name: "extra_note",
      label: "Description",
      type: "textarea",
      containerStyles: "w-full mt-3",
      textareaStyles: "h-32",
      rows: 4,
    },
  ];

  const Subjects = [
    {
      name: "academic_calender_id",
      type: "select",
      placeholder: "Academic Calendar",
      validate: required,
      containerStyles: " mt-6",
      label: "Academic Calendar",
      option: academicOptions,
      style: "lg:col-span-1",
    },
    {
      name: "module_id",
      type: "select",
      placeholder: "Select Module",
      validate: required,
      containerStyles: " mt-6",
      label: "Select Module",
      option: modules,
      style: "lg:col-span-1",
    },

    {
      name: "total_mark",
      type: "number",
      label: "Total Mark",
      validate: required,
      containerStyles: "mt-6",
      style: "lg:col-span-1",
    },
  ];

  const editField = [
    {
      name: "submission_date",
      style: "lg:col-span-1",
      type: "date",
      label: "Submission Date",
      min: true,
      validate: required,
    },
    {
      name: "total_mark",
      type: "number",
      label: "Total Mark",
      validate: required,
      style: "lg:col-span-1",
    },
  ];

  const onSubmit = async (values) => {
    const {
      academic_calender_id,
      topic,
      module_id,
      extra_note,
      submission_date,
      submission_time,
      file_url,
      total_mark,
    } = values;

    try {
      let resultUrl;

      if (edit !== 1) {
        // new assignment
        let formData = new FormData();
        formData.append("file", file_url[0]);
        resultUrl = await fileUpload({ body: formData }).unwrap();
        resultUrl?.error &&
          addToast(resultUrl?.error.data?.message, { appearance: "error" });
        resultUrl?.status === "success" && dispatch(setProgress(0));
      }
      if (resultUrl?.status === "success" || edit === 1) {
        resultUrl?.status === "success" &&
          addToast("File uploaded successfully", { appearance: "success" });
        // editing with new or existing pdf
        if (edit === 1) {
          const data = {
            academic_calender_id,
            topic,
            extra_note,
            module_id,
            total_mark,
            file_url: resultUrl?.data?.file_url || file_url,
            file_type: "file",
            submission_date,
            submission_time,
          };

          const res = await updateAssignment({ body: data, id: editTask?.id });
          if (res?.error) {
            perseCustomError(res?.error, addToast);
          } else {
            addToast("Assignment Succefully Updated", {
              appearance: "success",
            });
            submit();
          }
        } else {
          const data = {
            academic_calender_id,
            topic,
            extra_note,
            module_id,
            total_mark,
            file_url: resultUrl?.data?.file_url,
            file_type: "file",
            submission_date,
            submission_time,
          };

          // try {
          // formData.append('file', file_url[0]);
          // let resultUrl = await fileUpload({ body: formData }).unwrap();
          // resultUrl?.error &&
          //   addToast(resultUrl?.error.data?.message, { appearance: "error" });
          // if (resultUrl?.status === "success") {
          //   addToast("File uploaded successfully", { appearance: "success" });
          const res = await addAssignment({ body: data });
          if (res?.error) {
            perseCustomError(res?.error, addToast);
          } else {
            addToast("Assignment Succefully Submitted", {
              appearance: "success",
            });
            submit();
          }
        }
      }
      // } catch (e) {
      //   addToast('An error occurred, please try again later', { appearance: "error" });
      // }

      // }
    } catch (error) {
      dispatch(setProgress(0));
      perseCustomError(error, addToast);
    }
  };

  return (
    <div className="px-5">
      <Form
        btnStyles="my-5"
        buttonText={edit === 1 ? "Update Assignment" : "Submit Assignment"}
        end={true}
        onSubmit={onSubmit}
        isLoading={edit === 1 ? updating : isLoading}
        values={edit === 1 && editTask}
      >
        <Field
          component={DropComponent}
          name="file_url"
          validate={required}
          types={" application/pdf "}
          text="Drop the PDF file here"
        />

        <div className="">
          <div className="mt-7 flex flex-col lg:mt-5">
            {edit === 1 ? (
              <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-2 lg:space-y-0">
                <FieldArray field={editField} />
              </div>
            ) : (
              <>
                <div className="flex flex-col space-y-5 lg:mt-5 lg:grid lg:gap-3 lg:grid-cols-5 lg:space-y-0">
                  <FieldArray field={Fields} />
                </div>
                <FieldArray field={Textarea} />
                <div className="flex flex-col space-y-5 lg:mt-5 lg:grid lg:gap-3 lg:grid-cols-3 lg:space-y-0">
                  <FieldArray field={Subjects} />
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

const AVTutorial = ({ submit, edit, editTask }) => {
  /* The above code is using the useSelector hook to get the state from the redux store. */
  const modules = useSelector(userModule);
  const calender = useSelector(academicCalender);
  const moduleAllocation = useSelector(teacherModule);

  /* Destructuring the useToasts hook from the react-toast-notifications library. */
  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const [completed, setCompleted] = useState(0);

  /* Using the useUploadPredefinedUrlMutation hook to create a mutation function that will be used to
  upload a file to the server. */
  const [fileUploadPredefineUrl] = useUploadPredefinedUrlMutation();

  /* Finding the first item in the array that has a status of "Active" */
  const sessions = calender.filter((item) => item.status === "Active");
  const academicOptions = sessions.map((session) => ({
    id: session.id,
    name: session.name,
  }));

  /* Using the usePostTeacherLessonMutation hook to create a function that will be used to add a lesson
  to the database. */
  const [addLesson, { isLoading }] = usePostTeacherLessonMutation();

  const Fields = [
    {
      name: "topic",
      type: "text",
      validate: required,
      label: "Topic",
      style: "lg:col-span-1",
      containerStyles: "w-full",
    },
    {
      name: "module_id",
      type: "select",
      placeholder: "Select Module",
      validate: required,
      // containerStyles: "lg:mt-6",
      label: "Select Module",
      style: "lg:col-span-1",
      option: modules,
    },
    {
      name: "academic_calender_id",
      type: "select",
      placeholder: "Academic Calender",
      validate: required,
      // containerStyles: "lg:mt-6",
      label: "Academic Calender",
      style: "lg:col-span-1",
      option: academicOptions,
    },
  ];

  const Textarea = [
    {
      name: "extra_note",
      label: "Description",
      type: "textarea",
      validate: required,
      containerStyles: "w-full mt-3",
    },
  ];

  /**
   * It takes a file url, gets the file extension, and then sends a request to the server to get a
   * predefined url to upload the file to
   * @param file_url - The file you want to upload.
   * @returns The result of the fileUploadPredefineUrl function.
   */
  const getPredefinedUrl = async (file_url) => {
    let formData = new FormData();
    let file_extension = getFileExtension(file_url[0]);

    try {
      formData.append("file_type", file_extension.type);
      formData.append("file_name", file_url[0].name);
      formData.append("folder", "lessons");
      formData.append("file_extension", file_url[0].type);

      let result = await fileUploadPredefineUrl({ body: formData }).unwrap();

      return result;
    } catch (error) {
      console.error(error);
      return error.data;
    }
  };

  /**
   * It takes the values from the form, creates a formData object, gets the presigned url from the
   * server, uploads the file to the presigned url, and then sends the form data to the server
   * @param values - The values of the form.
   */
  const onSubmit = async (values) => {
    const { academic_calender_id, topic, extra_note, module_id, file_url } =
      values;
    let formData = new FormData();

    try {
      formData.append("file", file_url[0]);

      let presigned_url = await getPredefinedUrl(file_url);

      let uploadLargeFileResult = await Axios.put(presigned_url, file_url[0], {
        onUploadProgress: (progressEvent) =>
          getCompletedProgressStatus(progressEvent, (percent) => {
            dispatch(setProgress(percent));
          }),
      });

      uploadLargeFileResult?.error &&
        perseCustomError(uploadLargeFileResult?.error.data?.message, addToast);

      if (isEqual(uploadLargeFileResult?.status, 200)) {
        dispatch(setProgress(0));
        addToast("File uploaded successfully", { appearance: "success" });
        if (edit === 1) {
          //update endpoint
        } else {
          const file_type = getFileExtension(file_url[0]).type;

          const data = {
            academic_calender_id,
            topic,
            extra_note,
            module_id,
            file_url: `https:${presigned_url.split("?")[0].substr(6)}`,
            file_type: file_type,
          };

          const res = await addLesson({
            body: data,
            module_allocation_id: moduleAllocation?.id,
            module_id: module_id,
          });

          if (res?.error) {
            perseCustomError(res?.error, addToast);
          } else {
            addToast("Lesson Succefully Posted", { appearance: "success" });
            submit();
          }
        }
      }
    } catch (e) {
      perseCustomError(e, addToast);
    }
  };

  return (
    <div className="px-5">
      <Form
        btnStyles="my-5 "
        buttonText="Upload Lesson"
        onSubmit={onSubmit}
        progress={completed}
        end={true}
        isLoading={edit === 1 ? "updating" : isLoading}
        values={edit === 1 && editTask}
      >
        <Field
          component={DropComponent}
          name="file_url"
          types="video/mp4, audio/*, application/pdf"
          validate={required}
          text=" Drop the Audio/Video/Pdf file here"
        />
        <div className="">
          <div className="mt-7 flex flex-col lg:mt-5">
            <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-3 lg:space-y-0">
              <FieldArray field={Fields} />
            </div>
            <FieldArray field={Textarea} />
          </div>
        </div>
      </Form>
    </div>
  );
};

const Trainng = ({ submit, id }) => {
  const { addToast } = useToasts();
  const [fileUpload] = usePostFileUploadMutation();
  const [fileUploadPredefineUrl] = useUploadPredefinedUrlMutation();
  const [addLesson, { isLoading }] = usePostTeacherLessonMaterialMutation();

  const Fields = [
    {
      name: "title",
      type: "text",
      validate: required,
      label: "Topic",
      containerStyles: "",
    },
  ];

  const getPredefinedUrl = async () => {
    let formData = new FormData();
    try {
      formData.append("file_type", this.file_type);
      formData.append("file_name", this.file.name);
      formData.append("folder", "lessons");
      formData.append("file_extension", this.file.type);
      let result = await fileUploadPredefineUrl({ body: formData }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values) => {
    const { file_url, title } = values;
    let formData = new FormData();
    try {
      formData.append("file", file_url[0]);
      let resultUrl = await fileUpload({ body: formData }).unwrap();
      resultUrl?.error && perseCustomError(resultUrl?.error, addToast);
      if (resultUrl?.status === "success") {
        addToast("File uploaded successfully", { appearance: "success" });
        const data = {
          file_url: resultUrl?.data?.file_url,
          file_type: "file",
          title,
        };
        const res = await addLesson({ body: data, id });
        if (res?.error) {
          perseCustomError(res?.error, addToast);
        } else {
          addToast("Lesson Succefully Posted", { appearance: "success" });
          submit();
        }
      }
    } catch (e) {
      perseCustomError(e, addToast);
    }
  };
  return (
    <div className="px-5">
      <Form
        btnStyles="my-5 "
        buttonText="Upload Materials"
        onSubmit={onSubmit}
        end={true}
        isLoading={isLoading}
      >
        <Field
          component={DropComponent}
          name="file_url"
          types="application/pdf"
          validate={required}
          text=" Drop the Pdf file here"
        />
        <div className="">
          <div className="mt-7 flex flex-col lg:mt-5">
            <div className="flex  flex-col space-y-5 ">
              <FieldArray field={Fields} />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export const DropComponent = ({ text, types, input: { value, onChange } }) => {
  return (
    <div className="aspect-w-2 aspect-h-3 rounded-lg overflow-hidden lg:col-span-5">
      <div className="mt-3  justify-center px-6 pt-5 pb-10 border-2 border-gray-300 border-dashed rounded-lg bg-gray-200">
        <div className="space-y-1 text-center">
          <DropZone
            text={text}
            accept={types}
            styles="flex-1"
            onAcceptedFiles={(value) => onChange(value)}
          />
        </div>
      </div>
    </div>
  );
};
