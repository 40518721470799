import { configureStore, Reducer, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "../features/login/authApi";
import authReducer from "../features/login/authSlice";
import { verifyMeApi } from "../common/verifyMeApi";
import { dashboardApis } from "../features/dashboard/api";
import { tutorialApis } from "../features/tutorial/api";
import { assessmentApis } from "../features/assessment/api";
import moduleReducer from "../common/moduleSlice";
import { examinationApis } from "../features/examination/api";
import { assignmentApis } from "../features/assignment/api";
import { messagingApis } from "../features/messaging/api";
import { QuizApi } from "../features/Quiz/api";
import { academicCalenderApis } from "../common/academicApi";
import { notificationApis } from "../common/notificationApi";
import messageReducer from "../features/messaging/slice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import { LessonApis } from "../features/Lessons/api";
import { uploadApis, predefinedApi } from "../common/uploadApi";
import { updateVideoPercentage } from "../common/updateVideoPercentage";
import { reducer as uploadProgressReducer } from "../common/uploadProgressSlice";
import LogRocket from "logrocket";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const persistMessageConfig = {
  key: "message",
  version: 1,
  storage,
  blacklist: ["selectedChat"],
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedMessageReducer = persistReducer(
  persistMessageConfig,
  messageReducer
);

const appReducer = combineReducers({
  auth: persistedAuthReducer,
  message: persistedMessageReducer,
  uploadProgress: uploadProgressReducer,
  module: moduleReducer.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [QuizApi.reducerPath]: QuizApi.reducer,
  [verifyMeApi.reducerPath]: verifyMeApi.reducer,
  [dashboardApis.reducerPath]: dashboardApis.reducer,
  [tutorialApis.reducerPath]: tutorialApis.reducer,
  [assessmentApis.reducerPath]: assessmentApis.reducer,
  [examinationApis.reducerPath]: examinationApis.reducer,
  [assignmentApis.reducerPath]: assignmentApis.reducer,
  [messagingApis.reducerPath]: messagingApis.reducer,
  [notificationApis.reducerPath]: notificationApis.reducer,
  [academicCalenderApis.reducerPath]: academicCalenderApis.reducer,
  [LessonApis.reducerPath]: LessonApis.reducer,
  [uploadApis.reducerPath]: uploadApis.reducer,
  [updateVideoPercentage.reducerPath]: updateVideoPercentage.reducer,

  [predefinedApi.reducerPath]: predefinedApi.reducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === "auth/reset") {
    storage.removeItem("persist:root");
    storage.removeItem("persist:message");
    state = {};
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      verifyMeApi.middleware,
      dashboardApis.middleware,
      tutorialApis.middleware,
      assessmentApis.middleware,
      examinationApis.middleware,
      assignmentApis.middleware,
      messagingApis.middleware,
      academicCalenderApis.middleware,
      notificationApis.middleware,
      LessonApis.middleware,
      updateVideoPercentage.middleware,
      uploadApis.middleware,
      predefinedApi.middleware,
      QuizApi.middleware,
      createLogger({ collapsed: true }),
      LogRocket.reduxMiddleware()
    ),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export default store;
