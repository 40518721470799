import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "../../../common/sharedComponent";
import { ViewPdf } from "../ViewPdf";

export const PdfViewer = () => {

  const navigate = useNavigate();
  const {
    state: { assignment, id, mark },
  } = useLocation();

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        className="flex items-center gap-x-2 mb-3 cursor-pointer"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <Typography {...{}}>Back</Typography>
      </div>

      <ViewPdf
        id={id}
        student={assignment}
        task="assignment"
        mark={mark}
      />
    </>
  );
};
