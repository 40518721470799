import cn from "classnames";
import PropTypes from "prop-types";
import { Rings } from "react-loader-spinner";
import { FilterIcon, CheckIcon } from "@heroicons/react/outline";
import { RadioGroup } from "@headlessui/react";
import { Typography } from ".";
import { useState } from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const tailwindStyleVariantMapping = {
  text: "",
  contained: "rounded-md shadow",
  outlined: "rounded-md border",
  rounded: "rounded-full py-2 px-3",
};

const sizesVariantMapping = {
  none: "",
  sm: "h-[32px] w-[118px]",
  md: "h-[40px] w-[159px]",
  lg: "px-14 py-4",
  fullWidth: "w-full py-2",
};

const fontSizeVariantMapping = {
  text: "text-sm",
  sm: "text-sm",
  md: "text-md",
  lg: "text-lg",
};
const colorTextVariantMapping = {
  // primary: "text-white",
  secondary: `text-red`,
  dark: "text-dark text-opacity-60",
};

const colorBgVariantMapping = {
  primary: `bg-red text-white`,
  secondary: "bg-white ",
  dark: "bg-dark",
};

const colorBorderVariantMapping = {
  primary: `border-red`,
  secondary: `border-red`,
  dark: "border-dark",
};

const shadowVariant = {
  none: "",
  sm: "hover:shadow-sm hover:shadow-red-500/30",
  md: "hover:shadow-md hover:shadow-red-500/30",
  lg: "hover:shadow-lg hover:shadow-red-500/30",
};

const Button = ({
  variant,
  sizes,
  colors,
  className,
  children,
  type,
  onClick,
  disabled,
  isLoading,
  shadow,
  fullWidth,
  capitalize,
  uppercase,
  lowercase,
  component,
  ...props
}) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (!disabled && typeof onClick !== "undefined") onClick();
  };
  const Component = component ?? "button";

  return (
    <Component
      onClick={type ? null : handleClick}
      type={type}
      className={`${className} ${cn({
        "bg-opacity-30": disabled,
        "cursor-default": disabled,
        "w-full": fullWidth,
        [tailwindStyleVariantMapping[variant]]: variant,
        [sizesVariantMapping[sizes]]: sizes,
        [sizesVariantMapping["none"]]: variant === "text",
        [fontSizeVariantMapping[sizes]]: sizes,
        [colorTextVariantMapping[colors]]: colors && variant !== "contained",
        [colorBgVariantMapping[colors]]: variant === "contained",
        [colorBorderVariantMapping[colors]]: variant === "outlined",
        [shadowVariant[shadow]]: shadow,
        capitalize: capitalize,
        uppercase: uppercase,
        lowercase: lowercase,
        "border-red/30 text-red/30": variant === "outlined" && disabled,
        "hover:bg-opacity-80": !isLoading && !disabled,
      })} `}
      {...props}
    >
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Rings color="#FFf" height={30} width={50} className="mx-auto" />
        </div>
      ) : (
        children
      )}
    </Component>
  );
};

Button.defaultProps = {
  variant: "text",
  sizes: "md",
  colors: "primary",
  shadow: "lg",
};

Button.propTypes = {
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  sizes: PropTypes.oneOf(["sm", "md", "lg", "fullWidth"]),
  shadow: PropTypes.oneOf(["sm", "md", "lg", "none"]),
  colors: PropTypes.oneOf(["primary", "secondary", "dark"]),
};

export default Button;

export const UploadButton = ({
  variant,
  sizes,
  colors,
  className,
  children,
  type,
  onClick,
  disabled,
  isLoading,
  shadow,
  fullWidth,
  capitalize,
  uppercase,
  lowercase,
  accept,
  onFiles,
  ...props
}) => {
  return (
    <div className="relative overflow-hidden inline-block cursor-pointer">
      <button
        className={`${cn({
          "bg-opacity-30": disabled,
          "w-full": fullWidth,
          [tailwindStyleVariantMapping[variant]]: variant,
          [sizesVariantMapping[sizes]]: sizes,
          [sizesVariantMapping["none"]]: variant === "text",
          [fontSizeVariantMapping[sizes]]: sizes,
          [colorTextVariantMapping[colors]]: colors && variant !== "contained",
          [colorBgVariantMapping[colors]]: variant === "contained",
          [colorBorderVariantMapping[colors]]: variant === "outlined",
          [shadowVariant[shadow]]: shadow,
          capitalize: capitalize,
          uppercase: uppercase,
          lowercase: lowercase,
        })} ${className} hover:bg-opacity-80 cursor-pointer`}
        {...props}
      >
        {children}
      </button>
      <input
        type="file"
        name="myfile"
        className="absolute left-0 top-0 opacity-0 cursor-pointer"
        accept={accept}
        onChange={onFiles}
        {...props}
      />
    </div>
  );
};

export const FilterButton = ({ selected, setSelected, options = [] }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative w-72 mb-2 lg:mb-0">
      <div
        onClick={() => setShow(!show)}
        className="flex justify-center items-center gap-x-2 bg-secondary-container text-on-secondary-container h-[40px] w-[159px] rounded-md cursor-pointer"
      >
        <Typography className="" variant="caption">
          Filter
        </Typography>
        <FilterIcon className="h-4 w-4" />
      </div>
      <div className={`absolute left-3 mt-2 z-30 ${show ? "" : "hidden"}`}>
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only ">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.name}
                value={option}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-offset-2 ring-offset-tertiary ring-white ring-opacity-60"
                      : ""
                  }
                  ${
                    checked
                      ? "bg-tertiary bg-opacity-75 text-white"
                      : "bg-white"
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as={Typography}
                            className={`font-medium capitalize ${
                              checked ? "text-white" : "text-gray-900"
                            }`}
                            variant="caption"
                          >
                            {option.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-sky-100" : "text-gray-500"
                            }`}
                          >
                            {option.desc}
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="flex-shrink-0 text-white">
                          <CheckIcon className="w-6 h-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};
