import { Button, Typography } from ".";
import ProgressBar from "@ramonak/react-progress-bar";
import { CheckIcon } from "@heroicons/react/solid";

export const UploadItem = ({ file, success, onRemove, onUpload, progress }) => {
  const defaultComponet = (
    <div className="lg:h-[55px] py-5 lg:py-0 w-full bg-[#E5F0EF] mt-[40px] px-[10px] lg:flex items-center justify-between mb-4 rounded-md">
      <div className="flex items-center">
        <Typography className="!text-[#006B5D] text-[10px] leading-[12.1px] font-[600]">
          Document:
        </Typography>
        <Typography className="!text-[#006B5D] text-[10px] leading-[12.1px] ml-2">
          {file?.name}
        </Typography>
      </div>
      <div className="flex gap-2 items-center mt-3 lg:mt-0">
        {progress === 0 && (
          <Button
            {...{
              variant: "text",
              className:
                "!text-[#006B5D] text-[10px] leading-[12px] font-[600] bg-white w-fit p-[8px] h-fit",
              onClick: onRemove,
              shadow: "none",
            }}
          >
            Remove
          </Button>
        )}
        {progress === 0 && (
          <Button
            {...{
              variant: "text",
              className:
                "!text-white text-[10px] leading-[12px] font-[600] bg-[#006B5D] w-fit p-[8px] h-fit",
              onClick: onUpload,
              shadow: "none",
            }}
          >
            Upload
          </Button>
        )}
        {progress !== 0 && (
          <ProgressBar
            className="w-40"
            bgColor="#006B5D"
            // barContainerClassName="bg-red"
            completed={progress}
          />
        )}
      </div>
    </div>
  );

  const SuccessComponent = (
    <div className="h-[55px] w-full bg-[#E5F0EF] mt-[40px] px-[10px] flex items-center justify-center mb-4 rounded-md">
      <div className="flex items-center">
        <CheckIcon className="h-3 w-3 text-[#006B5D] mr-3" />
        <Typography className="!text-[#006B5D] text-[10px] leading-[12.1px] font-[600]">
          Uploaded:
        </Typography>
        <Typography className="!text-[#006B5D] text-[10px] leading-[12.1px] ml-2">
          Computer Practice(N4)_solution_bright.pdf
        </Typography>
      </div>
    </div>
  );
  return success ? SuccessComponent : defaultComponet;
};
