import { DocumentIcon, MessageIcon } from "../../assets";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  Button,
  CommentInput,
  Comments,
  MediaLayout,
  Typography,
  EmptyContent,
  CardPlaceholderLoader,
} from "../../common/sharedComponent";
import Upload from "../dashboard/components/upload";
import { useState } from "react";
import { useGetTeacherClassroomByIdQuery } from "./api";

const TeacherView = ({ id, deleted, data }) => {
  const [showUpload, setShowUpload] = useState(false);
  const [floatAction, setFloatAction] = useState(false);
  const { data: classroomDetails, isLoading } =
    useGetTeacherClassroomByIdQuery(id);
  const comments = []; //fetch from db
  const details = data?.find((data) => data.id === id);
  // console.log(details, data, "data");

  const uploadCourse = () => {
    setShowUpload(!showUpload);
    setFloatAction(false);
  };

  return (
    <div className="pb-10 ">
      {!isLoading && (
        <MediaLayout
          {...{
            //   isNewContent: true,
            containerClass: "",
            leftContainer:
              " mb-3 lg:w-52 border border-gray-200 rounded grid place-items-center lg:ml-4 py-4",
            left: (
              <img
                src={DocumentIcon}
                alt="document"
                className="h-[88px] w-[88px] lg:h-16 lg:w-16"
              />
            ),
            right: (
              <div className="lg:flex">
                <div className="flex-1">
                  <div className="flex gap-x-2 items-center mb-1 ">
                    <Typography
                      {...{
                        variant: "subheader2",
                        className: "text-black",
                      }}
                    >
                      {details?.title}
                    </Typography>
                  </div>
                  <Typography>{details?.meeting_id}</Typography>
                  <div className="flex flex-col space-y-3 mt-4 lg:flex-row lg:mt-7 lg:space-x-8 lg:space-y-0">
                    <div className="flex flex-col">
                      <Typography
                        {...{
                          variant: "caption",
                          className: "text-black",
                        }}
                      >
                        Status
                      </Typography>
                      <Typography color="red" variant="captionS">
                        {details?.status}
                      </Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography
                        {...{
                          variant: "caption",
                          className: "text-black ",
                        }}
                      >
                        Duration
                      </Typography>
                      <Typography color="red" variant="captionS">
                        {details?.duration}
                      </Typography>
                    </div>
                    <div className="flex flex-col">
                      <Typography
                        {...{
                          variant: "caption",
                          className: "text-black",
                        }}
                      >
                        Start Time
                      </Typography>
                      <Typography color="red" variant="captionS">
                        {details?.start_time}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 flex items-center justify-between lg:hidden">
                  <div className="flex gap-x-2 items-center">
                    <Typography color="defaultBody">14 New Comments</Typography>
                    <img
                      src={MessageIcon}
                      className="h-[20px] w-[20px] text-gray-600"
                    />
                  </div>
                  <div className="flex  justify-end">
                    <DotsVerticalIcon
                      className="h-[24px] w-[24px] absolute  right-3 cursor-pointer"
                      onClick={() => setFloatAction(!floatAction)}
                    />
                    {floatAction && (
                      <div className="border mr-8 flex flex-col">
                        <Button
                          colors={"dark"}
                          sizes={"sm"}
                          shadow={"sm"}
                          className={"border-b"}
                          onClick={() => uploadCourse()}
                        >
                          Edit
                        </Button>
                        <Button
                          variant={"contained"}
                          sizes={"sm"}
                          shadow={"sm"}
                          onClick={() => deleted(id)}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                </div> */}

                {/* <div className="flex-1 relative hidden lg:block">
                  <div className="flex  justify-end">
                    <DotsVerticalIcon
                      className="h-[24px] w-[24px] absolute top-0 right-3 cursor-pointer"
                      onClick={() => setFloatAction(!floatAction)}
                    />
                    {floatAction && (
                      <div className="border mr-8 flex flex-col">
                        <Button
                          colors={"dark"}
                          sizes={"sm"}
                          shadow={"sm"}
                          className={"border-b"}
                          onClick={() => uploadCourse()}
                        >
                          Edit
                        </Button>
                        <Button
                          variant={"contained"}
                          sizes={"sm"}
                          shadow={"sm"}
                          onClick={() => deleted(id)}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                  <div
                    className={`flex justify-end items-end h-full gap-x-2 pr-3 ${
                      floatAction && "-mt-[4.15rem]"
                    }`}
                  >
                    <Typography color="defaultBody">14 New Comments</Typography>
                    <img
                      src={MessageIcon}
                      className="h-[20px] w-[20px] text-gray-600"
                    />
                  </div>
                </div> */}
              </div>
              // </div>
            ),
          }}
        />
      )}
      {isLoading && <CardPlaceholderLoader />}
      <CommentInput />
      {comments.length > 0 && <Comments />}
      {comments.length === 0 && <EmptyContent message={"No Comments"} />}
      {showUpload && (
        <Upload action={uploadCourse} open={showUpload} editTask={id} />
      )}{" "}
    </div>
  );
};

export default TeacherView;
