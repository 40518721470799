import { TeacherCard } from "../components/Card";

export const Modules = ({ modules = [], onEdit, onView }) => {
  return (
    <div className="grid  lg:grid-cols-3 grid-rows-3 gap-3 w-full pt-3 overflow-auto scrollbar-hide lg:pr-0 pr-5 ">
      {modules?.map?.((assignment, i) => (
        <TeacherCard
          {...{
            assignment,
            onEdit: () => onEdit?.(assignment),
            onView: () => onView?.(assignment),
            key: assignment?.id,
          }}
        />
      ))}
    </div>
  );
};
