import { Typography } from ".";
// import { NoDataIcon } from "../../assets";
import  NoData from "../../assets/emptydata.png";

export const EmptyContent = ({ message, className }) => {
  return (
    <div className="h-full grid place-items-center">
      <div>
        <div className="h-16 w-16 mx-auto">
          <img src={NoData} className="h-full w-full" alt="empty" />
        </div>
        <Typography style={{color:"#006B5D", fontSize:"16px"}}  className={`text-center mt-2 ${className}`}>
          {message}
        </Typography>
      </div>
    </div>
  );
};
