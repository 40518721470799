import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";
import { teacherModuleAllocation } from "../../../helpers";

const enrollmentUrl = "/students/enrolments";
const moduleUrl = "/students/modules";
const fileUrl = "/students/dashboard/get-file";

export const dashboardApis = createApi({
  reducerPath: "dashboard",
  refetchOnReconnect: true,
  tagTypes: ["teacherAssignment"],
  baseQuery: baseQuery((headers) => {
    return headers.set("accept", "application/json");
  }),
  endpoints: (build) => ({
    getStudentDashboard: build.query({
      query: () => "/students/dashboard",
    }),

    getStudentAssignments: build.query({
      query: () => "/students/assignments",
    }),

    getStudentEnrollments: build.query({
      query: () => enrollmentUrl,
    }),

    getStudentModules: build.query({
      query: ({ page = 1, status = "enrolled" }) =>
        `${moduleUrl}?page=${page}&status=${status}`,
    }),

    getModuleFile: build.mutation({
      query: ({ lesson }) => ({
        url: `${fileUrl}/${lesson}`,
        method: "Get",
        responseType: "blob", // important
      }),
    }),

    getTeacherDashboard: build.query({
      query: () => "/teachers/dashboard",
    }),

    getTeacherEnrollment: build.query({
      query: ({ teacher, module, page }) =>
        `${teacherModuleAllocation}/${teacher}/modules/${module}/enrolments?page=${page}`,
    }),

    getModules: build.query({
      query: () => teacherModuleAllocation,
    }),

    getTeacherModules: build.query({
      query: () => `${teacherModuleAllocation}?status=Active`,
    }),

    postTeacherAssignment: build.mutation({
      query: ({ body }) => ({
        url: "/teachers/assignments",
        method: "Post",
        body,
      }),
      invalidatesTags: ["teacherAssignment"],
    }),

    patchTeacherAssignment: build.mutation({
      query: ({ body, id }) => ({
        url: `/teachers/assignments/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["assignment"],
    }),

    postTeacherLesson: build.mutation({
      query: ({ body, module_allocation_id, module_id }) => ({
        url: `${teacherModuleAllocation}/${module_allocation_id}/modules/${module_id}/lessons`,
        method: "Post",
        body,
      }),
    }),

    postTeacherLessonMaterial: build.mutation({
      query: ({ body, id }) => ({
        url: `/teachers/lessons/${id}/course-materials`,
        body,
        method: "Post",
      }),
    }),
  }),
});

export const {
  useGetStudentEnrollmentsQuery,
  useGetStudentAssignmentsQuery,
  useGetStudentDashboardQuery,
  useGetStudentModulesQuery,
  useGetTeacherDashboardQuery,
  useGetTeacherEnrollmentQuery,
  useGetTeacherModulesQuery,
  usePostTeacherAssignmentMutation,
  usePostTeacherLessonMutation,
  usePostTeacherLessonMaterialMutation,
  usePatchTeacherAssignmentMutation,
  useGetModuleFileMutation,
} = dashboardApis;
