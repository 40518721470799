import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState, useCallback } from "react";
import { Typography } from ".";

export const Tabs = ({
  selectedSubject,
  className,
  subjects,
  isLoading,
  valueSelector = "name",
  showAll = false,
}) => {
  const [active, setActive] = useState(null);
  const [scrollLength, setScrollLength] = useState(0);
  const [widthDifference, setWidthDifference] = useState(0);
  const scrollRef = useRef();

  let All = { id: 0, only_name: "all", name: "all" };

  useEffect(() => {
    let abortController = new AbortController();

    if (subjects?.length && active === null) {
      handleSelectedTab(subjects[0]);
    }

    let widthDiff =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    setWidthDifference(widthDiff);

    return () => {
      abortController.abort();
    };
  }, [subjects, showAll]);

  const handleSelectedTab = (value) => {
    setActive(value);
    selectedSubject(value);
  };

  const handleScroll = (value) => {
    // handle scrolling to left or
    // right of the content element.
    if (scrollRef.current === null) return;
    let updatedScrollLength = scrollLength + value;
    scrollRef.current.scroll({
      top: 0,
      left: updatedScrollLength,
      behaviour: "smooth",
    });
    setScrollLength(updatedScrollLength);
  };

  return (
    <div className={`relative flex items-center h-fit ${className}`}>
      <div
        className={`h-8 w-8 bg-red grid place-items-center absolute rounded-full -left-1 shadow cursor-pointer z-10 ${
          widthDifference <= 0
            ? "bg-opacity-30"
            : scrollLength <= 0
            ? "bg-opacity-30"
            : ""
        }`}
        onClick={
          widthDifference <= 0
            ? null
            : scrollLength <= 0
            ? null
            : () => handleScroll(-100)
        }
      >
        <ArrowLeftIcon className="h-4 w-4 text-white" />
      </div>
      <div
        className={`grid grid-cols-3 gap-4 items-center gap-x-1 overflow-x-auto scrollbar-hide px-9 lg:px-3`}
        ref={scrollRef}
      >
        {!isLoading && showAll ? (
          <TabContainer
            {...{
              item: All,
              handleSelectedTab,
              active,
              valueSelector,
            }}
          />
        ) : null}
        {!isLoading &&
          subjects.map((item) => (
            <TabContainer
              {...{ item, handleSelectedTab, valueSelector, active }}
            />
          ))}
        {isLoading && <TabLoader />}
        {!isLoading && subjects.length === 0 && !showAll && (
          <Typography className={"w-32"}>No Module Allocated</Typography>
        )}
      </div>
      <div
        className={`h-8 w-8 bg-red grid place-items-center absolute rounded-full -right-0 shadow cursor-pointer z-10 ${
          widthDifference <= 0
            ? "bg-opacity-30"
            : scrollLength >= scrollRef.current.scrollWidth
            ? "bg-opacity-30"
            : ""
        }`}
        onClick={
          widthDifference <= 0
            ? null
            : scrollLength === scrollRef.current.scrollWidth
            ? null
            : () => handleScroll(100)
        }
      >
        <ArrowRightIcon className="h-4 w-4 text-white" />
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  subjects: [],
};

const TabContainer = ({ handleSelectedTab, item, active, valueSelector }) => {
  return (
    <div
      onClick={() => handleSelectedTab(item)}
      key={item?.id}
      className={`rounded-md lg:rounded-t-md grid place-items-center cursor-pointer border-2 border-red lg:border-0 flex-shrink-0 h-10 ${
        active?.id === item?.id && `bg-red`
      }`}
    >
      <Typography
        {...{
          className: `px-3 py-1 lg:px-5 ${
            active?.id === item?.id ? "text-on-primary/100" : "text-red/60"
          }`,
          color: active?.id === item?.id ? "white" : "defaultHeader",
          variant: "body2",
          capitalize: true,
        }}
      >
        {item[valueSelector]}
      </Typography>
    </div>
  );
};

export const TabLoader = () => {
  return [1, 2, 3, 4].map((item) => (
    <div
      key={item}
      className="h-32 w-32 mx-auto bg-gray-200 animate-pulse rounded-md my-10"
    ></div>
  ));
};
