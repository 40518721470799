import { Link } from "react-router-dom";
import { Typography } from "..";
import { BG, Education, Logo } from "../../../assets";

const Auth = ({ children }) => {
  return (
    <div
      className="
		bg-no-repeat bg-cover bg-center 
		bg-mobileLoginBG
		lg:bg-loginBG h-screen w-full"
    >
      <div className="h-full w-full bg-[#AAAAAA]/[88%] backdrop-brightness-50">
        {/* <Typography color="red" variant="display" className="text-center">
        Welcome to HillCross
      </Typography>
      <Typography color="red" variant="display" className="text-center">
        E-Learning
      </Typography>
      <Link to="/">
        <img src={Logo} alt="logo" className="mx-auto h-full" />
      </Link> */}
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Auth;
