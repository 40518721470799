import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const Examination = ({ input: { value, onChange } }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertToHTML, setConvertToHTML] = useState(null);

  const editorStateChange = (editorState) => {
    setEditorState(editorState);
    setConvertToHTML(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    onChange(convertToHTML);
  };

  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbarClassName="toolbarClassName"
        onEditorStateChange={editorStateChange}
        editorStyle={{ border: "1px solid", height: "200px" }}
      />
    </>
  );
};

export default Examination;
