import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";

let baseAssessmentUrl = "/students/assessments";

export const assessmentApis = createApi({
  reducerPath: "assessments",
  refetchOnReconnect: true,
  tagTypes: ["studentAssessments", "teacherAssessment"],
  baseQuery: baseQuery((headers) => {
    return headers.set("accept", "application/json");
  }),
  endpoints: (builder) => ({
    /* Creating a query that can be used to fetch student assessments. */
    studentAssessments: builder.query({
      query: ({ page, module_id }) =>
        `${baseAssessmentUrl}?page=${page}&module=${module_id}&adminStatus=not-end`,
      providesTags: ["studentAssessments"],
    }),

    /* This is a query that is used to fetch the questions for a particular assessment. */
    studentAssessmentQuestion: builder.query({
      query: ({ assessment_id }) =>
        `/students/examinations/${assessment_id}/questions`,
    }),

    /* This is a mutation that is used to post an assessment. */
    studentPostAssessment: builder.mutation({
      query: ({ assessment_id, file_url }) => ({
        url: baseAssessmentUrl,
        method: "Post",
        body: {
          assessment_id,
          file_type: "file",
          file_url,
        },
      }),
      invalidatesTags: ["studentAssessments"],
    }),

    /* This is a mutation that is used to update an assessment. */
    studentUpdateAssessmment: builder.mutation({
      query: ({ assessment_id, file_url }) => ({
        url: `${baseAssessmentUrl}/${assessment_id}`,
        method: "Patch",
        body: {
          file_type: "pdf",
          file_url,
        },
      }),
    }),

    /* This is a query that is used to fetch the student dashboard. */
    getStudentDashboard: builder.query({
      query: () => "/students/dashboard",
    }),

    /* This is a query that is used to fetch the student dashboard. */
    studentRequestAttempt: builder.query({
      query: ({ assessment_id }) => `${baseAssessmentUrl}/attempted`,
    }),

    /* This is a query that is used to fetch the teacher assessments. */
    teacherAssessments: builder.query({
      query: ({ id, module_id }) =>
        `/teachers/assessments?page=${id}&module=${module_id}&adminStatus=not-end`,
      providesTags: ["teacherAssessment"],
    }),

    /* This is a query that is used to fetch the teacher assessment by id. */
    getTeacherAssessmentById: builder.query({
      query: (id) => `/teachers/assessments/${id}?adminStatus=not-end`,
      providesTags: ["documentAnnotation"],
    }),

    /* This is a mutation that is used to post a teacher assessment. */
    postTeacherAssessment: builder.mutation({
      query: ({ body }) => ({
        method: "Post",
        body,
        url: "teachers/assessments",
      }),
      invalidatesTags: ["teacherAssessment"],
    }),

    /* This is a mutation that is used to update a teacher assessment. */
    updateTeacherAssessment: builder.mutation({
      query: ({ body, id }) => ({
        method: "PATCH",
        body,
        url: `teachers/assessments/${id}`,
      }),
      invalidatesTags: ["assessment"],
    }),

    /* This is a mutation that is used to post a question to an assessment. */
    postCbtQuestions: builder.mutation({
      query: ({ body, id }) => ({
        url: `/teachers/assessments/${id}/questions`,
        body,
        method: "Post",
      }),
      invalidatesTags: ["assessment"],
    }),

    /* This is a query that is used to fetch the questions for a particular assessment. */
    getCbtQuestions: builder.query({
      query: ({ id, page }) =>
        `/teachers/assessments/${id}/questions?page=${page}&adminStatus=not-end`,
    }),

    /* This is a mutation that is used to update a question for a particular assessment. */
    updateCbtAssessmentQuestion: builder.mutation({
      query: ({ body, assessmentId, questionId }) => ({
        url: `/teachers/assessments/${assessmentId}/questions/${questionId}`,
        body,
        method: "PATCH",
      }),
      providesTags: ["assessment"],
    }),

    /* This is a mutation that is used to delete a question for a particular assessment. */
    deleteCbtAssessmentQuestion: builder.mutation({
      query: ({ assessmentId, questionId }) => ({
        url: `/teachers/assessments/${assessmentId}/questions/${questionId}`,
        method: "Delete",
      }),
    }),

    /* This is a mutation that is used to delete an assessment. */
    deleteAssessmentQuestion: builder.mutation({
      query: (assessmentId) => ({
        url: `/teachers/assessments/${assessmentId}`,
        method: "Delete",
      }),
      providesTags: ["assessment"],
    }),

    /* This is a query that is used to fetch the graded assessment. */
    getGradedAssessment: builder.query({
      query: ({ type, resource_id }) =>
        `/document-annotations/students/${type}/resources/${resource_id}`,
    }),
  }),
});

export const {
  useStudentAssessmentsQuery,
  useLazyStudentAssessmentsQuery,
  useStudentRequestAttemptQuery,
  useStudentPostAssessmentMutation,
  useGetStudentDashboardQuery,
  useStudentAssessmentQuestionQuery,
  useStudentUpdateAssessmmentMutation,
  useTeacherAssessmentsQuery,
  useLazyTeacherAssessmentsQuery,
  useGetTeacherAssessmentByIdQuery,
  usePostTeacherAssessmentMutation,
  useUpdateTeacherAssessmentMutation,
  usePostCbtQuestionsMutation,
  useGetCbtQuestionsQuery,
  useUpdateCbtAssessmentQuestionMutation,
  useDeleteCbtAssessmentQuestionMutation,
  useDeleteAssessmentQuestionMutation,
  useGetGradedAssessmentQuery,
  useLazyGetGradedAssessmentQuery,
} = assessmentApis;
