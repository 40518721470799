import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { Action, Typography } from "../../../common/sharedComponent";
import { ViewPdf } from "../../assignment/ViewPdf";

export const AssessmentPDF = () => {
  /* Destructuring the `useLocation` hook. */
  const {
    state: { assessment: task, id, isMarked },
  } = useLocation();

  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        className="flex items-center gap-x-2 mb-3 cursor-pointer w-fit"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <Typography {...{}}>Back</Typography>
      </div>

      <div className="h-4" />

      <ViewPdf id={id} student={task} task="assessment" mark={isMarked} />
    </>
  );
}