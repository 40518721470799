/* eslint-disable react-hooks/exhaustive-deps */
import { Rings } from "react-loader-spinner";
import ViewSDKClient from "../viewSdkClient";
import { EmptyContent } from ".";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userToken } from "../../features/login/authSlice";
import { useToasts } from "react-toast-notifications";

export const PdfViewer = ({ url, meta, containerClass, loader }) => {
  const token = useSelector(userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { addToast } = useToasts();

  const loadPDF = async () => {
    let viewerConfig = {
      embedMode: "FULL_WINDOW",
      defaultViewMode: "FIT_WIDTH",
      showAnnotationTools: false,
      showLeftHandPanel: false,
      showPageControls: false,
      showDownloadPDF: false,
      showPrintPDF: true,
      enableAnnotationAPIs: true,
      includePDFAnnotations: true,
    };

    try {
      setIsLoading(true);
      const viewSDKClient = new ViewSDKClient();
      let ready = await viewSDKClient.ready();

      if (ready) {
        viewSDKClient.previewFile("pdf-div", viewerConfig, { url, meta });
        const res = await viewSDKClient.registerSaveApiHandler(token, meta);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      addToast(`Failed to load ${meta?.filename}`, {
        appearance: "error",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    if(!loader) loadPDF();

    return () => abortController.abort();
  }, [ loader ]);

  return (
    <div className={`h-[30rem] overflow-auto ${containerClass ?? ''}`}>
      {(isLoading || loader) && (
        <div className="h-full w-full grid place-items-center">
          <Rings />
        </div>
      )}
      <div id="pdf-div" className={`overflow-auto h-full `}></div>
    </div>
  );
};
