import { useSelector } from "react-redux";
import { STUDENT_ROLE, TEACHER_ROLE } from "../../common/variables";
import { role } from "../login/authSlice";
import { StudentDashboard, TeacherDashboard } from "./layouts";

const Dashboard = () => {
  const userRole = useSelector(role);
  return (
    <div className="relative mx-auto lg:pt-7 pt-4 select-none">
      {userRole === STUDENT_ROLE ? (
        <StudentDashboard />
      ) : userRole === TEACHER_ROLE ? (
        <TeacherDashboard />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Dashboard;
