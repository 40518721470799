import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { annotatedExam } from "../../../common/moduleSlice";
import {
  Action,
  Button,
  GradePDF,
  Modal,
  Table,
  Typography,
} from "../../../common/sharedComponent";
import { isEqual } from "../../../utils";
import { useTeacherAssignmentByIdQuery } from "../api";

export const TeacherSingleView = () => {
  const [gradeStatus, setGradStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [gradeAssignment, setGradeAssignment] = useState(null);

  const {
    state: { assignment },
  } = useLocation();

  const navigate = useNavigate();

  /* A selector that is used to get the state of the annotated pdf. */
  const isAnnotatedPdf = useSelector(annotatedExam);

  const downloadAnnotated = (data) => {
    window.open(data.file_url, "_blank");
  };

  const { submitted, notSubmitted, newMark, isLoading } =
    useTeacherAssignmentByIdQuery(assignment?.id, {
      selectFromResult: ({ data, isLoading }) => ({
        submitted: data?.submitted_assignments ?? [],
        notSubmitted: data?.not_submitted ?? [],
        newMark: data?.marked_assignment ?? [],
        isLoading,
      }),
    });

  const notSubmittedCol = [
    {
      // Header: "Student Name",
      accessor: "name",
    },
    {
      // Header: "Student ID",
      accessor: "student_id",
    },
  ];

  const submittedCol = [
    {
      // Header: "Student Name",
      accessor: "student_name",
    },
    {
      // Header: "Student ID",
      accessor: "student_id",
    },
    {
      // Header: "Action",
      accessor: "id",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex space-x-3">
            <Button
              variant="outlined"
              sizes="fullWidth"
              className="text-red capitalize"
              shadow="none"
              capitalize
              onClick={() =>
                navigate("/dashboard/assignments/pdf", {
                  state: { id: value, assignment: row.original, mark: false },
                })
              }
            >
              View Solution / Add annotation
            </Button>
            <Button
              variant="outlined"
              sizes="fullWidth"
              className="text-red capitalize"
              shadow="none"
              capitalize
              onClick={() => {
                setGradStatus(true);
                setGradeAssignment(row?.original);
              }}
            >
              Add Score
            </Button>
            {/* <Button
              variant="outlined"
              sizes="fullWidth"
              className="text-red"
              shadow="none"
              capitalize
              onClick={() => viewMarkedPdf(value, row)}
            >
              View annotation
            </Button> */}
          </div>
        );
      },
    },
  ];

  const markedcol = [
    {
      // Header: "Student Name",
      accessor: "student_name",
    },
    {
      // Header: "Score",
      accessor: "score",
    },
    {
      // Header: "Action",
      accessor: "id",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex space-x-3">
            <Button
              variant="outlined"
              sizes="fullWidth"
              className="text-red capitalize"
              shadow="none"
              capitalize
              onClick={() =>
                navigate("/dashboard/assignments/pdf", {
                  state: { id: value, assignment: row.original, mark: true },
                })
              }
            >
              View annotation
            </Button>
            <Button
              variant="outlined"
              sizes="fullWidth"
              className="text-red capitalize"
              shadow="none"
              capitalize
              onClick={() => downloadAnnotated(row?.original)}
            >
              Download annotation
            </Button>
          </div>
        );
      },
    },
  ];

  const initialState = {
    hiddenColumns: ["file_url", "student_uuid"],
  };

  const submittedList = submitted.map((item) => {
    return {
      student_name: item?.student_name,
      file_url: item?.file_url,
      student_id: item?.student_id,
      student_uuid: item?.student_uuid,
      id: item?.id,
      total: item?.score,
    };
  });

  const markedList = newMark.map((item) => {
    return {
      student_name: item?.student.name,
      file_url: item?.file_url,
      student_score: item?.score,
      student_id: item?.resource?.student?.student_id,
      student_uuid: item?.resource?.student?.id,
      id: item?.resource?.id,
      total: item?.total,
      score: item?.score,
    };
  });

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        className="flex items-center gap-x-2 mb-3 cursor-pointer"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <Typography {...{}}>Back</Typography>
      </div>

      <Action noLive noUpload>
        Assignments - {assignment?.module?.name}
      </Action>

      <div className="lg:flex justify-between items-center mt-3 mb-5">
        <Typography className="card1 bg-white p-2">
          Number of Students Offering this course -{" "}
          {submitted?.length + notSubmitted?.length + newMark.length}
        </Typography>

        <div className="flex lg:block items-center mt-2 lg:mt-0">
          <Button
            variant={isEqual(selectedStatus, 1) ? "contained" : `outlined`}
            sizes={"sm"}
            onClick={() => {
              setSelectedStatus(1);
            }}
            className={
              isEqual(selectedStatus, 1)
                ? "text-white mr-2 lg:ml-2"
                : "text-on-primary-container border-on-primary-container mr-2 ml-2"
            }
          >
            All Students
          </Button>

          <Button
            variant={isEqual(selectedStatus, 2) ? "contained" : `outlined`}
            sizes={"sm"}
            onClick={() => {
              setSelectedStatus(2);
            }}
            className={
              isEqual(selectedStatus, 2)
                ? "text-white"
                : "text-on-primary-container border-on-primary-container"
            }
          >
            Submitted
          </Button>

          <Button
            variant={isEqual(selectedStatus, 3) ? "contained" : `outlined`}
            sizes={"sm"}
            onClick={() => {
              setSelectedStatus(3);
            }}
            className={
              isEqual(selectedStatus, 3)
                ? "text-white ml-2"
                : "text-on-primary-container border-on-primary-container ml-2"
            }
          >
            Marked
          </Button>
        </div>
      </div>

      {isAnnotatedPdf && (
        <div className=" w-full bg-red p-3 text-white">
          Please Add Score for {isAnnotatedPdf?.student_name} to grade
        </div>
      )}

      <Table
        {...{
          initialState,
          columns: isEqual(selectedStatus, 1)
            ? notSubmittedCol
            : isEqual(selectedStatus, 2)
            ? submittedCol
            : markedcol,
          data: isEqual(selectedStatus, 1)
            ? notSubmitted
            : isEqual(selectedStatus, 2)
            ? submittedList
            : markedList,
          isLoading: isLoading,
        }}
      />

      <Modal
        {...{ open: gradeStatus, onClose: () => setGradStatus(!gradeStatus) }}
      >
        <GradePDF
          annotatedPdf={gradeAssignment}
          // refetch={() => refetch()}
          close={() => setGradStatus(!gradeStatus)}
        />
      </Modal>
    </>
  );
};
