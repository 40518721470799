import { Content } from "./component";

const StudentAssignment = () => {
  return (
    <div className="pl-5 lg:px-12 lg:pt-7 pt-4">
      <Content />
    </div>
  );
};

export default StudentAssignment;
