import { useRef } from "react";
import Pdf from "react-to-pdf";
import { Button, Typography } from "../../../common/sharedComponent";

const CreatePdf = ({ children }) => {
  const ref = useRef(null);

  const create = async (file) => {
    const modulePdf = new FormData();
    modulePdf.append("file", file);
    modulePdf.append("name", "module.pdf");
    // let res = await submit(modulePdf);
  };
  return (
    <>
      <div ref={ref} className="text-center flex flex-col">
        {children}
        {/* <Typography>Instruction: {data?.instruction}</Typography>
        <div className="flex flex-row justify-center space-x-10">
          <Typography>
            Date: {data?.date}
          </Typography>
          <Typography>Duration:{data?.duration} minutes</Typography>
        </div>
        <div dangerouslySetInnerHTML={rawHTML(data?.examination)}></div> */}
      </div>
      <Pdf targetRef={ref} filename="module.pdf">
        {({ toPdf }) => {
          return (
            <div className="flex justify-between px-10 mt-10">
              <Button variant="contained" onClick={toPdf}>
                Save a copy
              </Button>
              <Button variant="contained" onClick={() => create(toPdf)}>
                Set Examination
              </Button>
            </div>
          );
        }}
      </Pdf>
    </>
  );
};
export default CreatePdf;
