import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { STUDENT_ROLE, TEACHER_ROLE } from "../../common/variables";
import { role } from "../login/authSlice";
import { StudentAssignment } from "./layout";
import { Teacher, TeacherSingleView, PdfViewer } from "./pages";

const Assignments = () => {
  const userRole = useSelector(role);
  return (
    <div className="lg:pt-7 pt-4 select-none">
      {userRole === STUDENT_ROLE ? (
        <StudentAssignment />
      ) : userRole === TEACHER_ROLE ? (
        <Routes path="/">
          <Route index element={<Teacher />} />
          <Route path="single" element={<TeacherSingleView />} />
          <Route path="pdf" element={<PdfViewer />} />
        </Routes>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Assignments;
