import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";

export const tutorialApis = createApi({
  reducerPath: "tutorials",
  refetchOnReconnect: true,
  baseQuery: baseQuery((headers) => {
    return headers.set("accept", "application/json");
  }),
  endpoints: (builder) => ({
    getStudentDashboard: builder.query({
      query: () => "/students/dashboard",
    }),
    studentClassroom: builder.query({
      query: (page) => `/students/classrooms??page=${page}&module=all`,
    }),
    studentModule: builder.query({
      query: () => `/students/modules`,
    }),
    teacherClassroom: builder.query({
      query: (page) => `/teachers/classrooms?page=${page}`,
    }),
    getTeacherClassroomById: builder.query({
      query: (id) => `/teachers/classrooms/${id}/join`,
    }),
    postTeacherClassroom: builder.mutation({
      query: ({ body }) => ({
        url: "/teachers/classrooms",
        method: "Post",
        body,
      }),
    }),
  }),
});

export const {
  useStudentClassroomQuery,
  useGetTeacherClassroomByIdQuery,
  useTeacherClassroomQuery,
  useGetStudentDashboardQuery,
  useStudentModuleQuery,
  usePostTeacherClassroomMutation
} = tutorialApis;
