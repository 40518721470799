import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modules: [],
  loadingModule: null,
  moduleIndex: [0], //first element of the arra
  lessons: [],
  teacherModule: null,
  pdfStatus: "",
  annotatedExam: "",
  aciveAsseesment: null,
  activeAssignment: null,
};

export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setLoadingModule: (state, action) => {
      state.loadingModule = action.payload;
    },
    SetModuleIndex: (state, action) => {
      state.moduleIndex = action.payload;
    },
    setLessons: (state, action) => {
      state.lessons = action.payload;
    },
    setTeacherModule: (state, action) => {
      state.teacherModule = action.payload;
    },
    setPdfStatus: (state, action) => {
      state.pdfStatus = action.payload;
    },
    setAnnotatedExam: (state, action) => {
      state.annotatedExam = action.payload;
    },
    setAciveAsseesment: (state, action) => {
      state.aciveAsseesment = action.payload;
    },
    setActiveAssignment: (state, action) => {
      state.activeAssignment = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     assessmentApis.endpoints.teacherAssessments.matchFulfilled,
  //     (state, { payload }) => {
  //       state.aciveAsseesment = payload.data;
  //     }
  //   );
  // },
});

export const {
  setModules,
  setLoadingModule,
  SetModuleIndex,
  setLessons,
  setTeacherModule,
  setPdfStatus,
  setAnnotatedExam,
  setAciveAsseesment,
  setActiveAssignment,
} = moduleSlice.actions;

export const userModule = (state) => state.module.modules;
export const moduleState = (state) => state.module.loadingModule;
export const selectedSubject = (state) => state.module.moduleIndex;
export const moduleLessons = (state) => state.module.lessons;
export const teacherModule = (state) => state.module.teacherModule;
export const pdfStatus = (state) => state.module.pdfStatus;
export const annotatedExam = (state) => state.module.annotatedExam;
export const aciveAsseesment = (state) => state.module.aciveAsseesment;
export const activeAssignment = (state) => state.module.activeAssignment;

export default moduleSlice;
