import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { selectedSubject, userModule } from "../../../common/moduleSlice";
import { Action, Button, FieldArray } from "../../../common/sharedComponent";
import {
  composeValidators,
  minNum,
  required,
} from "../../../common/sharedComponent/InputValidate";
import { usePostFileUploadMutation } from "../../../common/uploadApi";
import {
  selectUploadProgress,
  setProgress,
} from "../../../common/uploadProgressSlice";
import { isEqual, isUndefined } from "../../../utils";
import { DropComponent } from "../../dashboard/components/upload";
import { academicCalender } from "../../login/authSlice";
import {
  // usePostCbtQuestionsMutation,
  usePostTeacherAssessmentMutation,
  useUpdateTeacherAssessmentMutation,
} from "../api";

export const NewAssessment = ({ edit, editTask, submit }) => {
  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const modules = useSelector(userModule);
  const calender = useSelector(academicCalender);

  const progress = useSelector(selectUploadProgress);

  /* A destructuring assignment that assigns properties of an object to variables. */
  const [postAssessment, { isLoading }] = usePostTeacherAssessmentMutation();

  /* A destructuring assignment that assigns properties of an object to variables. */
  const [fileUpload] = usePostFileUploadMutation();

  /* A destructuring assignment that assigns properties of an object to variables. */
  const [updateAssessment, { isLoading: updating }] =
    useUpdateTeacherAssessmentMutation();

  /* Getting the current session from the academic calender. */
  const sessions = calender.filter((item) => item.status === "Active");
  const academicOptions = sessions.map((session) => ({
    id: session.id,
    name: session.name,
  }));

  const option = [
    { id: 1, name: "Take Home" },
    { id: 2, name: "CBT" },
  ];

  const SelectMenu = [
    {
      name: "type_id",
      type: "select",
      placeholder: "Assessment Type",
      validate: required,
      containerStyles: " mt-6",
      label: "Assessment Type",
      style: "lg:col-span-1",
      option: option,
    },
    {
      name: "module_id",
      type: "select",
      placeholder: "Select Module",
      validate: required,
      containerStyles: " mt-6",
      label: "Select Module",
      style: "lg:col-span-1",
      option: modules,
    },
    {
      name: "academic_calender_id",
      type: "select",
      placeholder: "Academic Calendar",
      validate: required,
      containerStyles: " mt-6",
      label: "Academic Calendar",
      style: "lg:col-span-1",
      option: academicOptions,
    },
  ];

  const Fields = [
    {
      name: "total_score",
      type: "number",
      label: "Total Mark",
      validate: composeValidators(required, minNum(10)),
      style: "lg:col-span-2",
    },
    {
      name: "available_at",
      type: "date",
      label: "Available At",
      validate: required,
      style: "lg:col-span-2",
    },
    {
      name: "submission_date",
      type: "date",
      label: "Submission Date",
      validate: required,
      style: "lg:col-span-2",
    },
  ];

  const Details = [
    {
      name: "total_questions",
      type: "number",
      label: "Total Question",
      validate: composeValidators(required, minNum(10)),
      style: "lg:col-span-2",
    },
    {
      name: "total_questions_answerable",
      type: "number",
      label: "Total Answerable Question",
      validate: composeValidators(required, minNum(5)),
      style: "lg:col-span-3",
    },
    {
      name: "total_score",
      type: "number",
      label: "Total Mark",
      validate: required,
      style: "lg:col-span-1",
    },
  ];

  const Duration = [
    {
      name: "duration",
      type: "number",
      label: "Duration",
      validate: required,
    },
    {
      name: "start_time",
      type: "time",
      label: "Start Time",
      validate: required,
    },
  ];

  const Dates = [
    {
      name: "start_date",
      type: "date",
      label: "Start Date",
      validate: required,
    },
    {
      name: "end_date",
      type: "date",
      label: "End Date",
      validate: required,
    },
  ];

  /**
   * It handles the submission of the form
   * @param values - This is the object that contains all the values of the form.
   */
  const onSubmit = async (values) => {
    const {
      type_id,
      start_time,
      total_questions_answerable,
      total_questions,
      duration,
      module_id,
      academic_calender_id,
      submission_date,
      total_score,
      available_at,
      file_url,
      start_date,
      end_date,
    } = values;

    if (
      isEqual(type_id, "1") ||
      (isEqual(edit, 1) && isEqual(editTask.assessment_type, "Take Home"))
    ) {
      //take home
      try {
        let resultUrl;
        if (file_url) {
          // new upload
          let formData = new FormData();

          formData.append("file", file_url[0]);

          resultUrl = await fileUpload({ body: formData }).unwrap();

          !isUndefined(resultUrl?.error) &&
            addToast(resultUrl?.error.data?.message, { appearance: "error" });

          isEqual(resultUrl?.status, "success") && dispatch(setProgress(0));
        }
        if (
          isEqual(resultUrl?.status, "success") ||
          !isUndefined(editTask.file)
        ) {
          isEqual(resultUrl?.status, "success") &&
            addToast("File uploaded successfully", { appearance: "success" });

          if (isEqual(edit, 1)) {
            const data = {
              academic_calender_id,
              module_id: editTask?.module_id,
              total_score,
              available_at,
              file_url: resultUrl?.data?.file_url || editTask?.file,
              file_type: "file",
              submission_date,
              assessment_type: "take home",
            };

            const res = await updateAssessment({
              body: data,
              id: editTask?.id,
            });

            if (res?.error) {
              addToast(res?.error.data?.message, { appearance: "error" });
            } else {
              addToast("Assessment Succefully Updated", {
                appearance: "success",
              });
              submit();
            }
          } else {
            const data = {
              academic_calender_id,
              module_id,
              total_score,
              available_at,
              file_url: resultUrl?.data?.file_url,
              file_type: "file",
              submission_date,
              assessment_type: "take home",
            };

            const res = await postAssessment({ body: data });

            if (res?.error) {
              addToast(res?.error.data?.errors.submission_date[0], {
                appearance: "error",
              });
            } else {
              addToast("Assessment Succefully Posted", {
                appearance: "success",
              });
              submit();
            }
          }
        }
      } catch (e) {
        addToast("An error occurred, please try again later", {
          appearance: "error",
        });
      }
    }

    // cbt submit
    if (type_id === "2" || (edit === 1 && editTask.assessment_type === "CBT")) {
      //cbt
      if (edit === 1) {
        const data = {
          academic_calender_id,
          start_time,
          total_questions_answerable,
          total_questions,
          duration,
          module_id: editTask?.module_id,
          total_score,
          start_date,
          end_date,
          assessment_type: "cbt",
        };
        try {
          const res = await updateAssessment({ body: data, id: editTask?.id });
          if (res?.error) {
            addToast(res?.error.data?.message, { appearance: "error" });
          } else {
            addToast("Assessment Succefully Posted", { appearance: "success" });
            submit();
          }
        } catch (e) {
          addToast("An error occurred, please try again later", {
            appearance: "error",
          });
        }
      } else {
        const data = {
          academic_calender_id,
          start_time,
          total_questions_answerable,
          total_questions,
          duration,
          module_id,
          total_score,
          start_date,
          end_date,
          assessment_type: "cbt",
        };
        try {
          const res = await postAssessment({ body: data });
          if (res?.error) {
            addToast(res?.error.data?.message, { appearance: "error" });
          } else {
            addToast("Assessment Succefully Posted", { appearance: "success" });
            submit();
          }
        } catch (e) {
          addToast("An error occurred, please try again later", {
            appearance: "error",
          });
        }
      }
    }
  };

  return (
    <>
      <>
        <Form
          className="w-2/3 mx-auto"
          onSubmit={onSubmit}
          initialValues={edit === 1 && editTask}
          render={({
            handleSubmit,
            submitting,
            values,
            hasValidationErrors,
            form,
          }) => (
            <form
              onSubmit={async (event) => {
                await handleSubmit(event);
                form.reset();
              }}
            >
              <>
                {edit !== 1 && (
                  <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-3 lg:space-y-0">
                    <FieldArray field={SelectMenu} />
                  </div>
                )}
                {(values.type_id === "1" ||
                  (edit === 1 && editTask.assessment_type === "Take Home")) && (
                  <>
                    <Field
                      component={DropComponent}
                      name="file_url"
                      validate={edit !== 1 && required}
                      types={"application/pdf"}
                      text="Drop the PDF file here"
                    />
                    <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-6 lg:space-y-0">
                      <FieldArray field={Fields} />
                    </div>
                  </>
                )}
                {(values.type_id === "2" ||
                  (edit === 1 && editTask.assessment_type === "CBT")) && (
                  <>
                    <div className="flex  flex-col space-y-5 lg:mt-5  lg:grid  lg:gap-3 lg:grid-cols-6 lg:space-y-0">
                      <FieldArray field={Details} />
                    </div>
                    <div className="flex  flex-col space-y-5 lg:mt-5  lg:flex-row  lg:space-x-3 lg:space-y-0">
                      <FieldArray field={Duration} />
                    </div>
                    <div className="flex  flex-col space-y-5 lg:mt-2  lg:flex-row  lg:space-x-3 lg:space-y-0">
                      <FieldArray field={Dates} />
                    </div>
                  </>
                )}
              </>
              {(values.type_id === "2" ||
                values.type_id === "1" ||
                edit === 1) && (
                <div className={`flex justify-end mt-5`}>
                  {progress !== 0 ? (
                    <ProgressBar
                      className="w-40"
                      bgColor="#006B5D"
                      // barContainerClassName="bg-red"
                      completed={progress}
                    />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      shadow="sm"
                      isLoading={edit === 1 ? updating : isLoading}
                      disabled={submitting || hasValidationErrors || isLoading}
                    >
                      {edit === 1 ? "Update Assessment" : "Submit Assessment"}
                    </Button>
                  )}
                </div>
              )}
            </form>
          )}
        />
      </>
    </>
  );
};
