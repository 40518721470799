import { useDispatch, useSelector } from "react-redux";
import {
  userModule,
  moduleState,
  SetModuleIndex,
  selectedSubject,
} from "../../../common/moduleSlice";
import { TeacherPanel } from "../components";

const Teacher = () => {
  const dispatch = useDispatch();
  const subjects = useSelector(userModule);
  const isLoading = useSelector(moduleState);
  const selectSubject = useSelector(selectedSubject);

  const loadSubject = (index) => {
    dispatch(SetModuleIndex(index));
  };
  return (
    <>
      <TeacherPanel
        subjects={subjects}
        selectSubject={selectSubject}
        loadSubject={loadSubject}
        isLoading={isLoading}
      />
    </>
  );
};

export default Teacher;
