/* eslint-disable no-sequences */
import { HTTPS_VARIABLES_RESPONSE } from "../common/variables";
import { enrolledModulesTypes, recentLessonTypes } from "./types";

/**
 * returns a new list of modules based on the select value
 * @param {array} modules
 * @param {object} value
 * @param {array} options
 * @returns
 */
export const getSelectedModule = (modules, value, options) => {
  return modules?.filter((item) => {
    return item;
  });
};

/**
 * returns true if the module id matches with the active id.
 * @param {object} module
 * @param {object} active
 * @returns {boolean}
 */
export const getTabModules = (module, active) => {
  let module_id = module?.module_id ?? module?.id;
  return module_id === active?.id;
};

/**
 * returns array of selected key word in array of object
 * @param {object[]} arr
 * @param {string} selector
 * @returns {array}
 */
export const getArray = (arr = [], selector) => {
  return arr.reduce((a, o) => (o[selector] && a.push(o[selector]), a), []);
};

/**
 * returns a callback value of the pdf url or data.
 * @param {array} pdfFiles
 * @param {function} callback
 */
export const retrievePdfFile = (pdfFiles = [], callback) => {
  pdfFiles.forEach((file) => {
    // Initialize FileReader browser API
    const reader = new FileReader();

    reader.onload = function (e) {
      callback(e.target.result);
    };
    reader.readAsDataURL(file);
  });
};

/**
 * returns the list of enrolled modules filtered out from the api.
 * else returns an empty array
 * @param {object} data
 * @returns {enrolledModulesTypes[]}
 */
export const getEnrolledModules = (data) =>
  data?.data?.currently_enrolled_modules ?? [];

/**
 * returns an array of recent lessons filtered out from the api
 * else returns empty array
 * @param {object} data
 * @returns {recentLessonTypes[]}
 */
export const getRecentLesson = (data) => data?.data?.recent_lesson ?? [];

export const findModule = (modules, id) =>
  modules?.data?.find((module) => module?.modules[0]?.id === id);

export const findTeacher = (modules, id) =>
  modules?.data?.find((module) => module?.teacher_id === id);

export const teacherModuleAllocation = "/teachers/modules-allocation";

/**
 * it downloads from the uri
 * @param {string} uri
 * @param {object} filename
 */
export const saveAs = (uri, filename) => {
  let link = document.createElement("a");
  if (typeof link.download === "string") {
    document.body.appendChild(link);
    link.download = filename;
    link.href = uri;
    link.click();
    document.body.removeChild(link);
  } else {
    window.location.replace(uri);
  }
};

/**
 * returns a reduced lenght of string based on the
 * number passed
 * @param {*} str
 * @param {*} num
 * @returns
 */
export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const truncateMiddleString = (fullStr, strLen, separator) => {
  if (fullStr?.length <= strLen) return fullStr;

  separator = separator || "...";

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr?.substr?.(0, frontChars) +
    separator +
    fullStr?.substr?.(fullStr.length - backChars)
  );
};

/**
 * returns the initial letters from a string passed.
 * @param {string} name
 * @returns {string}
 */
export const getNemaInitials = (name = "") => {
  let name_part = name.split(" ");

  return name_part[1]
    ? name_part[0].charAt(0).toUpperCase() +
        name_part[1].charAt(0).toUpperCase()
    : name_part[0]
    ? name_part[0].charAt(0).toUpperCase()
    : "HC";
};

/**
 * Filters out the current user from the array.
 * @param {array} arr
 * @param {string} currentUserId
 * @returns {array}
 */
export const RemoveCurrentUser = (arr, currentUserId) => {
  return arr.filter((item) => item?.id !== currentUserId);
};

/**
 * returns true if the id passed matches with the currentUserId.
 * @param {string} id
 * @param {string} currentUserId
 * @returns {boolean}
 */
export const isCurrentUser = (id, currentUserId) => currentUserId === id;

let argNames = ["video/mp4", "video/3gp", "audio/mp3", "video/mp4"];

export const getFileExtension = (file = "") => {
  let file_url_split = file.type.split("/");
  let data = { type: file_url_split[0], format: file_url_split[1] };
  return data;
};

/**
 * returns the percent of completed upload progress.
 * @param {object} progressEvent
 * @returns
 */
export const getCompletedProgressStatus = (progressEvent, callback) => {
  const percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  callback(percentCompleted);
};

export const groupBy = (arr = [], key = "") => {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * customize the flow of error data parsed.
 * @param {object | string} error
 * @param {any} componentError
 * @param {{ options: {}, errorKeys: [] }} config
 */
export const perseCustomError = (error, componentError, config) => {
  let possibleErrorKeys = config?.errorKeys
    ? ["message", ...config?.errorKeys]
    : ["message"];
  let componentOptions = config?.options
    ? { appearance: "error", ...config?.options }
    : { appearance: "error" };

  if (typeof error !== "string" && typeof error?.data !== "undefined") {
    let errorKeys = possibleErrorKeys[Symbol.iterator]();
    for (let errorKey of errorKeys) {
      error?.data[errorKey] &&
        componentError(error?.data[errorKey], componentOptions);
    }
  } else if (typeof error === "string") {
    componentError(error, componentOptions);
  } else if (
    typeof error !== "string" &&
    !error?.data?.message &&
    error?.status
  ) {
    componentError(HTTPS_VARIABLES_RESPONSE[error?.status], componentOptions);
  } else {
    componentError(
      "An error occurred, please try again later",
      componentOptions
    );
  }
};

/**
 * This function trigers the download of pdf file.
 * it accept the file url and filename.
 * @param {string} url
 * @param {string} filename
 */
export const handleDownloadFile = (url, filename) => {
  let link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "blank";
  link.dispatchEvent(new MouseEvent("click"));
};

/**
 * It takes a number of seconds and returns an object with the hours, minutes, and seconds
 * @param value - The value to be converted.
 * @returns { hours, minutes, seconds }
 */
export function convertHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02

  return { hours, minutes, seconds };
}

/**
 * It takes a number of milliseconds and returns the number of minutes
 * @param millis - The number of milliseconds to convert to minutes.
 * @returns The number of minutes in the given milliseconds.
 */
export function millisToMinutes(millis) {
  let minutes = Math.floor(millis / 60000);
  return minutes;
}
