export const LOGIN_ROUTE = "/";
export const FORGET_PASSWORD = "forget_password";
export const REGISTER = "register";
export const TUTORIAL_ROUTE = "/dashboard/tutorial";
export const ASSIGNMENT_ROUTE = "/dashboard/assignments";
export const STUDENT_ASSIGNMENT_ROUTE = "/dashboard/student_assignment";
export const TEACHER_LOGIN_ROUTE = "teacher";
export const ASSESSMENT_ROUTE = "/dashboard/assessments";
export const LESSONS_ROUTE = "/dashboard/lessons";
export const EXAMINATION_ROUTE = "/dashboard/examinations";
export const MESSAGE_INBOX_ROUTE = "/dashboard/inbox";
export const RESULT_ROUTE = "/dashboard/result";
export const NOTIFICATION_ROUTE = "/dashboard/notifications";
export { default as ProtectedRoutes } from "./ProtectedRoutes";
export { default as Protected } from "./Protected";
