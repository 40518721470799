import { AuthLayout } from "../../common/sharedComponent/layout";
import { Content } from "./component";

const ForgetPassword = () => {
  return (
    <AuthLayout>
      <Content />
    </AuthLayout>
  );
};

export default ForgetPassword;
