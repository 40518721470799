import { Link } from "react-router-dom";
import { FieldArray, Form, Typography } from "../../../common/sharedComponent";
import { required } from "../../../common/sharedComponent/InputValidate";
import { LOGIN_ROUTE } from "../../../routes";
import { useForgotPasswordMutation } from "../../login/authApi";
import { useToasts } from "react-toast-notifications";

const Content = () => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const { addToast } = useToasts();

  const onSubmit = async (values) => {
    try {
      let result = await forgotPassword(values);

      result?.error &&
        addToast(result?.error?.data?.message, { appearance: "error" });

      result?.data &&
        addToast(result?.data?.data?.message, { appearance: "success" });
    } catch (error) {
      addToast("An error occurred, please try again later", {
        appearance: "error",
      });
    }
  };

  const Fields = [
    {
      name: "username",
      type: "text",
      label: "Email or Username",
      validate: required,
    },
  ];
  return (
    <div className="w-[90%] sm:w-[60%] pt-20 mx-auto">
      <div className="bg-[#D9D9D9]/[20%]  pb-8">
        <div className="flex w-full bg-[#C50D00] text-center justify-center py-8 border-[#C50D00] border-t-2 !border-b-0 rounded-md">
          <Link to={`/`}>
            <Typography color="white" variant="display">
              Welcome to HillCross
            </Typography>
          </Link>
        </div>
        <Typography color="white" variant="h6" className="text-center my-10">
          Forget your account password?
        </Typography>
        <Form
          btnStyles="mt-12"
          buttonText="Reset Password"
          btnClassName="bg-[#C50D00]"
          isLoading={isLoading}
          onSubmit={onSubmit}
        >
          <div className=" w-4/5 mx-auto lg:w-2/3">
            <FieldArray field={Fields} />
          </div>
        </Form>
        {/* <Typography variant="small" className="text-center my-5 lg:my-7">
        Not Registered Yet?{" "}
        <Link to={`/${REGISTER}`} className="text-blue hover:text-red">
          Create an Account
        </Link>
      </Typography> */}
        <div className="text-center">
          <Typography variant="body2" className="my-5 lg:my-7">
            <Link
              to={LOGIN_ROUTE}
              className="text-[#191C1B] hover:text-[#C50D00]"
            >
              Back to Login Page
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Content;
