import { ACTIONKINDS, initialState } from "./constants";

export const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case ACTIONKINDS.SHOW_MODAL:
      return { ...state, showModal: !state.showModal };
    case ACTIONKINDS.ACTIVE_SUBJECT:
      return { ...state, activeSubject: action?.payload };
    case ACTIONKINDS.SINGLE_VIEW:
      return { ...state, singleView: !state.singleView };
    case ACTIONKINDS.SELECTED_EXAM:
      return { ...state, selectedExam: action?.payload };
    case ACTIONKINDS.UPLOAD_FILES:
      return { ...state, uploadFiles: action?.payload };
    case ACTIONKINDS.REMOVE_UPLOAD_FILES:
      let newUploadList = state.uploadFiles.filter(
        (item) => item.name !== action.payload
      );
      return { ...state, uploadFiles: newUploadList };
    default:
      return state;
  }
};
