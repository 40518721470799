import { ComingSoon } from "../../assets";
import { Typography } from '../../common/sharedComponent';


const ComingSoonComponent = () => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="grid place-items-center">
        <div className="h-60 w-60">
          <img src={ComingSoon} className="h-full w-full" />
        </div>
        <Typography className="text-center mt-2">
          It's under construction, please check back later
        </Typography>
      </div>
    </div>
  );
};

export default ComingSoonComponent;