import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { currentUser, role } from "../../../../features/login/authSlice";
import { Avatar, Typography } from "../..";
import { ChevronDownIcon, MenuIcon } from "@heroicons/react/solid";
import { TEACHER_ROLE } from "../../../variables";
import { reset } from "../../../../features/login/authSlice";
import { useLogOutQuery } from "../../../../features/login/authApi";
import hillcross from "../../../../assets/hillcross.png";
import SignOut from "../../../../assets/sign_out.png";

const DashboardHeader = ({ onMenu, onSideNav }) => {
  const user = useSelector(currentUser);
  const userRole = useSelector(role);

  const [requstLogout, setRequstLogout] = useState(true);
  const dispatch = useDispatch();
  const { refetch } = useLogOutQuery(undefined, {
    skip: requstLogout,
  });

  const handleLogOut = async () => {
    setRequstLogout(false);
    await refetch();
    dispatch(reset());
    setRequstLogout(true);
  };

  return (
    <div className="flex flex-col font-inter">
      <div
        className={
          userRole === TEACHER_ROLE
            ? "flex bg-no-repeat bg-cover bg-center bg-dashboardHeader w-full h-[25vh] lg:h-[35vh] items-center justify-between lg:justify-end text-white shadow overflow-hidden"
            : "flex bg-no-repeat bg-cover bg-center bg-dashboardHeader w-full h-[25vh] lg:h-[35vh] items-center justify-between lg:justify-end text-white shadow overflow-hidden"
        }
      >
        <div className="bg-[#006B5D]/[77%] backdrop-brightness-50 w-full h-full flex flex-col justify-between">
          {/* {userRole === TEACHER_ROLE && <TeacherHeader />} */}
          <div className="flex flex-row justify-between items-center gap-x-3 mx-16 ml-20 mt-20">
            <MenuIcon onClick={onSideNav} className="h-8 w-9 -ml-8 lg:hidden" />
            <div className="hidden lg:block w-28 h-8">
              <img
                src={hillcross}
                alt="HillCross Logo"
                className="w-full h-full"
              />
            </div>
            <div className="flex flex-row space-x-4 items-center">
              <div className="cursor-pointer group">
                <div
                  onClick={() => handleLogOut()}
                  className="block border-transparent border-l-4 h-8 w-9"
                >
                  <img
                    src={SignOut}
                    alt={`Sign Out`}
                    className={`w-full h-full object-cover rounded-full`}
                  />
                </div>
              </div>

              <div
                className="flex flex-row items-center space-x-4  cursor-pointer  mr-5"
                onClick={onMenu}
              >
                <div className=" h-9 w-9">
                  <Avatar
                    name={user?.profile?.avatar}
                    avatarAlt={user?.avatar_alternative}
                    sizes="10"
                  />
                </div>
                <Typography
                  style={{ color: "#FFFFFF" }}
                  {...{ className: "hidden lg:block" }}
                >{`${user?.first_name} ${user?.last_name}`}</Typography>
              </div>
            </div>
          </div>

          <div>
            <Typography
              style={{ color: "#FFFFFF" }}
              {...{
                className:
                  "text-center font-[500] text-[24px] leading-[28.73px] sm:font-[600] sm:text-[32px] sm:leading-[38.73px]",
              }}
            >
              Hello, {`${user?.first_name}`}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography
          style={{ color: "#FFFFFF" }}
          {...{
            className:
              "bg-[#1D3930] text-center py-2 font-[500] text-[24px] leading-[28.73px] sm:font-[600] sm:text-[32px] sm:leading-[38.73px]",
          }}
        >
          Welcome to your HillCross portal
        </Typography>
      </div>
    </div>
  );
};

export default DashboardHeader;
