import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseMembers: [],
  selectedChat: null
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setCourseMembers: (state, action) => {
      state.courseMembers = action.payload;
    },
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    }
  },
});

export const {
  setCourseMembers,
  setSelectedChat
} = messageSlice.actions;

export const getCourseMembers = (state) => state.message.courseMembers;
export const getSelectedChat = (state) => state.message.selectedChat;

export default messageSlice.reducer;
