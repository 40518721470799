import { Field } from "react-final-form";
import { Input } from ".";

const InputField = ({ element }) => {
  return (
    <Field name={element.name} validate={element.validate} type={element.type}>
      {({ input, meta }) => (
        <div className={`w-full ${element.style}`}>
          <Input
            {...input}
            type={element.type}
            label={element.label}
            placeholder={element.placeholder}
            styles={element.containerStyles}
            error={meta.error && meta.touched}
            options={element.option}
            textarea={element.textareaStyle}
            rows={element.rows}
            min={element.min}
            className={element.className}
          />
        </div>
      )}
    </Field>
  );
};

export default InputField;
