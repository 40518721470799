import React from "react";
import { DocumentTextIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { Typography } from "../../../common/sharedComponent";
import { ViewPdf } from "../../assignment/ViewPdf";

const View = ({ backOff, annotate, view }) => {
  return (
    <>
      <div
        onClick={backOff}
        className="flex gap-x-2 mb-3 items-center cursor-pointer"
      >
        {" "}
        <ArrowLeftIcon className="h-5 w-5 " />{" "}
        <Typography {...{ capitalize: true }}>Back</Typography>{" "}
      </div>
      <ViewPdf
        id={annotate?.id}
        data={annotate}
        task="examination"
        mark={view}
        student={annotate}
      />
    </>
  );
};

export default View;
