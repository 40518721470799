import { ArrowLeftIcon, SearchIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { annotatedExam } from "../../../common/moduleSlice";
import {
  Action,
  Button,
  GradePDF,
  Modal,
  Table,
  Typography,
} from "../../../common/sharedComponent";
import { handleDownloadFile } from "../../../helpers";
import { isEqual } from "../../../utils";
import { useGetTeacherAssessmentByIdQuery } from "../api";

export const TeacherSingleView = () => {
  /* Setting the state of the component. */
  const [goBack, setGoBack] = useState(true);
  const [marked, setMarked] = useState(false);
  const [student, setStudent] = useState(null);
  const [students, setStudents] = useState([]);
  const [gradeStatus, setGradStatus] = useState(false);
  const [detailedView, setDetailedView] = useState(null);
  const [assessmentView, setAssessmentView] = useState(1);
  const [gradeAssessment, setGradeAssessment] = useState(null);
  const [assessmentStatus, setAssessmentStatus] = useState(false);

  /* Destructuring the `useParams` hook. */
  const {
    state: { assessment: task, id },
  } = useLocation();
  const navigate = useNavigate();

  const downloadAnnotated = (data) => {
    window.open(data.file_url, "_blank");
  };

  /* A selector that is used to get the state of the `annotatedExam` from the redux store. */
  const isAnnotatedPdf = useSelector(annotatedExam);

  /* Destructuring the `useGetTeacherAssessmentByIdQuery` hook. */
  const { submitted, notSubmitted, newMark, isLoading, refetch } =
    useGetTeacherAssessmentByIdQuery(id, {
      selectFromResult: ({ data, isLoading }) => ({
        submitted: data?.submitted_assessments ?? [],
        notSubmitted: data?.not_submitted ?? [],
        newMark: data?.marked_assessment ?? [],
        isLoading,
      }),
    });

  /* An array of objects that is used to create a table. */
  const submittedCol = [
    {
      accessor: "student_name",
    },
    {
      accessor: "student_id",
    },
    {
      accessor: "id",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex space-x-3">
            {/* {task?.assessment_type === "take home" && ( */}
            <>
              <Button
                variant="outlined"
                sizes="fullWidth"
                className="btn text-dark"
                shadow="none"
                capitalize
                onClick={() => {
                  navigate("/dashboard/assessments/pdf", {
                    state: {
                      assessment: row?.original,
                      id: value,
                      isMarked: false,
                    },
                  });
                }}
                // style={{ color: "003d44" }}
              >
                Add annotation
              </Button>
              <Button
                variant="outlined"
                sizes="fullWidth"
                className="btn text-dark"
                shadow="none"
                capitalize
                onClick={() => {
                  setGradStatus(true);
                  setGradeAssessment(row?.original);
                }}
                style={{ color: "003d44" }}
              >
                Add score
              </Button>

              <Button
                variant="outlined"
                sizes="fullWidth"
                className="btn text-dark"
                shadow="none"
                capitalize
                onClick={() => {
                  let fileName = `${row?.original?.module} ${row?.original?.student_name}`;
                  handleDownloadFile(row?.original?.file_url, fileName);
                }}
                style={{ color: "003d44" }}
              >
                Download
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  /* An array of objects that is used to create a table. */
  const notSubmittedCol = [
    {
      accessor: "name",
    },
    {
      accessor: "student_id",
    },
  ];

  /* An array of objects that is used to create a table. */
  const markedcol = [
    {
      accessor: "student_name",
    },
    {
      accessor: "student_id",
    },
    {
      accessor: "student_score",
    },
    {
      accessor: "id",
      Cell: ({ cell: { value, row } }) => {
        return (
          <div className="flex space-x-3">
            <>
              <Button
                variant="contained"
                sizes="sm"
                className="btn text-white"
                shadow="none"
                capitalize
                onClick={() =>
                  navigate("/dashboard/assessments/pdf", {
                    state: {
                      assessment: row?.original,
                      id: value,
                      isMarked: true,
                    },
                  })
                }
              >
                View Annotation
              </Button>
              <Button
                variant="contained"
                sizes="sm"
                className="btn text-white"
                shadow="none"
                capitalize
                onClick={() => downloadAnnotated(row?.original)}
              >
                Download Annotation
              </Button>
            </>
            {/* view student cbt assessment */}
          </div>
        );
      },
    },
  ];

  /* Setting the state of the component. */
  const initialState = {
    hiddenColumns: ["file_url", "student_uuid"],
  };

  const markedList = newMark.map((item) => {
    return {
      student_name: item?.student.name,
      file_url: item?.file_url,
      student_score: item?.score,
      student_id: item?.resource?.student?.student_id,
      student_uuid: item?.resource?.student?.id,
      id: item?.resource?.id,
      total: item?.total,
      score: item?.score,
    };
  });

  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="flex items-center gap-x-2 mb-3 cursor-pointer w-fit"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <Typography {...{}}>Back</Typography>
      </div>

      <Action noButton>Assessments - {task?.module?.name}</Action>

      <div className="flex justify-between items-center mt-3 mb-5">
        <Typography className=" card1 bg-white p-2">
          Number of Students Offering this course -{" "}
          {submitted?.length + notSubmitted?.length}
        </Typography>

        <div>
          <Button
            variant={isEqual(assessmentView, 3) ? "contained" : `outlined`}
            sizes={"sm"}
            onClick={() => {
              setAssessmentView(3);
            }}
            className={
              isEqual(assessmentView, 3)
                ? "text-white mr-2 ml-2"
                : "text-dark border-on-primary-container mr-2 ml-2"
            }
          >
            All Students
          </Button>

          <Button
            variant={isEqual(assessmentView, 1) ? "contained" : `outlined`}
            sizes={"sm"}
            onClick={() => {
              setAssessmentView(1);
            }}
            className={
              isEqual(assessmentView, 1)
                ? "text-white"
                : "text-dark border-on-primary-container mr-2"
            }
          >
            Submitted
          </Button>

          <Button
            variant={isEqual(assessmentView, 2) ? "contained" : `outlined`}
            sizes={"sm"}
            onClick={() => {
              setAssessmentView(2);
            }}
            className={
              isEqual(assessmentView, 2)
                ? "text-white"
                : "text-dark border-on-primary-container ml-2"
            }
          >
            Marked
          </Button>
        </div>
      </div>

      <Table
        {...{
          columns: isEqual(assessmentView, 1)
            ? submittedCol
            : isEqual(assessmentView, 2)
            ? markedcol
            : notSubmittedCol,
          data: isEqual(assessmentView, 1)
            ? submitted
            : isEqual(assessmentView, 2)
            ? markedList
            : notSubmitted,
          isLoading: isLoading,
          initialState,
        }}
      />

      <Modal
        {...{ open: gradeStatus, onClose: () => setGradStatus(!gradeStatus) }}
      >
        <GradePDF
          refetch={() => refetch()}
          annotatedPdf={gradeAssessment}
          close={() => setGradStatus(!gradeStatus)}
        />
      </Modal>
    </div>
  );
};
