import { Pagination } from "react-headless-pagination";

export const CustomPagintion = ({
  currentPage,
  onChangePaginate,
  totalPages,
}) => {
  return (
    <Pagination
      currentPage={currentPage}
      setCurrentPage={onChangePaginate}
      totalPages={totalPages}
      edgePageCount={2}
      middlePagesSiblingCount={2}
      className="inline-flex -space-x-px"
      truncableText="..."
      truncableClassName=""
    >
      <Pagination.PrevButton className="bg-white border border-gray-300  hover:bg-secondary-container hover:text-on-secondary-container ml-0 rounded-l-lg py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-sm leading-5 font-medium text-on-background">
        Previous
      </Pagination.PrevButton>

      <div className="flex items-center justify-center flex-grow">
        <Pagination.PageButton
          activeClassName="bg-blue-50 border border-gray-300 text-on-secondary-container bg-secondary-container py-2 px-3 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
          inactiveClassName="text-opacity-50"
          className="bg-white border border-gray-300 hover:bg-secondary-container hover:text-on-secondary-container py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-sm leading-5 font-medium text-on-background"
        />
      </div>

      <Pagination.NextButton className="bg-white border border-gray-300 hover:bg-secondary-container hover:text-on-secondary-container rounded-r-lg py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-sm leading-5 font-medium text-on-background">
        Next
      </Pagination.NextButton>
    </Pagination>
  );
};
