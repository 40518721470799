import { useLocation } from "react-router-dom";
import { Typography } from ".";

export const BreadCrumb = ({ className, info }) => {
  const location = useLocation();

  let array_pathname = location.pathname.split("/");
  array_pathname.splice(0, 2);

  return (
    <nav
      className={`text-black font-bold lg:mb-4 mb-2 mt-2 ${className}`}
      aria-label="Breadcrumb"
    >
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center lg:hidden">
          <Typography
            // variant="link"
            to={`/dashboard`}>
            Home
          </Typography>
          <svg
            className="fill-current w-3 h-3 mx-1 text-[rgba(124,124,124,1)]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
          </svg>
        </li>
        {array_pathname.map((item, i) => (
          <li key={i} className="flex items-center">
            <Typography
              variant="link"
              to={`/${array_pathname[i]}`}
              className=""
              capitalize
            >
              {item}
            </Typography>
            {(array_pathname.length === i || info) && (
              <svg
                className="fill-current w-3 h-3 mx-1 text-[rgba(124,124,124,1)]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
              </svg>
            )}
          </li>
        ))}
        {info && (
          <li>
            <Typography
              variant="link"
              to="#"
              className="text-[rgba(124,124,124,1)]"
              capitalize
            >
              {info}
            </Typography>
          </li>
        )}
        {/* <li>
          <a href="#" class="text-gray-500" aria-current="page">
            Third Level
          </a>
        </li> */}
      </ol>
    </nav>
  );
};
