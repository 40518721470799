import { useCallback, useEffect, useRef, useState } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
// import DemoDoc from "../../assets/HillCross e-Learning.pdf";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js";

export const Canvas = (props) => {
  const canvasRef = useRef();
  const contextRef = useRef();
  const [isDrawing, setIsDrawing] = useState(false);
  const [pdfSetting, setPdfSetting] = useState({
    currentPage: 1,
    zoom: 1,
  });

  const renderPdf = useCallback((doc) => {
    console.log(doc._pdfInfo.numPages);

    doc.getPage(1).then((page) => {
      let viewport = page.getViewport(1);

      page.render({
        canvasContext: contextRef,
        viewport,
      });
    });
  }, []);

  const render = (pdf) => {
    pdf.getPage(pdfSetting.currentPage).then((page) => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      let viewport = page.getViewport(pdfSetting.zoom);
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      page.render({
        canvasContext: context,
        viewport,
      });
    });
  };

  useEffect(() => {
  
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;

    const context = canvas.getContext("2d");
    context.scale(2, 2);
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.lineWidth = 5;
    contextRef.current = context;
  }, [renderPdf]);

  const startDrawing = ({ nativeEvent }) => {
    let { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  // let loadingTask = getDocument(props.url);

  return (
    <canvas
      ref={canvasRef}
      onMouseDown={startDrawing}
      onMouseUp={finishDrawing}
      onMouseMove={draw}
      {...props}
    />
  );
};
