import { Typography, Button, Input } from "../../common/sharedComponent";
import { Timer, Options } from "./components";
import { XCircleIcon } from "@heroicons/react/solid";
import { useState, useReducer, useEffect } from "react";
import { reducer, QuizActionKind } from "./reducer";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostAnswersMutation } from "./api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const questionPayload = {
  data: [
    {
      id: "93b1dd23-d1eb-4676-945d-5b45210f452a",
      question: "2 + 2 = ?",
      has_options: true,
      options: [
        {
          id: "93b1dd23-de60-4724-9a81-838d9f4e486e",
          option: "6",
        },
        {
          id: "93b1dd23-ddb7-4e12-84b5-32407ffed47b",
          option: "4",
        },
        {
          id: "93b1dd23-df5a-452f-b720-59a04bf57ada",
          option: "1",
        },
        {
          id: "93b1dd23-e0e9-4715-8de6-7f335ee9ed02",
          option: "5",
        },
      ],
    },
    {
      id: "93b1dd62-431a-49bd-9133-d4694b55d075",
      question: "Where are you going",
      has_options: true,
      options: [
        {
          id: "93b1dd62-4de9-498e-b0ee-e9bdcc8117ff",
          option: "Ogun",
        },
        {
          id: "93b1dd62-4d32-413b-ab5a-de435c6010ae",
          option: "Lagos",
        },
      ],
    },
    {
      id: "93b1dd8b-c2aa-416e-9761-ddf55bc9fc06",
      question: "Who is the vice president",
      has_options: true,
      options: [
        {
          id: "93b1dd8b-cee4-41bc-a963-4fdfe285074b",
          option: "Yemi Osinbajo",
        },
        {
          id: "93b1dd8b-cfab-4c24-ab63-fc869a9d1df7",
          option: "Olusegun Obasanjo",
        },
      ],
    },
    {
      id: "93b1dd39-e6a3-42af-90ab-ed4abd39a67e",
      question: "Is this me?",
      has_options: false,
      options: [],
    },
  ],
  extra_data: {
    total_answerable: 20,
    module: "Computer practice (N4)",
    academic_calender: "New Calender",
    start_date: "2022-02-25 12:46:00",
    end_date: "2022-02-25 13:16:00",
    exam_duration_milliseconds: 30000,
  },
};

const initialState = {
  questionIndex: 0,
  answers: [],
  questions: [],
  selectedOption: null,
};

const Quiz = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [postAnswers, { isLoading }] = usePostAnswersMutation();
  const location = useLocation();
  const navigate = useNavigate();

  let start_date_int = new Date(questionPayload.extra_data.start_date)
    .getTime()
    .toString();
  let end_date_int = new Date(questionPayload.extra_data.end_date)
    .getTime()
    .toString();

  const handlePostAnswers = async () => {
    try {
      let result = await postAnswers({
        questions: state.answers,
        examination_id: location?.state?.test_id,
      });
    } catch (error) {}
  };

  let options = {
    title: `End ${questionPayload.extra_data.module} ${location.state?.type}`,
    message: "Are you certain, you want to end it?",
    buttons: [
      {
        label: "End",
        onClick: () => navigate(-1), // TODO: in case server action is required
      },
      {
        label: "Cancel",
        onClick: () => {},
      },
    ],
  };

  const handleAlert = () => confirmAlert(options);

  const handleClick = async () => {
    if (state.questionIndex < state?.questions.length) {
      dispatch({ type: QuizActionKind.INCREASE_QUESTION_INDEX });
    } else {
      await handlePostAnswers();
    }
  };

  useEffect(() => {
    if (state.questions?.length === 0) {
      dispatch({
        type: QuizActionKind.ADD_QUESTIONS,
        payload: questionPayload.data,
      });
    }
  }, [state.questions.length]);

  return (
    <div className="px-3 md:px-10 py-3 bg-gray-400 h-screen">
      <div className="md:h-20 w-full md:flex items-center justify-between lg:px-3 mb-5 lg:mb-0">
        <div className="h-full flex flex-col justify-center">
          <Typography {...{ variant: "headline" }}>
            {questionPayload.extra_data.module}
          </Typography>
          <Typography {...{ variant: "body", className: "mt-1 md:mt-0" }}>
            Total answerable questions{" "}
            {questionPayload.extra_data.total_answerable}
          </Typography>
        </div>
        <div className="flex items-center gap-x-10 mt-3 md:mt-0">
          <Timer {...{ eventTime: start_date_int, endTime: end_date_int }} />
          <div
            onClick={() => handleAlert()}
            className="flex items-center justify-center gap-x-2 cursor-pointer"
          >
            <Typography {...{ variant: "body", className: "text-danger" }}>
              End
            </Typography>
            <XCircleIcon className="h-5 w-5 text-danger" />
          </div>
        </div>
      </div>

      <div className="md:h-[85%] flex flex-col justify-center items-center">
        <Questions
          {...{
            num: state?.questionIndex + 1,
            question: state?.questions[state.questionIndex]?.question,
            hasOptions: state?.questions[state.questionIndex]?.has_options,
            options: state?.questions[state.questionIndex]?.options,
            selected: {
              id: state?.answers[state.questionIndex]?.option_id,
              option: state?.answers[state.questionIndex]?.option,
            },
            setSelected: (value) => {
              // TODO: restructure the payload data
              dispatch({
                type: QuizActionKind.SELECTED_OPTION,
                payload: value,
              });
              dispatch({
                type: QuizActionKind.UPDATE_ANSWER,
                payload: {
                  question_id: state?.questions[state.questionIndex]?.id,
                  option_id: value.id,
                  option: value.option,
                },
              });
            },
          }}
        />

        <div className="flex mt-10 items-center gap-x-5">
          <Button
            {...{
              variant: "outlined",
              capitalize: true,
              className: "text-red",
              shadow: "none",
              disabled: state?.questionIndex === 0,
              onClick: () => {
                dispatch({ type: QuizActionKind.DECREASE_QUESTION_INDEX });
              },
            }}
          >
            {" "}
            previous{" "}
          </Button>
          <Button
            {...{
              variant: "contained",
              capitalize: true,
              className: "text",
              onClick: handleClick,
            }}
          >
            {" "}
            {state?.questionIndex < state?.questions.length ? "next" : "submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Quiz;

const Questions = ({
  question,
  options = [],
  hasOptions,
  num,
  selected,
  setSelected,
}) => {
  return (
    <div className="max-w-md w-96">
      <Typography {...{ variant: "headline" }}>Question {num}</Typography>
      <Typography {...{ variant: "body2", className: "mt-2" }}>
        {question}
      </Typography>

      {hasOptions ? (
        <Options {...{ options, selected, setSelected }} />
      ) : (
        <Input
          {...{
            type: "textarea",
            rows: 4,
            placeholder: "Enter your answer here",
          }}
        />
      )}
    </div>
  );
};
