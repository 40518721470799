import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Action,
  CustomPagintion,
  Tabs,
  Typography,
} from "../../common/sharedComponent";
import { TEACHER_ROLE } from "../../common/variables";
import ExaminationCbt from "../examination/component/ExaminationCbt";
import { currentUser } from "../login/authSlice";
import Content from "./component";
import { EXAMINATION_ROUTE } from "../../routes";

const notificationTabs = [
  // {
  //   id: 1,
  //   name: "Upcoming Events",
  // },
  // {
  //   id: 2,
  //   name: "Announcements",
  // },
  // {
  //   id: 3,
  //   name: "Emergency Notifications",
  // },
  {
    id: 4,
    name: "Notifications",
  },
];

const Notification = ({ children }) => {
  const [activeNotification, setActiveNotification] = useState(null);
  const [showExam, setShowExam] = useState(null);
  const { state } = useLocation();
  const user = useSelector(currentUser);
  const navigate = useNavigate();

  const action = (data) => {
    setShowExam(data);
    navigate(EXAMINATION_ROUTE, { state: data });
  };

  return (
    <>
      {!showExam && (
        <div className="pl-5 pt-4 h-full">
          {/* <Tabs
            {...{
              className: "mb-3 lg:mx-12 mt-10",
              subjects: notificationTabs,
              valueSelector: "name",
              // isLoading,
              selectedSubject: (value) => setActiveNotification(value),
            }}
          /> */}
          <Action noButton>Notification</Action>
          <div className="pb-20">
            {state?.data?.map((item, i) => (
              <Content action={() => action(item?.data)} key={i}>
                <Typography>{item?.data?.module}</Typography>
                <Typography variant={"captionS"}>
                  {item?.data?.message}
                </Typography>
              </Content>
            ))}
            <div className="flex justify-center mt-10 ">
              <CustomPagintion
                {...{
                  currentPage: state?.meta?.current_page,
                  // onChangePaginate,
                  totalPages: state?.meta?.last_page,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {showExam && user === TEACHER_ROLE && <ExaminationCbt />}
    </>
  );
};

export default Notification;
