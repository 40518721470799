import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";

const messagingBaseUrl = "/students/messaging";

export const messagingApis = createApi({
  reducerPath: "messages",
  refetchOnReconnect: true,
  baseQuery: baseQuery(),
  tagTypes: ["Message", "Recipient"],
  endpoints: (builder) => ({
    getRecipients: builder.mutation({
      query: ({ module_id, receiver, user_role }) => ({
        url: `/${user_role}s/messaging/receivers`,
        method: "Post",
        body: {
          module_id,
          receiver_type: receiver,
        },
      }),
      invalidatesTags: ["Recipient"],
    }),
    getPreviousRecipient: builder.query({
      query: ({ page, user_id, role }) =>
        `/${role}s/messaging/?page=${page}&user_id=${user_id}`,
      providesTags: ["Message"],
    }),
    getMessages: builder.query({
      query: ({ id, userRole }) => `${userRole}s/messaging/${id}`,
    }),
    postReply: builder.mutation({
      query: ({ messaging_id, body, user_role }) => ({
        url: `/${user_role}s/messaging/${messaging_id}/reply`,
        method: "Post",
        body,
      }),
      invalidatesTags: ["Message"],
    }),
    createMessageChat: builder.mutation({
      query: ({ receiver, receiver_type, message, user_role }) => ({
        url: `/${user_role}s/messaging`,
        method: "Post",
        body: { receiver, receiver_type, body: message },
      }),
      invalidatesTags: ["Message", "Recipient"],
    }),
  }),
});

export const {
  useGetRecipientsMutation,
  useGetPreviousRecipientQuery,
  useGetMessagesQuery,
  usePostReplyMutation,
  useCreateMessageChatMutation,
} = messagingApis;
