import { useState } from "react";
import { Action, Question } from "../../../common/sharedComponent";
import { useGetStudentAnswerQuery } from "../api";

const Answer = ({ id, name }) => {
  const [page, setPage] = useState(1);
  const { students, loading, meta } = useGetStudentAnswerQuery(
    { id, page },
    {
      selectFromResult: ({ data, isLoading }) => ({
        students: data?.data ?? [],
        loading: isLoading,
        meta: data?.meta,
      }),
    }
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  // console.log(students);

  return (
    <>
      <Action noButton> {name} Answer</Action>
      <div className="mt-6 pb-20">
        <Question
          data={students}
          loading={loading}
          currentPage={page}
          totalPages={meta?.lastpage}
          onChangePaginate={handlePageChange}
          answer
          message={"Answer not available"}
        />
      </div>
    </>
  );
};

export default Answer;
