import {
  EmptyContent,
  PdfViewer,
  Typography,
} from "../../../common/sharedComponent";
import { useGetGradeExaminationQuery } from "../api";

export const StudentSingleView = ({ selectedExam }) => {
  /**
   * Using the useGetGradeExaminationQuery hook to fetch student grade data through the REST API.
   */
  const { gradedExam, isResultLoading, isError, errorMessage } =
    useGetGradeExaminationQuery(
      { type: "examination", resource_id: selectedExam?.annotation_id },
      {
        selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
          gradedExam: data?.data,
          isGradedSuccess: isSuccess,
          isError: isError,
          isResultLoading: isLoading,
          errorMessage: error?.data?.message,
        }),
      }
    );

  return (
    <div>
      {/* <div className={`bg-gray-400 w-full px-3 py-3 items-center`}>
        <Typography {...{ variant: "subheader1", color: "white" }}>
          {selectedExam?.module}
        </Typography>
        {/* <Typography
          {...{ variant: "body2", color: "white", className: "mt-2" }}
        >
          Grade: {selectedExam?.score}
        </Typography> 
      </div> */}
      <div className="h-[30rem] w-full relative pt-1">
        {!isError && gradedExam?.file_url && (
          <PdfViewer
            {...{
              url: gradedExam?.file_url,
              loader: isResultLoading,
              meta: {
                fileName: `Result ${gradedExam?.resource?.module?.only_name}`,
                id: gradedExam?.id,
              },
            }}
          />
        )}
        {isError && (
          <EmptyContent
            message={errorMessage ?? "Your marked pdf isn't available"}
          />
        )}
      </div>
    </div>
  );
};
