import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useStudentPostExaminationMutation } from "../api";
import {
  BreadCrumb,
  CardPlaceholderLoader,
  EmptyContent,
  Typography,
} from "../../../common/sharedComponent";
import "./teachernew.css";
import { ExaminationCard } from "../component";
import { perseCustomError } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { usePostFileUploadMutation } from "../../../common/uploadApi";
import { useToasts } from "react-toast-notifications";

import { useStudentExaminationQuery } from "../api";
import {
  setProgress,
  selectUploadProgress,
} from "../../../common/uploadProgressSlice";
import { initialState } from "../reducer/constants";
import { reducer } from "../reducer";
import {
  setSelectedExam,
  setSingleView,
  toggleModal,
  setUploadFiles,
  setRemoveUploadFiles,
} from "../reducer/actions";
import { StudentSingleView } from "./StudentSingleView";
import { UploadModal } from "../../../common/Layouts";

export const Student = () => {
  // this is a state management.
  const [state, dispatcher] = useReducer(reducer, initialState);

  // retrieve the progress state from redux store.
  const progress = useSelector(selectUploadProgress);

  // this is the redux dispatch function hooks.
  const dispatch = useDispatch();

  // this is a destructured notification hooks.
  const { addToast } = useToasts();

  // this is a react-router-dom location hooks.
  const { state: locationState } = useLocation();

  // this is a simple file upload success state.
  const [fileSuccess, setFileSuccess] = useState(false);

  // this is a state for the response from file upload api.
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  /* Using the usePostFileUploadMutation hook to upload file data through the REST API. */
  const [onFileUpload, { isLoading: isFileUploading }] =
    usePostFileUploadMutation();

  /* Using the useStudentPostExaminationMutation hook to post student examination through the REST API. */
  const [submit, { isLoading: postLoader }] =
    useStudentPostExaminationMutation();

  /* Using the useStudentExaminationQuery hook to fetch student examination data through the REST API. */
  const { examinationList, isExaminationLoading } = useStudentExaminationQuery(
    { exam_id: locationState ? locationState?.id : state.selectedExam?.id },
    {
      selectFromResult: ({ data, isLoading }) => ({
        examinationList: data?.data ?? [],
        isExaminationLoading: isLoading,
      }),
      // pollingInterval: 15000,
    }
  );

  /**
   * This function handles the upload of the selected pdf
   * files to the api hook trigger.
   * @param {object} file
   */
  const handleUploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await onFileUpload({ body: formData }).unwrap();

      if (result?.status !== "success") {
        // returns the upload progress state back to zero.
        dispatch(setProgress(0));
        perseCustomError(result, addToast);
      }

      // updates the success state
      if (result?.status === "success") {
        setFileSuccess(true);
        setFileUploadResponse(result?.data);
        addToast("Solution Uploaded successfully", {
          appearance: "success",
        });
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  /**
   * It takes the uploaded file url and the examination id
   * and sends it to the api hook trigger.
   */
  const handleAssessementSubmission = async () => {
    try {
      // parse the uploaded file url and examamination id to the api.
      let submitResult = await submit({
        examination_id: state.selectedExam?.id,
        file_url: fileUploadResponse?.file_url,
      }).unwrap();

      // if error occured while parsing the above data, show notification.
      submitResult?.error &&
        addToast(submitResult?.error.data?.message, { appearance: "error" });

      // if successfully while parsing the above data, show notification

      if (submitResult?.status === "success") {
        addToast("Examination submitted successfully", {
          appearance: "success",
        });
        // hides the upload modal
        toggleModal(dispatcher);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  // Extracting the neccessary information to display on the ExaminationCard.
  const reform_select_filtered = examinationList?.map((item) => {
    return {
      id: item?.id,
      module: item?.module?.only_name,
      start_time: item?.start_time,
      duration: item?.duration,
      file_url: item?.file_url,
      available_at: item?.available_at,
      exam_start_date: item?.exam_start_date,
      exam_end_date: item?.exam_end_date,
      duration_left: item?.duration_left,
      is_over: item?.is_over,
      exam_type: item?.examination_type,
      status: item?.status,
      score: item?.score,
      can_write: item?.can_write,
      annotation_id: item?.annotation_id,
      submission_file: item?.submission_file,
      has_annotated: item?.has_annotated,
      has_started: item?.has_started,
    };
  });

  /**
   * This function trigers the download of pdf file.
   * it accept the file url and filename.
   * @param {string} url
   * @param {string} filename
   */
  const handleDownload = (url, filename) => {
    let link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.target = "blank";
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div>
      {state.singleView ? (
        <div
          onClick={() => setSingleView(dispatcher)}
          className="flex gap-x-2 mb-3 items-center cursor-pointer"
        >
          {" "}
          <ArrowLeftIcon className="h-5 w-5 " />{" "}
          <Typography {...{ capitalize: true }}>back</Typography>{" "}
        </div>
      ) : (
        <BreadCrumb />
      )}

      {!state.singleView && (
        <div className={``}>
          <div className="pt-2">
            <div className="mb-3 text-center p-2  ml-2 mr-6 bg-white">
              <h1>All available examinations are shown below</h1>
            </div>
          </div>
          {!state.singleView && !isExaminationLoading && (
            <div className="grid lg:grid-cols-3 grid-rows-3 gap-x-20 gap-y-4 lg:gap-y-10 w-full mb-20 pt-3 pb-20 overflow-auto scrollbar-hide lg:h-98 lg:pr-0 pr-5 lg:pb-0">
              {!state.singleView &&
                reform_select_filtered?.map((exam, index) => (
                  <ExaminationCard
                    {...{
                      key: index,
                      exam,
                      state,
                      onDownload: handleDownload,
                      onUpload: () => {
                        setSelectedExam(dispatcher, exam);
                        toggleModal(dispatcher);
                      },
                      onGradedAssignment: () => {
                        setSelectedExam(dispatcher, exam);
                        setSingleView(dispatcher);
                      },
                    }}
                  />
                ))}
            </div>
          )}
          {isExaminationLoading && <CardPlaceholderLoader />}
          {!isExaminationLoading && reform_select_filtered?.length === 0 && (
            <div className="h-72">
              <EmptyContent message="No Examination Yet, Select another module" />
            </div>
          )}
        </div>
      )}

      {state.singleView && (
        <StudentSingleView {...{ selectedExam: state.selectedExam }} />
      )}

      <UploadModal
        {...{
          progress,
          open: state.showModal,
          uploadSuccess: fileSuccess,
          uploadFiles: state.uploadFiles,
          submitButtonLoader: postLoader,
          onClose: () => toggleModal(dispatcher),
          moduleName: state.selectedExam?.module,
          onSubmit: handleAssessementSubmission,
          onUpload: (item) => handleUploadFile(item),
          onRemove: (item) => setRemoveUploadFiles(dispatcher, item?.name),
          onAcceptedFiles: (value) => setUploadFiles(dispatcher, value),
          submitButtonDisabled:
            state?.uploadFiles?.length === 0 || isFileUploading,
        }}
      />
    </div>
  );
};
