import {
  Typography,
  Tabs,
  Modal,
  SubjectLabel,
  Button,
  SelectInput,
  Table,
} from "../../../common/sharedComponent";
import {
  StudentPanel,
  StudentDashboardTutorials,
  StudentDashboardAssignment,
} from "../components";
import { useState } from "react";
import { TutorialIcon, AssignmentIcon, InboxIcon } from "../../../assets";
import {
  useGetStudentAssignmentsQuery,
  useGetStudentDashboardQuery,
  useGetStudentModulesQuery,
} from "../api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  currentUser,
  enrolledModules,
  enrollableModules,
} from "../../login/authSlice";
import {
  getArray,
  getEnrolledModules,
  getRecentLesson,
  getRecentLessonFile,
  getTabModules,
} from "../../../helpers";
import { useToasts } from "react-toast-notifications";

const ActionData = [
  {
    id: "1",
    icon: TutorialIcon,
    title: "Lessons",
    link: "/dashboard/lessons",
    // desc: "2 New Tutorials",
  },
  {
    id: "2",
    icon: AssignmentIcon,
    title: "Assignments",
    link: "/dashboard/assignments",
    // desc: "3 New Tutorials",
  },
  {
    id: "3",
    icon: InboxIcon,
    title: "Inbox",
    link: "/dashboard/inbox",
    // desc: "3 New Tutorials",
  },
];

const MobilePanel = ({ actions }) => {
  const navigate = useNavigate();
  return (
    <div className="pr-5 lg:hidden">
      {actions.map((item) => (
        <div
          key={item.id}
          onClick={() => navigate(item?.link)}
          className="h-[105px] w-full bg-red rounded-[4px] flex items-center px-10 mb-3 cursor-pointer"
        >
          <div className=" flex-1">
            <img alt="" src={item.icon} className="h-[62px] w-[62px]" />
          </div>
          <div className="flex-1">
            <Typography
              {...{
                variant: "display",
                color: "white",
                className: "font-normal",
              }}
            >
              {item.title}
            </Typography>
            <Typography {...{ className: "mt-2", color: "white" }}>
              {item.desc}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

const columns = [
  {
    Header: "Name",
    accessor: "only_name",
  },
  {
    Header: "Semester",
    accessor: "semester",
  },
  {
    Header: "Standard",
    accessor: "",
  },
  {
    Header: "Pass Status",
    accessor: "start_time",
  },
];

export const StudentDashboard = () => {
  const { addToast } = useToasts();
  const [activeSubject, setActiveSubject] = useState(null);
  const [page, setPage] = useState(1);
  const [enrollModal, setEnrollModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const user = useSelector(currentUser);
  const enrolledUserModules = useSelector(enrolledModules);
  const enrollableUserModules = useSelector(enrollableModules);
  const { assignmentPayloads, isAssignmantLoading } =
    useGetStudentAssignmentsQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        assignmentPayloads: data?.data ?? [],
        isAssignmantLoading: isLoading,
      }),
    });
  const { moduleResult, meta, isResultLoading } = useGetStudentModulesQuery(
    { page },
    {
      selectFromResult: ({ isLoading, data }) => ({
        moduleResult: data?.data ?? [],
        isResultLoading: isLoading,
        meta: data?.meta,
      }),
    }
  );
  const { recentModules, subjects, isLoading } = useGetStudentDashboardQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => {
        return {
          recentModules: getRecentLesson(data),
          subjects: getEnrolledModules(data),
          isLoading,
        };
      },
    }
  );

  let filtered_modules = recentModules?.filter((module) =>
    getTabModules(module, activeSubject)
  );

  let filtered_assignments = assignmentPayloads?.filter((module) =>
    getTabModules(module?.module, activeSubject)
  );

  let enrolledList = getArray(enrolledUserModules, "id");

  const handleModulesReset = () => {
    setEnrollModal(!enrollModal);
    setSelectedModules([]);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="w-full pb-10">
      <StudentPanel
        studentName={user?.name}
        className="mt-5"
        studentId={user?.student_id}
        avatarUrl={user?.profile?.avatar}
        onEnrollModule={() => setEnrollModal(!enrollModal)}
        modules={enrolledUserModules}
        onResult={() => setResultModal(!resultModal)}
        isLoading={isLoading}
      />

      {/* <Modal
        {...{
          open: resultModal,
          onClose: () => setResultModal(!resultModal),
        }}
      >
        <div className="">
          <Table
            {...{
              columns,
              data: moduleResult,
              isLoading: isResultLoading,
              currentPage: page,
              totalPages: meta?.total,
              onChangePaginate: handlePageChange,
            }}
          />
        </div>
      </Modal> */}
    </div>
  );
};
