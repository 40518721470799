import { Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { Fragment } from "react"
import { EmptyContent, Typography } from "../../../common/sharedComponent"
import { UserPanelPlaceholderLoader } from '../../messaging/components';
import { useGetSingleLessonsQuery } from '../api'


export const LessonMaterial = ({ open, setOpen, tutorial }) => {
  const { isLoading, data, } = useGetSingleLessonsQuery(tutorial?.id);
  
  return (
    <Transition
      as={Fragment}
      show={open}
      enter="transition-all ease-in duration-1000"
      enterFrom="transform translate-x-full"
      enterTo="transform translate-x-0"
      leave="transition-all ease-out duration-1000"
      leaveFrom="transform translate-x-0"
      leaveTo="transform translate-x-full"
    >
      <div class="flex flex-col h-96 bg-white shadow-lg rounded lg:w-1/3  md:w-1/2 w-full p-5 mx-auto absolute top-10 right-0 overflow-auto">
        <div className="flex justify-between items-center">
          <Typography {...{ variant: 'subheader2', className: 'my-4 mt-3 cursor-pointer', capitalize: true }}>Lesson Materials</Typography>
          <XIcon
            onClick={() => setOpen(!open)}
            className="h-[16px] w-[16px]"
          />
        </div>
        {isLoading && <UserPanelPlaceholderLoader />}
        {data?.course_materials.length === 0 && <EmptyContent message="No materials" />}
        {/* <div class="odd:bg-gray-50 flex gap-3 items-center font-semibold text-gray-800 p-3 hover:bg-gray-100 rounded-md hover:cursor-pointer">
          <img class="w-10 h-10 rounded-full" src="https://randomuser.me/api/portraits/women/24.jpg" alt="Rebecca Burke" />
            <div class="flex flex-col">
                <div>
                    Rebecca Burke
                </div>
                <div class="text-gray-400 text-sm font-normal">
                    +1(227)-691-8675
                </div>
            </div>
        </div> */}

      </div>
    </Transition>
  )
}