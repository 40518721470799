import { Typography } from ".";
import MessagesPNG from "../../assets/lesson/messages.png";
import Button from "./Button";

function LessonTabs({ selectedSubject, className, subjects, isLoading }) {
  const handleSelectedTab = (value) => {
    selectedSubject(value);
  };
  return (
    <div
      className={`grid grid-cols-1 gap-4 lg:grid-cols-4 mb-20  mx-2 sm:mx-4 lg:mx-0 grid-flow-row ${className} `}
    >
      {isLoading && <TabLoader />}
      {subjects.length < 1 && !isLoading && (
        <Typography className={"w-32"}>No Module Allocated</Typography>
      )}

      {subjects.length >= 1 &&
        subjects.map((subject) => (
          <div class="border-white border-2 bg-[#F6F6F6] rounded-lg flex flex-col justify-between min-h-fit">
            <div className="px-3 pt-5 pb-3 flex flex-col space-y-3 w-full">
              <Typography
                variant={"body"}
                style={{
                  color: "#006B5D",
                }}
                {...{
                  className: "font-[400] text-[16px] leading-[21.28px]",
                }}
              >
                {subject?.semester?.name ?? "First Semester"}
              </Typography>
              <Typography
                variant={"headlineS"}
                style={{
                  color: "#006B5D",
                }}
                {...{
                  className: "font-[600] text-[20px] leading-[26.6px]",
                }}
              >
                {subject?.name ?? ""}
              </Typography>
            </div>
            <div className="w-full">
              <img
                src={MessagesPNG}
                className={`ml-auto mr-4 mb-5 w-6 h-6 p-1 bg-[#006B5D] rounded-full`}
                alt="messages"
              />
              <Button
                {...{
                  capitalize: true,
                  variant: "contained",
                  className: "text-white !w-full p-2",
                  //   sizes: "md",
                  shadow: "none",
                  onClick: () => handleSelectedTab(subject),
                }}
              >
                {"Click to view Lessons "}
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
}

export const TabLoader = () => {
  return [1, 2, 3, 4].map((item) => (
    <div
      key={item}
      className="h-32 w-32 mx-auto bg-gray-200 animate-pulse rounded-md my-10"
    ></div>
  ));
};

export default LessonTabs;
