import React from "react";

function StudentLists({ students }) {
  return (
    <div className="w-full bg-white overflow-auto p-5 text-[#006B5D]">
      {students.map((student, index) => (
        <div className="flex space-x-10">
          <span>{index + 1}.</span>
          <span className="">{student.id}</span>
          <span className="capitalize">{student.name}</span>
        </div>
      ))}
    </div>
  );
}

export default StudentLists;
