import { useState } from "react";
import {
  Action,
  ListStudents,
  Search,
  Tabs,
} from "../../../common/sharedComponent";
import { Assignment } from ".";

const Content = () => {
  const [detailedView, setDetailedView] = useState(false);
  const [detailedPageView, setDetailedPageView] = useState(null);
  const status = [
    { id: 1, only_name: "Submitted By" },
    { id: 2, only_name: "Not Submitted" },
  ];
  const selectStatus = (id) => {
    // console.log(id);
  };

  const students = [
    {
      image: "avatar",
      name: "Rachael Lebsack",
      date: "Tue Jun 04 2058 04:17:23 GMT+0500",
    },
    {
      image: "avatar",
      name: "Rachael Lebsack",
      date: "Tue Jun 04 2058 04:17:23 GMT+0500",
    },
    {
      image: "avatar",
      name: "Rachael Lebsack",
      date: "Tue Jun 04 2058 04:17:23 GMT+0500",
    },
    {
      image: "avatar",
      name: "Rachael Lebsack",
      date: "Tue Jun 04 2058 04:17:23 GMT+0500",
    },
    {
      image: "avatar",
      name: "Rachael Lebsack",
      date: "Tue Jun 04 2058 04:17:23 GMT+0500",
    },
  ];

  const searchResult = (values) => {
    // console.log(values);
  };

  const seePage = (id) => {
    // console.log(id);
    setDetailedView(id);
  };

  return (
    <div className="w-full scrollbar-hide">
      {!detailedView && (
        <>
          <Action noButton> Student Assignment</Action>
          <Tabs
            subjects={status}
            selectedSubject={selectStatus}
            isLoading={false}
          />
          <hr className=" bg-slate-100" />
          <div className="hidden lg:block mt-5 mb-3">
            <Search action={searchResult} />
          </div>
          <ListStudents
            students={students}
            firstColTitle="student name"
            secondColTitle="submission date/time"
            thirdColTitle="Assignments"
            seeDetails={seePage}
            assignment={true}
          />
        </>
      )}
      {detailedView && <Assignment />}
    </div>
  );
};

export default Content;
