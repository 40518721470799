import { Button, Typography } from ".";
import { useMediaQuery } from "../../hooks";

const Action = ({
  children,
  uploadAction,
  liveAction,
  noUpload,
  noLive,
  noButton,
  btnText,
}) => {
  const isMobileView = useMediaQuery("(max-width: 640px)");
  return (
    <div className="flex flex-col-reverse w-full space-y-5 lg:space-y-0 lg:flex-row lg:justify-between  lg:items-end">
      <Typography
        variant="headlineS"
        color="defaultBody"
        className="pt-4 lg:pt-0 lg:mt-2"
      >
        {children}
      </Typography>
      {!noButton && (
        <div
          className={`flex flex-col lg:flex-row space-y-5 lg:space-x-10  lg:space-y-0 pr-5 lg:pr-0 ${
            noLive ? "lg:w-40" : "lg:w-60"
          }`}
        >
          {!noLive && (
            <Button
              sizes={isMobileView ? "fullWidth" : "sm"}
              variant="contained"
              onClick={liveAction}
            >
              Go Live
            </Button>
          )}
          {!noUpload && (
            <Button
              variant="contained"
              sizes={isMobileView ? "fullWidth" : "sm"}
              onClick={uploadAction}
              capitalize
            >
              {btnText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Action.defaultProps = {
  btnText: "Upload",
};

export default Action;
