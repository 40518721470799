import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../../../common/baseQuery";

const teacher = `/teachers/examinations`;

export const examinationApis = createApi({
  reducerPath: "examinations",
  refetchOnReconnect: true,
  tagTypes: ["studentExaminations"],
  baseQuery: baseQuery((headers) => {
    return headers.set("accept", "application/json");
  }),
  endpoints: (builder) => ({
    getStudentDashboard: builder.query({
      query: () => "/students/dashboard",
    }),
    studentExamination: builder.query({
      query: () => `/students/examinations?status=not-end`,
      providesTags: ["studentExaminations"],
    }),
    submittedExamination: builder.query({
      query: ({ examination_id, student_id }) =>
        `/teachers/examinations/${examination_id}/students/${student_id}`,
    }),
    allSubExamination: builder.query({
      query: ({ examination_id }) =>
        `/teachers/examinations/${examination_id}?status=not-end`,
      providesTags: ["documentAnnotation"],
    }),

    studentExamQuestion: builder.query({
      query: ({ examination_id }) =>
        `/students/examinations/${examination_id}/questions`,
    }),
    teacherExaminations: builder.query({
      query: (page) => `${teacher}?page=${page}&module-all&status=not-end`,
      providesTags: ["examination"],
    }),

    postTeacherExamination: builder.mutation({
      query({ body }) {
        return { url: teacher, method: "POST", body };
      },
      invalidatesTags: ["examination"],
    }),

    studentPostExamination: builder.mutation({
      query: ({ examination_id, file_url }) => ({
        url: `/students/examinations/${examination_id}/answer`,
        method: "Post",
        body: {
          file_type: "pdf",
          file_url,
        },
      }),
      invalidatesTags: ["studentExaminations"],
    }),
    postExamCbtQuestions: builder.mutation({
      query: ({ body, id }) => ({
        url: `${teacher}/${id}/questions`,
        body,
        method: "Post",
      }),
      invalidatesTags: ["examination"],
    }),
    getExamCbtQuestions: builder.query({
      query: ({ id, page }) =>
        `${teacher}/${id}/questions?page=${page}&status=not-end`,
      providesTags: ["examination"],
    }),
    updateTeacherExamination: builder.mutation({
      query: ({ body, id }) => ({
        method: "PATCH",
        body,
        url: `${teacher}/${id}`,
      }),
      invalidatesTags: ["examination"],
    }),
    getSubmittedExam: builder.query({
      query: ({ id, page }) =>
        `${teacher}/${id}/students?page=${page}&status=not-end`,
    }),
    getStudentAnswer: builder.query({
      query: ({ exam, student }) => `${teacher}/${exam}/students/${student}`,
    }),
    deleteExamination: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `${teacher}/${id}`,
      }),
    }),
    getGradeExamination: builder.query({
      query: ({ type, resource_id }) =>
        `/document-annotations/students/${type}/resources/${resource_id}`,
    }),
    getAcademicCalender: builder.query({
      query: () => "/academic-calenders",
    }),
  }),
});

export const {
  useGetGradeExaminationQuery,
  useStudentExaminationQuery,
  useAllSubExaminationQuery,
  useStudentExamQuestionQuery,
  useSubmittedExaminationQuery,
  useGetStudentDashboardQuery,
  useTeacherExaminationsQuery,
  usePostTeacherExaminationMutation,
  useStudentPostExaminationMutation,
  usePostExamCbtQuestionsMutation,
  useGetExamCbtQuestionsQuery,
  useUpdateTeacherExaminationMutation,
  useGetSubmittedExamQuery,
  useGetStudentAnswerQuery,
  useDeleteExaminationMutation,
  useAllExaminationQuery,
  useGetAcademicCalenderQuery,
} = examinationApis;
