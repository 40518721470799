import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import {
  CardPlaceholderLoader,
  CustomPagintion,
  EmptyContent,
  Typography,
} from ".";

export const Question = ({
  data,
  loading,
  currentPage,
  totalPages,
  onChangePaginate,
  answer,
  message,
  assessmentId,
  editCbt,
  deleteCbt,
}) => {
  return (
    <>
      {loading && <CardPlaceholderLoader {...{ style: "grid-rows-1 mb-5" }} />}
      {!loading && !data?.length && (
        <div className="h-64">
          <EmptyContent message={message} />
        </div>
      )}
      {!loading && data?.length > 0 && (
        <>
          {data
            .map(({ question, has_options, options, id, mark }, i) => (
              <div class="sm:px-6 lg:px-8 pb-5 mb-3" key={i}>
                <div class="overflow-hidden shadow-md">
                  <div class="px-6 py-4 bg-gray-200 border-b border-gray-100 font-bold">
                    <div className="flex justify-between items-center">
                      <Typography>
                        Question: {answer ? question.question : question}
                      </Typography>
                      {assessmentId && (
                        <div className="flex space-x-3">
                          <PencilIcon
                            className="w-6 h-6 cursor-pointer"
                            onClick={() =>
                              editCbt(
                                id,
                                question,
                                has_options,
                                mark,
                                options,
                                assessmentId
                              )
                            }
                          />
                          <TrashIcon
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => deleteCbt(id, assessmentId)}
                          />
                        </div>
                      )}{" "}
                    </div>
                  </div>
                  {has_options &&
                    !answer &&
                    options.map(({ option, is_correct_answer }, i) => (
                      <div
                        class={`flex justify-between items-center p-6 bg-white border-b border-gray-200 ${
                          i % 2 !== 0 && "bg-gray-50"
                        }`}
                        key={i}
                      >
                        <Typography>{option}</Typography>
                        <input
                          type="radio"
                          readOnly
                          checked={is_correct_answer}
                          id={is_correct_answer}
                        />
                      </div>
                    ))}
                  {answer &&
                    question.has_options &&
                    question.options.map(({ option, is_correct_answer }, i) => (
                      <div
                        class={`flex justify-between items-center p-6 bg-white border-b border-gray-200 ${
                          i % 2 !== 0 && "bg-gray-50"
                        }`}
                        key={i}
                      >
                        <Typography>{option}</Typography>
                        <input
                          type="radio"
                          readOnly
                          checked={is_correct_answer}
                          id={is_correct_answer}
                        />
                      </div>
                    ))}

                  {answer && (
                    <Typography className={"py-5 pl-5"}>
                      Score {"mark"} mark(s)
                    </Typography>
                  )}
                </div>
              </div>
            ))
            .reverse()}
          <div className="flex justify-center py-3 border-t">
            <CustomPagintion
              {...{
                currentPage,
                onChangePaginate,
                totalPages,
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
