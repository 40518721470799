import { ACTIONKINDS, initialState } from "./constants";

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONKINDS.TOGGLEMODAL:
      return { ...state, modal: !state.modal };

    case ACTIONKINDS.SINGLEVIEW:
      return { ...state, singleView: !state.singleView };

    case ACTIONKINDS.SELECTEDASSESSNMENT:
      return { ...state, selectedAssessment: payload };

    case ACTIONKINDS.ASSESSMENTS:
      return { ...state, assessments: payload };

    case ACTIONKINDS.UPLOADFILES:
      return { ...state, uploadFiles: payload };

    case ACTIONKINDS.REMOVEUPLOADFILE:
      return { ...state, uploadFiles: [] };

    case ACTIONKINDS.SHOWSINGLEVIEWTYPE:
      return { ...state, showSingleType: payload };

    case ACTIONKINDS.GRADEDASSESSMENT:
      return { ...state, gradedAssessment: payload };
    
    case ACTIONKINDS.SELECTFILTERMODULE:
      return { ...state, selectFilterModule: payload };

      // Trachers 
    

    default:
      return state;
  }
};
