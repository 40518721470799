import {
  CardLayout,
  Typography,
  Button,
} from "../../../common/sharedComponent";
import cover from "../../../assets/hillcross-dark-examination.png";
import {
  convertHMS,
  millisToMinutes,
  truncateMiddleString,
} from "../../../helpers";
import UploadIcon from "../../../assets/upArrow.svg";
import DownloadIcon from "../../../assets/downArrow.svg";
import { useForceUpdater, useTimer } from "../../../hooks";
import { useEffect, useState } from "react";
import moment from "moment";

const Timer = ({ minutes, seconds, hours }) => (
  <div>
    {minutes === 0 && seconds === 0 ? null : (
      <Typography className="!text-[#ED1000] text-[15.3952px] leading-[19px] font-[700] p-2 bg-white mt-2">
        {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    )}
  </div>
);

export const ExaminationCard = ({
  exam,
  state,
  onUpload,
  onDownload,
  onGradedAssignment,
}) => {
  // const hasExamEnded = moment().isAfter(exam?.exam_end_date);

  const [duration, setDuration] = useState(null);

  useEffect(() => {
    let interval = setInterval(() => {
      /* Calculating the time left for the assessment. */
      const currentDate = new moment();
      const endAssessment = new moment(exam?.exam_end_date);

      let duration = moment.duration(endAssessment.diff(currentDate));

      setDuration(duration);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  /* Checking if the exam has started. */
  // const hasStarted = exam.has_started;

  /* Checking if the exam has started. */
  const hasStarted =
    moment().isAfter(exam?.exam_start_date) &&
    moment().isBefore(exam?.exam_end_date);

  /* Converting the duration left in milliseconds to minutes. */
  const examDurationMinutesLeft = millisToMinutes(exam?.duration_left);

  /* Formating duration left from minutes into hours, minutes and seconds */
  const { hours, seconds, minutes } = convertHMS(examDurationMinutesLeft * 60);

  /* A custom hook that forces the component to rerender. */
  const forceRerender = useForceUpdater();

  /* Time countdown hooks and returns the Timer component
   * with an onCompleted function and a boolean.
   */

  // // const { Timer, onCompleted, isCompleted } = useTimer({
  // //   initialHour: hours,
  // //   initialMinute: minutes,
  // //   initialSeconds: seconds,
  // // });
  // if (isCompleted && onCompleted) onCompleted(forceRerender());

  const duration_minutes = parseInt(exam?.duration)
    ? parseInt(exam?.duration) % 60 === 0
      ? ""
      : (parseInt(exam?.duration) % 60) + " minutes"
    : "";
  const hour = exam?.duration ? Math.floor(parseInt(exam?.duration) / 60) : "";
  return (
    <CardLayout
      {...{
        key: exam?.id,
        containerClass:
          "!rounded-[8.79725px] border border-[#006B5D1A]/10 bg-[#F8F8F8]",
        imgContainer:
          "w-full rounded-tl-md rounded-tr-md grid place-items-center relative lg:h-[129.76px] ",
        img: (
          <>
            <img className=" h-full w-full rounded-lg" alt="" src={cover} />
            <div className="absolute bottom-2 left-0 right-0 flex items-center justify-between mx-[25.29px]">
              <Typography
                var
                className="text-[15.3952px] leading-[19px] font-bold !text-white "
              >
                <h1 className="exam_name">
                  {truncateMiddleString(exam?.module ?? "", 23)}
                </h1>
              </Typography>
              {hasStarted && (
                <div className="text-right">
                  <Typography className="text-[12px] mr-2 leading-[10px] font-bold !text-white">
                    Time Left:
                  </Typography>
                  <Timer
                    hours={duration?.hours()}
                    minutes={duration?.minutes()}
                    seconds={duration?.seconds()}
                  />
                </div>
              )}
            </div>
          </>
        ),
        children: (
          <div className="w-full mt-5">
            {!state.singleView && (
              <>
                <div
                  style={{ marginTop: "70px" }}
                  className="flex w-full items-center justify-between px-[11px] mt-[9.9px]"
                >
                  <Button
                    shadow="none"
                    className={`h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px]  text-[12px] leading-[15px] flex items-center justify-center ${
                      !hasStarted ? "!text-[#006B5D]/60" : "!text-[#006B5D]"
                    }`}
                    onClick={() => onDownload(exam?.file_url, exam?.module)}
                    disabled={!hasStarted}
                  >
                    <img
                      src={DownloadIcon}
                      alt="upload"
                      className={`h-3 w-3 mr-[6.59px] ${
                        !hasStarted ? "opacity-60" : ""
                      }`}
                    />
                    Download
                  </Button>
                  {!exam?.has_annotated && (
                    <Button
                      shadow="none"
                      className={`h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px] text-[12px] leading-[15px] flex items-center justify-center  ${
                        !hasStarted ? "!text-[#006B5D]/60" : "!text-[#006B5D]"
                      }`}
                      onClick={onUpload}
                      disabled={!hasStarted}
                    >
                      <img
                        src={UploadIcon}
                        alt="upload"
                        className={`h-3 w-3 mr-[6.59px] ${
                          !hasStarted ? "opacity-60" : ""
                        }`}
                      />
                      Upload
                    </Button>
                  )}
                  {exam?.has_annotated && (
                    <Button
                      shadow="none"
                      className={`h-[30.59px] w-[107.32px] bg-[#006B5D1A]/20 rounded-[4.51792px] text-[12px] leading-[15px] flex items-center justify-center !text-[#006B5D] `}
                      onClick={onGradedAssignment}
                    >
                      Check Result
                    </Button>
                  )}
                </div>

                <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row items-start lg:items-center justify-between px-[11px] mt-4 mb-[9.9px]">
                  <div className="flex flex-col space-y-4 justify-start items-start">
                    <Typography className="typo_text mr-1">
                      Exam Starts At: {exam?.start_time}
                    </Typography>

                    <Typography className="typo_text mr-1">
                      Exam Ends At: {exam?.exam_end_date}
                    </Typography>
                  </div>

                  <div className="flex flex-col space-y-4 justify-start items-start">
                    <Typography className="typo_text mr-1 capitalize">
                      Status: {exam?.status.replace("_", " ")}
                    </Typography>
                    <div className="flex">
                      <Typography className="typo_text mr-1">
                        Duration:
                      </Typography>

                      <Typography className="typo_text mr-1">
                        {hour
                          ? hour + " hour(s) " + (duration_minutes ?? "")
                          : duration_minutes ?? ""}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ),
      }}
    />
  );
};
