import {
  Button,
  Typography,
  PdfViewer,
  EmptyContent,
} from "../../../common/sharedComponent";
import {
  useGetGradedAssessmentQuery,
  useStudentPostAssessmentMutation,
  useLazyGetGradedAssessmentQuery,
} from "../api";
import { useMediaQuery } from "../../../hooks";
import Moment from "react-moment";
import { useState } from "react";
import { usePostFileUploadMutation } from "../../../common/uploadApi";
import { useToasts } from "react-toast-notifications";
import {
  selectUploadProgress,
  setProgress,
} from "../../../common/uploadProgressSlice";
import { useDispatch, useSelector } from "react-redux";
import { perseCustomError } from "../../../helpers";
import { UploadModal } from "../../../common/Layouts";
import {
  setUploadFiles,
  setRemoveUploadFiles,
  setGradedAssessment,
} from "../reducer/action";

export const StudentSingleView = ({
  assessment,
  state,
  dispatcher,
  optionsData = [],
  selectFilter,
}) => {
  /**
   * A custom media query hook, for checking a particular screen size.
   */
  const isMobileView = useMediaQuery("(max-width: 640px)");

  /**
   * A react-toast-notification hook, for triggering a notification display.
   */
  const { addToast } = useToasts();

  const dispatch = useDispatch();

  // this is a state for upload modal.
  const [modal, setModal] = useState(false);

  // this is a simple file upload success state.
  const [fileSuccess, setFileSuccess] = useState(false);

  // this is a state for the response from file upload api.
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  // retrieve the progress state from redux store.
  const progress = useSelector(selectUploadProgress);

  /**
   * Using the useStudentPostAssessmentMutation hook to trigger a post of student assessment data through the REST API.
   */
  const [onSubmit, { isLoading }] = useStudentPostAssessmentMutation();

  // Using the usePostFileUploadMutation hook to upload file data through the REST API.
  const [onFileUpload, { isLoading: isFileUploading }] =
    usePostFileUploadMutation();

  /**
   * Using the useGetGradeAssignmentQuery hook to fetch student grade data through the REST API.
   */
  // const [onGetGradedAssessment, { isLoading }] = useLazyGetGradedAssessmentQuery();
  const { gradedAssessment, isResultLoading, isError, errorMessage } =
    useGetGradedAssessmentQuery(
      { type: "assessment", resource_id: assessment?.annotation_id },
      {
        selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
          gradedAssessment: data?.data,
          isGradedSuccess: isSuccess,
          isError: isError,
          isResultLoading: isLoading,
          errorMessage: error?.data?.message,
        }),
        skip: selectFilter === optionsData[0]?.name,
      }
    );

  /**
   * This function handles the upload of the selected pdf
   * files to the api hook trigger.
   * @param {object} file
   */
  const handleUpload = async (file) => {
    let data = new FormData();
    try {
      data.append("file", file);
      let result = await onFileUpload({ body: data }).unwrap();

      if (result?.status !== "success") {
        // returns the upload progress state back to zero.
        dispatch(setProgress(0));
        perseCustomError(result, addToast);
      }

      // updates the success state
      if (result?.status === "success") {
        dispatch(setProgress(0));
        setFileSuccess(true);
        setFileUploadResponse(result?.data);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  /**
   * It takes the uploaded file url and the assignment id
   * and sends it to the api hook trigger.
   */
  const handleAssessmentSubmission = async () => {
    try {
      // parse the uploaded file url and assessment id to the api.
      let submitResult = await onSubmit({
        assessment_id: state.selectedAssessment?.id,
        file_url: fileUploadResponse?.file_url,
      }).unwrap();

      // if error occured while parsing the above data, show notification.
      submitResult?.error &&
        addToast(submitResult?.error.data?.message, { appearance: "error" });

      // if successfully while parsing the above data, show notification
      if (submitResult?.data?.id) {
        addToast("Assessment submitted successfully", {
          appearance: "success",
        });

        // hides the upload modal
        setModal(false);
      }
    } catch (error) {
      perseCustomError(error, addToast);
    }
  };

  // const handleGradedAssessment = async () => {
  //   try {
  //     const result = await onGetGradedAssessment().unwrap();

  //     result?.data && setGradedAssessment(result?.data);
  //   } catch (error) {
  //      perseCustomError(error, addToast);
  //   }
  // }

  return (
    <div className="pb-10 pr-5">
      <div
        className={`bg-gray-400 w-full px-3 py-3 lg:flex justify-between items-center`}
      >
        <div>
          <Typography {...{ variant: "headline", color: "white" }}>
            {assessment?.module?.only_name}
          </Typography>
          {selectFilter === optionsData[2]?.name && (
            <Typography
              {...{ variant: "body2", color: "white", className: "mt-2" }}
            >
              Grade: {assessment?.score}
            </Typography>
          )}
          {selectFilter === optionsData[0]?.name && (
            <Typography
              {...{ variant: "caption", color: "white", className: "mt-2" }}
            >
              Deadline:{" "}
              <Moment format="LLL">{assessment?.submission_date}</Moment>
            </Typography>
          )}
          {selectFilter === optionsData[1]?.name && (
            <Typography
              {...{ variant: "caption", color: "white", className: "mt-2" }}
            >
              Submitted:{" "}
              <Moment format="LLL">{assessment?.date_submitted}</Moment>
            </Typography>
          )}
        </div>
        <div className="mt-5">
          {selectFilter === optionsData[0]?.name && (
            <a
              href={assessment?.file_url}
              rel="noreferrer"
              target="_blank"
              className="capitalize text-on-background mr-3"
            >
              download file
            </a>
          )}
          {selectFilter === optionsData[0]?.name && (
            <Button
              {...{
                variant: "contained",
                className: "text-white rounded mt-3 lg:mt-0",
                sizes: isMobileView ? "fullWidth" : "sm",
                onClick: () => setModal(!modal),
                // disabled: assessment?.has_submitted,
                disabled:
                  new Date(
                    assessment?.submission_date.split?.(" ")[0] + " 23:59:00"
                  ) > new Date()
                    ? ""
                    : "disabled",
                shadow: "none",
                uppercase: true,
              }}
            >
              upload Solution
            </Button>
          )}
          {/* {isGradedSuccess && (
            <Typography
              {...{
                variant: "body2",
                color: "white",
                className: "mt-2 cursor-pointer uppercase",
                onClick: () => serShowResult(!showResult),
              }}
            >
              {showResult ? "close" : "View result"}
            </Typography>
          )} */}
        </div>
      </div>
      <div className="h-[30rem] w-full relative pt-1">
        {selectFilter === optionsData[0]?.name && (
          <PdfViewer
            {...{
              url: assessment?.file_url,
              meta: {
                fileName: assessment?.module?.only_name,
                id: assessment?.id,
              },
            }}
          />
        )}
        {selectFilter === optionsData[1]?.name && (
          <PdfViewer
            {...{
              url: assessment?.submitted_file,
              meta: {
                fileName: assessment?.module?.only_name,
                id: assessment?.id,
              },
            }}
          />
        )}
        {selectFilter === optionsData[2]?.name &&
          !isError &&
          gradedAssessment?.file_url && (
            <PdfViewer
              {...{
                url: gradedAssessment?.file_url,
                loader: isResultLoading,
                meta: {
                  fileName: `Result ${gradedAssessment?.resource?.assessment?.module?.only_name}`,
                  id: gradedAssessment?.id,
                },
              }}
            />
          )}
        {selectFilter === optionsData[2]?.name && isError && (
          <EmptyContent
            message={errorMessage ?? "Your marked pdf isn't available"}
          />
        )}
      </div>

      <UploadModal
        {...{
          progress,
          open: modal,
          uploadSuccess: fileSuccess,
          uploadFiles: state.uploadFiles,
          moduleName: assessment?.module?.only_name,
          submitButtonLoader: isLoading || isFileUploading,
          onUpload: (item) => handleUpload(item),
          onClose: () => setModal(!modal),
          onSubmit: handleAssessmentSubmission,
          onRemove: (item) => setRemoveUploadFiles(dispatcher, item?.name),
          onAcceptedFiles: (value) => setUploadFiles(dispatcher, value),
          submitButtonDisabled:
            state?.uploadFiles?.length === 0 || isFileUploading,
        }}
      />
    </div>
  );
};
