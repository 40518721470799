import { useEffect } from "react";
import {
  EmptyContent,
  Modal,
  SelectInput,
  Typography,
} from "../../../common/sharedComponent";
import { STUDENT_ROLE, TEACHER_ROLE } from "../../../common/variables";
import { RemoveCurrentUser } from "../../../helpers";
import { useGetRecipientsMutation } from "../api";
import { UserPanel, UserPanelPlaceholderLoader } from "../components";
import { useToasts } from "react-toast-notifications";
import { MessageActionKind } from "../types";
import { useSelector } from 'react-redux';
import { teacherModule } from '../../../common/moduleSlice';

export const NewChatDialog = ({
  onClose,
  open,
  state,
  user,
  userRole,
  enrolled_modules = [],
  dispatch,
  recipientData,
  handleChatSelection,
}) => {
  const [onFetchRecipient, { isLoading }] = useGetRecipientsMutation();
  const { addToast } = useToasts();
  const teacherModules = useSelector(teacherModule);

  useEffect(() => {
    let abortController = new AbortController();

    let recipientFetch = async () => {
      try {
        let result = await onFetchRecipient({
          module_id: state.module?.id,
          receiver:
            userRole === TEACHER_ROLE ? "Student" : state.recipient?.only_name,
          user_role: userRole?.toLowerCase(),
        }).unwrap();

        let filtered_contacts = RemoveCurrentUser(result?.data, user?.id);

        result?.data &&
          dispatch({
            type: MessageActionKind.ADD_CONTACTS,
            payload: filtered_contacts,
          });

        return () => abortController.abort();
      } catch (error) {
        error?.error.data?.message && addToast(error?.error.data?.message, {
          appearance: "error",
        });
      }
    };

    // To prevent the function from being called on Feature mount.
    if (open) {
      recipientFetch();
    }

    return () => abortController.abort();
  }, [
    open,
    state.recipient?.only_name,
    state.module?.id,
    addToast,
    dispatch,
    onFetchRecipient,
    user?.id,
    userRole,
  ]);

  return (
    <Modal
      {...{
        open,
        onClose,
        width: "full",
        hideCloseButton: true,
        modalPaperClass: "md:mr-72",
      }}
    >
      <div className="md:h-[30rem] h-[45rem] md:w-[20rem] overflow-hidden pb-36">
        <div className="py-3 pb-1 px-4 flex justify-between items-center">
          <Typography {...{ variant: "headlineS" }}>New message</Typography>
          <Typography
            {...{
              variant: "body2",
              className: "cursor-pointer",
              onClick: onClose,
            }}
          >
            close
          </Typography>
        </div>
        <div className={`px-2 pt-1 `}>
          <SelectInput
            options={
              userRole !== TEACHER_ROLE
                ?  enrolled_modules
                : teacherModules?.modules
            }
            onchange={(value) =>
              dispatch({
                type: MessageActionKind.CHANGE_MODULE,
                payload: value,
              })
            }
          />
        </div>

        <ul id="tabs" className="inline-flex w-full px-1 pt-2 mb-3">
          {recipientData.map((item) => (
            <li
              key={item?.id}
              onClick={() =>
                dispatch({
                  type: MessageActionKind.CHANGE_RECIPIENT_TYPE,
                  payload: item,
                })
              }
              className={`px-4 py-2 -mb-px font-semibold rounded-t ${
                item.only_name === "Teacher" && userRole !== STUDENT_ROLE
                  ? "hidden"
                  : ""
              } cursor-pointer ${
                state.recipient?.id === item?.id
                  ? "border-primary-container border-b-2 opacity-100 text-primary-container"
                  : "opacity-50 border-b text-gray-800"
              }`}
            >
              <Typography>{item?.only_name}s</Typography>
            </li>
          ))}
        </ul>

        <div className="h-full w-full overflow-auto px-2">
          {!isLoading &&
            state?.newContacts?.length !== 0 &&
            state?.newContacts.map((item) => (
              <UserPanel
                {...{
                  name: item?.name,
                  // selected: item?.id === selectedChat,
                  avatar: item?.avatar,
                  avatarAlt: item?.avatar_alternative,
                  onClick: () =>
                    handleChatSelection({ ...item, isNewChatMessage: true }),
                }}
              />
            ))}
          {isLoading && <UserPanelPlaceholderLoader />}
          {!isLoading && state?.newContacts.length === 0 && (
            <EmptyContent message="No members" />
          )}
        </div>
      </div>
    </Modal>
  );
};
