import { Avatar, Typography } from "../../../common/sharedComponent";

export const UserPanel = ({ selected, name, avatar, avatarAlt, onClick, message }) => {
  return (
    <div
      className={`flex flex-row py-2 px-2 gap-x-3 justify-center items-center border-b-2 cursor-pointer hover:bg-secondary-container/40 group ${
        selected && "bg-secondary-container"
      }`}
      onClick={onClick}
    >
      <div class="w-16 h-12">
        <Avatar
          {...{
            name: avatar,
            avatarAlt
          }}
        />
      </div>
      <div className="w-full">
        <div className="font-semibold">
          <Typography
            {...{
              className: `group-hover:text-on-secondary-container ${
                selected && "text-on-secondary-container"
              } `,
            }}
          >
            {name}
          </Typography>
        </div>
        <Typography
          variant="span"
          className={`group-hover:text-on-secondary-container text-sm ${
            selected && "text-on-secondary-container"
          }`}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
};

export const UserPanelPlaceholderLoader = () => {
  return [1, 2, 3, 4].map((item) => (
    <div
      key={item}
      className="h-16 w-full bg-gray-200 animate-pulse my-1"
    ></div>
  ));
};
